import { pipe, tap } from 'wonka';
import { Exchange } from 'urql';

/**
 * Copied from urql's debugExchange, but using `console.debug()` instead of `console.log()`.
 *
 * https://github.com/FormidableLabs/urql/blob/3276a24fff7687fe2b154a191cbbf80819943026/packages/core/src/exchanges/debug.ts
 */

const debugExchange: Exchange = ({ forward }) => {
  if (process.env.NODE_ENV === 'production') {
    return (ops$) => forward(ops$);
  } else {
    return (ops$) =>
      pipe(
        ops$,
        tap((op) =>
          console.debug('[Exchange debug]: Incoming operation: ', op)
        ),
        forward,
        tap((result) =>
          console.debug('[Exchange debug]: Completed operation: ', result)
        )
      );
  }
};

export default debugExchange;
