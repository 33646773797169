// List of unique names that can be associated with parameters in an emissions
// model to indicate what type of simulation each supports.
export const SIMULATION_PARAMETER_TYPES = [
  // See definition in https://www.notion.so/watershedclimate/Material-taxonomy-1e36fd26916e44c5b31f27a3f3cc1e18
  'ws_raw_material',
  'tier_1_manufacturing_clean_power_percent',
  'tier_2_manufacturing_clean_power_percent',
  'tier_3_manufacturing_clean_power_percent',
  'tier_4_manufacturing_clean_power_percent',
  'tier_1_manufacturing_location_country',

  // Deprecated
  'higg_material_name',
] as const;

export type SimulationParameterType =
  (typeof SIMULATION_PARAMETER_TYPES)[number];

// Deprecated simulation param types that should not be used in new models.
// Kept around because some old models reference them already and will not load
// if removed.
export const deprecatedSimulationParamTypes = new Set<SimulationParameterType>([
  'higg_material_name',
]);
