import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';
import { SupportedLocale, LocalizationEnv } from '@watershed/intl/constants';

interface MessageLoadingOptions extends WatershedErrorOptions {
  locale?: SupportedLocale;
  env?: LocalizationEnv;
}

// Thrown when we fail to load the translations for internationalized strings
export class MessageLoadingError extends WatershedError {
  status = 500;
  env?: LocalizationEnv;
  locale?: SupportedLocale;

  constructor(message?: string, options?: MessageLoadingOptions) {
    super('MESSAGE_LOADING_ERROR', message, {
      ...options,
      data: {
        ...options?.data,
        locale: options?.locale,
        localizationEnv: options?.env,
      },
    });
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(MessageLoadingError);
  static wrapAndRethrow = makeRethrower(MessageLoadingError);
}
