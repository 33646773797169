import { GraphQLScalarType, Kind } from 'graphql';
import { DateTime } from 'luxon';
import { YM, YMInterval } from '../utils/YearMonth';

const scalars = {
  Date: new GraphQLScalarType({
    name: 'Date',
    serialize(value: unknown) {
      if (value instanceof DateTime) {
        return value.toISODate();
      } else if (value instanceof Date) {
        return DateTime.fromJSDate(value).toISODate();
      } else if (typeof value === 'string') {
        return value;
      } else {
        throw new Error(
          `Date GraphQL serialization: Unrecognized value: ${JSON.stringify(
            value
          )}`
        );
      }
    },
    parseValue(value: unknown) {
      if (value instanceof Date) return value;
      if (typeof value === 'string')
        return DateTime.fromFormat(value, 'yyyy-MM-dd').toJSDate();
      throw new Error(
        `Date GraphQL parseValue: Unrecognized value: ${JSON.stringify(value)}`
      );
    },
  }),
  DateTime: new GraphQLScalarType({
    name: 'DateTime',
    serialize(value: unknown) {
      if (value instanceof DateTime) {
        return value.toISO();
      } else if (value instanceof Date) {
        return DateTime.fromJSDate(value).toISO();
      } else if (typeof value === 'string') {
        return value;
      } else {
        throw new Error(
          `DateTime GraphQL serialization: Unrecognized value: ${JSON.stringify(
            value
          )}`
        );
      }
    },
    parseValue(value: unknown): Date {
      if (value instanceof Date) return value;
      if (typeof value === 'string') return new Date(value);

      throw new Error(
        `DateTime GraphQL parseValue: Unrecognized value: ${JSON.stringify(
          value
        )}`
      );
    },
  }),
  YearMonth: new GraphQLScalarType({
    name: 'YearMonth',
    serialize(value: unknown) {
      if (value instanceof Date) {
        return YM.fromJSDate(value);
      }
      if (typeof value === 'number') return YM.fromNumber(value);
      return value as number; // TODO(ankitr): remove
    },
    parseValue(value: unknown) {
      if (typeof value === 'number') return YM.fromNumber(value);
      if (value instanceof Date) return YM.fromJSDate(value);

      throw new Error(
        `YearMonth GraphQL parseValue: Unrecognized value: ${JSON.stringify(
          value
        )}`
      );
    },
    parseLiteral(ast) {
      if (ast.kind === Kind.INT) {
        return YM.fromNumber(parseInt(ast.value, 10));
      }
    },
  }),
  YMInterval: new GraphQLScalarType({
    name: 'YMInterval',
    serialize(value: unknown) {
      if (value instanceof YMInterval) {
        return {
          start: YM.toNumber(value.start),
          end: YM.toNumber(value.end),
        };
      }

      throw new Error(
        `YMInterval GraphQL serialization: Unrecognized value: ${JSON.stringify(
          value
        )}`
      );
    },
    parseValue(value: unknown) {
      if (value instanceof YMInterval) return value;
      if (
        value &&
        typeof value === 'object' &&
        'start' in value &&
        'end' in value &&
        typeof value.start === 'number' &&
        typeof value.end === 'number'
      )
        return new YMInterval(
          YM.fromNumber(value.start),
          YM.fromNumber(value.end)
        );

      throw new Error(
        `YMInterval GraphQL parseValue: Unrecognized value: ${JSON.stringify(
          value
        )}`
      );
    },
    parseLiteral(ast) {
      if (ast.kind === Kind.OBJECT) {
        if (
          ast.fields[0].name.value === 'start' &&
          ast.fields[0].value.kind === Kind.INT &&
          ast.fields[1].name.value === 'end' &&
          ast.fields[1].value.kind === Kind.INT
        ) {
          return new YMInterval(
            YM.fromNumber(parseInt(ast.fields[0].value.value, 10)),
            YM.fromNumber(parseInt(ast.fields[1].value.value, 10))
          );
        }
        return null;
      }
    },
  }),
};

export default scalars;
