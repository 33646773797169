import { ComponentPropsWithoutRef } from 'react';

/**
 * NOTE: This should only be used for styling Checkbox form controls,
 * not as a general-purpose UI icon. For that, see @link src/icons/Checkbox
 * @returns
 */
export default function CheckmarkIcon({
  fill = 'white',
  ...props
}: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width={11}
      height={11}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5.5L3.5 8.5L8.5 1"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
