import { useEffect } from 'react';
import { useRouter } from 'next/router';
import LoadingPage from './LoadingPage';

/**
 * Convenience for redirecting to a different page and showing a loading page.
 */
export default function Redirect({
  to,
  loadingElement = <LoadingPage />,
  query,
}: {
  to: string;
  query?: URLSearchParams;
  loadingElement?: React.ReactNode;
}) {
  const router = useRouter();
  useEffect(
    () => {
      // Parse the URL to avoid clobbering query params in the `to` string with the
      // separate `query` parameter.
      // We need to add a fake host because URL requires it if the string we're
      // parsing is a relative path.
      const toUrl = new URL(
        to,
        'https://should-not-be-used.watershedclimate.com'
      );
      const combinedParams = new URLSearchParams({
        ...Object.fromEntries(query?.entries() ?? []),
        ...Object.fromEntries(toUrl.searchParams.entries()),
      });

      void router.replace({
        pathname: toUrl.pathname,
        query: combinedParams.toString(),
        hash: toUrl.hash,
      });
    },
    // `router` is not referentially stable
    // https://github.com/vercel/next.js/discussions/29403#discussioncomment-1908563
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [to, query]
  );
  return <>{loadingElement}</>;
}
