export default function consoleDebug(
  category: string,
  color: string,
  message: string,
  ...args: Array<any>
) {
  console.debug(
    `%c${category}%c ${message}`,
    // CSS to apply to first `%c`
    `font-weight: bold; background-color: ${color}; color: white; border-radius: 2px; padding: 2px 4px`,
    // Emty string to reset CSS style for second `%c`
    '',
    ...args
  );
}
