import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
// eslint-disable-next-line no-restricted-imports
import { Link as MuiLink, LinkProps } from '@mui/material';
import { WatershedLinkProps } from '../types/LinkProps';
import isExternalUrl, { isApiRouteUrl } from '../utils/isExternalUrl';
import { format as formatUrl } from 'url';
import { mixinSx } from '@watershed/style/styleUtils';

type Props = Omit<
  LinkProps,
  | 'to'
  | 'href'
  | 'components'
  | 'componentsProps'
  // We're not using the `tw` experimental Tailwind prop, which is marked as
  // optional in `MuiButtonProps`. But for some reason, when we introduced
  // `next-env.d.ts` to `ui-core`, it started causing this bizarre type error,
  // so we'll just omit it here.
  // More details:
  // https://github.com/watershed-climate/ghg/pull/40050#issuecomment-1673322341
  | 'tw'
> &
  Omit<WatershedLinkProps, 'onClick'> & {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
  };

export default forwardRef<HTMLAnchorElement, Props>(function TextLink(
  {
    href,
    children,
    replace,
    as,
    scroll,
    shallow,
    prefetch,
    locale,
    newTab,
    startIcon,
    endIcon,
    ...linkProps
  }: Props,
  ref
) {
  const hrefString = href ? formatUrl(href) : '';
  const isExternal = href !== undefined && isExternalUrl(hrefString);
  const isApiRoute = href !== undefined && isApiRouteUrl(hrefString);

  const iconSx = (startIcon ?? endIcon) != null && {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  };
  const linkSx = mixinSx(iconSx, linkProps.sx);

  if (href && !newTab && !isExternal && !isApiRoute) {
    return (
      <NextLink
        replace={replace}
        href={href}
        passHref
        as={as}
        scroll={scroll}
        shallow={shallow}
        prefetch={prefetch}
        locale={locale}
        legacyBehavior
      >
        <MuiLink underline="hover" {...linkProps} ref={ref} sx={linkSx}>
          {startIcon}
          {children}
          {endIcon}
        </MuiLink>
      </NextLink>
    );
  }

  return (
    <MuiLink
      href={href ? hrefString : undefined}
      underline="hover"
      {...linkProps}
      {...(newTab ? { target: '_blank' } : {})}
      {...(isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      component={href ? 'a' : 'button'}
      ref={ref}
      sx={linkSx}
    >
      {startIcon}
      {children}
      {endIcon}
    </MuiLink>
  );
});
