import { pipe, onPush } from 'wonka';
import { Exchange } from 'urql';
import { OperationDefinitionNode } from 'graphql';

/*
  A urql exchange that changes the URL for GQL operations to include the operation name.
  eg. /graphql -> /graphql/ActiveOrganization
*/
export const splitUrlByOperationNameExchange: () => Exchange =
  () =>
  ({ forward }) => {
    return (ops$) => {
      const operations = pipe(
        ops$,
        onPush((op) => {
          if (op.kind === 'query' || op.kind === 'mutation') {
            const operationName = (
              op.query.definitions[0] as OperationDefinitionNode
            ).name?.value;
            if (operationName) {
              op.context.url = `/graphql/${operationName}`;
            }
          }
        })
      );
      return forward(operations);
    };
  };
