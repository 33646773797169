import { z } from 'zod';
import { FootprintScope } from './forecast/types';
import assertNever from '@watershed/shared-util/assertNever';
import { GQCleanPowerContractualInstrument } from './generated/graphql';
import { msg } from '@lingui/core/macro';
import { MessageDescriptor } from '@lingui/core';
import { environment } from '@watershed/config/env';

// For when you want that empty array but with referential equality
export const EMPTY_ARRAY: Array<never> = [];

// Scope 1 and 2 do not have any categories like Scope 3 does so we represent
// each scope as a category using the following IDs
export const SCOPE_1_CATEGORY_ID = '1 Scope 1';
export const SCOPE_1_GHG_CATEGORY_IDS = [SCOPE_1_CATEGORY_ID] as const;
export type Scope1GhgCategoryIds = (typeof SCOPE_1_GHG_CATEGORY_IDS)[number];

export const SCOPE_2_CATEGORY_ID = '2 Scope 2';
export const SCOPE_2_GHG_CATEGORY_IDS = [SCOPE_2_CATEGORY_ID] as const;
export type Scope2GhgCategoryIds = (typeof SCOPE_2_GHG_CATEGORY_IDS)[number];

export type Scope1Or2GhgCategoryIds =
  | Scope1GhgCategoryIds
  | Scope2GhgCategoryIds;

export const SCOPE_3_1_ID = '3.1 Purchased goods and services';
export const SCOPE_3_2_ID = '3.2 Capital goods';
export const SCOPE_3_3_ID = '3.3 Fuel and energy related activities';
export const SCOPE_3_4_ID = '3.4 Upstream transportation & distribution';
export const SCOPE_3_5_ID = '3.5 Waste generated in operations';
export const SCOPE_3_6_ID = '3.6 Business travel';
export const SCOPE_3_7_ID = '3.7 Employee commuting';
export const SCOPE_3_8_ID = '3.8 Upstream leased assets';
export const SCOPE_3_9_ID = '3.9 Downstream transportation and distribution';
export const SCOPE_3_10_ID = '3.10 Processing of sold products';
export const SCOPE_3_11_ID = '3.11 Use of sold products';
export const SCOPE_3_12_ID = '3.12 End-of-life treatment of sold products';
export const SCOPE_3_13_ID = '3.13 Downstream leased assets';
export const SCOPE_3_14_ID = '3.14 Franchises';
export const SCOPE_3_15_ID = '3.15 Investments';

export const ALL_GHG_IDS = [
  SCOPE_1_CATEGORY_ID,
  SCOPE_2_CATEGORY_ID,
  SCOPE_3_1_ID,
  SCOPE_3_2_ID,
  SCOPE_3_3_ID,
  SCOPE_3_4_ID,
  SCOPE_3_5_ID,
  SCOPE_3_6_ID,
  SCOPE_3_7_ID,
  SCOPE_3_8_ID,
  SCOPE_3_9_ID,
  SCOPE_3_10_ID,
  SCOPE_3_11_ID,
  SCOPE_3_12_ID,
  SCOPE_3_13_ID,
  SCOPE_3_14_ID,
  SCOPE_3_15_ID,
] as const;
export type GhgId = (typeof ALL_GHG_IDS)[number];

export const SCOPE_3_OTHER_UPSTREAM_ID = 'Other Upstream';
export const SCOPE_3_OTHER_DOWNSTREAM_ID = 'Other Downstream';

export const SCOPE_3_GHG_OFFICIAL_CATEGORY_IDS = [
  SCOPE_3_1_ID,
  SCOPE_3_2_ID,
  SCOPE_3_3_ID,
  SCOPE_3_4_ID,
  SCOPE_3_5_ID,
  SCOPE_3_6_ID,
  SCOPE_3_7_ID,
  SCOPE_3_8_ID,
  SCOPE_3_9_ID,
  SCOPE_3_10_ID,
  SCOPE_3_11_ID,
  SCOPE_3_12_ID,
  SCOPE_3_13_ID,
  SCOPE_3_14_ID,
  SCOPE_3_15_ID,
] as const;

export const GHG_IDS_INCL_UNOFFICIAL = [
  ...ALL_GHG_IDS,
  SCOPE_3_OTHER_UPSTREAM_ID,
  SCOPE_3_OTHER_DOWNSTREAM_ID,
] as const;
export type GhgIdInclUnofficial = (typeof GHG_IDS_INCL_UNOFFICIAL)[number];

// Same as above but includes two additional categories
// for misc. emissions.
export const SCOPE_3_GHG_CATEGORY_IDS = [
  ...SCOPE_3_GHG_OFFICIAL_CATEGORY_IDS,
  SCOPE_3_OTHER_UPSTREAM_ID,
  SCOPE_3_OTHER_DOWNSTREAM_ID,
] as const;
export type Scope3GhgCategoryId = (typeof SCOPE_3_GHG_CATEGORY_IDS)[number];

export const DOWNSTREAM_SCOPE_3_GHG_CATEGORY_IDS = [
  SCOPE_3_3_ID,
  SCOPE_3_9_ID,
  SCOPE_3_10_ID,
  SCOPE_3_11_ID,
  SCOPE_3_12_ID,
  SCOPE_3_13_ID,
  SCOPE_3_14_ID,
  SCOPE_3_15_ID,
  SCOPE_3_OTHER_DOWNSTREAM_ID,
] as const;

// GHG categories expected to be associated with a supplier
export const SCOPE_3_GHG_CATEGORY_IDS_WITH_SUPPLIERS = [
  SCOPE_3_1_ID,
  SCOPE_3_2_ID,
  SCOPE_3_4_ID,
  SCOPE_3_5_ID,
  SCOPE_3_6_ID,
  SCOPE_3_8_ID,
  SCOPE_3_9_ID,
  SCOPE_3_OTHER_UPSTREAM_ID,
] as const;

export const OTHER_CARBON_REMOVAL = 'other_carbon_removal';

export const CARBON_REMOVAL_CATEGORY_IDS = [OTHER_CARBON_REMOVAL] as const;

export const CLEAN_POWER_CATEGORY_IDS = [
  'other_additional_clean_power',
] as const;

export const CLEAN_POWER_ALLOCATABLE_GHG_CATEGORY_IDS_SCHEMA = z.enum([
  ...SCOPE_2_GHG_CATEGORY_IDS,
  SCOPE_3_1_ID,
  SCOPE_3_3_ID,
  SCOPE_3_7_ID,
  SCOPE_3_8_ID,
  SCOPE_3_13_ID,
]);

export const CLEAN_POWER_CONTRACTUAL_INSTRUMENT_LABELS: Record<
  GQCleanPowerContractualInstrument,
  string
> = {
  CompetitiveRetailProduct: 'Competitive retail product',
  PPA: 'PPA',
  SpotEAC: 'Spot EAC',
  Unknown: 'Unknown',
  UtilityCleanPowerProgram: 'Utility clean power program',
  UtilityGreenTariff: 'Utility green power product (including tariffs)',
  VPPA: 'VPPA',
};
export const CLEAN_POWER_CONTRACTUAL_INSTRUMENTS = Object.keys(
  CLEAN_POWER_CONTRACTUAL_INSTRUMENT_LABELS
);
export type CleanPowerContractualInstrument =
  (typeof CLEAN_POWER_CONTRACTUAL_INSTRUMENTS)[number];

export const GHG_CATEGORY_DISPLAY_OVERRIDE: Partial<Record<GhgId, string>> = {
  [SCOPE_2_CATEGORY_ID]: 'Scope 2',
  [SCOPE_3_1_ID]: '3.1 Purchased goods and services (data centers only)',
};

export const OTHER_GHG_CATEGORY_IDS_RAW = [
  ...CARBON_REMOVAL_CATEGORY_IDS,
  ...CLEAN_POWER_CATEGORY_IDS,
] as const;

export type OtherGhgCategoryId = (typeof OTHER_GHG_CATEGORY_IDS_RAW)[number];

export const GHG_CATEGORY_IDS = [
  ...SCOPE_1_GHG_CATEGORY_IDS,
  ...SCOPE_2_GHG_CATEGORY_IDS,
  ...SCOPE_3_GHG_CATEGORY_IDS,
] as const;

export const SCOPE_1_2_GHG_CATEGORY_IDS = [
  ...SCOPE_1_GHG_CATEGORY_IDS,
  ...SCOPE_2_GHG_CATEGORY_IDS,
];

export type GhgCategoryId = (typeof GHG_CATEGORY_IDS)[number];

export const PIPELINE_ACCEPTED_GHG_CATEGORY_IDS = [
  ...GHG_CATEGORY_IDS,
  ...OTHER_GHG_CATEGORY_IDS_RAW,
] as const;

export type PipelineAcceptedGhgCategoryId =
  (typeof PIPELINE_ACCEPTED_GHG_CATEGORY_IDS)[number];

export const SCOPE_1 = 'Scope 1';
export const SCOPE_2 = 'Scope 2';
export const SCOPE_3 = 'Scope 3';
export const GHG_SCOPES = [SCOPE_1, SCOPE_2, SCOPE_3] as const;
export type GhgScope = (typeof GHG_SCOPES)[number];

export const GHG_SCOPE_DISPLAY_NAMES = [
  ...GHG_SCOPES,
  'Carbon removal',
  'Additional clean power purchased',
] as const;
export type GhgScopeDisplayName = (typeof GHG_SCOPE_DISPLAY_NAMES)[number];

export const SCOPE_CATEGORY_IDS: Record<
  GhgScope,
  | typeof SCOPE_1_GHG_CATEGORY_IDS
  | typeof SCOPE_2_GHG_CATEGORY_IDS
  | typeof SCOPE_3_GHG_CATEGORY_IDS
> = {
  'Scope 1': SCOPE_1_GHG_CATEGORY_IDS,
  'Scope 2': SCOPE_2_GHG_CATEGORY_IDS,
  'Scope 3': SCOPE_3_GHG_CATEGORY_IDS,
};

export const SCOPE_NAMES: Record<string, string> = {
  s1: 'Scope 1: Direct Emissions',
  s2Location: 'Scope 2: Purchased Electricity',
  s2Market: 'Scope 2: Purchased Electricity',
  s3PurchasedGoodsAndServices: 'Scope 3: Purchased Goods & Services',
  s3CapitalGoods: 'Scope 3: Capital Goods',
  s3FuelAndEnergyRelatedActivities: 'Scope 3: Fuel & Energy Activities',
  s3UpstreamTransportationAndDistribution: 'Scope 3: Upstream Transport',
  s3WasteGeneratedInOperations: 'Scope 3: Waste Generated In Operations',
  s3BusinessTravel: 'Scope 3: Business Travel',
  s3EmployeeCommuting: 'Scope 3: Employee Commuting',
  s3UpstreamLeasedAssets: 'Scope 3: Upstream Leased Assets',
  s3DownstreamTransportationAndDistribution: 'Scope 3: Downstream Transport',
  s3ProcessingOfSoldProducts: 'Scope 3: Processing Of Sold Products',
  s3UseOfSoldProducts: 'Scope 3: Use Of Sold Products',
  s3EndOfLifeTreatmentOfSoldProducts:
    'Scope 3: End Of Life Treatment Of Sold Products',
  s3DownstreamLeasedAssets: 'Scope 3: Downstream Leased Assets',
  s3Franchises: 'Scope 3: Franchises',
  s3Investments: 'Scope 3: Investments',
  s3OtherUpstream: 'Scope 3: Other Upstream',
  s3OtherDownstream: 'Scope 3: Other Downstream',
  Other: 'Other',
  OtherCarbonRemoval: 'Other: Carbon Removal',
  OtherAdditionalCleanPower: 'Other: Additional Clean Power Purchased',
};

export enum Scopes {
  s1 = 's1',
  s2Location = 's2Location',
  s2Market = 's2Market',
  s3 = 's3',
  s3PurchasedGoodsAndServices = 's3PurchasedGoodsAndServices',
  s3CapitalGoods = 's3CapitalGoods',
  s3FuelAndEnergyRelatedActivities = 's3FuelAndEnergyRelatedActivities',
  s3UpstreamTransportationAndDistribution = 's3UpstreamTransportationAndDistribution',
  s3WasteGeneratedInOperations = 's3WasteGeneratedInOperations',
  s3BusinessTravel = 's3BusinessTravel',
  s3EmployeeCommuting = 's3EmployeeCommuting',
  s3UpstreamLeasedAssets = 's3UpstreamLeasedAssets',
  s3DownstreamTransportationAndDistribution = 's3DownstreamTransportationAndDistribution',
  s3ProcessingOfSoldProducts = 's3ProcessingOfSoldProducts',
  s3UseOfSoldProducts = 's3UseOfSoldProducts',
  s3EndOfLifeTreatmentOfSoldProducts = 's3EndOfLifeTreatmentOfSoldProducts',
  s3DownstreamLeasedAssets = 's3DownstreamLeasedAssets',
  s3Franchises = 's3Franchises',
  s3Investments = 's3Investments',
  s3OtherUpstream = 's3OtherUpstream',
  s3OtherDownstream = 's3OtherDownstream',
  Other = 'Other',
  OtherCarbonRemoval = 'OtherCarbonRemoval',
  OtherAdditionalCleanPower = 'OtherAdditionalCleanPower',
}

export const SCOPE_SHORTNAMES: Record<Exclude<Scopes, 's3'>, string> = {
  s1: 'Direct Emissions',
  s2Location: 'Purchased Electricity',
  s2Market: 'Purchased Electricity',
  s3PurchasedGoodsAndServices: 'Purchased Goods & Services',
  s3CapitalGoods: 'Capital Goods',
  s3FuelAndEnergyRelatedActivities: 'Fuel & Energy Activities',
  s3UpstreamTransportationAndDistribution: 'Upstream Transport',
  s3WasteGeneratedInOperations: 'Waste Generated In Operations',
  s3BusinessTravel: 'Business Travel',
  s3EmployeeCommuting: 'Employee Commuting',
  s3UpstreamLeasedAssets: 'Upstream Leased Assets',
  s3DownstreamTransportationAndDistribution: 'Downstream Transport',
  s3ProcessingOfSoldProducts: 'Processing Of Sold Products',
  s3UseOfSoldProducts: 'Use Of Sold Products',
  s3EndOfLifeTreatmentOfSoldProducts: 'End Of Life Treatment Of Sold Products',
  s3DownstreamLeasedAssets: 'Downstream Leased Assets',
  s3Franchises: 'Franchises',
  s3Investments: 'Investments',
  s3OtherUpstream: 'Other Upstream',
  s3OtherDownstream: 'Other Downstream',
  Other: 'Other',
  OtherCarbonRemoval: 'Carbon Removal',
  OtherAdditionalCleanPower: 'Additional Clean Power Purchased',
};

export const SCOPE_DESCRIPTIONS_SHORT: Record<GhgScope, string> = {
  'Scope 1': 'Direct emissions from sources you own or control',
  'Scope 2':
    'Indirect emissions from purchased electricity, steam, heat, or cooling',
  'Scope 3': 'Indirect emissions from your business, suppliers, and customers',
};

export const SCOPE_DESCRIPTIONS_SHORT_BY_NAME: Record<FootprintScope, string> =
  {
    'scope 1': SCOPE_DESCRIPTIONS_SHORT['Scope 1'],
    'scope 2': SCOPE_DESCRIPTIONS_SHORT['Scope 2'],
    'scope 3': SCOPE_DESCRIPTIONS_SHORT['Scope 3'],
  };

export const SCOPE_DESCRIPTIONS: Record<Scopes, MessageDescriptor> = {
  s1: msg`Direct release of greenhouse gases from sources you own or control (e.g. company-owned vehicles or furnaces).`,
  s2Location: msg`Emissions from the generation of electricity, steam, heat, or cooling that you purchase from companies (like your power utility).`,
  s2Market: msg`Emissions from the generation of electricity, steam, heat, or cooling that you purchase from companies (like your power utility).`,
  s3: msg`Upstream emissions of products and services you purchase, and downstream emissions when customers use/dispose of your products.`,
  s3PurchasedGoodsAndServices: msg`Emissions from the creation of products and services you purchase (e.g. materials, cloud servers, consulting services).`,
  s3CapitalGoods: msg`Emissions from the production of capital goods (e.g. equipment, machinery, buildings, vehicles) you purchased.`,
  s3FuelAndEnergyRelatedActivities: msg`Miscellaneous emissions connected to energy you purchase (e.g. power losses in the grid).`,
  s3UpstreamTransportationAndDistribution: msg`Emissions from moving products from your supplier to you, and from you to your customers.`,
  s3WasteGeneratedInOperations: msg`Emissions from the disposal of waste at your facilities (e.g. food waste from your cafeteria; discarded materials from your store).`,
  s3BusinessTravel: msg`Emissions from travel on planes, trains, buses, and cars for work trips.`,
  s3EmployeeCommuting: msg`Emissions from employees traveling to work (e.g. cars, carpooling, public transit).`,
  s3UpstreamLeasedAssets: msg`Emissions from assets you lease that are not considered in another category.`,
  s3DownstreamTransportationAndDistribution: msg`Emissions from moving products after they are sold.`,
  s3ProcessingOfSoldProducts: msg`Emissions from downstream manufacturers processing your products.`,
  s3UseOfSoldProducts: msg`Emissions from end customers using your product (e.g. electricity used to charge your product).`,
  s3EndOfLifeTreatmentOfSoldProducts: msg`Emissions from end customers disposing of your product (e.g. recycling your paper cup).`,
  s3DownstreamLeasedAssets: msg`Emissions from assets you owned that are leased by others (e.g. electricity used by your tenant to power your building).`,
  s3Franchises: msg`If you grant franchises to others, this category includes emissions from your franchisees.`,
  s3Investments: msg`Emissions from businesses in which you have invested.`,
  s3OtherUpstream: msg`Other emissions emitted from your suppliers.`,
  s3OtherDownstream: msg`Other emissions emitted after your product is sold.`,
  Other: msg`Includes several miscellaneous categories (e.g. emissions from power losses on the electricity grid, your customers’ disposal of your products).`,
  OtherCarbonRemoval: msg`Purchased and delivered carbon removal credits`,
  OtherAdditionalCleanPower: msg`Additional clean power purchased that is not applied to specific building emissions`,
};

export const GHG_ID_DESCRIPTIONS: Record<GhgId, MessageDescriptor> = {
  [SCOPE_1_CATEGORY_ID]: SCOPE_DESCRIPTIONS['s1'],
  [SCOPE_2_CATEGORY_ID]: SCOPE_DESCRIPTIONS['s2Location'],
  [SCOPE_3_1_ID]: SCOPE_DESCRIPTIONS['s3PurchasedGoodsAndServices'],
  [SCOPE_3_2_ID]: SCOPE_DESCRIPTIONS['s3CapitalGoods'],
  [SCOPE_3_3_ID]: SCOPE_DESCRIPTIONS['s3FuelAndEnergyRelatedActivities'],
  [SCOPE_3_4_ID]: SCOPE_DESCRIPTIONS['s3UpstreamTransportationAndDistribution'],
  [SCOPE_3_5_ID]: SCOPE_DESCRIPTIONS['s3WasteGeneratedInOperations'],
  [SCOPE_3_6_ID]: SCOPE_DESCRIPTIONS['s3BusinessTravel'],
  [SCOPE_3_7_ID]: SCOPE_DESCRIPTIONS['s3EmployeeCommuting'],
  [SCOPE_3_8_ID]: SCOPE_DESCRIPTIONS['s3UpstreamLeasedAssets'],
  [SCOPE_3_9_ID]:
    SCOPE_DESCRIPTIONS['s3DownstreamTransportationAndDistribution'],
  [SCOPE_3_10_ID]: SCOPE_DESCRIPTIONS['s3ProcessingOfSoldProducts'],
  [SCOPE_3_11_ID]: SCOPE_DESCRIPTIONS['s3UseOfSoldProducts'],
  [SCOPE_3_12_ID]: SCOPE_DESCRIPTIONS['s3EndOfLifeTreatmentOfSoldProducts'],
  [SCOPE_3_13_ID]: SCOPE_DESCRIPTIONS['s3DownstreamLeasedAssets'],
  [SCOPE_3_14_ID]: SCOPE_DESCRIPTIONS['s3Franchises'],
  [SCOPE_3_15_ID]: SCOPE_DESCRIPTIONS['s3Investments'],
};

export const GHG_ID_TO_NUMERIC_NAME: Record<GhgId, string> = {
  [SCOPE_1_CATEGORY_ID]: '1',
  [SCOPE_2_CATEGORY_ID]: '2',
  [SCOPE_3_1_ID]: '3.1',
  [SCOPE_3_2_ID]: '3.2',
  [SCOPE_3_3_ID]: '3.3',
  [SCOPE_3_4_ID]: '3.4',
  [SCOPE_3_5_ID]: '3.5',
  [SCOPE_3_6_ID]: '3.6',
  [SCOPE_3_7_ID]: '3.7',
  [SCOPE_3_8_ID]: '3.8',
  [SCOPE_3_9_ID]: '3.9',
  [SCOPE_3_10_ID]: '3.10',
  [SCOPE_3_11_ID]: '3.11',
  [SCOPE_3_12_ID]: '3.12',
  [SCOPE_3_13_ID]: '3.13',
  [SCOPE_3_14_ID]: '3.14',
  [SCOPE_3_15_ID]: '3.15',
};

enum MethodType {
  supplierSpecific = 'supplierSpecific',
  hybrid = 'hybrid',
  averageData = 'averageData',
  spendBased = 'spendBased',
  averageProduct = 'averageProduct',
  averageSpendBased = 'averageSpendBased',
  fuelBased = 'fuelBased',
  distanceBased = 'distanceBased',
  wasteTypeSpecific = 'wasteTypeSpecific',
  assetSpecific = 'assetSpecific',
  lessorSpecific = 'lessorSpecific',
  siteSpecific = 'siteSpecific',
  directUsePhaseEmissions = 'directUsePhaseEmissions',
  indirectUsePhaseEmissions = 'indirectUsePhaseEmissions',
  franchiseSpecific = 'franchiseSpecific',
  investmentSpecific = 'investmentSpecific',
  activityBased = 'activityBased',
}

export const methodIdToDisplayName: Record<MethodType, string> = {
  supplierSpecific: 'Supplier-specific method',
  hybrid: 'Hybrid method',
  averageData: 'Average data method',
  spendBased: 'Spend-based method',
  averageProduct: 'Average product method',
  averageSpendBased: 'Average spend-based method',
  fuelBased: 'Fuel-based method',
  distanceBased: 'Distance-based method',
  wasteTypeSpecific: 'Waste-type-specific method',
  assetSpecific: 'Asset-specific method',
  lessorSpecific: 'Lessor-specific method',
  siteSpecific: 'Site-specific method',
  directUsePhaseEmissions:
    'Methodology for direct use phase emissions, please specify',
  indirectUsePhaseEmissions:
    'Methodology for indirect use phase emissions, please specify',
  franchiseSpecific: 'Franchise-specific method',
  investmentSpecific: 'Investment-specific method',
  activityBased: 'Activity-based method',
};

export const SCOPE_1_GHG_CATEGORY_METHODS = [MethodType.activityBased];

// Hand-crafted Scope 1 category methodologies and method text, approved by APEX and found in:
// https://docs.google.com/document/d/1xApi5i0704wJqhcuhtQTxZyt0ddaQ1Mg/edit#heading=h.92s7jhaq0k2b
export const SCOPE_1_GHG_CATEGORY_METHODOLOGY = `We include the assessment of GHGs associated with stationary combustion in company owned buildings or facilities, emissions of refrigerants, emissions of company-owned vehicles and aircrafts, as well as the backup generators. 

For fuel stationary combustion in buildings and facilities, we collect the data on fuel consumption for each building or shared workspace used by the company. The primary data on fuel consumption typically comes from the utility-bills and internal meter readings or landlord provided consumption. If primary activity data is not available, benchmarks for fuel consumption per floor area by building type and fuel type breakdown from Building Performance Database are applied as a secondary activity data to estimate consumption. The consumption data is then multiplied by the relevant CO2e emission factor (EF) for that fuel. We use US EPA and DEFRA EFs for fuel combustion. 

Fugitive emissions from refrigerants are measured using the purchase data on refrigerant refills. We use a conservative assumption that all refrigerant refills are due to the refrigerant leakage. If purchase data is not available, refrigerant leakage is estimated based on building floor area using EPA HFC accounting tool. Refrigerant quantities are multiplied by their 100-year GWP from IPCC. 

Company-owned and company-operated vehicle combustion emissions are evaluated as Scope 1, while company-owned electric vehicle emissions are evaluated in Scope 2. This methodology collects fuel use data or vehicle class, distance traveled, and location data. Emissions are calculated by multiplying fuel use or distance by relevant emission factors coming from US EPA, DEFRA, and ecoinvent. 

Company-owned and company-operated aircraft emissions are calculated using flight records, aircraft make/model, and fuel consumption data. Emissions are calculated by multiplying fuel consumed by jet fuel emission factors from the US EPA.

Backup generators or other stationary sources that are not otherwise used for regular building heating result in Scope 1 combustion emissions. This methodology collects fuel use data and calculate emissions by multiplying fuel consumption by the relevant emission factors for each fuel type from the US EPA EF Hub.`;

export const SCOPE_2_GHG_CATEGORY_METHODS = [MethodType.activityBased];

// Hand-crafted Scope 2 category methodologies and method text, approved by APEX and found in:
// https://docs.google.com/document/d/1xApi5i0704wJqhcuhtQTxZyt0ddaQ1Mg/edit#heading=h.92s7jhaq0k2b
export const SCOPE_2_GHG_CATEGORY_METHODOLOGY_LOCATION = `Purchased or acquired electricity emissions are evaluated in Scope 2 consistent with GHG Protocol guidance. This methodology collects data on electricity consumption for each building used by the company. If consumption data is not available, benchmarks for electricity consumption per floor area are used as estimates. The consumption data is then multiplied by the relevant location-based CO2e emissions factors (EFs) for electricity generation. Renewable electricity purchases and clean energy programs are also considered. 

Purchased heat, steam, or cooling emissions are evaluated in Scope 2 consistent with GHG Protocol guidance. This methodology collects data on district heat, cooling, and steam consumption for each building used by the company. If consumption data is not available, benchmarks for district heat and steam consumption per floor area by country are used to estimate consumption. The consumption data is then multiplied by the relevant CO2e EF for heat and steam generation.

Company-owned vehicle combustion emissions are evaluated as Scope 1, while company-owned electric vehicle emissions are evaluated in Scope 2. This methodology collects electricity use data or vehicle class, distance traveled, and location data. Emissions are calculated by multiplying electricity use or distance by relevant EFs, using representative data where necessary.

For location-based electricity EFs we use the following sources: eGRID for the US, Canada National Inventory Report (1998-2020) for Canada, Australia National GHG Accounts Factors for Australia, IEA 2022 for all other countries, and ecoinvent 3.9.1. for each country where the grid data is not available from the aforementioned sources.`;

export const SCOPE_2_GHG_CATEGORY_METHODOLOGY_MARKET = `Market-based method of estimating Scope 2 electricity emissions is based on the same principles as the location-based approach, the difference is in the emissions factors (EFs). 

For market-based electricity EFs we use these sources: supplier-specific EFs following the data hierarchy in the GHG Protocol Scope 2 Guidance (Table 6.3), provided that the factors meet the Scope 2 Quality Criteria; Green-e residual EFs for the US grids, European Residual Mixes with CH4 and N2O emissions added from DEFRA for EU-based grids.
 
Market-based EFs are default for Scope 2 electricity. Location-based EFs are used to calculate electricity emissions if no other market-based EFs are available, following the data hierarchy in the GHG Protocol Scope 2 Guidance (Table 6.3).`;

// Hand-crafted 2024 Scope 3 category methodologies and method text, approved by APEX and found in:
// https://docs.google.com/document/d/1MYZRPNSIhHLPKWfcch47LqBhGJbNLxhee1a2UVeBAIU/edit#heading=h.6nptab2e8hj4export const SCOPE_3_GHG_CATEGORY_METHODOLOGIES: Record<
export const SCOPE_3_GHG_CATEGORY_METHODS: Record<
  (typeof SCOPE_3_GHG_CATEGORY_IDS)[number],
  Array<MethodType>
> = {
  [SCOPE_3_1_ID]: [
    MethodType.supplierSpecific,
    MethodType.spendBased,
    MethodType.averageData,
  ],
  [SCOPE_3_2_ID]: [MethodType.spendBased, MethodType.supplierSpecific],
  [SCOPE_3_3_ID]: [MethodType.supplierSpecific, MethodType.averageData],
  [SCOPE_3_4_ID]: [MethodType.distanceBased, MethodType.spendBased],
  [SCOPE_3_5_ID]: [MethodType.wasteTypeSpecific, MethodType.averageData],
  [SCOPE_3_6_ID]: [MethodType.distanceBased, MethodType.spendBased],
  [SCOPE_3_7_ID]: [MethodType.distanceBased, MethodType.averageData],
  [SCOPE_3_8_ID]: [
    MethodType.assetSpecific,
    MethodType.lessorSpecific,
    MethodType.averageData,
  ],
  [SCOPE_3_9_ID]: [MethodType.distanceBased, MethodType.spendBased],
  [SCOPE_3_10_ID]: [],
  [SCOPE_3_11_ID]: [
    MethodType.directUsePhaseEmissions,
    MethodType.indirectUsePhaseEmissions,
  ],
  [SCOPE_3_12_ID]: [MethodType.wasteTypeSpecific],
  [SCOPE_3_13_ID]: [
    MethodType.assetSpecific,
    MethodType.lessorSpecific,
    MethodType.spendBased,
  ],
  [SCOPE_3_14_ID]: [MethodType.franchiseSpecific, MethodType.averageData],
  [SCOPE_3_15_ID]: [MethodType.investmentSpecific, MethodType.averageData],
  [SCOPE_3_OTHER_UPSTREAM_ID]: [],
  [SCOPE_3_OTHER_DOWNSTREAM_ID]: [],
};

export const MANUAL_EXPLANATION_REQUIRED_TEXT = '[Manual explanation required]';

export const SCOPE_3_GHG_CATEGORY_METHODOLOGIES: Record<
  (typeof SCOPE_3_GHG_CATEGORY_IDS)[number],
  string
> = {
  [SCOPE_3_1_ID]: `For most purchased goods and services estimates, we calculate emissions using Watershed’s CEDA database or EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual supplier and procurement spend data. Spend is aggregated by each accounting category to get total spend. Each accounting category is mapped to the most accurate EEIO category. We account for the inflation or deflation to convert the EFs to the US dollars value for the year of the activity. We use the industry-level price index data (2012-2021 and 2022) published by the US. Bureau of Economic Analysis to get sector-specific inflation and deflation values. 

Spend with select vendors are mapped to those vendors’ unique revenue intensity estimates when complete and reported to the Carbon Disclosure Project (CDP). 

Total spend is multiplied by the EPA EF for that category or for that vendor to calculate CO2e emissions. 

To prevent double counting, supplier spend data that is accounted for under alternative scopes are removed from this analysis (e.g. electricity from facilities).

For cloud computing emissions, we use either cloud usage data or spend data to estimate electricity consumed and calculate electricity emissions by applying regional EFs. We also use spend data to estimate the indirect emissions associated with the cloud vendor.

For some physical goods where we have SKU data, BOMs are used to separate the SKU mass into individual commodities, which are multiplied by the total SKUs purchased to obtain the total mass per commodity per SKU. Mass is aggregated by each commodity to get total mass per commodity, and each commodity is mapped to the most accurate Emissions Factor(s). Emissions factors primarily come from ecoinvent and, in a few cases, publicly available scientific papers. We multiply total mass by the Emissions Factor(s) for that commodity to calculate CO2e emissions.

It is noteworthy that the choice of market- vs. location-based electricity emissions will also affect this category in the case of cloud usage and spending. As for Scope 2, market-based emissions are a default.`,

  [SCOPE_3_2_ID]: `We calculate emissions using Watershed’s CEDA database or the EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual supplier & procurement spend data. We account for the inflation or deflation to convert the EFs to the US dollars value for the year of the activity. We use the industry-level price index data (2012-2021 and 2022) published by the US. Bureau of Economic Analysis to get sector-specific inflation and deflation values. 

Spend is aggregated by each accounting category to get total spend. Each accounting category is mapped to the most accurate EEIO category. Spend with select vendors is mapped to those vendors’ unique revenue intensity estimates when they have submitted complete reports to complete and reported to the Carbon Disclosure Project (CDP). 

Total spend is multiplied by the Emissions Factor for that category or for that vendor to calculate CO2e emissions. To prevent double counting, supplier spend data that is accounted for under alternative scopes are removed from this analysis.

It is noteworthy that the choice of market- vs. location-based electricity emissions will also affect this category in the case of cloud usage and spend. As for Scope 2, market-based emissions are a default.`,

  [SCOPE_3_3_ID]: `We estimate fuel and energy related activities emissions for three categories:

1) Transmission and Distribution (T&D) - We estimate electricity lost to transmission and distribution. We apply regional grid loss rates from eGRID and Ecoinvent to estimate electricity lost in transmission and distribution, and apply the correct electricity emissions factor to estimate emissions. 

2) Natural Gas Leakage - We use fugitive emissions data from chapter 4.2 of the 2019 Refinement to the 2006 IPCC Guidelines for National Greenhouse Gas inventories. A tier 1 approach was taken to evaluate fugitive emissions from exploration, production, processing, and transmission & storage of natural gas.  Tier 1 was chosen as specific supply chain data was unavailable, and fugitive natural gas emissions are typically not significant for Watershed customers.

3) Upstream (well-to-tank or WTT) emissions- We calculate WTT emissions for stationary and mobile combustion, as well as WTT emissions for electricity production and electricity T&D loss. We use DEFRA EFs for WTT emissions. 

It is noteworthy that the choice of market- vs. location-based emissions in Scope 2 will also affect this category because electricity WTT and T&D loss emissions differ between the two methods. As for Scope 2, market-based emissions are a default.`,

  [SCOPE_3_4_ID]: `We estimate emissions through two methods:

1) In cases where we only have spend, logistics expenses are aggregated by category to get total spend. Each logistics category is mapped to the most accurate sector category. We multiply total spend by the EF for that category. Spend-based EFs originate from Watershed’s CEDA database or the EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual supplier & procurement spend data. We exclude logistics categories that are accounted for separately. We account for the inflation or deflation to convert the EFs to the US dollars value for the year of the activity. We use the industry-level price index data (2012-2021 and 2022) published by the US. Bureau of Economic Analysis to get sector-specific inflation and deflation values. 

2) Where we have available data on delivery distance and mass, we map the delivered goods to metric tons and multiply by distance traveled to get tonnes-km. We then choose the appropriate EF based on transportation method from EPA and DEFRA and multiply by tonnes-KM to get emissions.`,

  [SCOPE_3_5_ID]: `1) We estimate waste emissions by evaluating the number of employees working from each office location - this is assumed to match the number of employees that are actively commuting each day (see Scope 3.7). We use the CalRecycle benchmarks as an estimate for waste produced per employee per day. We multiply waste produced for each month by emissions factors for landfill and recycling. No waste estimate is included for work from home employees. We use emissions factors from DEFRA for landfill, composting, and recycling. We use emission factors from the USEPA EF Hub for landfill, composting, incineration, and digestion in the US.  

2) Where waste other than employee-generated waste is expected to be relevant, we collect information on tonnage of waste disposal by waste type and treatment methods, total tonnage of waste disposal, or spend on waste disposal services.`,

  [SCOPE_3_6_ID]: `We estimate three emissions inputs for business travel. 

1) Flights - We calculate the distance traveled by looking at flight routes and calculating the distance between airports. We calculate total emissions using Emissions Factors from DEFRA, grouped by category of flight (e.g. long haul, medium haul, short haul). When origin, destination, and mileage data is not available, we use spend on flights applied to the relevant EEIO emissions factor. 

2) Hotels - We calculate the number of nights stayed at a hotel using the check-in and check-out dates, and apply a country specific emission factors (kg CO2e / room per night) from DEFRA. When this data is not available, we use spend on hotels applied to the relevant EEIO emissions factor. 

3) For all other types of business travel (e.g. Uber, Trains), we calculate emissions using Watershed’s CEDA database or the EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual spend data. Spend is aggregated by each travel category to get total spend. Each accounting category is mapped to the most accurate EEIO category.

For all EEIO EFs, we account for the inflation or deflation to convert the EFs to the US dollars value for the year of the activity. We use the industry-level price index data (2012-2021 and 2022) published by the US. Bureau of Economic Analysis to get sector-specific inflation and deflation values.`,

  [SCOPE_3_7_ID]: `We estimate emissions in two categories.

1) Commute. We estimate the number of employees commuting in each location by aggregating employees by location. We exclude any remote employees, and exclude any months where employees were working from home due to COVID-19. We use data published by governments to estimate average commute mix and distance for each location, and apply that to the total number of commuting employees in each location to determine miles traveled by car, public transit, walking and biking (Example sources: US Census Bureau for US states, Euro State for select EU cities). We multiply miles by the emissions factor for that commute-method category. For commute, we use EFs from EPA EF Hub for cars and public transit, while for walking and biking, we assume that EFs are 0. 

2) Remote work. We estimate that the square footage occupied by a home office is 150 square feet. We use the Department of Energy’s Building Performance Database to find benchmarks for electricity consumption per square foot of residential space and natural gas per square foot of residential space. We then multiply energy usage by the corresponding region's electricity and natural gas emissions factors. Since the DoE's data set does not assume homes are being used non-stop during working hours, we adjust these estimates up to correct for this.

It is noteworthy that the choice of market- vs. location-based electricity emissions will also affect this category for remote work electricity usage. As for Scope 2, market-based emissions are a default.`,

  [SCOPE_3_8_ID]: `We estimate emissions from upstream leased assets in the following  ways: 

1) We use the same inputs as for Scope 1 and 2. Alternatively, the record of all leasing-related expenses during the measurement period, including account, currency, total spend, details (where available), vendor (where available). 

2) For some leased assets such as shared co-working spaces, we have sq-ft estimates and then generate activity based EFs for electricity and natural gas then calculate emissions based on assumed activity.

It is noteworthy that the choice of market- vs. location-based electricity emissions will also affect this category in the case of assets that utilize electricity. As for Scope 2, market-based emissions are a default.`,

  [SCOPE_3_9_ID]: `1) In cases where we only have spend, logistics expenses are aggregated by category to get total spend. Each logistics category is mapped to the most accurate EEIO category. We multiply total spend by the EF for that category. We exclude logistics categories that are accounted for separately. We account for the inflation or deflation to convert the EFs to the US dollars value for the year of the activity. We use the industry-level price index data (2012-2021 and 2022) published by the US. Bureau of Economic Analysis to get sector-specific inflation and deflation values. 

2) Where we have available data on delivery distance and mass, we map the delivered goods to metric tons and multiply by distance traveled to get tonnes-km. We then choose the appropriate EF based on transportation method from EPA and DEFRA and multiply by tonnes-KM to get emissions.`,

  [SCOPE_3_10_ID]: MANUAL_EXPLANATION_REQUIRED_TEXT,
  [SCOPE_3_11_ID]: `Direct use stage emissions are calculated for the retail products with direct electricity, fuels, and/ or refrigerants consumption, as well as sold buildings, sold vehicles, sold fuels, and sold refrigerants. For each product type, 3.11. emissions are calculated by multiplying the product lifetime energy consumption [electricity in kWh, fuels in mmBTU] or refrigerant consumption or leakage [kg of refrigerant] by the appropriate EF or GWP. Per-product emissions are multiplied by the total quantity of sold products and summed across the full product inventory. We use the same EF and GWP values as previously defined in Scope 1 and 2. We collect the data on product life time, and energy or refrigerant usage from the customer (ideally from the product LCA, if available). If such data is lacking, we use publicly available sources, including EPA's ENERGY STAR Scope 3 Use of Sold Products tool, Lawrence Berkeley National Laboratory's (LBL) Home Energy Saver & Score, Silicon Valley Power, EPA HFC Emissions Accounting Tool (“refrigerant model”),  US Energy Information Agency energy consumption surveys. For buildings in the US, we use the Department of Energy’s Building Performance Database to energy use per building type. For buildings outside of the US, we use IEA Energy Efficiency Indicators to calculate fuel mix, which is then applied to the median fuel EUI from the BPD database. For refrigerants in buildings, we use  EPA HFC accounting tool.

Indirect use stage emissions are calculated for apparel by estimating energy (natural gas or electricity) needed for washing and drying throughout the lifetime of the product using the average energy consumption from the Sustainable Apparel Coalition. 

It is noteworthy that the choice of market- vs. location-based electricity emissions will also affect this category in the case of products that utilize electricity (that includes indirect emissions for apparel). As for Scope 2, market-based emissions are a default.`,

  [SCOPE_3_12_ID]: `We calculate emissions by collecting data on SKU sold and SKU masses. SKU masses are multiplied by the number of units sold per SKU to determine the total waste produced of each SKU. Each SKU is mapped to the most accurate waste type per the waste disposal tab of the UK government greenhouse gas reporting conversion factors database.

We multiply the total mass of waste by the Emissions Factor for that waste type to calculate CO2e emissions.`,

  [SCOPE_3_13_ID]: `We estimate emissions from downstream leased assets in the following ways: 

1) Where activity data is available, we use the same inputs as for Scope 1 and 2. Alternatively, the record of all leasing-related expenses during the measurement period, including account, currency, total spend, details (where available), vendor (where available). 

2) In cases where activity data is unavailable but spend is, we calculate emissions using Watershed’s CEDA database or EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual spend data. We exclude categories that are accounted for separately (i.e. buildings). We account for the inflation or deflation to convert the EFs to the US dollars value for the year of the activity. We use the industry-level price index data (2012-2021 and 2022) published by the US. Bureau of Economic Analysis to get sector-specific inflation and deflation values. 

3) For some leased assets such as shared co-working spaces, we have sq-ft estimates and then generate activity based EFs for electricity and natural gas then calculate emissions based on assumed activity.

It is noteworthy that the choice of market- vs. location-based electricity emissions will also affect this category in the case of assets that utilize electricity. As for Scope 2, market-based emissions are a default.`,

  [SCOPE_3_14_ID]: `We use the same inputs as for Scope 1 and 2 and follow the same process as in Scope 3.8.`,

  [SCOPE_3_15_ID]: `We estimate the emissions from corporate investments, specifically equity and debt investments. To determine the EFs, we use the input data on the currency, country, industry, and the annual revenue of the asset for the specified measurement period. We also determine the attribution factor of the asset using the outstanding amount and the asset value. We use spend-based EFs from Watershed’s CEDA database or the EPA Environmentally Extended Economic Input Output (EEIO) or asset-specific EFs where available. For EEIO-based EFs, we account for inflation or deflation to convert the EFs to the US dollars value for the year of the activity. We use the industry-level price index data (2012-2021 and 2022) published by the US. Bureau of Economic Analysis to get sector-specific inflation and deflation values.`,

  [SCOPE_3_OTHER_UPSTREAM_ID]: MANUAL_EXPLANATION_REQUIRED_TEXT,
  [SCOPE_3_OTHER_DOWNSTREAM_ID]: MANUAL_EXPLANATION_REQUIRED_TEXT,
};

export function getMethodologyForCategoryId({
  categoryId,
  isLocation,
}: {
  categoryId: GhgCategoryId;
  isLocation: boolean;
}): string {
  switch (categoryId) {
    case SCOPE_1_CATEGORY_ID:
      return SCOPE_1_GHG_CATEGORY_METHODOLOGY;
    case SCOPE_2_CATEGORY_ID:
      return isLocation
        ? SCOPE_2_GHG_CATEGORY_METHODOLOGY_LOCATION
        : SCOPE_2_GHG_CATEGORY_METHODOLOGY_MARKET;
    case SCOPE_3_1_ID:
    case SCOPE_3_2_ID:
    case SCOPE_3_3_ID:
    case SCOPE_3_4_ID:
    case SCOPE_3_5_ID:
    case SCOPE_3_6_ID:
    case SCOPE_3_7_ID:
    case SCOPE_3_8_ID:
    case SCOPE_3_9_ID:
    case SCOPE_3_10_ID:
    case SCOPE_3_11_ID:
    case SCOPE_3_12_ID:
    case SCOPE_3_13_ID:
    case SCOPE_3_14_ID:
    case SCOPE_3_15_ID:
    case SCOPE_3_OTHER_UPSTREAM_ID:
    case SCOPE_3_OTHER_DOWNSTREAM_ID:
      return SCOPE_3_GHG_CATEGORY_METHODOLOGIES[categoryId];
    default:
      assertNever(categoryId);
  }
}

// Hand-crafted 2022 Scope 3 category methodologies and method text.
// These were crafted in:
// https://www.notion.so/watershedclimate/Project-Walters-Reporting-Q2-67a857e743e4468abd1a7dca3f7fbb7d#3aa2985b1d0542c2bfec30dd29327515
export const SCOPE_3_GHG_CATEGORY_METHODOLOGIES_2022_LEGACY: Record<
  (typeof SCOPE_3_GHG_CATEGORY_IDS)[number],
  Array<string>
> = {
  [SCOPE_3_1_ID]: [
    'Supplier-specific method, spend-based method, average data method',
    `For most purchased goods and services estimates, we calculate emissions using the EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual supplier and procurement spend data. Spend is aggregated by each accounting category to get total spend. Each accounting category is mapped to the most accurate EEIO category.

Spend with select vendors are mapped to those vendors’ unique revenue intensity estimates when complete and reported to the Carbon Disclosure Project (CDP).

Total spend is multiplied by the EPA EF for that category or for that vendor to calculate CO2e emissions.

To prevent double counting, supplier spend data that is accounted for under alternative scopes are removed from this analysis (e.g. electricity from facilities).

For cloud computing emissions, we use either cloud usage data or spend data to estimate electricity consumed and calculate electricity emissions by applying regional EFs. We also use spend data to estimate the indirect emissions associated with the cloud vendor.

For some physical goods where we have SKU data, BOMs are used to separate the SKU mass into individual commodities, which are multiplied by the total SKUs purchased to obtain the total mass per commodity per SKU. Mass is aggregated by each commodity to get total mass per commodity, and each commodity is mapped to the most accurate Emissions Factor(s). We multiply total mass by the Emissions Factor(s) for that commodity to calculate CO2e emissions.`,
  ],
  [SCOPE_3_2_ID]: [
    'Spend-based method, supplier-specific method',
    `We calculate emissions using the EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual supplier & procurement spend data.

Spend is aggregated by each accounting category to get total spend. Each accounting category is mapped to the most accurate EEIO category. Spend with select vendors is mapped to those vendors’ unique revenue intensity estimates when they have submitted complete reports to complete and reported to the Carbon Disclosure Project (CDP).

Total spend is multiplied by the Emissions Factor for that category or for that vendor to calculate CO2e emissions. To prevent double counting, supplier spend data that is accounted for under alternative scopes are removed from this analysis.`,
  ],
  [SCOPE_3_3_ID]: [
    'Spend-based method, site-specific method, fuel-based method',
    `We estimate fuel and energy related activities emissions for two categories:

1) Transmission and Distribution - We estimate electricity lost to transmission and distribution. We apply regional grid loss rates from eGRID and Ecoinvent to estimate electricity lost in transmission and distribution, and apply the correct electricity emissions factor to estimate emissions.

2) Natural Gas Leakage - We use fugitive emissions data from chapter 4.2 of the 2019 Refinement to the 2006 IPCC Guidelines for National Greenhouse Gas inventories. A tier 1 approach was taken to evaluate fugitive emissions from exploration, production, processing, and transmission & storage of natural gas.  Tier 1 was chosen as specific supply chain data was unavailable, and fugitive natural gas emissions are typically not significant for Watershed customers.`,
  ],
  [SCOPE_3_4_ID]: [
    'Distance-based method, spend-based method',
    `We estimate emissions through two methods:

1) In cases where we only have spend, logistics expenses are aggregated by category to get total spend.Each logistics category is mapped to the most accurate EPA USEEIO category. We multiply total spend by the EF for that category. We exclude logistics categories that are accounted for separately

2) Where we have available data on delivery distance and mass, we map the delivered goods to metric tons and multiply by distance traveled to get tonnes-km. We then choose the appropriate EF based on transportation method from EPA and DEFRA and multiply by tonnes-KM to get emissions.`,
  ],
  [SCOPE_3_5_ID]: [
    'Waste-type-specific method',
    `We estimate waste emissions by evaluating the number of employees working from each office location - this is assumed to match the number of employees that are actively commuting each day (see Scope 3.7). We use the CalRecycle benchmarks as an estimate for waste produced per employee per day. We multiply waste produced for each month by emissions factors for landfill and recycling. No waste estimate is included for work from home employees. We use emissions factors from DEFRA for landfill, composting, and recycling.1)`,
  ],
  [SCOPE_3_6_ID]: [
    'Fuel-based method, distance-based method, average data method, spend-based method',
    `We estimate three emissions inputs for business travel.

(1) Flights - We calculate the distance travelled by looking at flight routes and calculating the distance between airports. We calculate total emissions using Emissions Factors from DEFRA, grouped by category of flight (e.g. long haul, medium haul, short haul). When origin, destination, and mileage data is not available, we use spend on flights applied to the relevant EEIO emissions factor.

(2) Hotels - We calculate the number of nights stayed at a hotel using the check-in and check-out dates, and apply an emissions factor based on estimated electricity and natural consumption for an upscale hotel. When this data is not available, we use spend on hotels applied to the relevant EEIO emissions factor.

(3) For all other types of business travel (e.g. Uber, Trains), we calculate emissions using the EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual spend data. Spend is aggregated by each travel category to get total spend. Each accounting category is mapped to the most accurate EEIO category.`,
  ],
  [SCOPE_3_7_ID]: [
    'Distance-based method, average data method',
    `We estimate emissions in two categories.

(1) Commute. We estimate the number of employees commuting in each location by aggregating employees by location. We exclude any remote employees, and exclude any months where employees were working from home due to COVID-19. We use data published by governments to estimate average commute mix and distance for each location, and apply that to the total number of commuting employees in each location to determine miles traveled by car, public transit, walking and biking (Example sources: US Census Bureau for US states, Euro State for select EU cities). We multiply miles by the emissions factor for that commute-method category.

(2) Remote work. We estimate that the square footage occupied by a home office is 150 square feet. We use the Department of Energy’s Building Performance Database to find benchmarks for electricity consumption per square foot of residential space and natural gas per square foot of residential space. We then multiply energy usage by the corresponding region\'s electricity and natural gas emissions factors. Since the DoE\'s data set does not assume homes are being used non-stop during working hours, we adjust these estimates up to correct for this.`,
  ],
  [SCOPE_3_8_ID]: [
    'Spend-based method, average data method',
    `We estimate emissions from upstream leased assets in two ways:

1) For leased assets where we have spend data, we calculate emissions using the EPA Environmentally Extended Economic Input Output (EEIO) emissions factors applied to annual spend data. We exclude categories that are accounted for separately (i.e. buildings)

2) For some leased assets such as shared co-working spaces, we have sq-ft estimates and then generate activity based EFs for electricity and NG and calculate emissions based on assumed activity.`,
  ],
  [SCOPE_3_9_ID]: [
    'Spend-based method, distance-based method',
    `1) In cases where we only have spend, logistics expenses are aggregated by category to get total spend.Each logistics category is mapped to the most accurate EPA USEEIO category. We multiply total spend by the EF for that category. We exclude logistics categories that are accounted for separately

2) Where we have available data on delivery distance and mass, we map the delivered goods to metric tons and multiply by distance traveled to get tonnes-km. We then choose the appropriate EF based on transportation method from EPA and DEFRA and multiply by tonnes-KM to get emissions.`,
  ],
  [SCOPE_3_10_ID]: ['N/A', '[Manual input required]'],
  [SCOPE_3_11_ID]: [
    'Methodology for direct use phase emissions, please specify, Methodology for indirect use phase emissions, please specify',
    '[Manual input required]',
  ],
  [SCOPE_3_12_ID]: [
    'Waste-type-specific method',
    `We calculate emissions by collecting data on SKU sold and SKU masses. SKU masses are multiplied by the number of units sold per SKU to determine the total waste produced of each SKU. Each SKU is mapped to the most accurate waste type per the waste disposal tab of the UK government greenhouse gas reporting conversion factors database.

We multiply the total mass of waste by the Emissions Factor for that waste type to calculate CO2e emissions.`,
  ],
  [SCOPE_3_13_ID]: ['Spend-based method', '[Manual input required]'],
  [SCOPE_3_14_ID]: ['N/A', ''],
  [SCOPE_3_15_ID]: ['N/A', ''],
  [SCOPE_3_OTHER_UPSTREAM_ID]: ['N/A', ''],
  [SCOPE_3_OTHER_DOWNSTREAM_ID]: ['N/A', ''],
};

export const INDUSTRIES = [
  'Apparel',
  'Consumer Goods',
  'Cosmetics',
  'Financial Services',
  'Food',
  'Health & Wellness',
  'Hotels',
  'Logistics',
  'Online Retailer',
  'Restaurant',
  'Software as a Service',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ONE_INDEXED_MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const DATE_FORMAT_MONTH_YEAR = 'MMM yyyy';
export const DATE_FORMAT_YMD = 'yyyy-MM-dd';
export const DATE_FORMAT_MDY = 'MM/dd/yyyy';
export const DATE_FORMAT_MDY_TEXT = 'MMMM d, yyyy';
export const DATE_FORMAT_MDY_TEXT_SHORT_MONTH = 'MMM d, yyyy';
export const DATE_FORMAT_QY = 'Qq yyyy';

export const MAX_CONSECUTIVE_LOGIN_FAILURES = 15;

export const syncDataCompanySouceKeyId = `cmpsrc_2kC4ytw8JUUtQkmwcg5c`;
export const syncDataCompanyId = `comp_2wU4SxrFWoatckVehwPr`;
export const syncDataCompanyNameId = `cmpname_2um5AeozRz9gJPg68Mtb`;
export const syncDataOrgId = `org_2CC4SxrFWoR41hsFXtok`;
export const syncDataUserId = `usr_2CC4Sxs47XVWMMnw5S3z`;
export const syncDataEmployeeId = `wsem_2wy4SxrFWouR1GZXcfVV`;
export const syncDataOrgAccessId = `orga_2ph4gNYoa1rhKNIw4iCKS`;
export const mtsOrgId =
  environment() === 'production' ? 'org_2tY4dMVxKxoT8b3TV1Y4' : syncDataOrgId;

export const FOOTPRINT_MISSING_VERSION = 'No version';
export const CORPORATE_FOOTPRINT = 'corporate_footprint';

export const DOORDASH_ORG_ID = 'org_1JV2qFjbfqcjrFi72oG4';

// Max number of NDJSON rows that duckdb-server supports writing (to avoid
// hitting JS string length limits).
export const DUCKDB_SERVER_NDJSON_MAX_ROWS = 50_000;

export const EXAMPLE_ORG_STRUCTURE_NAME = 'Example Org Structure';
