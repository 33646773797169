import { CombinedError } from 'urql';
import { retryExchange as urqlRetryExchange } from '@urql/exchange-retry';

function shouldRetry(error?: CombinedError) {
  return (
    !!error?.networkError &&
    // If a user isn't authenticated, don't retry
    error?.response?.status !== 401 &&
    // If a user doesn't have access to a resource, don't retry
    error?.response?.status !== 403
  );
}

export default function retryExchange(): ReturnType<typeof urqlRetryExchange> {
  return urqlRetryExchange({
    initialDelayMs: 100,
    // Retry for longer in development because as the codebase grows, it takes
    // an increasing amount of time for the server to compile and restart when
    // there's a server code change.
    maxDelayMs: process.env.NODE_ENV === 'development' ? 15000 : 1000,
    maxNumberAttempts: process.env.NODE_ENV === 'development' ? 15 : 5,
    retryIf: shouldRetry,
  });
}
