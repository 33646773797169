import { useEffect } from 'react';

export default function useKeydown(cb: (evt: KeyboardEvent) => void) {
  useEffect(() => {
    window.addEventListener('keydown', cb);
    return () => {
      window.removeEventListener('keydown', cb);
    };
  }, [cb]);
}
