import { Tooltip, BoxProps, Box, TooltipProps } from '@mui/material';
import InfoIcon from '@watershed/icons/components/Info';
import { IconElement } from '@watershed/icons/Icon';

export type IntoTooltipProps = Omit<BoxProps, 'title'> &
  Pick<
    TooltipProps,
    'title' | 'placement' | 'disableInteractive' | 'onOpen'
  > & {
    size?: number;
    tabIndex?: number;
    iconColor?: string;
    tooltipMaxWidth?: number;
    Icon?: IconElement;
  };

export default function InfoTooltip({
  children,
  title,
  onOpen,
  component = 'div',
  placement = 'top',
  size = 16,
  marginLeft = 0.5,
  tabIndex = 0,
  iconColor = 'text.secondary',
  disableInteractive = true,
  Icon = InfoIcon,
  tooltipMaxWidth,
  ...boxProps
}: IntoTooltipProps) {
  return (
    <Tooltip
      title={title}
      placement={placement}
      disableInteractive={disableInteractive}
      onOpen={onOpen}
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: tooltipMaxWidth,
          },
        },
      }}
    >
      <Box
        component={component}
        display="inline-flex"
        alignItems="center"
        tabIndex={tabIndex}
        {...boxProps}
      >
        {children}
        <Icon
          size={size}
          color={iconColor}
          marginLeft={marginLeft}
          flexShrink={0}
          // Hide from PNG export
          data-html2canvas-ignore
        />
      </Box>
    </Tooltip>
  );
}
