import { z, ZodError, ZodTypeAny } from 'zod';
import { ValueOf } from './utilTypes';
import debug from './debug';

export type ZodFieldExample = {
  name: string;
  example: unknown;
};

export type ZodFieldDescription = {
  description: string;
  examples: Array<ZodFieldExample>;
};

export function createFieldDescriptionWithExamples(
  description: string,
  examples: Array<ZodFieldExample>
): string {
  return JSON.stringify({ description, examples });
}

export function getZodEnumValuesFromEnum<T extends Record<string, string>>(
  enumType: T
): [ValueOf<T>, ...Array<ValueOf<T>>] {
  const kindValues = Object.values(enumType) as Array<ValueOf<T>>;
  const categoryEnum: [ValueOf<T>, ...Array<ValueOf<T>>] = [
    kindValues[0],
    ...kindValues.slice(1),
  ];
  return categoryEnum;
}

export function messageFromZodParseError(error: z.ZodError): string {
  return error.issues
    .map((zodIssue) => {
      const { code, path, message } = zodIssue;
      const received = 'received' in zodIssue ? zodIssue.received : undefined;
      return `[Property ${path.join(
        '.'
      )}]:: code: ${code}, message: ${message}, received: ${received}`;
    })
    .join('; ');
}

export type ZodJsonSchema = {
  required: Array<string>;
  properties: Record<string, ZodFieldData>;
  anyOf?: Array<ZodJsonSchema>;
  description?: string;
};

export type ZodFieldData = {
  type: string;
  description?: string;
  default?: unknown;
  enum?: Array<string>;
  const?: string;
};

/**
 * I'd like this to be recursive but it creates an "infinite instantiation error" if I make it call itself.
 * This is probably just as good for normal usage?
 */
export type UnwrapZodType<T extends z.ZodTypeAny> = T extends z.ZodOptional<any>
  ? T['_def']['innerType']
  : T extends z.ZodNullable<any>
    ? T['_def']['innerType'] extends z.ZodOptional<any>
      ? T['_def']['innerType']['_def']['innerType']
      : T['_def']['innerType']
    : T;

type NullishZodObject<Shape extends z.ZodRawShape> = z.ZodObject<{
  [Key in keyof Shape]: z.ZodNullable<z.ZodOptional<UnwrapZodType<Shape[Key]>>>;
}>;

/**
 *  Take a zod shape and return a zod object with all the properties
 *
 *  Ask Ben about his keyboard shortcuts: ಠ_ಠ
 *  */
export function makeShapeNullish<Shape extends z.ZodRawShape>(
  shape: Shape
): NullishZodObject<Shape> {
  return z.object(
    Object.fromEntries(
      Object.entries(shape).map(([key, zodType]) => [key, zodType.nullish()])
    )
  ) as unknown as NullishZodObject<Shape>;
}

// Formats a ZodError for display in admin UI.
export function formatZodError(err: ZodError): string {
  return err.issues
    .map(
      ({ code, path, message }) => `${path.join('.')} [${code}]: ${message}.`
    )
    .join(' ');
}

export function makeSafeParseOrNullParser<Z extends ZodTypeAny>(
  schema: Z
): (value: unknown) => z.infer<Z> | null {
  return function safeParser(value: unknown): z.infer<Z> | null {
    return safeParseOrNull(schema, value);
  };
}

export function safeParseOrNull<Z extends ZodTypeAny>(
  schema: Z,
  value: unknown
): z.infer<Z> | null {
  const parseResult = schema.safeParse(value);
  if (parseResult.success) {
    return parseResult.data;
  } else {
    // To access this line, set $debug = true in your console
    debug(() => {
      console.info('[zod] Failed to safeParse value', {
        value,
        error: parseResult.error,
      });
    });
  }
  // TODO: should we capture a warning here on parse failures?
  return null;
}
