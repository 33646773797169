// Keep this in sync with shared-schema and references to [tag:watershed-teams].
export enum Teams {
  Calcprint = 'Calcprint',
  CopyDemoData = 'CopyDemoData',
  DataInfrastructure = 'DataInfrastructure',
  DataIngestion = 'DataIngestion',
  DataIngestionPlaywright = 'DataIngestionPlaywright',
  DataScience = 'DataScience',
  DatasetsAndObjects = 'DatasetsAndObjects',
  Engineering = 'Engineering',
  EnterpriseFoundations = 'EnterpriseFoundations',
  Infrastructure = 'Infrastructure',
  Methodology = 'Methodology',
  Reductions = 'Reductions',
  Reliability = 'Reliability',
  Reporting = 'Reporting',
  SupplyChain = 'SupplyChain',
  WinCsrd = 'WinCsrd',
  WinFinance = 'WinFinance',

  TestTeam = 'TestTeam',
}

export function kebabCaseTeam(team: Teams): string {
  return team.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

interface SlackChannelInfo {
  name: string;
  id: string;
}

interface TeamInfoOverride {
  name: string;
  slackChannel: string;
  slackChannelId: string;
  alertSlackChannel?: string;
  alertSlackChannelId?: string;
  playwrightSlackChannel?: SlackChannelInfo; // defaults to alert or team channel
  qnrSlackChannelId?: string;
  linearTeamId: string;
  linearTeamKey: string;
  oncallHandle?: string;
  githubTeamAlias?: string;
  isTest?: boolean;
  oncallGroupId?: string;
  sentryTeam?: string;
}

export interface TeamInfo {
  name: string;
  slackChannel: string;
  slackChannelId: string;
  alertSlackChannel?: string;
  alertSlackChannelId?: string;
  playwrightSlackChannel?: SlackChannelInfo; // defaults to alert or team channel
  qnrSlackChannelId?: string;
  oncallHandle: string;
  oncallGroupId?: string;
  githubTeamAlias: string;
  linearTeamKey: string;
  linearTeamId: string;
  sentryTeam: string;
  isTest?: boolean;
}

const TEAM_OVERRIDES: Record<Teams, TeamInfoOverride> = {
  [Teams.Calcprint]: {
    name: 'Calcprint',
    slackChannel: '#ask-calcprint',
    slackChannelId: 'C05A93914BA',
    alertSlackChannel: '#alerts-calcprint',
    alertSlackChannelId: 'C05NYQ94RQR',
    qnrSlackChannelId: 'C045NBFPPGR', // #team-calcprint
    linearTeamId: '3a172826-4819-43bb-a00d-26107e2d212b',
    linearTeamKey: 'CPT',
    oncallHandle: '@calcprint-oncall',
    githubTeamAlias: '@watershed-climate/calculation',
    sentryTeam: 'calcprint',
  },
  [Teams.CopyDemoData]: {
    name: 'Copy demo data',
    slackChannel: '#proj-self-serve-demo-data',
    slackChannelId: 'C0563EW67UJ',
    linearTeamId: '6191c5d7-7587-49c6-832d-4c1a026ef11b',
    linearTeamKey: 'COP',
  },
  [Teams.DataInfrastructure]: {
    name: 'Data infrastructure',
    slackChannel: '#team-data-infra',
    slackChannelId: 'C06H96QB3GT',
    alertSlackChannel: '#alerts-data-infrastructure',
    alertSlackChannelId: 'C074GJRP8AC',
    linearTeamId: 'd079ab7f-1bd7-4752-887f-334890047515',
    linearTeamKey: 'DATA',
    githubTeamAlias: '@watershed-climate/data-infra',
    oncallHandle: '@data-infra-oncall',
  },
  [Teams.DataIngestion]: {
    name: 'Data ingestion',
    slackChannel: '#alerts-data-ingestion',
    slackChannelId: 'C05C5K9ANAK',
    alertSlackChannel: '#alerts-data-ingestion',
    alertSlackChannelId: 'C05C5K9ANAK',
    linearTeamId: 'ce80f3fb-62bb-4055-8434-7bf2d8be9965',
    linearTeamKey: 'DI',
  },
  [Teams.DataIngestionPlaywright]: {
    name: 'Data ingestion playwright',
    slackChannel: '#alerts-playwright-data-ingestion',
    slackChannelId: 'C061E2FJ5S4',
    alertSlackChannel: '#alerts-playwright-data-ingestion',
    alertSlackChannelId: 'C061E2FJ5S4',
    linearTeamId: 'ce80f3fb-62bb-4055-8434-7bf2d8be9965',
    linearTeamKey: 'DI',
  },
  [Teams.DataScience]: {
    name: 'Data science',
    slackChannel: '#team-data-and-climate-science',
    slackChannelId: 'C05LMSLGHAN',
    linearTeamId: '7de939ba-7abb-4810-92ef-4ddb63463c68',
    linearTeamKey: 'DS',
  },
  [Teams.DatasetsAndObjects]: {
    name: 'Datasets and objects',
    slackChannel: '#ask-datasets-n-objects',
    slackChannelId: 'C076QPSPKU2',
    alertSlackChannel: '#alerts-datasets-n-objects',
    alertSlackChannelId: 'C0757E6MZ1D',
    linearTeamId: '4a742678-be63-4f34-a913-bae6d15f01cd',
    linearTeamKey: 'DNO',
  },
  [Teams.Engineering]: {
    name: 'Engineering',
    slackChannel: '#engineering',
    slackChannelId: 'C022LBWT9GA',
    oncallHandle: '@eng-oncall',
    linearTeamId: '69916935-8cdb-4c6a-baf9-4082be3dbc31',
    linearTeamKey: 'ENG',
    githubTeamAlias: '@watershed-climate/engineering',
    alertSlackChannel: '#errors',
    alertSlackChannelId: 'C02JM11N62F',
  },
  [Teams.EnterpriseFoundations]: {
    name: 'Enterprise foundations',
    slackChannel: '#team-enterprise-foundations',
    slackChannelId: 'C06QFJGJ9CH',
    alertSlackChannel: '#alerts-enterprise-foundations',
    alertSlackChannelId: 'C07CECL269X',
    oncallHandle: '@enterprise-foundations-oncall',
    githubTeamAlias: '@watershed-climate/enterprise-foundations',
    linearTeamId: 'fdd6a77f-b907-4fd5-b3c2-eeffcac80c81',
    linearTeamKey: 'ENT',
  },
  [Teams.Infrastructure]: {
    name: 'Infrastructure',
    slackChannel: '#team-infrastructure',
    slackChannelId: 'C045WQWEGH4',
    alertSlackChannel: '#alerts-infrastructure',
    alertSlackChannelId: 'C035FH0B0JZ',
    oncallHandle: '@infra-oncall',
    githubTeamAlias: '@watershed-climate/infrastructure',
    linearTeamId: 'afce5321-0bdd-4910-8bfc-3b5f6a4a2080',
    linearTeamKey: 'INF',
  },
  [Teams.Methodology]: {
    name: 'Methodology',
    slackChannel: '#team-methodology',
    slackChannelId: 'C062NQ81K1N',
    alertSlackChannel: '#team-methodology',
    alertSlackChannelId: 'C062NQ81K1N',
    linearTeamId: '83faeedb-181b-40c0-a521-84f6da2a679d',
    linearTeamKey: 'MET',
    githubTeamAlias: '@watershed-climate/team-methodology',
  },
  [Teams.Reductions]: {
    name: 'Reduction Targets',
    slackChannel: '#team-reduction-targets',
    slackChannelId: 'C04464Y3RJS',
    alertSlackChannel: '#team-reduction-targets-alerts',
    alertSlackChannelId: 'C05MUFNTDC6',
    oncallHandle: '@reductions-oncall',
    linearTeamId: 'c5b8fca8-b34e-4351-8f24-6e3fc8bc6f11',
    linearTeamKey: 'EUR',
  },
  [Teams.Reliability]: {
    name: 'Reliability',
    slackChannel: '#reliability',
    slackChannelId: 'C04LEQWDNN4',
    alertSlackChannel: '#reliability-alerts',
    alertSlackChannelId: 'C07BUNGCDPD',
    oncallHandle: '@neilcarpenter',
    linearTeamId: '69e89a08-9034-4e5b-baed-28b2e5999eca',
    linearTeamKey: 'REL',
  },
  [Teams.Reporting]: {
    name: 'Reporting',
    slackChannel: '#team-reporting',
    slackChannelId: 'C030D9KLPLG',
    alertSlackChannel: '#reporting-notifications',
    alertSlackChannelId: 'C03BRQR45J5',
    oncallHandle: '@reporting-eng-oncall',
    linearTeamId: '4e5c9cb4-0f0d-411c-b3a6-10da7b03c703',
    linearTeamKey: 'REP',
    githubTeamAlias: '@watershed-climate/team-reporting',
  },
  [Teams.SupplyChain]: {
    name: 'Supply chain',
    slackChannel: '#team-supply-chain',
    slackChannelId: 'C05N5558TDG',
    alertSlackChannel: '#supply-chain-alerts',
    alertSlackChannelId: 'C04E88HLSL9',
    playwrightSlackChannel: {
      name: '#supply-chain-playwright-alerts',
      id: 'C06JENSKB08',
    },
    linearTeamId: '4d9f70d1-b60e-4c85-a72f-4fb1cfc8116e',
    linearTeamKey: 'SUP',
  },
  // This is mean to be a (relatively) short-lived team before moving ownership
  // of permanent architecture to the appropriate teams.
  [Teams.WinCsrd]: {
    name: 'Win CSRD',
    slackChannel: '#ask-csrd-eng',
    slackChannelId: 'C06UDHKP7NV',
    alertSlackChannel: '#alerts-win-csrd',
    alertSlackChannelId: 'C06E9D3QC01',
    linearTeamId: '67ad19a8-302a-4711-b3dd-d75dafeaf3dd',
    linearTeamKey: 'CSRD',
    oncallHandle: '@csrd-oncall',
    oncallGroupId: 'S075SFJPFA7',
  },
  [Teams.WinFinance]: {
    name: 'Win Finance',
    slackChannel: '#alerts-ws-finance',
    slackChannelId: 'C04E7T46GHX',
    alertSlackChannel: '#alerts-ws-finance',
    alertSlackChannelId: 'C04E7T46GHX',
    linearTeamId: '7ebbded3-d98a-4226-8835-fe7d4ef72597',
    linearTeamKey: 'FIN',
    oncallHandle: '@ws-finance-oncall',
    githubTeamAlias: '@watershed-climate/ws-finance',
  },

  // TEST TEAM
  [Teams.TestTeam]: {
    name: 'Emojineering',
    slackChannel: '#test-slack-integration',
    slackChannelId: 'C04MRTLS99T',
    linearTeamId: '5d669b0e-5bdc-4295-a67c-30d9685edf6d',
    linearTeamKey: 'EMOJI',
    isTest: true,
  },
};

export function getTeamInfo(team: Teams): TeamInfo {
  const override = TEAM_OVERRIDES[team];
  const oncallHandle =
    override.oncallHandle ?? `@${kebabCaseTeam(team)}-oncall`;
  const githubTeamAlias =
    override.githubTeamAlias ??
    `@watershed-climate/team-${kebabCaseTeam(team)}`;

  return {
    name: override.name,
    slackChannel: override.slackChannel,
    slackChannelId: override.slackChannelId,
    alertSlackChannel: override.alertSlackChannel,
    alertSlackChannelId: override.alertSlackChannelId,
    playwrightSlackChannel: override.playwrightSlackChannel,
    qnrSlackChannelId: override.qnrSlackChannelId,
    linearTeamId: override.linearTeamId,
    linearTeamKey: override.linearTeamKey,
    sentryTeam: override.sentryTeam ?? kebabCaseTeam(team),
    isTest: override.isTest,
    oncallHandle,
    oncallGroupId: override.oncallGroupId,
    githubTeamAlias,
  };
}

export const teamNames = new Set(Object.values(Teams) as Array<string>);

export function maybeGetTeamInfo(team: string): TeamInfo | null {
  if (teamNames.has(team)) {
    return getTeamInfo(team as Teams);
  }

  return null;
}
