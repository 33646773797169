import { pipe, tap } from 'wonka';
import { Exchange } from 'urql';
import { Analytics } from '@watershed/analytics/analyticsUtils';

const fullstoryExchange: Exchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      tap((op) => {
        const sessionUrl = Analytics.syncGetFullStorySessionURL();
        const sessionId = Analytics.syncGetFullStorySessionId();
        if (sessionUrl && sessionId) {
          if (typeof op.context.fetchOptions === 'function') {
            op.context.fetchOptions = op.context.fetchOptions();
          }

          op.context.fetchOptions = {
            ...(op.context.fetchOptions || {}),
            headers: {
              ...(op.context.fetchOptions?.headers || {}),
              'X-Fullstory-Session-URL': sessionUrl,
              'X-Fullstory-Session-ID': sessionId,
            },
          };
        }
      }),
      forward
    );
};

export default fullstoryExchange;
