import Icon, { IconProps } from '../Icon';
const ArrowUpIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M19.725 11.325 12.75 4.35c-.075-.075-.225-.15-.3-.225-.15-.075-.225-.075-.375-.075s-.225 0-.375.075-.225.15-.3.225l-6.975 6.975c-.375.375-.375 1.05 0 1.425s1.05.375 1.425 0l5.325-5.325v11.55c0 .525.45.975.975.975.525 0 .975-.45.975-.975V7.425l5.325 5.325c.375.375 1.05.375 1.425 0 .225-.45.225-1.05-.15-1.425Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ArrowUpIcon;
