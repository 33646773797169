import { pipe, tap } from 'wonka';
import { Exchange } from 'urql';
import { v4 as uuidv4 } from 'uuid';

const requestIdExchange: Exchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      tap((op) => {
        const browserRequestId = uuidv4();

        if (typeof op.context.fetchOptions === 'function') {
          op.context.fetchOptions = op.context.fetchOptions();
        }
        op.context.browserRequestId = browserRequestId;
        op.context.fetchOptions = {
          ...(op.context.fetchOptions || {}),
          headers: {
            ...(op.context.fetchOptions?.headers || {}),
            'Watershed-Browser-Request-Id': browserRequestId,
          },
        };
      }),
      forward
    );
};

export default requestIdExchange;
