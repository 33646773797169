// Warning, don't import this file directly, as there's a bug in Lingui's swc
// macro that can mess up imports.
// eslint-disable-next-line no-restricted-imports
import { i18n as baseI18n, I18n } from '@lingui/core';
import { DEFAULT_LOCALE } from './constants';

/**
 * The i18n instance to use for translations. On the client, this is the global
 * i18n instance from @lingui/core, which we update whenever the locale changes.
 * On the server, this is a custom implementation that uses AsyncContext to
 * ensure the appropriate locale from the ancestor scope is used.
 */
let i18n = baseI18n;

if (i18n.locale === '') {
  // Ensure that i18n is in a valid working state, even if it hasn't been
  // initialized yet.
  if (!i18n.messages[DEFAULT_LOCALE]) {
    i18n.load({ [DEFAULT_LOCALE]: {} });
  }
  i18n.activate(DEFAULT_LOCALE);
}

if (typeof window === 'undefined' && !process.env.IS_TEMPORAL_WORKFLOW) {
  // Stop webpack from trying to bundle the lingui package on the server, so
  // that we only use this in bundle-free environments.
  i18n =
    (require(/* webpackExclude: /node\/lingui/ */ './node/lingui')
      .i18n as I18n) ?? baseI18n;
}

export { i18n, I18n };
