import AutoUpdateController, {
  AutoUpdateControllerOptions,
} from '../utils/AutoUpdateController';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useAutoRefresh({
  interval,
  onUpdateRequired,
}: {
  interval?: AutoUpdateControllerOptions['interval'];
  onUpdateRequired?: AutoUpdateControllerOptions['onUpdateRequired'];
} = {}) {
  const router = useRouter();
  useEffect(() => {
    AutoUpdateController.initialize(router, { interval, onUpdateRequired });
    // `router` is not referentially stable
    // https://github.com/vercel/next.js/discussions/29403#discussioncomment-1908563
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, onUpdateRequired]);
}
