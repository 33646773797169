import Icon, { IconProps } from '../Icon';
const PushPinIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M8 1a3 3 0 1 0 0 6v3.76a1 1 0 0 1-.555.894l-1.783.902A3 3 0 0 0 4 15.24V17a1 1 0 0 0 1 1h6v4a1 1 0 1 0 2 0v-4h6a1 1 0 0 0 1-1v-1.76a3 3 0 0 0-1.662-2.684l-1.777-.898-.006-.003a1 1 0 0 1-.555-.896V7a3 3 0 0 0 0-6H8Zm10 15v-.76a1 1 0 0 0-.555-.895l-.006-.003-1.777-.898A3 3 0 0 1 14 10.76V6a1 1 0 0 1 1-1h1a1 1 0 1 0 0-2H8a1 1 0 0 0 0 2h1a1 1 0 0 1 1 1v4.76a3.002 3.002 0 0 1-1.662 2.684l-1.783.902A.999.999 0 0 0 6 15.24V16h12Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default PushPinIcon;
