import React, { useEffect, useState } from 'react';
import useIsMounted from '../hooks/useIsMounted';
import usePrevious from '../hooks/usePrevious';

const DELAY_MS = 225;

function useWaitForModalClose<Args extends Array<unknown>>(
  originalOnClose: (...args: Args) => void,
  originalOpen: boolean,
  enabled: boolean
): [(...args: Args) => void, boolean] {
  const isMounted = useIsMounted();
  const prevOpen = usePrevious(originalOpen);
  const [open, setOpen] = useState(originalOpen);

  useEffect(() => {
    if (prevOpen !== originalOpen) {
      setOpen(originalOpen);
    }
  }, [originalOpen, prevOpen]);

  if (!enabled) {
    return [originalOnClose, originalOpen];
  }

  const onClose = (...args: Args) => {
    setOpen(false);
    setTimeout(() => {
      if (isMounted.current) {
        originalOnClose(...args);
      }
    }, DELAY_MS);
  };

  return [onClose, open];
}

type WithWaitForModalCloseProps = {
  onClose: (...args: Array<any>) => void;
  open?: boolean;
  disableWaitForModalClose?: boolean;
};

export default function withWaitForModalClose<
  P extends WithWaitForModalCloseProps,
>(Component: React.ComponentType<React.PropsWithChildren<P>>) {
  return function WithWaitForModalClose(props: P) {
    const [onClose, open] = useWaitForModalClose(
      props.onClose,
      props.open ?? true,
      !props.disableWaitForModalClose
    );
    return <Component {...props} onClose={onClose} open={open} />;
  };
}
