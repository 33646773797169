import invariant from 'invariant';

export default function must<T>(
  obj: T | null | undefined,
  errorMessage?: string
): T {
  if (obj == null) {
    // Hide this in a conditional so we don't construct the string object unless
    // we need to
    invariant(false, errorMessage ?? `must found ${obj}`);
  }
  return obj;
}
