import Icon, { IconProps } from '../Icon';
const ComposeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="m22.207 6.207-9.5 9.5a1 1 0 0 1-.465.263l-4 1a1 1 0 0 1-1.212-1.212l1-4a1 1 0 0 1 .263-.465l9.5-9.5a3.121 3.121 0 0 1 4.414 4.414Zm-3-3a1.121 1.121 0 0 1 1.586 1.586L20 5.586 18.414 4l.793-.793ZM17 5.414 18.586 7l-7.097 7.097-.782.196-1-1 .196-.782L17 5.414Z"
      clipRule="evenodd"
    />
    <path d="M4 3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-7a1 1 0 1 0-2 0v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h7a1 1 0 1 0 0-2H4Z" />
  </Icon>
);
export default ComposeIcon;
