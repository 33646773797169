import Icon, { IconProps } from '../Icon';
const LeafIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M20.9 2.55c-.16-.31-.45-.51-.79-.55l.01-.01c-.34-.03-.68.11-.89.37-.93 1.15-1.68 1.78-2.83 2.28-1.22.53-2.91.92-5.77 1.47-1.97.34-3.75 1.4-4.97 2.99a8.003 8.003 0 0 0-1.234 7.502C2.881 17.877 2 19.76 2 22c0 .55.45 1 1 1l.01-.01c.55 0 1-.45 1-1 0-1.426.475-2.635 1.343-3.524A7.941 7.941 0 0 0 6.59 19.9a7.99 7.99 0 0 0 5.42 2.1c5.76 0 11-4.93 11-11 0-4.04-1.08-6.39-2.11-8.45ZM7.016 17.352c.263.389.572.748.924 1.068a5.948 5.948 0 0 0 4.07 1.57c4.69 0 9-4.07 9-9 0-2.79-.56-4.63-1.26-6.22-.74.72-1.53 1.26-2.56 1.7v.01c-1.45.63-3.36 1.05-6.19 1.6h-.02a6.053 6.053 0 0 0-3.74 2.25 5.986 5.986 0 0 0-1.05 5.209 8.586 8.586 0 0 1 1.7-.519c2.28-.45 4.58-1.89 5.41-2.73a.996.996 0 1 1 1.41 1.41c-1.23 1.23-3.97 2.78-6.43 3.27-.453.09-.875.218-1.264.382Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default LeafIcon;
