import Box, { type BoxProps } from '@mui/material/Box';
import type { ComponentPropsWithoutRef } from 'react';

// TODO: Add ref forwarding
export type IconProps = BoxProps &
  Omit<ComponentPropsWithoutRef<'svg'>, 'color'> & {
    size?: number | string;
  };

export type IconElement = (props: IconProps) => JSX.Element;

const Icon = ({ size = 20, ...props }: IconProps) => (
  <Box
    component="svg"
    width={size}
    height={size}
    aria-hidden={props['aria-label'] ? undefined : true}
    {...props}
  />
);

export default Icon;
