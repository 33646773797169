import keys from 'lodash/keys';

export type ObjectKeys<T> = T extends object
  ? Array<keyof T>
  : T extends number
    ? []
    : T extends Array<any> | string
      ? Array<string>
      : never;

/**
 * Returns the keys of an object with strong typing.
 * Uses https://lodash.com/docs/4.17.15#keys
 *
 * Warning: this ignores the possibility of prototype pollution,
 * which is the reason TS doesn't strongly type this by default.
 *
 * Usually this is safe for us but use at your own risk.
 *
 * Reference:
 * https://fettblog.eu/typescript-better-object-keys/
 * https://effectivetypescript.com/2020/05/26/iterate-objects/
 */
export function getObjectKeys<T>(object: T): ObjectKeys<T> {
  return keys(object) as ObjectKeys<T>;
}
