// eslint-disable-next-line no-restricted-imports
import {
  GridValidRowModel,
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import {
  type GridBaseColDef,
  type GridActionsColDef,
  type GridSingleSelectColDef,
} from '@mui/x-data-grid/models/colDef/gridColDef';
import { FormikContextType } from 'formik';
import { createContext, useContext } from 'react';
import { SegmentedControlProps } from '../SegmentedControl';
import { DataGridDrawerSection } from './DataGridDrawer';
import type { MessageDescriptor } from '@lingui/core';

export const CUSTOM_SLOT_TYPES = ['drawer'] as const;

export const UNKNOWN_ROW_COUNT = Number.MAX_VALUE;

export type GridContextType<R extends GridValidRowModel> = {
  search?: string;
  setSearch?: (search: string) => void;
  createRecord?: () => void;
  slots?: DataGridPremiumProps<R>['slots'] & {
    /**
     * Custom slots below. Note that these will NOT be rendered within the DataGrid context.
     */
    drawer?: React.JSXElementConstructor<DataGridDrawerProps<R>>;
  };

  slotProps?: DataGridPremiumProps<R>['slotProps'] & {
    /**
     * Props to pass to the Watershed-specific data grid drawer
     */
    drawer?: DataGridDrawerProps<R>;
  };

  /**
   * The value of the `bigRocked` prop.
   */
  bigRocked?: boolean;
};

// Here is our opportunity to add or remove props as needed
export type DataGridProps<
  R extends GridValidRowModel,
  FormikFilters = {},
> = Omit<DataGridPremiumProps<R>, 'components' | 'componentsProps'> &
  GridContextType<R> & {
    id?: string;
    filtersFormik?: FormikContextType<FormikFilters>;
    disableRowCountDisplay?: boolean;
    aggregation?: boolean;

    /**
     * Controls enabling an alpha-level redesign of the grid itself - please don't use in production
     * yet, as it's still in development. Talk to Spike or Dylan G if you have questions.
     * @default false
     */
    bigRocked?: boolean;
  };

export const GridContext = createContext<GridContextType<any>>({
  search: '',
  setSearch: () => {},
  createRecord: () => {},
  slots: {},
  slotProps: {},
  bigRocked: undefined,
});
GridContext.displayName = 'GridContext';

export function useGridContext() {
  return useContext(GridContext);
}

export type DataGridDrawerSize = 'sm' | 'md' | 'lg';

export type ColumnPreset = {
  presetName: LocalizedString;
  presetColumns: Array<string>;
};

export type DataGridDrawerProps<R extends GridValidRowModel> = {
  columns?: DataGridPremiumProps<R>['columns'];
  size?: DataGridDrawerSize;
  columnPresets?: Array<ColumnPreset>;
  segmentedControlProps?: SegmentedControlProps<any>;
  topSection?: React.ReactNode;
  hideFilters?: boolean;
  hideSearch?: boolean;
  hideColumnSelector?: boolean;
  showColumnSearch?: boolean;
  customSections?: Array<DataGridDrawerSection>;
  drawerOpenInitially?: boolean;
  sectionOrder?: number;
  viewSectionPosition?: number;
};

/**
 * Use this instead of `GridColDef` when defining columns for a data grid. This type enforces that
 * the `headerName` prop is localized, which is necessary for customers.
 */
export type LocalizedGridColDef<
  R extends GridValidRowModel = GridValidRowModel,
  V = any,
  F = V,
> = (
  | Omit<GridBaseColDef<R, V, F>, 'headerName'>
  | Omit<GridActionsColDef<R, V, F>, 'headerName'>
  | Omit<GridSingleSelectColDef<R, V, F>, 'headerName'>
) & {
  /**
   * The headers should always be localized!
   */
  headerName: MessageDescriptor;
};
