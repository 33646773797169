import Icon, { IconProps } from '../Icon';
const BookIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M21.975 2.025h-6c-1.35 0-2.625.525-3.525 1.5-.15.15-.3.375-.45.525-.15-.225-.3-.375-.45-.525-.975-.975-2.175-1.5-3.525-1.5h-6c-.6 0-1.05.45-1.05.975v15c0 .525.45.975.975.975H9A2.05 2.05 0 0 1 11.025 21c0 .525.45.975.975.975.525 0 .975-.45.975-.975A2.049 2.049 0 0 1 15 18.975h6.975c.525 0 .975-.45.975-.975V3c.075-.525-.375-.975-.975-.975ZM11.025 17.55c-.6-.375-1.275-.525-2.025-.525H3V3.975h5.025c.825 0 1.575.3 2.1.9.6.6.9 1.35.9 2.1V17.55ZM21 17.025h-6c-.675 0-1.425.225-2.025.525V7.05c0-.825.3-1.575.9-2.1.6-.6 1.35-.9 2.1-.9H21v12.975Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default BookIcon;
