import memoize from 'lodash/memoize';
import unitsJson from '../generated/shared_data/units.json';
import { stringArraySchema } from '../yupSchemas/utilSchemas';

/**
 * Returns a list of all the units we're allowed to use.
 */
export const getKnownUnits = memoize((): Set<string> => {
  const units = stringArraySchema.validateSync(unitsJson);
  return new Set(units);
});
