import { Box } from '@mui/material';
import { useEffect, useLayoutEffect, useRef } from 'react';
import useMeasure from 'react-use/lib/useMeasure';

export default function AnimateHeight({
  children,
  onTransitionEnd,
}: {
  /**
   * A single child content element.
   */
  children: React.ReactElement;
  onTransitionEnd?: (evt: TransitionEvent) => void;
}) {
  const outerRef = useRef<HTMLDivElement>(null);
  const [ref, rect] = useMeasure();

  // uses the latest ref pattern to obviate the need for memoizing the callback
  // https://epicreact.dev/the-latest-ref-pattern-in-react/
  const onTransitionEndRef = useRef<
    ((evt: TransitionEvent) => void) | undefined
  >(onTransitionEnd);
  useLayoutEffect(() => {
    onTransitionEndRef.current = onTransitionEnd;
  });

  useEffect(() => {
    const outerNode = outerRef.current;
    const cb = onTransitionEndRef.current;
    if (outerNode && cb) {
      function listener(evt: TransitionEvent) {
        if (evt.currentTarget === outerNode) {
          cb?.(evt);
        }
      }
      outerNode.addEventListener('transitionend', listener);
      return () => {
        outerNode.removeEventListener('transitionend', listener);
      };
    }
  }, []);

  return (
    <Box
      ref={outerRef}
      sx={{
        overflow: 'hidden',
        transition: (theme) =>
          theme.transitions.create('height', { duration: '240ms' }),
        height: rect.height !== 0 ? rect.height : 'auto',
      }}
    >
      <Box ref={ref}>{children}</Box>
    </Box>
  );
}
