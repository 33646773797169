import Icon, { IconProps } from '../Icon';
const LayersIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M11.553 1.106a1 1 0 0 1 .894 0l10 5a1 1 0 0 1 0 1.788l-10 5a1 1 0 0 1-.894 0l-10-5a1 1 0 0 1 0-1.788l10-5ZM4.236 7 12 10.882 19.764 7 12 3.118 4.236 7Z"
      clipRule="evenodd"
    />
    <path d="M1.106 16.553a1 1 0 0 1 1.341-.447L12 20.882l9.553-4.776a1 1 0 1 1 .894 1.788l-10 5a1 1 0 0 1-.894 0l-10-5a1 1 0 0 1-.447-1.341Z" />
    <path d="M2.447 11.106a1 1 0 1 0-.894 1.788l10 5a1 1 0 0 0 .894 0l10-5a1 1 0 1 0-.894-1.788L12 15.882l-9.553-4.776Z" />
  </Icon>
);
export default LayersIcon;
