/* eslint-disable no-restricted-imports */
import {
  Tab as MuiTab,
  Tooltip,
  TabProps,
  TooltipProps,
  Tabs as MuiTabs,
  TabsProps,
} from '@mui/material';
import {
  TabContext,
  TabPanel,
  TabPanelProps,
  TabContextProps,
  TabList,
  TabListProps,
} from '@mui/lab';
import { mixinSx } from '@watershed/style/styleUtils';
// Because `Tab` and `Tabs` are almost-always imported together, and `TabPanel`
// and `TabContext` live in mui/labs, let's export 'em from here side-by-side so
// it's wild-easy to import them all together.
export { TabPanel, TabContext, TabList };
export type {
  TabsProps,
  TabProps,
  TabPanelProps,
  TabContextProps,
  TabListProps,
};

/**
 * Customizes MUI Tabs to match Watershed's design system:
 * - Uses a rounded indicator line under the selected tab
 * - Uses our cobalt brand color for the indicator
 */
export const Tabs = ({ sx, ...props }: TabsProps) => (
  <MuiTabs
    {...props}
    sx={mixinSx(
      (theme) => ({
        '& .MuiTabs-indicator': {
          borderRadius: '100px',
          backgroundColor: theme.palette.cobalt,
        },
      }),
      sx
    )}
  />
);

/**
 * Customizes MUI Tab to match Watershed's design system:
 * - Uses semi-bold font weight
 * - Uses secondary text color for unselected tabs
 * - Uses cobalt brand color for selected tab
 */
const WatershedTab = ({ sx, ...props }: TabProps) => (
  <MuiTab
    {...props}
    sx={mixinSx(
      {
        fontWeight: 600,
        color: (theme) => theme.palette.text.secondary,
        '&.Mui-selected': {
          color: (theme) => theme.palette.cobalt,
        },
      },
      sx
    )}
  />
);

/**
 * Wraps a MUI Tab in a MUI Tooltip, so you can show a tooltip on-hover.
 *
 * (For example: when a tab is disabled, it's often nice to have a tooltip to
 * explain *why* it's disabled.) */
export function Tab({
  tooltip,
  tooltipProps,
  href,
  ...rest
}: {
  tooltip?: TooltipProps['title'];
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  href?: string;
} & TabProps) {
  // NOTE (bryan): I don't know why this isn't in TabProps, but it's a valid
  // prop for the MuiTab component.
  const tabProps = { ...rest, href };

  // If there aren't any tooltip props, just render the tab.
  if (!tooltip && !tooltipProps) {
    return <WatershedTab {...tabProps} />;
  }

  // NOTE (bryan): We wrap the <Tab> in a <div> when disabled, because otherwise
  // the Tooltip won't show up. This is because the Tab doesn't respond to hover
  // events when it's disabled.
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      {tabProps.disabled ? (
        <div>
          <WatershedTab {...tabProps} />
        </div>
      ) : (
        <WatershedTab {...tabProps} />
      )}
    </Tooltip>
  );
}
