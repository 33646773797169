import { SxProps, Theme } from '@mui/material';
import type {
  UncapitalizedGridPremiumSlotsComponent,
  GridProSlotProps,
} from './DataGrid';
import { CONTAINER_SIZE } from './bigRockIconSlots';
import { FilterPanel } from './FilterPanel';
import { getPaletteUtils } from '@watershed/style/styleUtils';
import { ColumnsPanel } from './ColumnsPanel';

export const bigRockMenuSlots: Partial<UncapitalizedGridPremiumSlotsComponent> =
  {
    filterPanel: FilterPanel,
    columnsPanel: ColumnsPanel,
  };

/**
 * For anything that needs a dropdown styling
 */
export const sharedPaperSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    boxShadow:
      '0px 0px 16px 2px rgba(79, 89, 110, 0.06), 0px 0px 8px -4px rgba(79, 89, 110, 0.16), 0px 4px 8px 0px rgba(79, 89, 110, 0.08)',
    borderRadius: 1,
    border: (t) => `1px solid ${t.palette.divider}`,
    letterSpacing: '-0.03em', // overrides a bunch of spots internally
  },
  '& .MuiList-root': {
    padding: 0, // inexplicable vertical padding
  },
};

/**
 * This styling object will be used to style the dropdown menu in the DataGrid headers (the ... button on each column).
 * The goal is a more design system-y look and feel.
 */
const bigRockDropdownMenuSx: SxProps<Theme> = {
  ...sharedPaperSx,

  '&& .MuiMenuItem-root': {
    border: 'none', // no bottom border since we already have dividers
    paddingY: 1.25,

    '&:first-of-type': {
      borderRadius: '8px 8px 0 0', // round top corners
    },

    '&:last-of-type': {
      borderRadius: '0 0 8px 8px', // round bottom corners
    },
  },

  '&& .MuiListItemIcon-root': {
    minWidth: CONTAINER_SIZE, // matching our icon button size
    marginRight: 1,
  },

  '&& .MuiDivider-root': {
    margin: 0, // no weird extra padding on each row
  },
};

/**
 * Ensures that text fields look the same as our normal fields for things like the filter panel's inputs.
 */
export const bigRockTextFieldSx: SxProps<Theme> = {
  '&.MuiTextField-root': (t) => ({
    backgroundColor: t.palette.white,
    color: t.palette.text.primary,
    borderRadius: 0.5,

    '& .MuiInput-root': {
      boxShadow: getPaletteUtils(t.palette).boxShadowField.base,
      borderRadius: 0.5,
      '&.Mui-focused': {
        boxShadow: getPaletteUtils(t.palette).boxShadowField.focus,
      },
    },

    '& .MuiInput-input': {
      paddingBlock: 0.75,
      paddingInline: 1,
      lineHeight: 1.333333, // 20px / 15px = 1.333333 (line-height / font-size), for total height of 32px to match other inputs
      height: 'auto',
      minHeight: 20,
    },
  }),

  '& .MuiAutocomplete-inputRoot': {
    paddingBlock: '4px !important', // smaller to accommodate chips, and overrides a built in default
    paddingInline: '8px !important',
    minHeight: 24, // matching inputs too!
  },

  '&& .MuiAutocomplete-input': {
    height: 24, // matches the height of the other inputs from text fields above
    letterSpacing: '-0.03em', // for some reason, it's "normal" by default
    lineHeight: '24px', // matches above
  },
};

/**
 * Menu specific slot props for big rocks
 */
export const bigRockMenuSlotProps: GridProSlotProps = {
  basePopper: {
    sx: bigRockDropdownMenuSx,
  },
  baseSelect: {
    native: false,
    sx: {
      '&': {
        letterSpacing: '-0.03em', // for some reason, it's "normal" by default
      },
    },
  },
};
