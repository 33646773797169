import Icon, { IconProps } from '../Icon';
const WarningIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M10.48 2.394a3 3 0 0 1 4.12 1.111v.002L22.596 17.5l.001.002A2.999 2.999 0 0 1 20.001 22H4.004a3 3 0 0 1-2.621-4.498l.001-.002L9.38 3.507l.001-.002a3 3 0 0 1 1.099-1.11Zm1.51 1.592a1 1 0 0 0-.87.507l-.002.003L3.116 18.5a1 1 0 0 0 .875 1.5H20a1 1 0 0 0 .865-1.5l-.002-.004-8-14-.002-.003a1 1 0 0 0-.87-.507Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12 8a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1Zm-1 9a1 1 0 0 1 1-1h.01a1 1 0 0 1 0 2H12a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default WarningIcon;
