import { forwardRef } from 'react';

// eslint-disable-next-line no-restricted-imports
import Link from 'next/link';
import { WatershedLinkPropsRequiredHref } from '../types/LinkProps';
import isExternalUrl, { isApiRouteUrl } from '../utils/isExternalUrl';
import { format as formatUrl } from 'url';

type Props = WatershedLinkPropsRequiredHref;

export default forwardRef<HTMLAnchorElement, Props>(function UnstyledLink(
  {
    href,
    onClick,
    onMouseEnter,
    children,
    replace,
    as,
    scroll,
    shallow,
    prefetch,
    locale,
    newTab,
  }: Props,
  ref
) {
  const hrefString = formatUrl(href);
  const isExternal = isExternalUrl(hrefString);
  const isApiRoute = isApiRouteUrl(hrefString);
  if (newTab || isExternal || isApiRoute) {
    return (
      <a
        ref={ref}
        href={hrefString}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        {...(isExternal
          ? { rel: 'noopener noreferrer', target: '_blank' }
          : {
              target: '_blank',
            })}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      ref={ref}
      href={href}
      replace={replace}
      passHref
      as={as}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
      locale={locale}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      legacyBehavior
    >
      {children}
    </Link>
  );
});
