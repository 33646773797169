import range from 'lodash/range';
import sortedIndex from 'lodash/sortedIndex';
import must from './must';

export class InvertedIndex<T> {
  words: ReadonlyArray<string>;
  values: ReadonlyArray<T>;
  tokenize: (s: string) => Array<string>;
  normalize: (s: string) => string;
  allValues: Set<T>;

  constructor(
    sortedWords: ReadonlyArray<string>,
    sortedValues: ReadonlyArray<T>,
    tokenize: (s: string) => Array<string>,
    normalize: (s: string) => string
  ) {
    this.words = sortedWords;
    this.values = sortedValues;
    this.tokenize = tokenize;
    this.normalize = normalize;
    this.allValues = new Set(sortedValues);
  }

  find(word: string): Set<T> {
    word = this.normalize(word);
    const results = new Set<T>();
    const startIndex = sortedIndex(this.words, word);
    // In theory we can binary search for the end too
    for (let i = startIndex; i < this.words.length; i++) {
      if (this.words[i] > word) {
        break;
      }
      results.add(this.values[i]);
    }
    return results;
  }
  findPrefix(prefix: string): Set<T> {
    prefix = this.normalize(prefix);
    const results = new Set<T>();
    const startIndex = sortedIndex(this.words, prefix);
    for (let i = startIndex; i < this.words.length; i++) {
      if (!this.words[i].startsWith(prefix)) {
        break;
      }
      results.add(this.values[i]);
    }
    return results;
  }
  findAll(phrase: string): Set<T> {
    const words = this.tokenize(phrase);
    return words.reduce(
      (a, w) => setIntersection(a, this.find(w)),
      this.allValues
    );
  }
  findAllPrefix(phrase: string): Set<T> {
    const prefixes = this.tokenize(phrase);
    return prefixes.reduce(
      (a, p) => setIntersection(a, this.findPrefix(p)),
      this.allValues
    );
  }
}

export class InvertedIndexBuilder<T> {
  words: Array<string>;
  values: Array<T>;
  dedup: Map<T, Set<string>>;
  tokenize: (s: string) => Array<string>;
  normalize: (s: string) => string;

  constructor(
    tokenize: (s: string) => Array<string> = _tokenize,
    normalize: (s: string) => string = _normalize
  ) {
    this.words = [];
    this.values = [];
    this.dedup = new Map();
    this.tokenize = tokenize;
    this.normalize = normalize;
  }

  add(value: T, phrase: string): void {
    // Defensive runtime check: sometimes `undefined` sneaks in
    if (typeof phrase !== 'string') {
      throw new Error('runtime type error: phrase is not a string');
    }

    if (!this.dedup.has(value)) {
      this.dedup.set(value, new Set());
    }

    const dedup = must(this.dedup.get(value));
    const words = this.tokenize(phrase);
    for (const word of words) {
      const normalized = this.normalize(word);
      if (!dedup.has(normalized)) {
        dedup.add(normalized);
        this.words.push(normalized);
        this.values.push(value);
      }
    }
  }

  compile(): InvertedIndex<T> {
    // We keep words and values in parallel arrays (i.e., struct-of-arrays) for
    // performance (mostly to avoid allocations, and perhaps to give the
    // javascript engine room to do type-based optimization). But this makes
    // sorting them a little annoying. Our solution: make an array of indexes,
    // sort that with a custom comparator that looks up the "real" data sources,
    // and use the sorted indexes to reconstruct each of the sorted arrays.
    const indexes = range(this.words.length);
    indexes.sort((a, b) => {
      if (this.words[a] < this.words[b]) {
        return -1;
      } else if (this.words[a] > this.words[b]) {
        return 1;
      } else {
        return 0;
      }
    });
    return new InvertedIndex<T>(
      indexes.map((i) => this.words[i]),
      indexes.map((i) => this.values[i]),
      this.tokenize,
      this.normalize
    );
  }
}

function _tokenize(str: string): Array<string> {
  return str.split(/\s+/);
}

function _normalize(str: string): string {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

function setIntersection<T>(a: Set<T>, b: Set<T>): Set<T> {
  if (b.size < a.size) {
    return setIntersection(b, a);
  }
  return new Set([...a].filter((x) => b.has(x)));
}
