import { useEffect, useRef } from 'react';

export default function usePrevious<T>(
  value: T,
  predicate: (newValue: T) => boolean = (newValue: T) => true
) {
  const ref = useRef<T>();

  useEffect(() => {
    if (predicate(value)) {
      ref.current = value;
    }
  }, [value, predicate]);

  return ref.current;
}
