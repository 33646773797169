import {
  UncapitalizedGridPremiumSlotsComponent,
  useGridContext,
  useGridRootProps,
} from './DataGrid';
import { DataGridDrawerContainer } from './DataGridDrawer';

/**
 * WatershedGridToolbar
 * In addition to the normal DataGrid toolbar, we have a standard pattern of
 * rendering toolbar elements in a Drawer. This wrapper renders the Drawer
 * alongside the normal toolbar, if necessary.
 */
function WatershedGridToolbar() {
  const { slots, slotProps } = useGridContext();
  const { columns } = useGridRootProps();
  return (
    <>
      {slots?.toolbar && <slots.toolbar {...slotProps?.toolbar} />}
      {slots?.drawer && (
        <DataGridDrawerContainer>
          <slots.drawer columns={columns} {...slotProps?.drawer} />
        </DataGridDrawerContainer>
      )}
    </>
  );
}

/**
 * This slots object provides some default toolbar component overrides and will be combined with the normal
 * passed slots from DataGrid use sites. These should not be overridden.
 */
export const bigRockToolbarSlots: Partial<UncapitalizedGridPremiumSlotsComponent> =
  {
    toolbar: WatershedGridToolbar,
  };
