import invariant from 'invariant';

export default function assertKeyOf<T extends object>(
  key: string | number | symbol,
  obj: T
): asserts key is keyof T {
  invariant(
    obj.hasOwnProperty(key),
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    `assertKeyOf found ${String(key)} for ${obj}`
  );
}
