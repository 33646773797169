import * as Yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { AnyObject } from 'yup/lib/types';

import { YearMonth, YM } from '../utils/YearMonth';

// These nullable* schemas are useful in Formik forms where you want the schema
// to output null if the input is empty.

export const nullableStringSchema = Yup.string()
  .transform((value) =>
    typeof value === 'string' && value !== '' ? value : null
  )
  .trim()
  .nullable(true)
  .default(null)
  .typeError('${path} must be a string or null');

export const nullableBooleanSchema = Yup.boolean()
  .transform((value, originalValue) =>
    originalValue === '' || originalValue === 'null' || originalValue == null
      ? null
      : Boolean(value)
  )
  .nullable(true)
  .default(null)
  .typeError('${path} must be a boolean or null');

export const ymIntervalSchema = Yup.object().shape({
  start: Yup.number().required().positive().integer(),
  end: Yup.number().required().positive().integer(),
});

// The initial transform allows the schema to be used in a Formik form where the
// initial value is '', since undefined and null cannot be used as initial values.
export const nullableNumberSchema = Yup.number()
  .transform((value, originalValue) =>
    originalValue === '' || originalValue == null ? null : value
  )
  .nullable(true)
  .default(null)
  .typeError('${path} must be a number or null');

// The default value works around a current Yup bug:
// https://github.com/jquense/yup/issues/1440
export const trimmedStringSchema = Yup.string()
  .trim()
  .required()
  .typeError('${path} must be a string')
  .default('');

export const yearMonthSchema = Yup.mixed<YearMonth>().typeError(
  '${path} must be a YearMonth'
);

// This simply provides a better typeError than the default, useful when <input>
// element values are being coerced to numbers
export const coercedNumberSchema = Yup.number().typeError(
  '${path} must be a number'
);

// Inspired by
// https://github.com/jaredpalmer/formik/issues/712#issuecomment-452749640.
export function isFieldRequired(
  validationSchema: Yup.AnyObjectSchema,
  fieldName: string
): boolean {
  const fieldDescription = validationSchema.describe().fields[fieldName];
  if (fieldDescription && 'tests' in fieldDescription) {
    return fieldDescription.tests.some(
      ({ name }: { name?: string }) => name === 'required'
    );
  }
  return false;
}

/**
 * A Yup.date() schema that transforms BigQuery dates and timestamps into Dates.
 * Ref:
 * https://github.com/googleapis/nodejs-bigquery/blob/44e1ac7cf8604d79508316d70a3a98e2953d59f0/src/bigquery.ts#L2021-L2052.
 */
export const dateBqCompatSchema = Yup.date().transform(
  (value: any, originalValue: any) => {
    if (originalValue?.value) {
      return new Date(originalValue.value);
    }
    return value;
  }
);

/**
 * A Yup.date() schema that transforms BigQuery dates and timestamps into
 * YearMonths. Ref:
 * https://github.com/googleapis/nodejs-bigquery/blob/44e1ac7cf8604d79508316d70a3a98e2953d59f0/src/bigquery.ts#L2021-L2052
 */
export const yearMonthBqCompatSchema = yearMonthSchema.transform(
  (value: any, originalValue: any) => {
    if (originalValue?.value) {
      return YM.fromISO(originalValue.value);
    }
    return value;
  }
);

export const enumSchema = <T>(enumObject: {
  [s: string]: T;
}): MixedSchema<T | undefined, AnyObject, T | undefined> =>
  Yup.mixed<T>().oneOf(Object.values(enumObject));

/**
 * A schema for a string array
 */
export const stringArraySchema = Yup.array(Yup.string().defined()).defined();
