import Icon, { IconProps } from '../Icon';
const ShoeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M17.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
    <path
      fillRule="evenodd"
      d="M18 9c0-1.182-.557-2.317-1.391-3.081-.845-.775-2.064-1.243-3.374-.883-1.128.31-2.644 1.215-3.751 2.72-1.135 1.542-1.833 3.701-1.286 6.44a27.59 27.59 0 0 0 1.105 3.962c.44 1.193 1 2.38 1.669 3.05.768.767 1.967 1.053 3.028.977 1.084-.077 2.29-.55 3.011-1.63.464-.696.439-1.474.313-2.098-.122-.604-.376-1.242-.595-1.795l-.015-.037c-.238-.6-.449-1.133-.567-1.64-.117-.5-.117-.876-.02-1.169.154-.46.387-.866.674-1.366.11-.192.229-.398.354-.626.413-.758.845-1.69.845-2.824Zm-4.235-2.036c.512-.14 1.043.017 1.492.429C15.718 7.816 16 8.43 16 9c0 .617-.229 1.184-.6 1.864-.076.14-.163.29-.255.45-.315.547-.692 1.202-.915 1.87-.26.783-.19 1.571-.03 2.256.154.662.416 1.322.639 1.882l.017.042c.241.608.426 1.082.507 1.488.08.399.02.54-.016.593-.28.42-.823.697-1.49.745-.688.05-1.24-.165-1.471-.397-.331-.331-.77-1.144-1.206-2.326a25.484 25.484 0 0 1-1.02-3.663c-.433-2.164.119-3.755.934-4.862.843-1.145 1.978-1.788 2.67-1.978Z"
      clipRule="evenodd"
    />
    <path d="M11 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM7 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm6-5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
  </Icon>
);
export default ShoeIcon;
