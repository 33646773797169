import * as Sentry from '@sentry/browser';
import { trace, context } from '@opentelemetry/api';
import { Teams, getTeamInfo } from '@watershed/constants/teams';

/**
 * Global variable used to track current owning team. This only works for
 * shared-frontend, if we ever expose this module to shared-universal we'll
 * need to rethink how this works.
 */
let currentTeam: Teams | null = null;

/**
 * This utility is designed to set the owner for you in places like
 * Next.js routes. It checks for the active span in case one is not set,
 * and it sets Sentry tags.
 */
export function setOwnerContext(team: Teams | null) {
  if (team) {
    console.debug(`Setting owner to ${team}`);
    const activeSpan = trace.getSpan(context.active());
    if (activeSpan) {
      console.debug(`Setting active span owner to ${team}`);
      activeSpan.setAttributes({ owner: team });
    } else {
      console.debug(`No active span; would have set owner to ${team}`);
    }
    Sentry.setTags({
      owner: getTeamInfo(team).sentryTeam,
    });
  } else {
    console.debug('Clearing owner');

    // Note: We do *not* clear the active span
    // in case the active span has not yet closed.

    Sentry.setTags({
      owner: null,
    });
  }

  currentTeam = team;
}

/**
 * Returns the current owner if one has been set by setOwnerContext.
 */
export function getOwnerContext() {
  return currentTeam;
}
