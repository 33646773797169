/**
 * Javascript has distinct values for positive and negative zero. This function
 * normalizes negative zero to positive zero. :/
 */
export default function normalizeNegativeZero(n: number): number {
  if (n === 0) {
    return 0;
  }
  return n;
}
