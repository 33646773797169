import { Analytics } from '@watershed/analytics/analyticsUtils';
import { CustomSpanAttributes } from '@watershed/shared-universal/constants/otelCustomAttributes';
import {
  Honeycomb,
  HoneycombEvent,
} from '@watershed/shared-universal/honeycomb';
import { getOwnerContext } from './ownerContext';

/**
 * This is the pathname used by NextJS to render individual pages. It includes
 * variable names, and not values (eg. /page/[pageName]/[activeTab] instead of
 * /page/foo/bar), which can make grouping events more meaningful (ie. group
 * based on the NextJS rendered page, rather than browser path / URL).
 *
 * This has to be updated from within a React context so we can access the
 * NextJS router instance.
 */
let nextJsPathname: string | null = null;

export function setNextJsPathname(pathname: string) {
  nextJsPathname = pathname;
}

const frontendHoneycomb = new Honeycomb({
  enabled: process.env.NEXT_PUBLIC_HONEYCOMB_ENABLED !== 'false',
  key: process.env.NEXT_PUBLIC_HONEYCOMB_KEY || '',
  dataset: process.env.NEXT_PUBLIC_ENVIRONMENT || '',
  serviceName: `${process.env.NEXT_PUBLIC_APPLICATION}-frontend`.toLowerCase(),
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || '',
  developerName: process.env.NEXT_PUBLIC_WS_USER,
  universalAttributes: {},
  beforeSend: honeycombBeforeSend,
});

export default frontendHoneycomb;

function honeycombBeforeSend(event: HoneycombEvent): void {
  event.addField(CustomSpanAttributes.BROWSER_PATH, window.location.pathname);
  event.addField(CustomSpanAttributes.BROWSER_URL, window.location.href);

  if (nextJsPathname) {
    event.addField(CustomSpanAttributes.NEXT_JS_PATHNAME, nextJsPathname);
  }

  if (Analytics.userProperties) {
    event.addField(
      CustomSpanAttributes.USER_ID,
      Analytics.userProperties['userId']
    );
    event.addField(
      CustomSpanAttributes.USER_IS_CUSTOMER,
      Analytics.userProperties['isCustomer']
    );
    event.addField(
      CustomSpanAttributes.USER_EMAIL,
      Analytics.userProperties['userEmail']
    );
    event.addField(
      CustomSpanAttributes.ORG_ID,
      Analytics.userProperties['orgId']
    );
    event.addField(
      CustomSpanAttributes.ORG_NAME,
      Analytics.userProperties['orgName']
    );
    if (Analytics.dashboardUserProperties) {
      event.addField(
        CustomSpanAttributes.ORG_IS_DEMO,
        Analytics.dashboardUserProperties['demoOrg']
      );
      event.addField(
        CustomSpanAttributes.ORG_IS_TEST,
        Analytics.dashboardUserProperties['testOrg']
      );
    }
    event.addField(
      CustomSpanAttributes.LOGIN_AS_USER_ID,
      Analytics.userProperties['loginAsUserId']
    );
    event.addField(
      CustomSpanAttributes.LOGIN_AS_USER_NAME,
      Analytics.userProperties['loginAsUserName']
    );
    event.addField(
      CustomSpanAttributes.FULLSTORY_SESSION_URL,
      Analytics.syncGetFullStorySessionURL()
    );
    event.addField(
      CustomSpanAttributes.FULLSTORY_SESSION_ID,
      Analytics.syncGetFullStorySessionId()
    );
    event.addField(CustomSpanAttributes.OWNER, getOwnerContext());
  }
}
