import { UseQueryState } from 'urql';
import { getGqlResultDataBang } from './errorUtils';

export default function isFetchingOrStale<T extends object>(
  result: UseQueryState<T>
) {
  return result.fetching || result.stale;
}

// This is Adam's quixotic attempt to refine this
// and be a bit more careful to avoid unecessary loading states
export function isFetching<T extends object>(result: UseQueryState<T>) {
  return !result.data && result.fetching;
}

/**
 * Utility function to more explicitly handle fetching state and data
 * availability of a GraphQL query. Queries can be in three distinct states:
 *
 * 1. Fetching: No data is available, but it’s actively being fetched.
 * 2. Data available: The query has completed fetching, and data is available.
 * 3. Paused: The query is not trying to fetch data, and no data is available.
 *
 * Using this function helps TypeScript understand that if the query isn’t
 * paused or fetching, the data is ready for access.
 */
export function isFetchingOrDataBang<T extends object>(
  result: UseQueryState<T>
):
  | ({ fetching: true; paused: false } & Omit<UseQueryState<T>, 'fetching'>)
  | ({ fetching: false; data: T; paused: false } & Omit<
      UseQueryState<T>,
      'fetching' | 'data'
    >)
  | ({ fetching: false; data: undefined; paused: true } & Omit<
      UseQueryState<T>,
      'fetching' | 'data'
    >) {
  // Fetching
  if (result.fetching) {
    return { ...result, fetching: true, paused: false };
  }

  // Data available
  if (result.operation) {
    return {
      ...result,
      fetching: false,
      data: getGqlResultDataBang(result),
      paused: false,
    };
  }

  // Paused
  return { ...result, fetching: false, data: undefined, paused: true };
}
