import type { ComponentProps } from 'react';
import { Trans } from '@lingui/react/macro';
import {
  GridPanelFooter,
  useGridApiContext,
  GridColumnsPanel,
} from './DataGrid';
import { mixinSx } from '@watershed/style/styleUtils';
import { sharedPaperSx } from './bigRockMenuSlots';
import Button from '../Button';
import { Stack, SxProps, Theme } from '@mui/material';
import ViewIcon from '@watershed/icons/components/View';
import ViewOffIcon from '@watershed/icons/components/ViewOff';
import { visuallyHidden } from '@mui/utils';
import { useClickAway } from 'react-use';
import { useRef } from 'react';

const gridColumnsPanelSx: SxProps<Theme> = {
  '& .MuiInput-root': {
    marginTop: 0, // too much top padding
  },

  // More spacing for the individual switches, and gaps between
  '& .MuiDataGrid-columnsPanel': {
    padding: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,

    // The empty state is woefully empty so let's hack it
    '&:empty:before': {
      content: '"No columns matching that search term"',
      color: (t) => t.palette.text.secondary,
      padding: 1,
    },
  },

  // Don't show the top label at all
  '& .MuiInputLabel-root': visuallyHidden,

  // Override the borders! This level of specificity is needed ugh
  '&&& .MuiTextField-root': {
    '&, &:hover, &:focus, &:active, & .Mui-focused, & .MuiInput-root': {
      boxShadow: 'none',
    },
  },

  // New border below
  '.MuiDataGrid-panelHeader': {
    borderBottom: (t) => `1px solid ${t.palette.divider}`,
    paddingY: 0.5,
  },

  // Better text colors
  '.MuiInput-input': {
    color: (t) => t.palette.text.secondary,
  },
  '.MuiFormControlLabel-label': {
    color: (t) => t.palette.text.secondary,
  },

  // Placeholder: MUI has an annoying !important override on the opacity - and yes this specificity is necessary
  'label+.MuiInputBase-formControl .MuiInput-input.MuiInput-input::placeholder':
    {
      textOverflow: 'ellipsis',
      color: (t) => t.palette.grey50,
      opacity: '1 !important',
    },
};

/**
 * Shows up when you manage columns in a header to show visibility for all columns
 */
export function ColumnsPanel(props: ComponentProps<typeof GridColumnsPanel>) {
  const { current: gridApi } = useGridApiContext();
  const ref = useRef(null);
  const { columns } = gridApi.state;
  const filteredFields = columns.orderedFields.filter(
    (field) => columns.lookup[field].hideable === true
  );
  const allEnabled = filteredFields.every(
    (f) => columns.columnVisibilityModel[f] !== false
  ); // either true or undefined
  const allDisabled = filteredFields.every(
    (f) => columns.columnVisibilityModel[f] === false
  );

  function setColumnVisibilityToValue(visible: boolean) {
    const newModel = Object.fromEntries(
      filteredFields.map((col) => [col, visible])
    );
    gridApi.setColumnVisibilityModel(newModel);
  }

  // At least ONE thing has to be visible, so enable the first if we click away when nothing's enabled
  useClickAway(ref, () => {
    if (
      columns.orderedFields.every(
        (f) => columns.columnVisibilityModel[f] === false
      )
    ) {
      gridApi.setColumnVisibilityModel({
        ...columns.columnVisibilityModel,
        [filteredFields[0]]: true,
      });
    }
  });

  return (
    <Stack sx={{ flex: 1 }} ref={ref}>
      <GridColumnsPanel
        {...props}
        disableHideAllButton
        disableShowAllButton
        sx={mixinSx(sharedPaperSx, gridColumnsPanelSx, props.sx)}
      />
      <GridPanelFooter
        sx={{
          display: 'flex',
          gap: 1,
          flexDirection: 'row',
          padding: 1.5,
          borderTop: '1px solid',
          borderColor: (t) => t.palette.divider,
        }}
      >
        <Button
          onClick={() => setColumnVisibilityToValue(true)}
          startIcon={<ViewIcon />}
          disabled={allEnabled}
          sx={{ flex: 1 }}
        >
          <Trans context="Button copy to display all records in a list">
            Show all
          </Trans>
        </Button>
        <Button
          onClick={() => setColumnVisibilityToValue(false)}
          startIcon={<ViewOffIcon />}
          disabled={allDisabled}
          sx={{
            flex: 1,
          }}
        >
          <Trans context="Button copy to hide all records in a list">
            Hide all
          </Trans>
        </Button>
      </GridPanelFooter>
    </Stack>
  );
}
