import { ListItem, Typography } from '@mui/material';
import TextLink from '@watershed/ui-core/components/TextLink';
import TruncatingTypographyWithTooltip from '@watershed/ui-core/components/TruncatingTypographyWithTooltip';
import { useEffect, useRef } from 'react';

/**
 * One singular entry in the quick switcher
 */
export function QuickSwitcherItem({
  url,
  title,
  onClose,
  index,
  focus,
  hasChildItems,
  newTab,
}: {
  url: string;
  title: string;
  onClose: () => void;
  index: number;
  focus: boolean;
  hasChildItems: boolean;
  newTab?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);

  // When this element is focused, scroll into view so if it's offscreen it becomes visible. Helpful with arrow keys to navigate these
  useEffect(() => {
    if (focus && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [focus]);

  return (
    <TextLink
      href={url}
      sx={{
        '&:hover': {
          // Overridden in child
          textDecoration: 'none',
        },
      }}
      newTab={newTab}
    >
      <ListItem
        key={url}
        button
        onClick={onClose}
        tabIndex={0}
        ref={ref}
        aria-selected={focus}
        id={index.toString()}
        sx={(theme) => ({
          display: 'flex',
          gap: 2,
          padding: theme.spacing(2, 4),
          '&:hover, &:focus, &[aria-selected="true"]': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
          },
        })}
      >
        <TruncatingTypographyWithTooltip
          tooltip={title}
          sx={{
            'a:hover &': {
              textDecoration: 'underline',
            },
            color: (theme) => theme.palette.cobalt,
          }}
        >
          {title}
        </TruncatingTypographyWithTooltip>
        {hasChildItems && (
          <Typography
            sx={(theme) => ({
              border: `1px solid ${theme.palette.grey50}`,
              borderRadius: 1,
              paddingY: 0.5,
              paddingX: 0.75,
              color: theme.palette.grey50,
            })}
            variant="caption"
          >
            Tab for more
          </Typography>
        )}
      </ListItem>
    </TextLink>
  );
}
