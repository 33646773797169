import { Box, Fade, SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { visuallyHidden } from '@mui/utils';
import omit from 'lodash/omit';
import type { ReactNode } from 'react';
import { mixinSx } from '@watershed/style/styleUtils';
import WarningIcon from '@watershed/icons/components/Warning';
import InfoTooltip, { IntoTooltipProps } from '../InfoTooltip';
import React from 'react';

export const requiredSymbol = (
  <Box
    component="span"
    aria-hidden
    sx={{ paddingLeft: 0.5, color: (theme) => theme.palette.error.main }}
  >
    *
  </Box>
);

export type ValidationState = 'default' | 'warning' | 'error';
export interface ValidationProps {
  validationMessage?: React.ReactNode;
  validationState?: ValidationState;
  size?: 'small' | 'medium';
}
export interface FieldProps extends ValidationProps {
  label?: ReactNode;
  labelVariant?: Variant;
  infoTooltipProps?: Pick<IntoTooltipProps, 'Icon' | 'title' | 'sx'>;

  // The inline prop returns a div and an input,
  // expecting to be laid out in a `display: grid` by the parent
  inline?: boolean;
  name?: string;

  inputId: string;
  className?: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  visuallyHideLabel?: boolean;
  renderLabel?: (labelNode: ReactNode) => ReactNode;
  required?: boolean;
  // Used to hide the required symbol. This should only be used in special circumstances.
  hideRequired?: boolean;
  sublabel?: ReactNode;
  sublabelVariant?: Variant;
  fullWidth?: boolean;
  nullValueLabel?: LocalizedString;
  disableNullOption?: boolean;
  dataTest?: string;
  fsUnmask?: boolean;
  maxLength?: number;
  validationPlacement?: 'top' | 'bottom';
}

const fieldPropKeys = [
  'label',
  'labelVariant',
  'className',
  'sx',
  'visuallyHideLabel',
  'sublabel',
  'validationState',
  'validationMessage',
  'disableResize',
  'required',
  'hideRequired',
  'renderLabel',
  'inline',
  'nullValueLabel',
  'infoTooltipProps',
  'fullWidth',
] as const;
export function filterFieldInputProps<T extends object>(
  props: T
): Omit<T, (typeof fieldPropKeys)[number]> {
  return omit(props, ...fieldPropKeys);
}

export default function Field({
  label,
  labelVariant = 'body1',
  inline,
  inputId,
  children,
  className,
  sx,
  visuallyHideLabel,
  renderLabel,
  required = false,
  hideRequired = false,
  nullValueLabel = 'None',
  sublabel,
  sublabelVariant = 'body2',
  validationState,
  validationMessage,
  size,
  validationPlacement,
  fullWidth,
  infoTooltipProps,
  dataTest,
}: FieldProps) {
  const titleComponent = label ? (
    <Typography
      variant={inline ? 'body2' : labelVariant}
      color={inline ? undefined : 'textPrimary'}
      component="label"
      htmlFor={inputId}
      sx={mixinSx(
        {
          overflowWrap: 'break-word',
          width: '100%',
        },
        visuallyHideLabel ? visuallyHidden : undefined
      )}
    >
      {label}
      {required && !hideRequired && requiredSymbol}
      {infoTooltipProps && (
        <InfoTooltip
          {...infoTooltipProps}
          sx={mixinSx({ verticalAlign: 'text-bottom' }, infoTooltipProps.sx)}
        />
      )}
    </Typography>
  ) : null;
  const renderedLabel = titleComponent
    ? renderLabel
      ? renderLabel(titleComponent)
      : titleComponent
    : null;
  return (
    <>
      {inline && renderedLabel}
      <Box
        className={className}
        sx={mixinSx(
          {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            alignItems: fullWidth ? 'stretch' : 'start',
            width: fullWidth ? '100%' : undefined,
            // This is to prevent the field from extending past the side
            // of its container
            minWidth: 0,
          },
          sx
        )}
        data-test={dataTest}
      >
        {!inline && renderedLabel}
        {sublabel && (
          <Typography
            variant={sublabelVariant ?? 'body2'}
            id={inputId + '-sublabel'}
            sx={{
              marginTop: -0.5,
              paddingBottom: 0.5,
              width: '100%',
            }}
          >
            {sublabel}
          </Typography>
        )}
        {validationPlacement === 'top' && (
          <ValidationMessage
            inputId={inputId}
            size={size}
            validationMessage={validationMessage}
            validationState={validationState}
          />
        )}
        {children}
        {validationPlacement !== 'top' && (
          <ValidationMessage
            inputId={inputId}
            size={size}
            validationMessage={validationMessage}
            validationState={validationState}
          />
        )}
      </Box>
    </>
  );
}

export function ValidationMessage({
  inputId,
  validationMessage,
  validationState,
  size,
}: ValidationProps & { inputId: string }) {
  if (!validationMessage) {
    return null;
  }
  return (
    <Fade in>
      <Box
        component="p"
        id={inputId + '-validationMessage'}
        sx={{
          alignItems: 'center',
          display: 'flex',
          margin: 0,
          color: (theme) =>
            fieldValidationStateIsWarningOrError(validationState)
              ? theme.palette[validationState].dark
              : theme.palette.text.primary,
          fontSize: size === 'small' ? '0.75rem' : undefined,
        }}
      >
        <WarningIcon size={16} marginRight={0.5} flexShrink={0} />
        {validationMessage}
      </Box>
    </Fade>
  );
}

/**
 * Typeguard to narrow field validation state into 'warning' or 'error'
 */
function fieldValidationStateIsWarningOrError(
  validationState?: ValidationState
): validationState is 'warning' | 'error' {
  return ['warning', 'error'].includes(validationState || '');
}
