import { HeaderKeys } from '@watershed/shared-universal/utils/constants';
import { getPinnedOrganizationId } from './pinnedOrganizationId';

export default function wsFetch(
  input: RequestInfo | URL,
  init?: RequestInit | undefined
): Promise<Response> {
  const organizationId = getPinnedOrganizationId();
  let headers = { ...init?.headers };
  if (organizationId) {
    // might not always be defined (i.e. if called from admin)
    headers = { ...headers, [HeaderKeys.activeOrganizationId]: organizationId };
  }
  /* eslint-disable @watershed/must-use-wsfetch */
  return fetch(input, {
    ...init,
    headers,
  });
}
