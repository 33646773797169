import BookIcon from '@watershed/icons/components/Book';
import CodeIcon from '@watershed/icons/components/Code';
import DatabaseIcon from '@watershed/icons/components/Database';
import FlagIcon from '@watershed/icons/components/Flag';
import InboxIcon from '@watershed/icons/components/Inbox';
import LayersIcon from '@watershed/icons/components/Layers';
import SearchIcon from '@watershed/icons/components/Search';
import ServerIcon from '@watershed/icons/components/Server';
import ShoppingBasketIcon from '@watershed/icons/components/ShoppingBasket';
import TagIcon from '@watershed/icons/components/Tag';
import TransfersIcon from '@watershed/icons/components/Transfers';
import {
  BACKGROUND_JOBS_ROUTE,
  routeForEmissionsModels,
  routeForFeatureFlags,
  routeForGmailTools,
  routeForCanonicalDatasets,
  routeForMarketplaceIndex,
  routeForObjectViewer,
  routeForReferenceData,
  urlForQueryPlayground,
  routeForGlobalFootprintTagEditor,
  routeForTSchemaPlatform,
} from '@watershed/shared-universal/adminRoutes';

export interface TopLevelNavItem {
  to: string;
  label: string;
  shortLabel?: string;
  icon: React.ReactNode;
  bg: string;
}

/**
 * These are the top level nav items for all of admin - each appear as a nicely colored
 * button background for easy recognition.
 */
export const TOP_LEVEL_NAV_ITEMS: Array<TopLevelNavItem> = [
  {
    to: routeForMarketplaceIndex(),
    icon: <ShoppingBasketIcon />,
    label: 'Marketplace',
    bg: 'slate',
  },
  {
    to: routeForEmissionsModels(),
    icon: <TransfersIcon />,
    label: 'Emissions models',
    shortLabel: 'Models',
    bg: 'midnight',
  },
  {
    to: routeForReferenceData(),
    icon: <BookIcon />,
    label: 'Reference data',
    shortLabel: 'Ref data',
    bg: 'info.dark',
  },
  {
    to: routeForGlobalFootprintTagEditor(),
    icon: <TagIcon />,
    label: 'Default tags',
    shortLabel: 'Default tags',
    bg: 'forest',
  },
  {
    to: BACKGROUND_JOBS_ROUTE,
    icon: <ServerIcon />,
    label: 'Jobs',
    bg: 'success.dark',
  },
  {
    to: urlForQueryPlayground(),
    icon: <CodeIcon />,
    label: 'Query playground',
    shortLabel: 'Playground',
    bg: 'grass',
  },
  {
    to: routeForCanonicalDatasets(),
    icon: <LayersIcon />,
    label: 'Datasets / Datasources',
    shortLabel: 'Datasets',
    bg: 'lightBlue',
  },
  {
    to: routeForObjectViewer(),
    icon: <SearchIcon />,
    label: 'Object viewer',
    shortLabel: 'Objects',
    bg: 'marigold',
  },
  {
    to: routeForGmailTools(),
    icon: <InboxIcon />,
    label: 'Gmail tools',
    shortLabel: 'Gmail',
    bg: 'poppy',
  },
  {
    to: routeForFeatureFlags(),
    icon: <FlagIcon />,
    label: 'Feature flags',
    shortLabel: 'Flags',
    bg: 'error.dark',
  },
  {
    to: routeForTSchemaPlatform(),
    icon: <DatabaseIcon />,
    label: 'TSchemas',
    bg: 'info.dark',
  },
];
