import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

// Distinct from UserInputError because it does not assume that the input came
// from an end user of a browser application.
export class BadInputError extends WatershedError {
  // Even though it relates to "input," so you might think it's a 4xx, unless
  // it's caught and recast as a UserInputError, it actually reflects a bug in
  // our code. So 500 is appropriate.
  status = 500;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('BAD_INPUT', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BadInputError);
    }
    Object.setPrototypeOf(this, BadInputError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(BadInputError);
  static wrapAndRethrow = makeRethrower(BadInputError);
}
