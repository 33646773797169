import { getRouteParams } from '@watershed/shared-universal/utils/queryParamUtils';
import { GQActiveWatershedEmployeeFieldsFragment } from '@watershed/shared-universal/generated/graphql';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';
import Mixpanel from 'mixpanel-browser';
import { getArrayMode } from '@watershed/shared-universal/utils/arrayUtils';
import { Analytics } from '@watershed/analytics/analyticsUtils';

const ADMIN_MIXPANEL_PROJECT_ID = 'beda7316030228f11a5fbc7e14a8abe2';

// In development, consider using GA's tooling:
// https://developers.google.com/analytics/devguides/collection/analyticsjs/debugging
const shouldTrack = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

export async function initializeAdminAnalyticsAtTopLevelOfModule() {
  // Admin Google Analytics key
  ReactGA.initialize('G-GBC9RNSYXF');

  if (!shouldTrack) {
    ReactGA.set({ sendHitTask: null });
  }

  // Disable advertising tracking features. Not useful for Admin.
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/config?hl=en
  ReactGA.set({
    allowAdFeatures: false,
    allowAdPersonalizationSignals: false,
  });

  await Analytics.init({
    fullStoryOrgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
    mixpanelProjectId: shouldTrack ? ADMIN_MIXPANEL_PROJECT_ID : undefined,
  });
}

function getUserKind(
  activeWatershedEmployee: GQActiveWatershedEmployeeFieldsFragment
) {
  const { orgPointsOfContact } = activeWatershedEmployee || {};

  // To determine the wsem's singular kind, choose the most common kind among
  // their point of contact relationships. In almost all cases, there will
  // just be one kind.
  const orgPointOfContactKind = getArrayMode(
    (orgPointsOfContact || []).map((poc) => poc.kind)
  );

  // Just in case, always log as EngineerInDevelopment while in dev.
  const userKind = shouldTrack
    ? orgPointOfContactKind
    : 'EngineerInDevelopment';

  return userKind;
}

export function useAdminAnalytics(
  activeWatershedEmployee:
    | GQActiveWatershedEmployeeFieldsFragment
    | null
    | undefined
) {
  const router = useRouter();
  const { query, asPath } = router;

  useEffect(() => {
    if (activeWatershedEmployee) {
      const routeParams = getRouteParams(query, asPath);
      const orgId =
        (routeParams ? (routeParams as any).orgId : undefined) ??
        activeWatershedEmployee.user.primaryLoginOrgId;

      void Analytics.identify({
        userProperties: {
          // In admin we use the id of the WatershedEmployee, rather than the User
          userId: activeWatershedEmployee.id,
          createdAt: activeWatershedEmployee.user.createdAt,
          userName: activeWatershedEmployee.name,
          userEmail: activeWatershedEmployee.email,
          orgId,
          // Let's give these a different org name to separate them from
          // Watershed Root dashboard sessions
          orgName: 'Watershed Admin',
          isCustomer: false,
          isAdminUser: true,
          isCreatedAtCurrentMonthOfQuarter: true,
          loginAsUserId: undefined,
          loginAsUserName: undefined,
          loginAsUserIsCustomer: undefined,
          preferredLocale: undefined,
        },
        dashboardUserProperties: null,
        adminUserProperties: {
          watershedEmployeeHandle: activeWatershedEmployee.handle,
          watershedEmployeeKind: getUserKind(activeWatershedEmployee),
          // Anonymization in admin not yet properly supported
          anonymizeInFullStory: false,
        },
      });
    }
  }, [activeWatershedEmployee, query, asPath]);

  useTrackPageview(activeWatershedEmployee);
}

export function registerPropertiesMixpanel(properties: Record<string, any>) {
  if (shouldTrack) {
    Mixpanel.register(properties);
  }
}

function trackEventMixpanel(
  eventName: 'view' | 'viewWithDuration',
  eventProperties: Record<string, any>
) {
  console.debug(
    'Detected Mixpanel track request',
    eventName,
    eventProperties['genericPath'],
    eventProperties['durationMs']
  );
  if (shouldTrack) {
    Mixpanel.track(eventName, eventProperties, { transport: 'sendBeacon' });
  }
}

type LastViewMetadata = {
  lastViewEvent: object | null;
  lastViewEventTime: number | null;
};

function useTrackPageview(
  activeWatershedEmployee:
    | GQActiveWatershedEmployeeFieldsFragment
    | null
    | undefined
) {
  const router = useRouter();
  // `pathname` is the generic page path, e.g. `/org/[orgId]/flags`.
  const { pathname, isReady, query, asPath } = router;
  const asPathPath = asPath.split('?')[0];
  useEffect(() => {
    if (isReady) {
      const routeParams = getRouteParams(query, asPath);
      ReactGA.send({ hitType: 'pageview', page: pathname, ...routeParams });
    }
    // `router` is not referentially stable, and we really want to track a new
    // pageview when either the `pathname` or "route params" change - equivalent
    // to when the `asPathPath` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, asPathPath]);

  // Mixpanel version
  const lastViewMetadataRef = useRef<LastViewMetadata>({
    lastViewEvent: null,
    lastViewEventTime: null,
  });

  // A snippet of code which could be used in both useEffect's below. It's
  // pulled out here for consistency, and is NOT memoized to prevent any bugs
  // with the resulting memoization/closure/etc.
  const trackLastEventDuration = () => {
    const { lastViewEvent, lastViewEventTime } = lastViewMetadataRef.current;
    if (lastViewEvent && lastViewEventTime) {
      const durationMs = Date.now() - lastViewEventTime;
      trackEventMixpanel('viewWithDuration', {
        ...lastViewEvent,
        durationMs,
      });
    }
  };

  // Visibility changes - e.g. tab switching or tab closing
  useEffect(() => {
    const onFocus = () => {
      // If now visible, treat as a new pageview
      const { lastViewEvent } = lastViewMetadataRef.current;
      if (lastViewEvent) {
        const newViewEvent = { ...lastViewEvent, eventUuid: uuidv4() };
        lastViewMetadataRef.current = {
          lastViewEvent: newViewEvent,
          lastViewEventTime: Date.now(),
        };
        trackEventMixpanel('view', newViewEvent);
      }
    };

    const onBlur = () => {
      // If now hidden, track the last event duration
      trackLastEventDuration();
      lastViewMetadataRef.current = {
        ...lastViewMetadataRef.current,
        lastViewEventTime: null,
      };
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    if (isReady && activeWatershedEmployee) {
      trackLastEventDuration();

      const routeParams = getRouteParams(query, asPath);

      const pointOfContactKind = (() => {
        if (!routeParams || !(routeParams as any).orgId) {
          return undefined;
        }
        if (!activeWatershedEmployee) {
          return undefined;
        }

        const orgAndPocKind = activeWatershedEmployee.orgPointsOfContact.find(
          (orgAndPocKind) => orgAndPocKind.orgId === (routeParams as any).orgId
        );
        return orgAndPocKind?.kind;
      })();

      const mixpanelEvent = {
        eventType: 'view',
        eventUuid: uuidv4(),

        genericPath: pathname.split('?')[0],
        nongenericPath: asPathPath,
        genericPathWithQuery: pathname,
        nongenericPathWithQuery: asPath,

        query,
        routeParams,
        pointOfContactKind,

        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      };

      lastViewMetadataRef.current = {
        lastViewEvent: mixpanelEvent,
        lastViewEventTime: Date.now(),
      };
      trackEventMixpanel('view', mixpanelEvent);
    }
    // `router` is not referentially stable; we want to track when `asPathPath` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, asPathPath, activeWatershedEmployee]);
}
