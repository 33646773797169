import Icon, { IconProps } from '../Icon';
const AddIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M18.975 11.025h-6v-6A.993.993 0 0 0 12 4.05a.993.993 0 0 0-.975.975v6h-6A.993.993 0 0 0 4.05 12c0 .525.45.975.975.975h6v6c0 .525.45.975.975.975.525 0 .975-.45.975-.975v-6h6c.525 0 .975-.45.975-.975 0-.525-.375-.975-.975-.975Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default AddIcon;
