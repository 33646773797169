import { Box, Typography } from '@mui/material';
import HelpIcon from '@watershed/icons/components/Help';
import { DetailedErrorBox } from '@watershed/shared-frontend/components/DetailedError';

export default function NotFoundPage({ error }: { error: Error }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      alignItems="center"
      sx={{ padding: 8 }}
    >
      <HelpIcon size={128} marginBottom={1} />
      <Typography variant="h1" sx={{ my: 1 }}>
        Page not found
      </Typography>
      <Box mt={8}>
        <DetailedErrorBox error={error} />
      </Box>
    </Box>
  );
}
