import Icon, { IconProps } from '../Icon';
const FolderIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M1.879 2.879A3 3 0 0 1 4 2h3.935a3 3 0 0 1 2.485 1.342l.816 1.194.01.014a1 1 0 0 0 .827.45H20a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H4c-1.652 0-3-1.348-3-3V5a3 3 0 0 1 .879-2.121ZM4 4a1 1 0 0 0-1 1v13c0 .548.452 1 1 1h16a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-7.935A3 3 0 0 1 9.58 5.658l-.816-1.194-.01-.014A1 1 0 0 0 7.928 4H4Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default FolderIcon;
