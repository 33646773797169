import { ComponentType, forwardRef, ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  ButtonProps as MuiButtonProps,
  SxProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import type { WatershedLinkProps } from '../types/LinkProps';
import isExternalUrl, { isApiRouteUrl } from './isExternalUrl';
import { format as formatUrl } from 'url';
import { mixinSx } from '@watershed/style/styleUtils';
import usePaletteUtils from '../hooks/usePaletteUtils';
import { Theme } from '@mui/system';

interface CommonButtonProps
  extends Pick<
    MuiButtonProps,
    'sx' | 'ref' | 'disabled' | 'children' | 'fullWidth'
  > {}

export type WatershedButtonProps<Props extends CommonButtonProps> = Omit<
  Props,
  'to' | 'href'
> &
  Omit<WatershedLinkProps, 'onClick' | 'children'> & {
    id?: string;
    tooltip?: ReactNode;
    tooltipPlacement?: TooltipProps['placement'];
    isIcon?: boolean;
    tooltipDisableInteractive?: boolean;
    flat?: boolean;
    buttonWrapperSx?: SxProps<Theme>;
  };

export default function createWatershedButton<Props extends CommonButtonProps>(
  ButtonToWrap: ComponentType<CommonButtonProps>
) {
  return forwardRef<HTMLButtonElement, WatershedButtonProps<Props>>(
    function Button(
      {
        href,
        children,
        replace,
        as,
        scroll,
        shallow,
        prefetch,
        locale,
        newTab,
        tooltip,
        tooltipPlacement,
        buttonWrapperSx,
        isIcon,
        tooltipDisableInteractive,
        flat,
        sx = [],
        ...buttonProps
      },
      ref
    ) {
      const paletteUtils = usePaletteUtils();
      const hrefString = href ? formatUrl(href) : '';
      const isExternal = href !== undefined && isExternalUrl(hrefString);
      const isApiRoute = href !== undefined && isApiRouteUrl(hrefString);

      const component = (() => {
        if (href && !newTab && !isExternal && !isApiRoute) {
          return (
            <NextLink
              replace={replace}
              href={href}
              passHref
              as={as}
              scroll={scroll}
              shallow={shallow}
              prefetch={prefetch}
              locale={locale}
              legacyBehavior
            >
              <ButtonToWrap {...buttonProps} sx={sx} ref={ref}>
                {children}
              </ButtonToWrap>
            </NextLink>
          );
        }

        return (
          <ButtonToWrap
            href={href ? hrefString : undefined}
            {...buttonProps}
            {...(newTab ? { target: '_blank' } : {})}
            {...(isExternal
              ? { target: '_blank', rel: 'noopener noreferrer' }
              : {})}
            component={href ? 'a' : 'button'}
            ref={ref}
            sx={mixinSx(
              isIcon ? { minWidth: 32, padding: 0 } : undefined,
              flat && {
                boxShadow: paletteUtils.boxShadowField.base,
                '&:hover': { boxShadow: paletteUtils.boxShadowField.base },
                '&:focus': { boxShadow: paletteUtils.boxShadowField.base },
              },
              ...(Array.isArray(sx) ? sx : [sx])
            )}
          >
            {children}
          </ButtonToWrap>
        );
      })();

      if (tooltip) {
        return (
          <Tooltip
            title={tooltip}
            placement={tooltipPlacement}
            disableInteractive={tooltipDisableInteractive}
          >
            <Box
              tabIndex={buttonProps.disabled ? 0 : undefined}
              width={buttonProps.fullWidth ? '100%' : 'initial'}
              sx={mixinSx(
                {
                  borderRadius: 1,
                  '&:focus, &:focus-visible': {
                    outline: 'none',
                  },
                  '&:focus-visible': {
                    boxShadow: paletteUtils.boxShadowButton.focus,
                  },
                },
                buttonWrapperSx
              )}
            >
              {component}
            </Box>
          </Tooltip>
        );
      }

      return component;
    }
  );
}
