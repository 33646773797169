import {
  type UncapitalizedGridPremiumSlotsComponent,
  GridPagination,
  UNKNOWN_ROW_COUNT,
  useGridRootProps,
} from './DataGrid';
import { Trans, useLingui } from '@lingui/react/macro';
import {
  Stack,
  useTheme,
  type SxProps,
  type Theme,
  Typography,
  MenuItem,
} from '@mui/material';
import type { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import ChevronLeftIcon from '@watershed/icons/components/ChevronLeft';
import ChevronRightIcon from '@watershed/icons/components/ChevronRight';
import IconButton from '../IconButton';
import { WATERSHED_BIG_ROCK_DATA_GRID_ROW_HEIGHT } from './bigRockSx';
import { SelectFieldNonFormik } from '../Form/SelectField';
import useLocale from '@watershed/intl/frontend/useLocale';
import { formatNumber } from '@watershed/intl/formatters';

/**
 * Shows the same two chevrons for buttons but also adds a Page X picker between them.
 */
function WatershedPaginationActions(props: TablePaginationActionsProps) {
  const locale = useLocale();
  const numFormatOpts = {
    locale,
    maximumFractionDigits: 0,
  };
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, ...rest } = props;
  // NOTE: This can be `Infinity` if `rowsPerPage` is `0`, which happens when
  // the `autoPageSize` prop is used.
  const totalPages =
    count === UNKNOWN_ROW_COUNT ? null : Math.ceil(count / rowsPerPage);

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const { paginationMode } = useGridRootProps();

  // Selector for selecting any of the valid pages!
  const currPageWithSelector =
    totalPages && Number.isFinite(totalPages) ? (
      <SelectFieldNonFormik
        id="page-select-custom"
        value={page + 1}
        onChange={(event) => {
          onPageChange(
            event as unknown as React.MouseEvent<HTMLButtonElement>,
            (typeof event.target.value === 'string'
              ? parseInt(event.target.value, 10)
              : event.target.value) - 1
          );
        }}
        className="MuiTablePagination-select"
        sx={(theme) => ({
          '& .MuiInputBase-root, & .MuiSelect-select': {
            ...theme.typography.body3,
            lineHeight: 1.5,
            color: theme.palette.text.secondary,
          },
          display: 'inline-flex',
        })}
      >
        {Array.from({ length: totalPages }, (_, i) => (
          <MenuItem key={i} value={i + 1}>
            {formatNumber(i + 1, numFormatOpts)}
          </MenuItem>
        ))}
      </SelectFieldNonFormik>
    ) : null;

  const pageNum = formatNumber(page + 1, numFormatOpts);
  return (
    <Stack flexDirection="row" gap={1} {...rest} alignItems="center">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
      {
        // Jumping to specific pages is generally not supported with server-side cursor-based pagination
        paginationMode !== 'server' && (
          <Typography variant="body3">
            {totalPages ? (
              <>
                <Trans context="Page counter">
                  Page {currPageWithSelector} of {totalPages}
                </Trans>
              </>
            ) : (
              <Trans context="Page counter">Page {pageNum}</Trans>
            )}
          </Typography>
        )
      }
      <IconButton
        onClick={handleNextButtonClick}
        disabled={totalPages === null ? false : page >= totalPages - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </Stack>
  );
}

/**
 * Unlike default pagination, shows page count on left side, row limit in middle, and rows x-y of z on right side.
 */
function WatershedGridPagination(
  props: Record<string | number | symbol, unknown>
) {
  const locale = useLocale();
  const numFormatOpts = {
    locale,
    maximumFractionDigits: 0,
  };
  const { t } = useLingui();

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <GridPagination
        {...props}
        labelDisplayedRows={({ count, from, to }) => {
          const previewRowNumStart = formatNumber(from, numFormatOpts);
          const previewRowNumEnd = formatNumber(to, numFormatOpts);
          if (count === UNKNOWN_ROW_COUNT) {
            return t({
              message: `Showing rows ${previewRowNumStart}–${previewRowNumEnd}`,
              context: 'Indicates the rows displayed in a paginated table',
            });
          }

          const totalRowCount = formatNumber(count, numFormatOpts);
          return t({
            message: `Showing rows ${previewRowNumStart}–${previewRowNumEnd} of ${totalRowCount}`,
            context: 'Indicates the rows displayed in a paginated table',
          });
        }}
        labelRowsPerPage={t({
          message: 'Row limit per page:',
          context:
            'Indicates the number of rows displayed in a paginated table',
          comment: 'Precedes the row limit count',
        })}
        ActionsComponent={WatershedPaginationActions}
        slotProps={{
          select: {
            size: 'small',
          },
        }}
      />
    </Stack>
  );
}

/**
 * This styling applies to pagination alone. Creates a three column
 * layout, unless a row is selected. Also changes order of elements.
 */
export const bigRockPaginationSx: SxProps<Theme> = {
  '& .MuiDataGrid-footerContainer': {
    width: '100%', // Ensure pagination takes up full width
    justifyContent: 'stretch',
    minHeight: WATERSHED_BIG_ROCK_DATA_GRID_ROW_HEIGHT,

    '& > .MuiStack-root': {
      flexGrow: 1,
    },
  },

  '& .MuiTablePagination-spacer': {
    flex: 0, // we don't need this to flex with my new layout
  },

  '& .MuiTablePagination-toolbar': {
    paddingX: '16px', // Add padding around container
    width: '100%',
    justifyContent: 'space-between',
    gap: 1,
    minHeight: WATERSHED_BIG_ROCK_DATA_GRID_ROW_HEIGHT,

    '& > *': {
      order: 2, // everything by default should be between actions and displayedRows
    },
  },

  '& .MuiTablePagination-root': {
    width: '100%', // so hard to get it all stretched out
  },

  // Double ampersand to get more specificity
  '&& .MuiTablePagination-actions': {
    order: 1, // Move to left side of container
    marginLeft: 0,
    flexGrow: 1,
  },

  '& .MuiTablePagination-input': {
    marginRight: 0,
    marginLeft: 0, // offset for gap
  },

  '&& .MuiTablePagination-select': (theme) => ({
    ...theme.typography.body3,
    lineHeight: 1.5,
    color: theme.palette.text.secondary,
  }),

  '& .MuiTablePagination-selectIcon': {
    width: '16px',
    height: '16px',
  },

  '& .MuiTablePagination-selectLabel': (theme) => ({
    ...theme.typography.body3,
    marginY: 1,
  }),

  '& .MuiTablePagination-displayedRows': (theme) => ({
    ...theme.typography.body3,
    marginY: 1,
    order: 3, // Move to right side of container
    flexGrow: 1,
    textAlign: 'right',
  }),

  '& .MuiDataGrid-selectedRowCount': {
    display: 'none', // Hide selected row count
  },
};

/**
 * This slots object provides some default pagination component overrides and will be combined with the normal
 * passed slots from DataGrid use sites. These should not be overridden.
 */
export const bigRockPaginationSlots: Partial<UncapitalizedGridPremiumSlotsComponent> =
  {
    pagination: WatershedGridPagination,
  };
