import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { CustomSpanAttributes } from './constants/otelCustomAttributes';
// eslint-disable-next-line
// @ts-ignore
import Libhoney from 'libhoney';
import { environment } from '@watershed/config/env';

export type HoneycombEvent = {
  /**
   * Adds a group of field->values to this event.
   */
  add: (data: Record<string, any>) => HoneycombEvent;

  /**
   * Adds a single field->value mapping to this event.
   */
  addField: (name: string, value: any) => HoneycombEvent;

  /**
   * Sets the timestamp of the event (defaults to Date.now())
   */
  timestamp?: Date;

  /**
   * Sends this event to honeycomb, sampling if necessary.
   */
  send: () => void;
};

export type HoneycombConfig = {
  enabled: boolean;
  key: string;
  dataset: string;
  serviceName: string;
  environment: string;
  developerName?: string;
  /**
   * Attributes to be added to every event.
   */
  universalAttributes?: Record<string, any>;

  /**
   * Callback which runs before the event is sent. Useful
   * for adding additional common dynamic fields to all events.
   */
  beforeSend?: (event: HoneycombEvent) => void;
};

const noop = () => {};

export class Honeycomb {
  readonly hny;
  readonly eventBuilder;
  readonly disabled;
  readonly config;
  readonly beforeSend;

  constructor(config: HoneycombConfig) {
    this.config = config;
    this.disabled = config.enabled === false;

    if (this.disabled && environment() !== 'test') {
      console.warn('Honeycomb is disabled.');
    }

    this.beforeSend = config.beforeSend || noop;

    this.hny = new Libhoney({
      writeKey: config.key,
      dataset: config.dataset,
      disabled: this.disabled,
    });

    const universalAttributes: Record<string, any> = Object.assign(
      {},
      { [SemanticResourceAttributes.SERVICE_NAME]: config.serviceName },
      config.universalAttributes
    );

    if (config.environment === 'development' && config.developerName) {
      universalAttributes[CustomSpanAttributes.DEVELOPER_NAME] =
        config.developerName;
    }

    this.eventBuilder = this.hny.newBuilder(universalAttributes);
  }

  async sendEvent(
    eventData: Record<string, unknown>,
    timestamp?: Date
  ): Promise<void> {
    try {
      const hnyEvent = this.eventBuilder.newEvent() as HoneycombEvent;
      hnyEvent.timestamp = timestamp;
      this.beforeSend(hnyEvent);

      hnyEvent.add(eventData);
      hnyEvent.send();
    } catch (e) {
      console.warn(`failed to send honeycomb event ${e}`);
    }
  }
}
