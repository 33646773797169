import Icon, { IconProps } from '../Icon';
const DeleteIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Zm5 1a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0v-6Z" />
    <path
      fillRule="evenodd"
      d="M17 5V4c0-.825-.402-1.567-.918-2.082C15.567 1.403 14.825 1 14 1h-4c-.825 0-1.567.403-2.082.918C7.403 2.433 7 3.175 7 4v1H3a1 1 0 0 0 0 2h1v13c0 .825.403 1.567.918 2.082C5.433 22.597 6.175 23 7 23h10c.825 0 1.567-.402 2.082-.918.515-.515.918-1.257.918-2.082V7h1a1 1 0 1 0 0-2h-4ZM9.332 3.332C9.097 3.567 9 3.825 9 4v1h6V4c0-.175-.098-.433-.332-.668C14.433 3.097 14.175 3 14 3h-4c-.175 0-.433.097-.668.332ZM6 7v13c0 .175.097.433.332.668.235.235.493.332.668.332h10c.175 0 .433-.098.668-.332.235-.235.332-.493.332-.668V7H6Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default DeleteIcon;
