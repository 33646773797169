import type { ReactNode } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Button as MuiButton,
  // eslint-disable-next-line no-restricted-imports
  ButtonProps as MuiButtonProps,
  TooltipProps,
} from '@mui/material';
import type { WatershedLinkProps } from '../types/LinkProps';
import createWatershedButton from '../utils/createWatershedButton';

export type ButtonProps = Omit<
  MuiButtonProps,
  | 'components'
  | 'componentsProps'
  | 'to'
  | 'href'
  // We're not using the `tw` experimental Tailwind prop, which is marked as
  // optional in `MuiButtonProps`. But for some reason, when we introduced
  // `next-env.d.ts` to `ui-core`, it started causing this bizarre type error,
  // so we'll just omit it here.
  // More details:
  // https://github.com/watershed-climate/ghg/pull/40050#issuecomment-1673322341
  | 'tw'
> &
  // link props makes children required whereas button props makes children optional
  Omit<WatershedLinkProps, 'onClick' | 'children'> & {
    tooltip?: ReactNode;
    tooltipPlacement?: TooltipProps['placement'];
    isIcon?: boolean;
  };
const Button = createWatershedButton<ButtonProps>(MuiButton);
export default Button;
