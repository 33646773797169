import Icon, { IconProps } from '../Icon';
const ServerIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M5 6a1 1 0 0 1 1-1h.01a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1Z" />
    <path
      fillRule="evenodd"
      d="M4 1a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H4ZM3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Z"
      clipRule="evenodd"
    />
    <path d="M6 17a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H6Z" />
    <path
      fillRule="evenodd"
      d="M4 13a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-4a3 3 0 0 0-3-3H4Zm-1 3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-4Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ServerIcon;
