import Icon, { IconProps } from '../Icon';
const ColumnIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M5 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5Zm0 2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3V4H5Zm5 0v16h4V4h-4Zm6 0v16h3a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-3Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ColumnIcon;
