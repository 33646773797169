import { useResizeObserverWithPredicate } from '@watershed/shared-frontend/hooks/useResizeObserverWithPredicate';
import { SetStateAction, useState, Dispatch } from 'react';

function elementIsOverflowingHorizontal(element: HTMLElement) {
  return element.scrollWidth > element.clientWidth;
}

function elementIsOverflowingVertical(element: HTMLElement) {
  return element.scrollHeight > element.clientHeight;
}

function overflowingHorizontalPredicate(entry: ResizeObserverEntry) {
  return elementIsOverflowingHorizontal(entry.target as HTMLElement);
}

function overflowingVerticalPredicate(entry: ResizeObserverEntry) {
  return elementIsOverflowingVertical(entry.target as HTMLElement);
}

export interface ElementIsOverflowingOptions<T extends HTMLElement> {
  /**
   * The element to observe for overflow. If this is provided, the ref returned by
   * this hook will be set to this element.
   *
   * The use-case for this is if you already have a ref to the element you want
   * to observe, and can't use the one created by this hook.
   */
  elementToObserve?: T;

  /**
   * Whether to check for vertical overflow instead of horizontal.
   * Defaults to false.
   */
  vertical?: boolean;
}

/**
 * Hook that indicates whether the element is overflowing. Useful for
 * e.g. showing a tooltip when an item's text is truncated.
 *
 * @param opts - An instance of `ElementIsOverflowingOptions`.
 * @returns {[Dispatch<SetStateAction<T | null>>, boolean]} A tuple containing
 * the ref to the element and a boolean indicating whether it is overflowing.
 *
 * @example
 * ```ts
 * const [ref, isOverflowing] = useElementIsOverflowing();
 * return <div ref={ref}>...</div>;
 * ```
 *
 */
export function useElementIsOverflowing<T extends HTMLElement>(
  opts: ElementIsOverflowingOptions<T> = {}
): [Dispatch<SetStateAction<T | null>>, boolean] {
  const { elementToObserve, vertical } = opts;

  const [refValue, ref] = useState<T | null>(elementToObserve || null);

  const predicate = vertical
    ? overflowingVerticalPredicate
    : overflowingHorizontalPredicate;

  const isOverflowing = useResizeObserverWithPredicate(predicate, {
    observedElement: refValue,
  });

  return [ref, isOverflowing] as const;
}
