import z from 'zod';
import { Immutable } from 'immer';
import { JSONSchemaType, SomeJSONSchema } from './JsonSchemaType';
import { FUNCTIONAL_UNIT_NODE_NAME, NAME_PATTERN } from './constants';
import { ParameterResolver } from './parameterResolverSchema';
import {
  SIMULATION_PARAMETER_TYPES,
  SimulationParameterType,
} from './simulation';
import invariant from 'invariant';
import {
  EmissionsKindId,
  EMISSIONS_KIND_IDS,
} from './emissionsKindDefinitions';
import { ResultKindId, RESULT_KIND_IDS } from './resultKindDefinitions';
import must from '../utils/must';

export const LATEST_EMISSIONS_MODEL_SCHEMA_VERSION = '2024-05-31.1';
/**
 * CHANGELOG
 * ====================================
 * - 2024-05-31.1: Remove unused result kinds for pollutant + microplastics
 * - 2024-05-28.1: For emissions make unit a non-nullable string rather than hardcoded kgco2e
 * - 2024-05-23.1: Migrate from emissionsKinds array to a single emissionsKind 
 * - 2024-04-29.1: Add emission kinds for clean power avoided emissions
 * - 2024-04-10.1: Add 4 result kinds for microplastics/pollutant inflows + outflows
 * - 2024-02-14.1: Remove 'kg_of_landfilled_waste' result kind.
 * - 2024-01-23.1: Add 3 result kinds for water verbs
 * - 2023-11-20.1: Add result kind for microplastics
 * - 2023-11-10.1: Add result kinds for pollutants and for waste
 * - 2023-10-24.1: Force emissions variables unit to be kgco2e (not ever kilogram[co2e]).
 * - 2023-10-19.2: Rename __typename to __emTypename to avoid future conflict with gql.
 * - 2023-10-19.1:
 *       - ModelParameter fields {nullable, displayName, unit} are no longer optional.
 *       - ModelParameter displayName is no longer nullable.
 *       - ModelParameter and Unbound Parameter add a new __typename field.
 *       - UnboundParameter gets a non-nullable displayName and a nullable description.
 * - 2023-10-16.1: Add UnboundParameters array.
 * - 2023-10-10.1: Mark parameters as emissions factors when they're used that way.
 * - 2023-09-31.2: Add new result kind for kg of landfilled waste
 * - 2023-09-31.1: Add `date` and `datetime` types to ModelParameter.
 * - 2023-06-13.1: Block displayName and description fields on ResultVariables.
 * - 2023-06-09.2: Remove emissions-related result kinds.
 * - 2023-06-09.1: Remove `expression` from EmissionsVariable and make the newer
 *   fields required.
 * - 2023-06-06.2: Remove `displayName` and `description` from ResultVariable.
 * - 2023-06-06.1: Enforce result kind enum with JSON Schema validation.
 * - 2023-05-26.2: Add `emissions` emissions kind, and remove
 *   `emissions_market`.
 * - 2023-05-26.1: Block resultKind field on variables that aren't
 *   ResultVariables. Camelcase the newish EmissionsVariable properties. Rename
 *   emissionsTags to emissionsKinds.
 * - 2023-05-24.1: Update EmissionsVariable to make expression optional and add
     the following optional fields: emissions_tags, emissions_factor,
     emissions_facto_unit, activity_expression, activity_unit. This is part 1 of
     a migration.
 * - 2023-04-13.1: Delete labels now that they're fully migrated to regions.
 * - 2023-03-07.1: Migrate labels to a single region.
 * - 2023-02-17.1: Add hasEmissionsInCorporateFootprint to ProcessNode.
 * - 2023-02-09.1: Add SimulationParameterType to parameters.
 * - 2023-02-02.1: Add regions.
 * - 2023-01-19.1: Delete functional unit sockets and edges to support implicit
     functional unit sockets.
 * - 2023-01-18.1: Make socketName required in SocketEdgeEndpoint.
 * - 2023-01-05.2: Add sockets. Remove ioEndpoints. Require scope.
 * - 2023-01-05.1: Update schemaVersion to date-based format.
 * - 1.0.5: Add fields for scope and ioEndpoints.
 * - 1.0.4: Require imports nodes array.
 * - 1.0.3: Require import node parameterOverrides array.
 * - 1.0.2: Require parameters array.
 * - 1.0.1: Change emissionsModelInstanceId to emissionsModelVersionId.
 * - 1.0.0: Initial version.
 */

export const genericProcessKinds = [
  'Production (generic)',
  'Supply (generic)',
  'Transportation (generic)',
  'Treatment (generic)',
  'Use (generic)',
  'Well-to-tank (generic)',
] as const;

export const electricityProcessKinds = [
  'Electricity distribution',
  'Electricity production',
  'Electricity transmission & distribution loss',
  'Electricity well-to-tank',
] as const;

export const employeeProcessKinds = ['Commute', 'Waste'] as const;

export const travelProcessKinds = ['Commercial flight'] as const;

export const shoeProductionProcessKinds = [
  'Shoe upper production',
  'Shoe lining production',
  'Shoe midsole production',
  'Shoe outsole production',
] as const;

export const cloudComputingProcessKinds = [
  'Computing',
  'Power usage efficiency',
  'Networking',
] as const;

// these are Warby Parker specific glasses process kinds
export const glassesProcessKinds = [
  '[Warby] Edging mounting and assembling of product kit',
  '[Warby] Manufacture of cleaning cloth',
  '[Warby] Manufacture of frames and demo lenses',
  '[Warby] Manufacture of inner box',
  '[Warby] Manufacture of inner pamphlet',
  '[Warby] Manufacture of metal case',
  '[Warby] Manufacture of optical lens',
  '[Warby] Manufacture of shipper',
] as const;

// these are FarFetch specific process kinds
export const farfetchProcessKinds = ['FarFetch material EF'] as const;

// See https://howtohigg.org/higg-msi/synthetic-leather
const higgSyntheticLeatherProcessKinds = [
  'Synthetic leather production',
  'Synthetic leather PU resin production',
  'Synthetic leather specialty application',
  'Synthetic leather substrate formation',
  'Synthetic leather substrate raw material production',
] as const;

const processKindsUnsorted = [
  ...genericProcessKinds,
  ...electricityProcessKinds,
  ...employeeProcessKinds,
  ...travelProcessKinds,
  ...shoeProductionProcessKinds,
  ...glassesProcessKinds,
  ...farfetchProcessKinds,
  ...cloudComputingProcessKinds,
  ...higgSyntheticLeatherProcessKinds,
  // This process is meant for Consumer Goods models usage to label product / material quantities
  // on local variables. It should not be used for customer processes
  '[WCG][Internal] Quantity Labeling',
  // unsorted processes
  'Assembly',
  'Boarding',
  'Bonding',
  'Capital expenditure',
  'Carbon removal',
  'Conversion',
  'Cut and sew',
  'Detergent production',
  'Direct ghg emissions',
  'District heating transmission & distribution loss',
  'District heating well-to-tank',
  'District heating',
  'Dry cleaning',
  'Drying',
  'Dye production',
  'End of life',
  'Expenditure',
  'Fabric coloration',
  'Fabric manufacturing',
  'Fabric preparation',
  'Finished material processing',
  'Finishing',
  'Foaming',
  'Forming',
  'Fuel combustion',
  'Fuel well-to-tank',
  'Injection Molding',
  'Leather finishing',
  'Liquid carbon dioxide production',
  'Lodging',
  'Membrane and film creation',
  'Mixing and preparation',
  'Molding and curing',
  'Molding and pouring',
  'Raw material production',
  'Re-tanning',
  'Refrigerant leakage',
  'Shaping',
  'Stationary combustion',
  'Tanning',
  'Textile formation',
  'Thermal energy production',
  'Transport between processes',
  'Transport to customer',
  'Transport to end of life',
  'Use phase',
  'Water usage',
  'Yarn formation',
] as const;
export type ProcessKind = (typeof processKindsUnsorted)[number];

export const processKinds: Immutable<Array<ProcessKind>> = processKindsUnsorted
  .slice()
  .sort();

export const variableKinds = [
  'input',
  'output',
  'emissions',
  'result',
  'local',
] as const;

export type VariableKind = (typeof variableKinds)[number];

export const processVariableListKeys = [
  'inputs',
  'outputs',
  'emissions',
  'results',
  'locals',
] as const;
export type ProcessVariableListKey = (typeof processVariableListKeys)[number];

/**
 * Maps a VariableListKey to its corresponding VariableKind.
 */
export const variableListKeyToVariableKind = {
  inputs: 'input',
  outputs: 'output',
  emissions: 'emissions',
  results: 'result',
  locals: 'local',
} as const;

/**
 * Maps a VariableKind to its corresponding VariableListKey.
 */
export const variableKindToVariableListKey = {
  input: 'inputs',
  output: 'outputs',
  emissions: 'emissions',
  result: 'results',
  local: 'locals',
} as const;

/**
 * Maps a ProcessVariableListKey to the corresponding ProcessVariable type.
 */
export interface VariableListKeyToVariableType {
  inputs: InputVariable;
  outputs: OutputVariable;
  emissions: EmissionsVariable;
  results: ResultVariable;
  locals: LocalVariable;
}

/**
 * Returns a boolean indicating whether the input is a ProcessVariableListKey,
 * and type-guards to that effect.
 */
export function isProcessVariableListKey(
  input: string
): input is ProcessVariableListKey {
  const stringList: Array<string> = [...processVariableListKeys];
  return stringList.includes(input);
}

export type EmissionsVariable = {
  name: string;
  displayName: string;
  description?: string | null;
  unit: string;
  emissionsKind: EmissionsKindId;
  emissionsFactor: string;
  emissionsFactorUnit: string;
  activityExpression: string;
  activityUnit?: string | null;
};

export type ResultVariable = {
  name: string;
  unit?: string | null;
  expression: string;
  resultKind: ResultKindId;
};

export type InputVariable = {
  name: string;
  displayName: string;
  description?: string | null;
  unit?: string | null;
  expression?: string | null;
};

export type OutputVariable = {
  name: string;
  displayName: string;
  description?: string | null;
  unit?: string | null;
  expression?: string | null;
};

export type LocalVariable = {
  name: string;
  displayName: string;
  description?: string | null;
  unit?: string | null;
  expression: string;
};

export type ProcessVariable =
  | EmissionsVariable
  | ResultVariable
  | LocalVariable
  | InputVariable
  | OutputVariable;

const nodeVariableSchemaProperties = {
  name: { type: 'string', pattern: NAME_PATTERN },
  displayName: { type: 'string' },
  description: { type: 'string', nullable: true },
  unit: { type: 'string', nullable: true },
} as const;

const nodeVariableSchemaWithOptionalExpression = {
  type: 'object',
  properties: {
    ...nodeVariableSchemaProperties,
    expression: { type: 'string', nullable: true },
  },
  required: ['name', 'displayName'],
  additionalProperties: false,
} as const;

const nodeVariableSchemaWithRequiredExpression = {
  type: 'object',
  properties: {
    ...nodeVariableSchemaProperties,
    expression: { type: 'string' },
  },
  required: ['name', 'displayName', 'expression'],
  additionalProperties: false,
} as const;

const emissionsVariableSchema: JSONSchemaType<EmissionsVariable> = {
  type: 'object',
  properties: {
    ...nodeVariableSchemaProperties,
    unit: { type: 'string' },
    emissionsKind: {
      type: 'string',
      enum: EMISSIONS_KIND_IDS,
    },
    emissionsFactor: { type: 'string' },
    emissionsFactorUnit: { type: 'string' },
    activityExpression: { type: 'string' },
    activityUnit: { type: 'string', nullable: true },
  },
  required: [
    'name',
    'emissionsKind',
    'displayName',
    'emissionsFactor',
    'emissionsFactorUnit',
    'activityExpression',
  ],
  additionalProperties: false,
} as const;

const resultVariableSchema: JSONSchemaType<ResultVariable> = {
  type: 'object',
  properties: {
    name: { type: 'string', pattern: NAME_PATTERN },
    unit: { type: 'string', nullable: true },
    expression: { type: 'string' },
    resultKind: { type: 'string', enum: RESULT_KIND_IDS },
  },
  required: ['name', 'expression', 'resultKind'],
  additionalProperties: false,
} as const;

export type ProcessNode = {
  name: string;
  displayName: string;
  description?: string | null;
  kind: ProcessKind;
  inputs?: Array<InputVariable>;
  outputs?: Array<OutputVariable>;
  emissions?: Array<EmissionsVariable>;
  results?: Array<ResultVariable>;
  hasEmissionsInCorporateFootprint?: boolean | null;
  locals?: Array<LocalVariable>;
};

const processSchema: JSONSchemaType<ProcessNode> = {
  type: 'object',
  properties: {
    name: { type: 'string', pattern: NAME_PATTERN },
    displayName: { type: 'string' },
    description: { type: 'string', nullable: true },
    kind: { type: 'string' },
    inputs: { type: 'array', items: nodeVariableSchemaWithOptionalExpression },
    outputs: { type: 'array', items: nodeVariableSchemaWithOptionalExpression },
    emissions: { type: 'array', items: emissionsVariableSchema },
    results: { type: 'array', items: resultVariableSchema },
    hasEmissionsInCorporateFootprint: { type: 'boolean', nullable: true },
    locals: { type: 'array', items: nodeVariableSchemaWithRequiredExpression },
  },
  required: ['name', 'displayName', 'kind'],
  additionalProperties: false,
} as const;

export type ProcessVariableEdgeEndpoint = {
  process: {
    name: string;
    variableName: string;
  };
};

const processVariableEdgeEndpointSchema: JSONSchemaType<ProcessVariableEdgeEndpoint> =
  {
    type: 'object',
    properties: {
      process: {
        type: 'object',
        properties: {
          name: { type: 'string', pattern: NAME_PATTERN },
          variableName: { type: 'string', pattern: NAME_PATTERN },
        },
        required: ['name', 'variableName'],
        additionalProperties: false,
      },
    },
    required: ['process'],
    additionalProperties: false,
  } as const;

export type FunctionalUnitEdgeEndpoint = { functionalUnit: {} };

const functionalUnitEdgeEndpointSchema: JSONSchemaType<FunctionalUnitEdgeEndpoint> =
  {
    type: 'object',
    properties: {
      functionalUnit: {
        type: 'object',
        properties: {},
        additionalProperties: false,
      },
    },
    required: ['functionalUnit'],
    additionalProperties: false,
  } as const;

type ImportFuEdgeEndpoint = {
  import: {
    name: string;
    socketName: typeof FUNCTIONAL_UNIT_NODE_NAME;
  };
};
export type ImportEdgeEndpoint =
  | {
      import: {
        name: string;
        socketName: string;
      };
    }
  | ImportFuEdgeEndpoint;

const socketKinds = ['input', 'output', 'functionalUnit'] as const;
export type SocketKind = (typeof socketKinds)[number];

// this socket will not exist on a model, but is a utility
// for validation and other places where we work with the
// implicit functional unit socket
export function makeFuSocket(unit?: string | null): Socket {
  return {
    name: FUNCTIONAL_UNIT_NODE_NAME,
    kind: 'functionalUnit',
    unit,
  };
}
export type Socket = { name: string; kind: SocketKind; unit?: string | null };

const socketSchema: JSONSchemaType<Socket> = {
  type: 'object',
  properties: {
    name: {
      anyOf: [
        { type: 'string', pattern: NAME_PATTERN },
        { type: 'string', const: FUNCTIONAL_UNIT_NODE_NAME },
      ],
    },
    kind: { type: 'string', enum: socketKinds },
    unit: { type: 'string', nullable: true },
  },
  required: ['name', 'kind'],
  additionalProperties: false,
};

export type SocketEdgeEndpoint = { socket: { name: string } };

const socketEdgeEndpointSchema: JSONSchemaType<SocketEdgeEndpoint> = {
  type: 'object',
  properties: {
    socket: {
      type: 'object',
      properties: {
        name: { type: 'string', pattern: NAME_PATTERN },
      },
      required: ['name'],
      additionalProperties: false,
    },
  },
  required: ['socket'],
  additionalProperties: false,
};

export type RegionAttribute = { name: string; expression: string };

export type Region = {
  name: string;
  displayName: string;
  description?: string | null;
  // Strings are `name`s of process and import nodes.
  nodeNames: Array<string>;
  processKind?: ProcessKind | null;
  // The expressions have access to model parameters (and cannot perform
  // lookups). Example: {"name":"material", "expression":"param1"}.
  attributes?: Array<RegionAttribute>;
  subregions?: Array<Region>;
};

const regionSchema: JSONSchemaType<Region> = {
  type: 'object',
  properties: {
    name: { type: 'string', pattern: NAME_PATTERN },
    displayName: { type: 'string' },
    description: { type: 'string', nullable: true },
    nodeNames: { type: 'array', items: { type: 'string' } },
    processKind: { type: 'string', enum: processKinds, nullable: true },
    attributes: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string', pattern: NAME_PATTERN },
          expression: { type: 'string' },
        },
        required: ['name', 'expression'],
        additionalProperties: false,
      },
      nullable: true,
    },
    subregions: {
      type: 'array',
      items: {
        $ref: '#/definitions/region',
      },
      nullable: true,
    },
  },
  required: ['name', 'displayName', 'nodeNames'],
  additionalProperties: false,
};

export function getSubbiestRegionForNode(
  emissionModel: Immutable<EmissionsModel>,
  node: EmissionsModelNode
): Immutable<Region> | null {
  if (isFunctionalUnitNode(node)) {
    return null;
  }
  const nodeName: string = node.name;

  // get narrowest/subbiest subregion with node
  // can return the input region
  // assumes that input region contains node
  function getSubbiestSubregion(region: Immutable<Region>): Immutable<Region> {
    if (region.subregions) {
      for (const subregion of region.subregions) {
        if (subregion.nodeNames.includes(nodeName)) {
          return getSubbiestSubregion(subregion);
        }
      }
    }
    return region;
  }

  // get most nested region (currently only does top-level region)
  for (const region of emissionModel.regions) {
    if (region.nodeNames.includes(nodeName)) {
      return getSubbiestSubregion(region);
    }
  }

  return null;
}

/**
 * Returns a boolean indicating if an edge end is a ProcessEdgeEndpoint.
 */
export function isProcessVariableEdgeEndpoint<
  T extends Immutable<EdgeEndpoint>,
>(edgeEndpoint: T): edgeEndpoint is Extract<T, ProcessVariableEdgeEndpoint> {
  return 'process' in edgeEndpoint;
}

/**
 * Returns a boolean indicating if an edge end is a FunctionalUnitEdgeEndpoint.
 */
export function isFunctionalUnitEdgeEndpoint<T extends Immutable<EdgeEndpoint>>(
  edgeEndpoint: T
): edgeEndpoint is Extract<T, FunctionalUnitEdgeEndpoint> {
  return 'functionalUnit' in edgeEndpoint;
}

/**
 * Returns a boolean indicating if an edge end is an ImportEdgeEndpoint.
 */
export function isImportEdgeEndpoint<T extends Immutable<EdgeEndpoint>>(
  edgeEndpoint: T
): edgeEndpoint is Extract<T, ImportEdgeEndpoint> {
  return 'import' in edgeEndpoint;
}

/**
 * Returns a boolean indicating if an edge end is a SocketEdgeEndpoint.
 */
export function isSocketEdgeEndpoint<T extends Immutable<EdgeEndpoint>>(
  edgeEndpoint: T
): edgeEndpoint is Extract<T, SocketEdgeEndpoint> {
  return 'socket' in edgeEndpoint;
}

/**
 * Returns a boolean indicating if an edge endpoint is the functional unit of an
 * imported descendant model.
 */
export function isImportedFunctionalUnitEdgeEndpoint<
  T extends Immutable<EdgeEndpoint>,
>(edgeEndpoint: T): edgeEndpoint is Extract<T, ImportFuEdgeEndpoint> {
  if (isImportEdgeEndpoint(edgeEndpoint)) {
    return edgeEndpoint.import.socketName === FUNCTIONAL_UNIT_NODE_NAME;
  }
  return false;
}

const importEdgeEndpointSchema: JSONSchemaType<ImportEdgeEndpoint> = {
  type: 'object',
  properties: {
    import: {
      type: 'object',
      properties: {
        name: { type: 'string', pattern: NAME_PATTERN },
        socketName: { type: 'string' },
      },
      required: ['name', 'socketName'],
      additionalProperties: false,
    },
  },
  required: ['import'],
  additionalProperties: false,
} as const;

export type EdgeEndpoint =
  | ProcessVariableEdgeEndpoint
  | FunctionalUnitEdgeEndpoint
  | ImportEdgeEndpoint
  | SocketEdgeEndpoint;

const edgeEndpointSchema: JSONSchemaType<EdgeEndpoint> = {
  type: 'object',
  oneOf: [
    processVariableEdgeEndpointSchema,
    functionalUnitEdgeEndpointSchema,
    importEdgeEndpointSchema,
    socketEdgeEndpointSchema,
  ],
} as const;

export const edgeEndpointKinds = ['source', 'target'] as const;
export type EdgeEndpointKind = (typeof edgeEndpointKinds)[number];

/**
 * Asserts that a value is an EdgeEndpointKind.
 */
export function assertIsEdgeEndpointKind(
  input: unknown
): asserts input is EdgeEndpointKind {
  invariant(
    input === 'source' || input === 'target',
    `${JSON.stringify(input)} is not an EdgeEndpointKind`
  );
}

export type Edge = {
  source: EdgeEndpoint;
  target: EdgeEndpoint;
};

const edgeSchema: JSONSchemaType<Edge> = {
  type: 'object',
  properties: {
    source: edgeEndpointSchema,
    target: edgeEndpointSchema,
  },
  required: ['source', 'target'],
  additionalProperties: false,
} as const;

export type FunctionalUnitNode = {
  displayName: string;
  description?: string | null;
  unit?: string | null;
};

const functionalUnitSchema: JSONSchemaType<FunctionalUnitNode> = {
  type: 'object',
  properties: {
    displayName: { type: 'string' },
    description: { type: 'string', nullable: true },
    unit: { type: 'string', nullable: true },
  },
  required: ['displayName'],
  additionalProperties: false,
} as const;

export type ImportParameterOverride = {
  name: string;
  expression: string;
};

const importParameterOverrideSchema: JSONSchemaType<ImportParameterOverride> = {
  type: 'object',
  properties: {
    name: { type: 'string', pattern: NAME_PATTERN },
    expression: { type: 'string' },
  },
  required: ['name', 'expression'],
  additionalProperties: false,
} as const;

export type ImportNode = {
  displayName: string;
  name: string;
  emissionsModelVersionId: string;
  parameterOverrides: Array<ImportParameterOverride>;
};

export type EmissionsModelNode = FunctionalUnitNode | ProcessNode | ImportNode;

const importSchema: JSONSchemaType<ImportNode> = {
  type: 'object',
  properties: {
    displayName: { type: 'string' },
    name: { type: 'string', pattern: NAME_PATTERN },
    emissionsModelVersionId: { type: 'string' },
    parameterOverrides: {
      type: 'array',
      items: importParameterOverrideSchema,
    },
  },
  required: [
    'displayName',
    'name',
    'emissionsModelVersionId',
    'parameterOverrides',
  ],
  additionalProperties: false,
} as const;

/**
 * Model parameters
 */

export type ModelParameterSimulationConfig = {
  type: SimulationParameterType;
};

export const modelParameterTypes = [
  'number',
  'string',
  'boolean',
  'date',
  'datetime',
] as const;
export type ModelParameterType = (typeof modelParameterTypes)[number];

/** Shared elements of model (bound) parameters and unbound parameters */
export type BaseParameter = {
  name: string;
  type: ModelParameterType;
  unit: string | null;
  nullable: boolean;
  displayName: string;
  description?: string | null;
};

const baseParameterSchema: JSONSchemaType<BaseParameter> = {
  type: 'object',
  properties: {
    name: { type: 'string', pattern: NAME_PATTERN },
    type: { type: 'string', enum: modelParameterTypes },
    unit: { type: 'string', nullable: true },
    nullable: { type: 'boolean' },
    displayName: { type: 'string' },
    description: { type: 'string', nullable: true },
  },
  required: ['name', 'type', 'unit', 'nullable', 'displayName'],
  additionalProperties: false,
};

export type UnboundParameter = BaseParameter & {
  __emTypename: 'UnboundParameter';
};

// We have cause to parse unbound parameters out of strings in a few places,
// so we add this simple zod schema. The return type is our main-line type
// for UnboundParameters, so we should be insured against any changes to the
// main-line EM schema.
const zodUnboundParameterSchema = z.object({
  name: z.string(),
  type: z.enum(modelParameterTypes),
  unit: z.string().nullable(),
  displayName: z.string().default(''),
  description: z.string().optional().nullable(),
  nullable: z.boolean(),
});

export function zodParseUnboundParameter(raw: object): UnboundParameter {
  const parsed = zodUnboundParameterSchema.parse(raw);
  return { ...parsed, __emTypename: 'UnboundParameter' };
}

export function zodParseUnboundParameterArray(
  raw: object
): Array<UnboundParameter> {
  return z
    .array(zodUnboundParameterSchema)
    .parse(raw)
    .map((p) => ({ ...p, __emTypename: 'UnboundParameter' }));
}

const unboundParameterSchema: JSONSchemaType<UnboundParameter> = {
  type: 'object',
  properties: {
    ...must(baseParameterSchema.properties),
    __emTypename: { type: 'string', const: 'UnboundParameter' },
  },
  required: ['name', 'type', 'unit', 'nullable', 'displayName', '__emTypename'],
};

export type ModelParameter = BaseParameter & {
  // If set, indicates that this parameter can be used for simulation.
  simulationConfig?: ModelParameterSimulationConfig | null;
  isEmissionsFactor?: boolean | null;
  __emTypename: 'ModelParameter';
};

const modelParameterSchema: JSONSchemaType<ModelParameter> = {
  type: 'object',
  properties: {
    ...must(baseParameterSchema.properties),
    simulationConfig: {
      type: 'object',
      properties: {
        type: { type: 'string', enum: SIMULATION_PARAMETER_TYPES },
      },
      required: ['type'],
      additionalProperties: false,
      nullable: true,
    },
    isEmissionsFactor: { type: 'boolean', nullable: true },
    __emTypename: { type: 'string', const: 'ModelParameter' },
  },
  required: ['name', 'type', 'unit', 'nullable', 'displayName', '__emTypename'],
  additionalProperties: false,
};

export const modelScopes = ['full', 'partial'] as const;
export type ModelScope = (typeof modelScopes)[number];

export type Nodes = {
  functionalUnit: FunctionalUnitNode;
  processes: Array<ProcessNode>;
  imports: Array<ImportNode>;
};

/**
 * Emissions model
 */
export type EmissionsModel = {
  schemaVersion: string;
  nodes: Nodes;
  edges: Array<Edge>;
  parameters: Array<ModelParameter>;
  unboundParameters: Array<UnboundParameter>;
  scope: ModelScope;
  sockets: Array<Socket>;
  regions: Array<Region>;
};

export const emissionsModelSchema: JSONSchemaType<EmissionsModel> = {
  type: 'object',
  definitions: {
    // TODO: Weird that we have to do this typecast. Will discuss with David when he's back from OOO
    region: regionSchema as SomeJSONSchema,
  },
  properties: {
    schemaVersion: {
      type: 'string',
      pattern: '^\\d{4}-\\d{2}-\\d{2}\\.\\d+$',
    },
    nodes: {
      type: 'object',
      properties: {
        functionalUnit: functionalUnitSchema,
        processes: { type: 'array', items: processSchema },
        imports: { type: 'array', items: importSchema },
      },
      required: ['functionalUnit', 'processes', 'imports'],
      additionalProperties: false,
    },
    edges: { type: 'array', items: edgeSchema },
    parameters: { type: 'array', items: modelParameterSchema },
    unboundParameters: { type: 'array', items: unboundParameterSchema },
    scope: { type: 'string', enum: modelScopes },
    sockets: { type: 'array', items: socketSchema },
    regions: { type: 'array', items: regionSchema },
  },
  required: [
    'schemaVersion',
    'nodes',
    'edges',
    'parameters',
    'unboundParameters',
    'scope',
    'sockets',
    'regions',
  ],
  additionalProperties: false,
} as const;

/**
 * Returns a boolean indicating whether a node is a functional unit node, and
 * type narrows accordingly.
 */
export function isFunctionalUnitNode(
  node: Immutable<EmissionsModelNode>
): node is Immutable<FunctionalUnitNode> {
  return 'name' in node === false;
}

/**
 * Returns a boolean indicating whether a model node is an ImportNode, and
 * guards for that type.
 */
export function isImportNode(node: EmissionsModelNode): node is ImportNode {
  return 'emissionsModelVersionId' in node;
}

/**
 * Indicates whether a model node is a ProcessNode, and guards for that type.
 */
export function isProcessNode(node: EmissionsModelNode): node is ProcessNode {
  return 'name' in node && 'kind' in node;
}

export type ImportedDescendant = {
  id: string;
  title: string;
  description: string;
  model: EmissionsModel;
  parameterResolver: ParameterResolver;
  createdAt: Date;
  changelog: string;
  stableModel: {
    id: string;
    latestPublishedVersion: {
      id: string;
      createdAt: Date;
      changelog: string;
    };
  };
};
