import isPlainObject from 'lodash/isPlainObject';
import debug from './debug';

export const EqualityFunction = Symbol('equals');

type EqualityFn = <T>(this: T, b: T, gas?: GasRef) => boolean;

export type GasRef = { gas: number };

// Samesies only returns true if two objects are in practice the same, i.e., it
// would be difficult to tell them apart as a normal javascript user interacting
// with them in normal ways. It assumes the passed objects were constructed by
// sane, ordinary, well-meaning javascript engineers who are not out to destroy
// you and everything you love. It might return false for objects that you think
// are samesies, so your code should be prepared to deal with that.
//
// This function makes some effort to not be expensive. It would rather tell you
// that two values are not-samesies than spend a bunch of time walking your
// objects to find out.
export function samesies<T>(a: T, b: T, gas?: GasRef): boolean {
  if (gas === undefined) {
    gas = { gas: 10000 };
  }
  if (gas.gas-- <= 0) {
    debug(() => {
      console.info('[SAMESIES] ran out of gas');
    });
    return false;
  }

  // Javascript equality is a mess! We want both of the following to be true:
  // - samesies(0, -0)
  // - samesies(NaN, NaN)
  if (a === b || Object.is(a, b)) {
    return true;
  }
  if (a === undefined || a === null || b === undefined || b === null) {
    return false;
  }

  // Functions are weird because they can close over all kinds of fun data,
  // which might drastically change their behavior even if the text of their
  // code is the same. So, not samesies.
  if (typeof a === 'function' || typeof b === 'function') {
    return false;
  }

  // Dates
  if (a instanceof Date) {
    if (b instanceof Date) {
      return +a === +b;
    }
    return false;
  }

  // Arrays
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (!samesies(a[i], b[i], gas)) {
        return false;
      }
    }
    return true;
  }

  // TODO: Typed arrays? Does anyone actually use those?

  // Maps
  if (a instanceof Map) {
    if (b instanceof Map) {
      if (a.size !== b.size) {
        return false;
      }
      // Maps are ordered, so compare the keys and values pairwise.
      return iteratorsSamesies(a.entries(), b.entries(), gas);
    }
    return false;
  }

  // Sets
  if (a instanceof Set) {
    if (b instanceof Set) {
      if (a.size !== b.size) {
        return false;
      }
      // Sets are ordered, so compare the entries pairwise.
      return iteratorsSamesies(a.entries(), b.entries(), gas);
    }
    return false;
  }

  // Regexps
  if (a instanceof RegExp) {
    if (b instanceof RegExp) {
      return a.source === b.source && a.flags === b.flags;
    }
    return false;
  }

  // Custom types with equality functions
  const customFn = (a as any)[EqualityFunction];
  if (customFn) {
    // Both types need to have the *same* equality function. This means the
    // equality function can assume it's being given two objects of the same
    // type, which simplifies implementation a lot
    if (customFn !== (b as any)[EqualityFunction]) {
      return false;
    }
    return ((a as any)[EqualityFunction] as EqualityFn)(b, gas);
  }

  // Normal javascript objects
  if (isPlainObject(a)) {
    if (!isPlainObject(b)) {
      return false;
    }
    const aEntries = Object.entries(a);
    if (Object.keys(b).length !== aEntries.length) {
      return false;
    }

    for (const [k, v] of aEntries) {
      if (!Object.prototype.hasOwnProperty.call(b, k)) {
        return false;
      }
      if (!samesies((b as any)[k], v, gas)) {
        return false;
      }
    }
    return true;
  }

  // We've tried pretty hard by this point. Probably not samesies.
  return false;
}

function iteratorsSamesies<T>(
  a: Iterator<T>,
  b: Iterator<T>,
  gas: GasRef
): boolean {
  while (gas.gas > 0) {
    const av = a.next();
    const bv = b.next();
    if (av.done !== bv.done) {
      return false;
    }
    if (av.done) {
      return true;
    }
    if (!samesies(av.value, bv.value, gas)) {
      return false;
    }
  }
  debug(() => {
    console.info('[SAMESIES] ran out of gas');
  });
  return false;
}
