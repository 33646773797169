import Icon, { IconProps } from '../Icon';
const TagIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M17 5.5a1.5 1.5 0 0 1 0 3h-.01a1.5 1.5 0 0 1 0-3H17Z" />
    <path
      fillRule="evenodd"
      d="M21 1a2 2 0 0 1 2 2v8.586A2 2 0 0 1 22.414 13l-8.997 8.997a3.426 3.426 0 0 1-4.834 0l-6.58-6.58a3.426 3.426 0 0 1 0-4.834L11 1.586A2 2 0 0 1 12.414 1H21Zm0 2h-8.586l-8.997 8.997c-.55.55-.55 1.456 0 2.006l6.58 6.58c.55.55 1.456.55 2.006 0L21 11.586V3Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default TagIcon;
