import { SxProps, Theme } from '@mui/material';

export const WATERSHED_BIG_ROCK_DATA_GRID_ROW_HEIGHT = 40; // to replace the standard row height when launched

/**
 * This styling object will be mixed into the normal styling in Mui's DataGrid when Big Rocks redesign is on.
 */
export const bigRockSx: SxProps<Theme> = {
  // Monospaced numbers + smaller letter spacing, table wide
  '&': {
    fontVariantNumeric: 'tabular-nums',
    letterSpacing: '-0.03em',
  },
  // Border radius on selected cells
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader.pseudo-focus, & .pseudo-focus-all .MuiDataGrid-columnHeader, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell.pseudo-focus, & .pseudo-focus-all .MuiDataGrid-cell, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
    {
      ':not(.Mui-selected) &': {
        backgroundColor: (theme) => theme.palette.grey10,
      },
      borderRadius: 1,
      outlineOffset: '-1.5px',
      outlineWidth: 1,
      borderRightColor: 'transparent',
      position: 'relative',
      overflow: 'visible',
    },

  // More consistent border on bottom of column headers
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: (theme) => `1px solid ${theme.palette.border}`,
  },

  // Consistent color and padding for all headers
  '& .MuiDataGrid-columnHeader': {
    '&:not(:last-child)': {
      borderRight: (theme) => `1px solid ${theme.palette.border}`,
    },
    backgroundColor: (theme) => theme.palette.grey10,
    color: (theme) => theme.palette.grey70,
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'nowrap',
    },
    '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
      padding: '0 12px',
    },

    // Ensure the column separator is 1px wide and plays well with padding placement wise
    '& .MuiDataGrid-columnSeparator': {
      right: '-14px',
      transform: 'scaleY(5) scaleX(0.5)', // 2 px wide in svg, we want 1px

      // Swap direction for left one
      '&.MuiDataGrid-columnSeparator--sideLeft': {
        right: 'auto',
        left: '-14px',
      },

      // Default is the same color as our cell border colors, this makes it more visible
      '& .MuiDataGrid-iconSeparator': {
        fill: (t) => t.palette.grey30,
      },
    },
    // ...but focused column headers need no colored separator!
    '&:focus, &.pseudo-focus': {
      '& .MuiDataGrid-columnSeparator': {
        '& .MuiDataGrid-iconSeparator': {
          fill: 'transparent',
        },
      },
    },

    // Make the line invisible when the column is not resizable
    '& .MuiDataGrid-columnSeparator:not(.MuiDataGrid-columnSeparator--resizable) .MuiDataGrid-iconSeparator':
      {
        fill: 'transparent',
      },
  },

  // Consistent color and padding for all cells
  '& .MuiDataGrid-cell': {
    '&:not(:last-child)': {
      borderRight: (theme) => `1px solid ${theme.palette.border}`,
    },
    borderBottomWidth: 0,
    color: (theme) => theme.palette.grey100,
    padding: '0 12px',
  },

  // Pinned left columns have a box shadow on the right
  '& .MuiDataGrid-pinnedColumns--left, & .MuiDataGrid-pinnedColumnHeaders--left':
    {
      boxShadow: `1px 0px 4px 0px rgba(0, 0, 0, 0.06)`,
    },

  // Pinned right columns have a box shadow on the left
  '& .MuiDataGrid-pinnedColumns--right, & .MuiDataGrid-pinnedColumnHeaders--right':
    {
      boxShadow: `-2px 0px 4px 0px rgba(0, 0, 0, 0.06)`,
    },

  // Sort/menu icons need these to be visible when unsorted!
  '& .MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
    width: 'initial', // ensures right aligned content shows up without overlaid headers
  },
  '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIcon': {
    opacity: 'inherit !important',
  },

  // Spacing between header and sort icon
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    gap: 0.5,
    paddingRight: 0.25,
  },

  '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignRight': {
    // Override the paddingRight from the title container to be left if we're swapping direction
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingRight: 0,
      paddingLeft: 0.25,
    },

    // Ensures right aligned menu icon (while hovered) has the same space to the left of the element as left aligned menu icons have to the right
    '& .MuiDataGrid-menuIcon': {
      marginLeft: '-4px', // matching the total 8px for `menuIconButton`'s right padding visually
      // ...and override the right padding on the inner button since we don't need it for a left aligned button
      '& .MuiDataGrid-menuIconButton': {
        marginRight: 0,
      },
    },
  },

  // Default is 10px for a total of 12px of padding, make it 6px for a total of 8px
  '& .MuiDataGrid-menuIconButton': {
    marginRight: '6px',
  },
};
