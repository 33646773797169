import * as Sentry from '@sentry/browser';
import { useAtomValue } from 'jotai';
import { atomLocale, atomLocalizationEnv } from './atoms';
import { I18nProvider } from '@lingui/react';
import { ComponentProps, useMemo } from 'react';
import { LocalizationEnv, SupportedLocale } from '../constants';
// eslint-disable-next-line no-restricted-imports
import { i18n, Locale, Messages } from '@lingui/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { captureException, setTag } from '@sentry/browser';
import { Teams, getTeamInfo } from '@watershed/constants/teams';
import { ErrorBoundary } from 'react-error-boundary';
import { MessageLoadingError } from '@watershed/errors/MessageLoadingError';

interface Props extends Omit<ComponentProps<typeof I18nProvider>, 'i18n'> {
  loadMessages?: (
    locale: SupportedLocale,
    env: LocalizationEnv,
    ...extraLoadMessagesArgs: Array<string>
  ) => Promise<Messages>;
  extraLoadMessagesArgs?: Array<string>;
}

type MissingMessageEvent = {
  locale: Locale;
  id: string;
};

function onMissingTranslation(event: MissingMessageEvent) {
  // For now, we'll route all errors to the localization team. Eventually,
  // we'll forward all alerts to the owners of the respective surface area.
  setTag('owner', getTeamInfo(Teams.EnterpriseFoundations).sentryTeam);
  setTag('stringId', event.id);
  captureException(new Error('Missing translation for string'), {
    extra: event,
  });
}

function WatershedI18nProvider({
  loadMessages = () => Promise.resolve({}),
  extraLoadMessagesArgs = [],
  children,
}: Props) {
  const locale = useAtomValue(atomLocale);
  const env = useAtomValue(atomLocalizationEnv);

  const { data: messages } = useSuspenseQuery({
    queryKey: ['i18n', locale, env, ...extraLoadMessagesArgs],
    queryFn: async () => {
      try {
        const messages = await loadMessages(
          locale,
          env,
          ...extraLoadMessagesArgs
        );
        return messages;
      } catch (error: unknown) {
        throw new MessageLoadingError('Failed to load frontend messages', {
          locale,
          env,
          cause: error,
        });
      }
    },
  });

  const activatedI18n = useMemo(() => {
    i18n.load(locale, messages);
    i18n.activate(locale);

    if (env === 'production') {
      i18n.on('missing', onMissingTranslation);
    } else {
      i18n.removeListener('missing', onMissingTranslation);
    }
    return i18n;
  }, [env, locale, messages]);

  return <I18nProvider i18n={activatedI18n}>{children}</I18nProvider>;
}

export default function WrappedWatershedI18nProvider({
  FallbackComponent = () => null,
  ...props
}: Props & { FallbackComponent?: any }) {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error) => {
        // We expect MessageLoadingErrors to be transient, due to
        // chunks loading issues, e.g. https://watershed-0y.sentry.io/issues/5588874212
        if (!(error instanceof MessageLoadingError)) {
          throw error;
        }

        console.warn(error);
        Sentry.captureException(error, {
          tags: { owner: getTeamInfo(Teams.EnterpriseFoundations).sentryTeam },
        });
      }}
    >
      <WatershedI18nProvider {...props} />
    </ErrorBoundary>
  );
}
