import { DependencyList, EffectCallback, useEffect } from 'react';

import afterFrame from 'afterframe';

/**
 * An effect that is guarenteed to fire after a paint.
 *
 * Normal useEffect callbacks can be flushed synchronously
 * before the initial paint if they're part of a render
 * where a subsequent render is triggered from within a
 * useLayoutEffect.
 * https://blog.thoughtspile.tech/2021/11/15/unintentional-layout-effect/
 *
 * Use this sparingly, as there are few situations where this should
 * be used over useEffect. The motivating use case was for measuring
 * iteraction->paint latency
 */
export default function usePaintEffect(
  callback: EffectCallback,
  dependencies?: DependencyList
): void {
  useEffect(() => {
    // Wrap the callback in a container so we can "cancel" it if the component
    // unmounts before the callback is called.
    const wrapper = {
      callback,
    } as {
      callback: EffectCallback | null;
    };

    afterFrame(() => wrapper.callback?.());

    return () => {
      wrapper.callback = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
