import { msg } from '@lingui/core/macro';
import { GhgIdInclUnofficial } from '../constants';
import { MessageDescriptor } from '@lingui/core';
import { i18n } from '@watershed/intl';

export const ALL_BUSINESS_CATEGORIES = [
  'acquisitions',
  'apparel',
  'application_hosting',
  'bitcoin',
  'buildings',
  'business_travel',
  'car',
  'capital_goods',
  'carbon_removal',
  'cloud',
  'cloud_usage',
  'cloud_purchased_goods_and_services',
  'commuting',
  'communication_marketing_and_advertising',
  'components',
  'consumer',
  'crypto',
  'crypto_mining_facility',
  'cx_partners',
  'dashmarts',
  'data_centers',
  'direct',
  'dry_ice',
  'employees',
  'employee_benefits',
  'end_use',
  'energy',
  'experiences',
  'facilities',
  'financed_emissions',
  'food_production',
  'fuel_consumption',
  'gnfr_and_services',
  'hardware',
  'hardware_software_services',
  'home_textiles',
  'hotels',
  'ingredients',
  'infrastructures',
  'information_technology',
  'kitchens',
  'labs',
  'leather',
  'logistics',
  'manufacturing',
  'magazine',
  'non_magazine',
  'marketing',
  'marketing_and_communication',
  'marketing_and_recruiting',
  'markets',
  'minibrand_deliveryhero',
  'mining',
  'non_manageable_expenses',
  'offices',
  'packaged_food',
  'packaging',
  'payments',
  'pcps',
  'postage_and_logistics',
  'produce',
  'product_use',
  'product_use_eol',
  'pre_owned_products',
  'products',
  'properties',
  'purchases',
  'purchased_goods_and_services',
  'marketing_purchased_goods_and_services',
  'rail',
  'raw_materials',
  'remote_work',
  'restaurants',
  'retail',
  'retail_buildings',
  'retail_goods',
  'shipping_products',
  'stays',
  'suppliers',
  'supplies_for_the_business_and_services',
  'test_homes',
  'travel',
  'travel_and_offsite_events',
  'treatment_plants',
  'vehicles',
  'warehouses',
  'waste',
] as const;

export type BusinessCategory = (typeof ALL_BUSINESS_CATEGORIES)[number];

export function isBusinessCategory(
  category: string
): category is BusinessCategory {
  return ALL_BUSINESS_CATEGORIES.includes(category as BusinessCategory);
}

const DESCRIPTION_BY_BUSINESS_CATEGORIES: Record<
  // In some cases (e.g. summary report), we use "our" to refer to the business
  // -- the climate lead is talking to the rest of their company. In other
  // cases, we're talking to the customer, so we use "your" to refer to the
  // business. Note that we explicitly don't want to make these passive /
  // generic (e.g. "Emissions from activities to operate buildings") because
  // that makes it unclear if we're talking about the business itself, or all
  // the businesses in the supply-chain.
  string,
  { our: string; your: string }
> = {
  acquisitions: {
    our: 'Emissions from acquired business entities',
    your: 'Emissions from acquired business entities',
  },
  buildings: {
    our: 'Emissions from electricity, natural gas, waste, and other activities to operate our buildings',
    your: 'Emissions from electricity, natural gas, waste, and other activities to operate your buildings',
  },
  employees: {
    our: 'Emissions generated by employees working from home',
    your: 'Emissions generated by employees working from home',
  },
  cloud: {
    our: 'Emissions from our use of cloud computing platforms',
    your: 'Emissions from your use of cloud computing platforms',
  },
  // Duplicate of cloud with a different label specifically requested by Dataiku
  cloud_usage: {
    our: 'Emissions from our use of cloud computing platforms',
    your: 'Emissions from your use of cloud computing platforms',
  },
  car: {
    our: 'Emissions from our use of ground transportation by car',
    your: 'Emissions from your use of ground transportation by car',
  },
  components: {
    our: 'Emissions from our production of components',
    your: 'Emissions from your production of components',
  },
  purchases: {
    our: 'Emissions from the products and services we buy from suppliers',
    your: 'Emissions from the products and services you buy from suppliers',
  },
  products: {
    our: 'Emissions from making the products we sell to customers',
    your: 'Emissions from making the products you sell to customers',
  },
  leather: {
    our: 'Emissions from our production of leather',
    your: 'Emissions from your production leather',
  },
  logistics: {
    our: 'Emissions from transportation of products between our suppliers, facilities, and customers',
    your: 'Emissions from transportation of products between your suppliers, facilities, and customers',
  },
  postage_and_logistics: {
    our: 'Emissions from transportation of materials between our suppliers, facilities, and customers',
    your: 'Emissions from transportation of materials between your suppliers, facilities, and customers',
  },
  suppliers: {
    our: 'Emissions from our key suppliers',
    your: 'Emissions from your key suppliers',
  },
  // Duplicate of travel with a different label specifically requested by Dataiku
  business_travel: {
    our: 'Emissions from employee business travel (flights, hotels, meals, ground transportation)',
    your: 'Emissions from employee business travel (flights, hotels, meals, ground transportation)',
  },
  travel: {
    our: 'Emissions from employee business travel (flights, hotels, meals, ground transportation)',
    your: 'Emissions from employee business travel (flights, hotels, meals, ground transportation)',
  },
  commuting: {
    our: "Emissions from all our employees' commutes to work",
    your: "Emissions from all your employees' commutes to work",
  },
  manufacturing: {
    our: 'Emissions from manufacturing our products',
    your: 'Emissions from manufacturing your products',
  },
  magazine: {
    our: 'Emissions from magazines',
    your: 'Emissions from magazines',
  },
  non_magazine: {
    our: 'Emissions from non-magazine print production',
    your: 'Emissions from non-magazine print production',
  },
  ingredients: {
    our: 'Emissions from the production of ingredients we purchase',
    your: 'Emissions from the production of ingredients you purchase',
  },
  consumer: {
    our: 'Emissions that happen when our customers use our products',
    your: 'Emissions that happen when your customers use your products',
  },
  'product use': {
    our: 'Emissions that happen when our customers use our products',
    your: 'Emissions that happen when your customers use your products',
  },
  product_use: {
    our: 'Emissions that happen when our customers use our products',
    your: 'Emissions that happen when your customers use your products',
  },
  product_use_eol: {
    our: 'Emissions that happen when our customers use our products, accounting for end of life',
    your: 'Emissions that happen when your customers use your products, accounting for end of life',
  },
  pre_owned_products: {
    our: 'Emissions from pre-owned products that we resell',
    your: 'Emissions from pre-owned products that you resell',
  },
  packaging: {
    our: 'Emissions from our packaging',
    your: 'Emissions from your packaging',
  },
  'capital goods': {
    our: 'Emissions from producing and maintaining our long-term fixed assets (like buildings or machinery)',
    your: 'Emissions from producing and maintaining your long-term fixed assets (like buildings or machinery)',
  },
  capital_goods: {
    our: 'Emissions from producing and maintaining our long-term fixed assets (like buildings or machinery)',
    your: 'Emissions from producing and maintaining your long-term fixed assets (like buildings or machinery)',
  },
  waste: {
    our: 'Emissions from landfill, recycling, compost and other waste in our operations',
    your: 'Emissions from landfill, recycling, compost and other waste in your operations',
  },
  bitcoin: {
    our: 'Emissions from our use of the Bitcoin blockchain',
    your: 'Emissions from your use of the Bitcoin blockchain',
  },
  crypto: {
    our: 'Emissions from our cryptocurrency holdings and transactions',
    your: 'Emissions from your cryptocurrency holdings and transactions',
  },
  hardware: {
    our: 'Emissions from manufacturing and shipping our hardware products, and the emissions from customers using our hardware',
    your: 'Emissions from manufacturing and shipping your hardware products, and the emissions from customers using your hardware',
  },
  other: {
    our: 'Emissions from other areas not explicitly broken out',
    your: 'Emissions from other areas not explicitly broken out',
  },
  'food production': {
    our: 'Emissions from the food we purchase from suppliers',
    your: 'Emissions from the food you purchase from suppliers',
  },
  food_production: {
    our: 'Emissions from the food we purchase from suppliers',
    your: 'Emissions from the food you purchase from suppliers',
  },
  payments: {
    our: 'Emissions from our payment processing activity',
    your: 'Emissions from your payment processing activity',
  },
  rail: {
    our: 'Emissions from our use of ground transportation by rail',
    your: 'Emissions from your use of ground transportation by rail',
  },
  shipping_products: {
    our: 'Emissions from shipping our products',
    your: 'Emissions from shipping your products',
  },
  stays: {
    our: "Emissions from customers' stays at a host or other lodging",
    your: "Emissions from customers' stays at a host or other lodging",
  },
  home_textiles: {
    our: 'Emissions from home textiles manufacturing and raw materials',
    your: 'Emissions from home textiles manufacturing and raw materials',
  },
  hotels: {
    our: "Emissions from customers' stays at a hotel",
    your: "Emissions from customers' stays at a hotel",
  },
  infrastructures: {
    our: "Emissions from customers' infrastructure-related financial accounts",
    your: "Emissions from customers' infrastructure-related financial accounts",
  },
  information_technology: {
    our: "Emissions from customers' information technology financial accounts",
    your: "Emissions from customers' information technology financial accounts",
  },
  non_manageable_expenses: {
    our: "Emissions from customers' non-manageable expense financial accounts",
    your: "Emissions from customers' non-manageable expense financial accounts",
  },
  communication_marketing_and_advertising: {
    our: "Emissions from customers' communication, marketing, and advertising spend",
    your: "Emissions from customers' communication, marketing, and advertising spend",
  },
  supplies_for_the_business_and_services: {
    our: "Emissions from customers' supplies for business services",
    your: "Emissions from customers' supplies for business services",
  },
  experiences: {
    our: 'Emissions from customer experiences booked via the platform',
    your: 'Emissions from customer experiences booked via the platform',
  },
  cx_partners: {
    our: 'Emissions from cx partners',
    your: 'Emissions from cx partners',
  },
  packaged_food: {
    our: 'Emissions from packaged food and grocery',
    your: 'Emissions from packaged food and grocery',
  },
  pcps: {
    our: 'Emissions from primary care providers',
    your: 'Emissions from primary care providers',
  },
  produce: {
    our: 'Emissions from produce',
    your: 'Emissions from produce',
  },
  data_centers: {
    our: 'Emissions from our data center operation, usage, and hardware',
    your: 'Emissions from your data center operation, usage, and hardware',
  },
  offices: {
    our: 'Emissions from our offices, including utilities & asset expenditures',
    your: 'Emissions from your offices, including utilities & asset expenditures',
  },
  marketing: {
    our: 'Emissions from our spend on marketing, including the cost of purchasing ads and any agency and consulting expenses',
    your: 'Emissions from your spend on marketing, including the cost of purchasing ads and any agency and consulting expenses',
  },
  // Duplicate of marketing with a different label specifically requested by Dataiku
  marketing_and_communication: {
    our: 'Emissions from our spend on marketing, including the cost of purchasing ads and any agency and consulting expenses',
    your: 'Emissions from your spend on marketing, including the cost of purchasing ads and any agency and consulting expenses',
  },
  warehouses: {
    our: 'Emissions from electricity, natural gas, waste, and other activities to operate our warehouses',
    your: 'Emissions from electricity, natural gas, waste, and other activities to operate your warehouses',
  },
  apparel: {
    our: 'Emissions from apparel manufacturing and raw materials',
    your: 'Emissions from apparel manufacturing and raw materials',
  },
  retail: {
    our: 'Emissions from our retail locations',
    your: 'Emissions from your retail locations',
  },
  labs: {
    our: 'Emissions from our lab locations',
    your: 'Emissions from your lab locations',
  },
  carbon_removal: {
    our: 'Our emissions negated by carbon removal',
    your: 'Your emissions negated by carbon removal',
  },
  end_use: {
    our: 'Our emissions from end use of products',
    your: 'Your emissions from end use of products',
  },
  energy: {
    our: 'Our emissions from electricity, natural gas, and other purchased energy',
    your: 'Your emissions from electricity, natural gas, and other purchased energy',
  },
  minibrand_deliveryhero: {
    our: 'Our emissions from Mini-brand',
    your: 'Your emissions from Mini-brand',
  },
  mining: {
    our: 'Our emissions from mining',
    your: 'Your emissions from mining',
  },
  facilities: {
    our: 'Our emissions from facilities',
    your: 'Your emissions from facilities',
  },
  fuel_consumption: {
    our: 'Our emissions from fuel consumption',
    your: 'Your emissions from fuel consumption',
  },
  raw_materials: {
    our: 'Our emissions from raw materials',
    your: 'Your emissions from raw materials',
  },
  direct: {
    our: 'Our direct greenhouse gas emissions',
    your: 'Your direct greenhouse gas emissions',
  },
  application_hosting: {
    our: 'Activity related to data centers and software associated with hosting our applications',
    your: 'Activity related to data centers and software associated with hosting your applications',
  },
  travel_and_offsite_events: {
    our: 'Emissions from employee business travel (flights, hotels, meals, ground transportation) and offsite events',
    your: 'Emissions from employee business travel (flights, hotels, meals, ground transportation) and offsite events',
  },
  crypto_mining_facility: {
    our: 'Our emissions from crypto mining facilities',
    your: 'Your emissions from crypto mining facilities',
  },
  dry_ice: {
    our: 'Our emissions from dry ice',
    your: 'Your emissions from dry ice',
  },
  // this description is in the context of Doordash/Wolt org
  markets: {
    our: 'Our emissions from markets, including utilities',
    your: 'Your emissions from markets, including utilities',
  },
  test_homes: {
    our: 'Our emissions from test homes',
    your: 'Your emissions from test homes',
  },
  marketing_and_recruiting: {
    our: 'Our emissions from marketing and recruiting',
    your: 'Your emissions from marketing and recruiting',
  },
  hardware_software_services: {
    our: 'Our emissions from hardware, software, and services',
    your: 'Your emissions from hardware, software, and services',
  },
  employee_benefits: {
    our: 'Our emissions from employee benefits',
    your: 'Your emissions from employee benefits',
  },
  treatment_plants: {
    our: 'Our emissions from treatment plants',
    your: 'Your emissions from treatment plants',
  },
  vehicles: {
    our: 'Our emissions from vehicles',
    your: 'Your emissions from vehicles',
  },
  retail_buildings: {
    our: 'Emissions from retail buildings',
    your: 'Emissions from retail buildings',
  },
  // this description is in the context of Doordash org
  dashmarts: {
    our: 'Our emissions from DashMarts, including utilities',
    your: 'Your emissions from DashMarts, including utilities',
  },
  kitchens: {
    our: 'Our emissions from kitchens, including utilities',
    your: 'Your emissions from kitchens, including utilities',
  },
  purchased_goods_and_services: {
    our: 'Emissions from the products and services we buy from suppliers',
    your: 'Emissions from the products and services you buy from suppliers',
  },
  marketing_purchased_goods_and_services: {
    our: 'Emissions from the marketing products and services we buy from suppliers',
    your: 'Emissions from the marketing products and services we buy from suppliers',
  },
  cloud_purchased_goods_and_services: {
    our: 'Emissions from the cloud products and services we buy from suppliers',
    your: 'Emissions from the cloud products and services we buy from suppliers',
  },
  remote_work: {
    our: 'Emissions generated by employees working from home',
    your: 'Emissions generated by employees working from home',
  },
};

export const BUSINESS_CATEGORY_LABELS: Record<
  BusinessCategory,
  MessageDescriptor
> = {
  acquisitions: msg({ context: 'Business category', message: 'Acquisitions' }),
  apparel: msg({ context: 'Business category', message: 'Apparel' }),
  application_hosting: msg({
    context: 'Business category',
    message: 'Application hosting',
  }),
  bitcoin: msg({ context: 'Business category', message: 'Bitcoin' }),
  buildings: msg({ context: 'Business category', message: 'Buildings' }),
  business_travel: msg({
    context: 'Business category',
    message: 'Business travel',
  }),
  car: msg({ context: 'Business category', message: 'Car' }),
  capital_goods: msg({
    context: 'Business category',
    message: 'Capital goods',
  }),
  carbon_removal: msg({
    context: 'Business category',
    message: 'Carbon removal',
  }),
  cloud: msg({ context: 'Business category', message: 'Cloud' }),
  cloud_usage: msg({ context: 'Business category', message: 'Cloud usage' }),
  cloud_purchased_goods_and_services: msg({
    context: 'Business category',
    message: 'Cloud (purchased goods and services)',
  }),
  commuting: msg({ context: 'Business category', message: 'Commuting' }),
  communication_marketing_and_advertising: msg({
    context: 'Business category',
    message: 'Communication, marketing, and advertising',
  }),
  components: msg({ context: 'Business category', message: 'Components' }),
  consumer: msg({ context: 'Business category', message: 'Consumer' }),
  crypto: msg({ context: 'Business category', message: 'Crypto' }),
  crypto_mining_facility: msg({
    context: 'Business category',
    message: 'Crypto mining facility',
  }),
  cx_partners: msg({ context: 'Business category', message: 'CX partners' }),
  dashmarts: msg({ context: 'Business category', message: 'DashMarts' }),
  data_centers: msg({ context: 'Business category', message: 'Data centers' }),
  direct: msg({ context: 'Business category', message: 'Direct emissions' }),
  dry_ice: msg({ context: 'Business category', message: 'Dry ice' }),
  employees: msg({ context: 'Business category', message: 'Employees' }),
  employee_benefits: msg({
    context: 'Business category',
    message: 'Employee benefits',
  }),
  end_use: msg({ context: 'Business category', message: 'End use' }),
  energy: msg({ context: 'Business category', message: 'Energy' }),
  experiences: msg({ context: 'Business category', message: 'Experiences' }),
  facilities: msg({ context: 'Business category', message: 'Facilities' }),
  financed_emissions: msg({
    context: 'Business category',
    message: 'Financed emissions',
  }),
  food_production: msg({
    context: 'Business category',
    message: 'Food production',
  }),
  fuel_consumption: msg({
    context: 'Business category',
    message: 'Fuel consumption',
  }),
  gnfr_and_services: msg({
    context: 'Business category',
    message: 'GNFR & services',
  }),
  hardware: msg({ context: 'Business category', message: 'Hardware' }),
  hardware_software_services: msg({
    context: 'Business category',
    message: 'Hardware, software, and services',
  }),
  home_textiles: msg({
    context: 'Business category',
    message: 'Home textiles',
  }),
  hotels: msg({ context: 'Business category', message: 'Hotels' }),
  ingredients: msg({ context: 'Business category', message: 'Ingredients' }),
  infrastructures: msg({
    context: 'Business category',
    message: 'Infrastructures',
  }),
  information_technology: msg({
    context: 'Business category',
    message: 'Information technology',
  }),
  kitchens: msg({ context: 'Business category', message: 'Kitchens' }),
  labs: msg({ context: 'Business category', message: 'Labs' }),
  leather: msg({ context: 'Business category', message: 'Leather' }),
  logistics: msg({ context: 'Business category', message: 'Logistics' }),
  manufacturing: msg({
    context: 'Business category',
    message: 'Manufacturing',
  }),
  magazine: msg({ context: 'Business category', message: 'Magazine' }),
  non_magazine: msg({
    context: 'Business category',
    message: 'Non-magazine print production',
  }),
  marketing: msg({ context: 'Business category', message: 'Marketing' }),
  marketing_and_communication: msg({
    context: 'Business category',
    message: 'Marketing & communication',
  }),
  marketing_and_recruiting: msg({
    context: 'Business category',
    message: 'Marketing & recruiting',
  }),
  markets: msg({ context: 'Business category', message: 'Markets' }),
  minibrand_deliveryhero: msg({
    context: 'Business category',
    message: 'Mini-brand',
  }),
  mining: msg({ context: 'Business category', message: 'Mining' }),
  non_manageable_expenses: msg({
    context: 'Business category',
    message: 'Non-manageable expenses',
  }),
  offices: msg({ context: 'Business category', message: 'Offices' }),
  packaged_food: msg({
    context: 'Business category',
    message: 'Packaged Food',
  }),
  packaging: msg({ context: 'Business category', message: 'Packaging' }),
  payments: msg({
    context: 'Business category',
    message: 'Payment processing',
  }),
  pcps: msg({
    context: 'Business category',
    message: 'Primary care providers',
  }),
  postage_and_logistics: msg({
    context: 'Business category',
    message: 'Postage and logistics',
  }),
  produce: msg({ context: 'Business category', message: 'Produce' }),
  product_use: msg({ context: 'Business category', message: 'Product use' }),
  product_use_eol: msg({
    context: 'Business category',
    message: 'Product use and end of life',
  }),
  pre_owned_products: msg({
    context: 'Business category',
    message: 'Pre-owned products',
  }),
  products: msg({ context: 'Business category', message: 'Products' }),
  properties: msg({ context: 'Business category', message: 'Properties' }),
  purchases: msg({ context: 'Business category', message: 'Goods & services' }),
  purchased_goods_and_services: msg({
    context: 'Business category',
    message: 'Purchased goods and services',
  }),
  marketing_purchased_goods_and_services: msg({
    context: 'Business category',
    message: 'Marketing (purchased goods and services)',
  }),
  rail: msg({ context: 'Business category', message: 'Rail' }),
  raw_materials: msg({
    context: 'Business category',
    message: 'Raw materials',
  }),
  remote_work: msg({ context: 'Business category', message: 'Remote work' }),
  restaurants: msg({ context: 'Business category', message: 'Restaurants' }),
  retail: msg({ context: 'Business category', message: 'Retail' }),
  retail_buildings: msg({
    context: 'Business category',
    message: 'Retail buildings',
  }),
  retail_goods: msg({ context: 'Business category', message: 'Retail goods' }),
  shipping_products: msg({
    context: 'Business category',
    message: 'Shipping products',
  }),
  stays: msg({ context: 'Business category', message: 'Stays' }),
  suppliers: msg({ context: 'Business category', message: 'Suppliers' }),
  supplies_for_the_business_and_services: msg({
    context: 'Business category',
    message: 'Supplies for the business and services',
  }),
  test_homes: msg({ context: 'Business category', message: 'Test homes' }),
  travel: msg({ context: 'Business category', message: 'Travel' }),
  travel_and_offsite_events: msg({
    context: 'Business category',
    message: 'Travel and offsite events',
  }),
  treatment_plants: msg({
    context: 'Business category',
    message: 'Treatment plants',
  }),
  vehicles: msg({ context: 'Business category', message: 'Vehicles' }),
  warehouses: msg({ context: 'Business category', message: 'Warehouses' }),
  waste: msg({ context: 'Business category', message: 'Waste' }),
};

export const GHG_CATEGORY_LABELS: Record<
  GhgIdInclUnofficial,
  MessageDescriptor
> = {
  '1 Scope 1': msg({
    context: 'GHG category label',
    message: '1 Direct emissions',
  }),
  '2 Scope 2': msg({
    context: 'GHG category label',
    message: '2 Purchased electricity, steam, heat, and cooling',
  }),
  '3.1 Purchased goods and services': msg({
    context: 'GHG category label',
    message: '3.1 Purchased goods and services',
  }),
  '3.2 Capital goods': msg({
    context: 'GHG category label',
    message: '3.2 Capital goods',
  }),
  '3.3 Fuel and energy related activities': msg({
    context: 'GHG category label',
    message: '3.3 Fuel and energy related activities',
  }),
  '3.4 Upstream transportation & distribution': msg({
    context: 'GHG category label',
    message: '3.4 Upstream transportation & distribution',
  }),
  '3.5 Waste generated in operations': msg({
    context: 'GHG category label',
    message: '3.5 Waste generated in operations',
  }),
  '3.6 Business travel': msg({
    context: 'GHG category label',
    message: '3.6 Business travel',
  }),
  '3.7 Employee commuting': msg({
    context: 'GHG category label',
    message: '3.7 Employee commuting',
  }),
  '3.8 Upstream leased assets': msg({
    context: 'GHG category label',
    message: '3.8 Upstream leased assets',
  }),
  '3.9 Downstream transportation and distribution': msg({
    context: 'GHG category label',
    message: '3.9 Downstream transportation and distribution',
  }),
  '3.10 Processing of sold products': msg({
    context: 'GHG category label',
    message: '3.10 Processing of sold products',
  }),
  '3.11 Use of sold products': msg({
    context: 'GHG category label',
    message: '3.11 Use of sold products',
  }),
  '3.12 End-of-life treatment of sold products': msg({
    context: 'GHG category label',
    message: '3.12 End-of-life treatment of sold products',
  }),
  '3.13 Downstream leased assets': msg({
    context: 'GHG category label',
    message: '3.13 Downstream leased assets',
  }),
  '3.14 Franchises': msg({
    context: 'GHG category label',
    message: '3.14 Franchises',
  }),
  '3.15 Investments': msg({
    context: 'GHG category label',
    message: '3.15 Investments',
  }),
  'Other Upstream': msg({
    context: 'GHG category label',
    message: 'Other Upstream',
  }),
  'Other Downstream': msg({
    context: 'GHG category label',
    message: 'Other Downstream',
  }),
};

export function categoryDescription(
  // TODO(jessica): can we modify the callers of this function to enforce this type as BusinessCategory instead?
  category: string,
  voice: 'our' | 'your'
): string {
  const normalizedCategory = category.toLocaleLowerCase(i18n.locale);
  if (
    isBusinessCategory(normalizedCategory) &&
    normalizedCategory in DESCRIPTION_BY_BUSINESS_CATEGORIES
  ) {
    return DESCRIPTION_BY_BUSINESS_CATEGORIES[normalizedCategory][voice];
  }
  return '';
}
