import { Children } from 'react';
import { ButtonProps } from '@watershed/ui-core/components/Button';

export function isButtonIconOnly({
  children,
  startIcon,
  endIcon,
}: Pick<ButtonProps, 'children' | 'startIcon' | 'endIcon'>): boolean {
  return Children.count(children) === 0 && (startIcon ?? endIcon) != null;
}
