import * as Sentry from '@sentry/core';
import type { CaptureContext } from '@sentry/types';
import type { Extras } from '@sentry/types';
import pickBy from 'lodash/pickBy';
import { mapStrToEnumOrUndefined } from './mapStrToEnum';
import { isAuthenticatedUserACustomer } from './userUtils';

export const SENTRY_MAX_VALUE_LENGTH = 2048;

const SENTRY_BEFORE_SEND_POLICY = 'BEFORE_SEND_POLICY';

export enum SentryBeforeSendPolicy {
  /** Infer whether the error should be sent to Sentry. */
  AUTO = 'AUTO',
  /** Always send the error to Sentry. */
  ALWAYS = 'ALWAYS',
}

export function configureSharedSentryContext({
  orgId,
  orgName,
  testOrg,
  demoOrg,
  userId,
  userName,
  loginAsUserId,
  loginAsUserName,
  isWatershedEmployee,
  isWatershedContractor,
  isE2ETester,
  referrer,
  featureFlags,
  footprintVersionOverride,
  isProxy = false,
  sessionId,
}: {
  orgId: string;
  orgName?: string;
  testOrg: boolean;
  demoOrg: boolean;
  userId: string;
  userName: string;
  loginAsUserId: string | undefined;
  loginAsUserName: string | undefined;
  isWatershedEmployee: boolean;
  isWatershedContractor: boolean;
  isE2ETester: boolean;
  referrer: string;
  featureFlags: Record<string, boolean>;
  footprintVersionOverride: string | undefined;
  isProxy?: boolean;
  sessionId?: string;
}): void {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: userId, name: userName, isWatershedEmployee });
    scope.setTag(
      'isRealCustomer',
      isAuthenticatedUserACustomer({
        loginAsUserId,
        isWatershedEmployee,
        isWatershedContractor,
        isE2ETester,
      })
    );
    scope.setTag('org', orgId);
    scope.setTag('testOrg', testOrg);
    scope.setTag('demoOrg', demoOrg);
    scope.setTag('isWatershedEmployee', isWatershedEmployee);
    scope.setTag('orgName', orgName);
    scope.setTag('isProxy', isProxy.toString());

    scope.setExtra(SENTRY_BEFORE_SEND_POLICY, SentryBeforeSendPolicy.AUTO);

    const formatFeatureFlags: (isEnabled: boolean) => Array<string> = (
      isEnabled: boolean
    ) => {
      return Object.keys(pickBy(featureFlags, (v) => v === isEnabled)).sort();
    };

    const featureFlagContext: {
      enabled: Array<string>;
      disabled: Array<string>;
    } = {
      enabled: formatFeatureFlags(true),
      disabled: formatFeatureFlags(false),
    };

    scope.setContext('FeatureFlags', featureFlagContext);

    // Sentry doesn't seem to like empty strings
    if (referrer) {
      scope.setTag('referrer', referrer);
    }
    if (loginAsUserId) {
      scope.setTag('loginAsUserId', loginAsUserId);
    }
    if (loginAsUserName) {
      scope.setTag('loginAsUserName', loginAsUserName);
    }
    if (footprintVersionOverride) {
      scope.setTag('footprintVersionOverride', footprintVersionOverride);
    }
    if (sessionId) {
      scope.setTag('sessionId', sessionId);
    }
  });

  Sentry.setContext('watershed', {
    orgId,
    orgName,
    testOrg,
  });
}

export function getBeforeSendPolicy(extras?: Extras): SentryBeforeSendPolicy {
  return (
    mapStrToEnumOrUndefined(
      extras?.[SENTRY_BEFORE_SEND_POLICY] as string | undefined,
      SentryBeforeSendPolicy
    ) ?? SentryBeforeSendPolicy.AUTO
  );
}

/**
 * Force capturing of an error to Sentry, ignoring our custom error code
 * filtering logic in `sentryBeforeSend`.
 */
export function forceCaptureException(
  error: unknown,
  captureContext?: CaptureContext
): void {
  Sentry.withScope((scope) => {
    scope.setExtra(SENTRY_BEFORE_SEND_POLICY, SentryBeforeSendPolicy.ALWAYS);
    Sentry.captureException(error, captureContext);
  });
}
