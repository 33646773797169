import { Exchange, OperationResult } from 'urql';
import { map, pipe } from 'wonka';
import {
  LOGIN_QUERY_PARAMS,
  routeForLogin,
} from '@watershed/shared-universal/dashboardRoutes';
import getPathWithQuery from '@watershed/shared-universal/utils/getPathWithQuery';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

function getLoginPath() {
  const queryParams = new URLSearchParams(window.location.search);
  const queryObj = Object.fromEntries(queryParams);

  // Move login-related query params from redirect to login path
  return routeForLogin({
    redirect: getPathWithQuery(
      window.location.pathname,
      omit(queryObj, LOGIN_QUERY_PARAMS),
      window.location.hash.slice(1)
    ),
    ...pick(queryObj, LOGIN_QUERY_PARAMS),
  });
}

const errorExchange: Exchange =
  ({ forward }) =>
  (ops$) => {
    return pipe(
      forward(ops$),
      map((res) => handleAuthenticationError(res))
    );
  };

// On authentication errors, show user an error and/or login prompt
function handleAuthenticationError(res: OperationResult<any>) {
  if (res.error) {
    if (
      res.error.graphQLErrors.some(
        (err) => err.extensions?.code === 'UNAUTHENTICATED'
      )
    ) {
      window.alert(
        'Authentication error. You do not have permission to view this resource.'
      );
      window.location.replace(getLoginPath());
    }

    /**
     * There is also a circumstance not caught by the above,
     * where the user has been logged out due to inactivity.
     * In these cases, we want to redirect to /login without
     * the window.alert.
     */
    if (
      res?.error?.response?.status === 401 &&
      // Don't automatically redirect if we're explicitly checking
      // if the user is already authenticated on one of the login pages.
      !(
        isDashboardCheckUserAuthenticationQuery(res) ||
        isAdminCheckAdminAuthenticationQuery(res)
      )
    ) {
      window.location.replace(getLoginPath());
    }
  }

  return res;
}

function isDashboardCheckUserAuthenticationQuery(res: OperationResult<any>) {
  return res.operation?.query?.definitions?.some(
    (def) =>
      def.kind === 'OperationDefinition' &&
      def.name?.value === 'CheckUserAuthentication'
  );
}

function isAdminCheckAdminAuthenticationQuery(res: OperationResult<any>) {
  return res.operation?.query?.definitions?.some(
    (def) =>
      def.kind === 'OperationDefinition' &&
      def.name?.value === 'CheckAdminAuthentication'
  );
}

export default errorExchange;
