export default function optionalOnly<T>(
  array: Array<T>,
  message: string = 'Expected array to have at most one element'
): T | undefined {
  if (array.length > 1) {
    let debugArray: string;
    if (array.length > 10) {
      debugArray = `[${array
        .slice(0, 10)
        .map((arr) => JSON.stringify(arr).slice(0, 50))}, ...]`;
    } else {
      debugArray = `[${array.map((arr) => JSON.stringify(arr).slice(0, 50))}]`;
    }
    throw new Error(
      `${message}, received ${array.length}; array=${debugArray}`
    );
  }
  return array[0];
}
