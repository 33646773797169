import { Exchange } from 'urql';
import customScalarsExchange from './customScalarsExchange';
import scalars from '@watershed/shared-universal/graphql/scalars';

type BasicIntrospectionQuery = { __schema: any };

export default function scalarsExchange(
  schema: BasicIntrospectionQuery
): Exchange {
  return customScalarsExchange({
    schema,
    scalars,
  });
}
