import Icon, { IconProps } from '../Icon';
const ShoppingBasketIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M14.445 1.168a1 1 0 0 1 1.387.277L19.535 7H21a2 2 0 0 1 2 2v2a2 2 0 0 1-1.031 1.75c.032.124.04.257.021.391l-.991 6.937A3 3 0 0 1 18 23H6a3 3 0 0 1-2.999-2.922l-.99-6.937a.997.997 0 0 1 .02-.39A2 2 0 0 1 1 11V9a2 2 0 0 1 2-2h1.465l3.703-5.555a1 1 0 0 1 1.664 1.11L6.87 7h10.26l-2.963-4.445a1 1 0 0 1 .277-1.387ZM4.985 9H21v2H3V9h1.984Zm15.005 4H4.01l.98 6.859A1 1 0 0 1 5 20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1c0-.047.003-.095.01-.141L19.99 13Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ShoppingBasketIcon;
