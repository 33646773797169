// Regular expression that all names in the model must adhere to.
// - The name may not start with underscore, we reserve it for internal names.
// - The name may not contain '.', to allow us to construct paths.
export const NAME_PATTERN = '^[A-Za-z0-9][A-Za-z0-9_]*$';

// Reserved name to indicate functional unit node in an eval graph.
// Guaranteed to have no conflict since we reserve '__' prefix for internal use.
export const FUNCTIONAL_UNIT_NODE_NAME = '__functional_unit__';

// Yield kinds for model outputs
export const RESULT_KIND_KWH = 'kwh';
export const RESULT_KIND_KG_OF_REFRIGERANT = 'kg_of_refrigerant';
export const RESULT_KIND_MMBTU_OF_ENERGY = 'mmbtu_of_energy';
export const RESULT_KIND_LAND_AREA = 'land_area';
export const RESULT_KIND_EXPENDITURE_IN_USD = 'expenditure_in_usd';
export const RESULT_KIND_LITERS_OF_WATER_USED = 'liters_of_water_used';
export const RESULT_KIND_LITERS_OF_WATER_CONSUMED = 'liters_of_water_consumed';
export const RESULT_KIND_LITERS_OF_WATER_RECYCLED = 'liters_of_water_recycled';
export const RESULT_KIND_LITERS_OF_WATER_STORED = 'liters_of_water_stored';
export const RESULT_KIND_LITERS_OF_WATER_DISCHARGED =
  'liters_of_water_discharged';
export const RESULT_KIND_KG_OF_WASTE = 'kg_of_waste';
export const RESULT_KIND_KG_OF_SUBSTANCE_INFLOW = 'kg_of_substance_inflow';
export const RESULT_KIND_KG_OF_SUBSTANCE_OUTFLOW = 'kg_of_substance_outflow';
export const RESULT_KIND_KG_OF_MICROPLASTICS_INFLOW =
  'kg_of_microplastics_inflow';
export const RESULT_KIND_KG_OF_MICROPLASTICS_OUTFLOW =
  'kg_of_microplastics_outflow';
export const RESULT_KIND_GDP_IN_PPP_ADJUSTED_USD = 'usd_of_ppp_adjusted_gdp';
export const RESULT_KIND_ATTRIBUTION_FACTOR = 'attribution_factor';

export const REQUIRED_ERROR_PHRASE = 'cannot be empty';

export const MAX_LENGTH_OF_NAMES = 130;

export const NAME_TOO_LONG_ERROR = ` is too long of a name: names can be no longer than ${MAX_LENGTH_OF_NAMES} characters.`;

export const LSCRIPT_NAME_PREFIX_EMISSIONS_FACTOR = 'emissions_factor';
export const LSCRIPT_NAME_PREFIX_EMISSIONS_ACTIVITY = 'activity';

/**
 * Slack ID for @calculation-oncall
 */
export const CALC_ONCALL_MENTION = `<!subteam^S04MTMG9R6V>`;

/***
 * Slack ID for @csrd-oncall
 */
export const CSRD_ONCALL_MENTION = `<!subteam^S075SFJPFA7>`;

/***
 * Slack ID for @methodology-oncall
 */
export const METHODOLOGY_ONCALL_MENTION = `<!subteam^S0644037SAZ>`;

/***
 * Slack ID for @cmv-traffic-controller
 */
export const CMV_TRAFFIC_CONTROLLER_MENTION = `<!subteam^S07C7TLELJ0>`;

/**
 * LEGACY! DO NOT USE UNLESS YOU KNOW THE TERRIBLE REPERCUSSIONS!
 *
 * Special local variable names that ReliableInputQuantityService looks for.
 *
 * These are not codegenned, just very unique so as not to conflict with other
 * variable names.
 */
const RIQS_LOCAL_VARIABLE_KEY_PRODUCT = 'wcg_product_quantity_6d4800da';
const RIQS_LOCAL_VARIABLE_KEY_MATERIAL = 'wcg_material_quantity_e45e216c';
const RIQS_LOCAL_VARIABLE_KEY_ELECTRICITY = 'wcg_electricity_quantity_k24e759j';
export const RIQS_LOCAL_VARIABLES = [
  RIQS_LOCAL_VARIABLE_KEY_PRODUCT,
  RIQS_LOCAL_VARIABLE_KEY_MATERIAL,
  RIQS_LOCAL_VARIABLE_KEY_ELECTRICITY,
];

/**
 * LEGACY! DO NOT USE UNLESS YOU KNOW THE TERRIBLE REPERCUSSIONS!
 *
 * Special region attribute names that ReliableInputQuantityService looks for.
 */
export const RIQS_REGION_ATTRIBUTES = [
  'org_material',
  'org_raw_material',
  'ws_raw_material',
  'product',
] as const;

// Pipeline run descriptions
export const CRON_TRIGGERED_DESCRIPTION = `Smoke test `;

// Date of emissions models GA
export const EMISSIONS_MODEL_GA_DATE = new Date('2023-07-01');
