import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { GQActiveWatershedEmployeeFieldsFragment } from '@watershed/shared-universal/generated/graphql';
import { ContextDrawerConfig } from './measurement/context/FiltersUtils';

interface AdminContextData {
  activeWatershedEmployee: GQActiveWatershedEmployeeFieldsFragment;

  // There are two possible side drawers in Admin, accessible on all Org pages.
  openSideDrawer: 'inbox' | 'context' | null;
  setOpenSideDrawer: Dispatch<SetStateAction<'inbox' | 'context' | null>>;

  // Configures the context drawer; components/pages can set this.
  contextDrawerConfig: ContextDrawerConfig;
  setContextDrawerConfig: Dispatch<SetStateAction<ContextDrawerConfig>>;
}

const AdminContext = React.createContext<AdminContextData>({
  activeWatershedEmployee: {
    id: 'id',
    name: 'name',
    handle: 'handle',
    email: 'email',
    user: {
      id: 'id',
      createdAt: new Date(),
      primaryLoginOrgId: 'orgId',
      accessibleOrgs: [],
    },
    orgPointsOfContact: [],
  },
  openSideDrawer: null,
  setOpenSideDrawer: () => {},
  contextDrawerConfig: {},
  setContextDrawerConfig: () => {},
});

export function AdminContextProvider({
  activeWatershedEmployee,
  children,
}: React.PropsWithChildren<{
  activeWatershedEmployee: GQActiveWatershedEmployeeFieldsFragment;
}>) {
  const [openSideDrawer, setOpenSideDrawer] = useState<
    'inbox' | 'context' | null
  >(null);
  const [contextDrawerConfig, setContextDrawerConfig] =
    useState<ContextDrawerConfig>({});

  return (
    <AdminContext.Provider
      value={{
        activeWatershedEmployee,
        openSideDrawer,
        setOpenSideDrawer,
        contextDrawerConfig,
        setContextDrawerConfig,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export function useAdminContext() {
  return useContext(AdminContext);
}
