import {
  useLayoutEffect,
  useCallback,
  useRef,
  useSyncExternalStore,
} from 'react';

type ResizeObserverPredicate = (entry: ResizeObserverEntry) => boolean;

interface UseResizeObserverOptions {
  /**
   * The element to observe. Defaults to the body. If set to null, this hook will always return the
   * default value.
   * If a string is provided, it will be used as a query selector to find the element.
   * Usage note: when passing an `Element` instance, you will want to use the useState ref pattern
   * to make sure it is properly updated as per the React lifecycle. E.g.:
   *
   * ```tsx
   * const [element, setElement] = useState<Element | null>(null);
   * const isWide = useResizeObserver((entry) => entry.contentRect.width > 800, { observedElement: element });
   *
   * return <div ref={setElement}>...</div>;
   *
   * ```
   * */
  observedElement?: string | Element | null;

  /**
   * Whether the predicate should default to true or false before the first observation,
   * i.e. when the value is accessed during SSR. Defaults to false.
   *
   */
  defaultValue?: boolean;
}

/**
 * Returns true if the predicate is true for the observed element (the body by default).
 * Note that this hook will only trigger a re-render when the output boolean changes,
 * which makes it preferable to using `useWindowSize` or similar in cases where renders
 * are expensive.
 *
 * NOTE: Generally speaking the body will never change *height* when the window is resized.
 * NOTE: For performance reasons, try to ensure that the predicate is stable, either by
 *       defining it outside the component, or by using `useCallback`.
 *
 * @param predicate - A function that takes a ResizeObserverEntry and returns a boolean.
 * @param options - An instance of `UseResizeObserverOptions`.
 * @returns {boolean} Whether the predicate is true for the observed element.
 *
 * @example
 * ```ts
 * const predicate = useCallback(
 *   (entry: ResizeObserverEntry) => entry.contentRect.width < 800,
 *   []
 * );
 * const isNarrowerThan800Px = useResizeObserverWithPredicate(predicate);
 * ```
 * */
export function useResizeObserverWithPredicate(
  predicate: ResizeObserverPredicate,
  options: UseResizeObserverOptions = {}
): boolean {
  const { observedElement, defaultValue = false } = options;

  // Defend against users defining the predicate inline, which would cause the subscribe
  // callback to be called on every render.
  const predicateRef = useRef(predicate);
  useLayoutEffect(() => {
    predicateRef.current = predicate;
  }, [predicate]);

  const valueRef = useRef(defaultValue);
  const observerRef = useRef<ResizeObserver | null>(null);

  const subscribe = useCallback(
    (callback: () => void) => {
      const observer = new ResizeObserver((entries) => {
        if (entries.length < 1) return; // Should never happen, but just in case
        const newVal = predicateRef.current(entries[0]);
        const changed = newVal !== valueRef.current;
        valueRef.current = newVal;
        if (changed) callback();
      });

      const toObserve = resolveElement(observedElement);

      if (toObserve != null) {
        observer.observe(toObserve);
      }
      observerRef.current = observer;

      return () => {
        observer.disconnect();
        observerRef.current = null;
      };
    },
    [observedElement]
  );

  const getSnapshot = useCallback(() => valueRef.current, []);
  const getServerSnapshot = useCallback(() => defaultValue, [defaultValue]);

  const value = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  return value;
}

function resolveElement(
  element: string | Element | null | undefined
): Element | null {
  if (element === null) {
    // Probably a ref that hasn't been set yet.
    return null;
  }

  if (typeof element === 'string') {
    // A query selector
    const elem = document.querySelector(element);
    if (elem == null) {
      console.warn(
        `[useResizeObserver]: Query selector: Element not found: ${element}`
      );
    }

    return elem;
  }
  // A ref that has been set.
  if (element instanceof Element) return element;

  // Default to the body.
  return document.body;
}
