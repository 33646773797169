import { EffectCallback, useEffect } from 'react';

/**
 * Fires a callback once after the component first mounts (its first render).
 *
 * This is not called useComponentDidMount because the timing is slightly
 * different (later) than the old componentDidMount lifecycle method:
 * https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
 */
export default function useEffectOnce(callback: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
}
