import invariant from 'invariant';

export default function only<T>(
  array: ReadonlyArray<T>,
  message: string = 'Expected array to have exactly one element'
): T {
  const condition = array.length === 1;
  invariant(
    condition,
    condition
      ? 'error'
      : `${message}, received ${array.length}; JSON=(${JSON.stringify(
          array
        ).slice(0, 100)})`
  );
  return array[0];
}
