import { NonOptionalKeys } from '@watershed/shared-universal/utils/utilTypes';
import { useCallback } from 'react';
import {
  Dialog,
  DialogProps as DefaultDialogProps,
} from '../components/Dialog';
import { usePortalContext } from '../utils/PortalContext';

// See does in useDialog.stories.mdx.

export type UseDialogProps<OnCloseArgs = never> = {
  onClose: (...args: Array<OnCloseArgs>) => void;
  children?: React.ReactNode;
};

// Make the 'onClose' prop optional when opening a dialog with the `openDialog` function
export type WithOptionalOnClose<T extends UseDialogProps> = Omit<T, 'onClose'> &
  Partial<Pick<T, 'onClose'>>;

// Make the 'props' argument optional if all props are optional
export type OpenDialog<T extends UseDialogProps> = [
  NonOptionalKeys<WithOptionalOnClose<T>>,
] extends [never]
  ? (props?: WithOptionalOnClose<T>) => void
  : (props: WithOptionalOnClose<T>) => void;

export function createDialogHook<T extends UseDialogProps>(
  DialogComponent: (props: T) => JSX.Element
): () => OpenDialog<T> {
  const useDialog = () => {
    const { addPortal, removePortal } = usePortalContext();

    const openDialog = useCallback<OpenDialog<T>>(
      (props: WithOptionalOnClose<T> | undefined) => {
        const portalId = addPortal(
          // @ts-expect-error TODO: fix type error resulting from upgrade to @types/react@18
          <DialogComponent
            {...props}
            onClose={(...args) => {
              props?.onClose?.(...args);
              removePortal(portalId);
            }}
          />
        );
      },
      [addPortal, removePortal]
    );

    return openDialog;
  };
  return useDialog;
}

/**
 * Returns a hook that opens a dialog with the specified props on demand.
 *
 * One caveat: since the dialog is rendered in a portal rather than as a child
 * of the component, the dialog props cannot be updated. For example, if you are
 * passing in the result of a GraphQL query, the dialog will not update if the
 * query result changes - and if you need it to, you should use the standard
 * Dialog-as-a-child approach.
 */
export function useDialog<T extends UseDialogProps = DefaultDialogProps>(
  DialogComponent: (props: T) => JSX.Element = Dialog
): OpenDialog<T> {
  const hook = createDialogHook(DialogComponent);
  return hook();
}
