import {
  Box,
  Typography,
  Collapse,
  SxProps,
  Theme,
  TypographyProps,
  Stack,
} from '@mui/material';
import Button from './Button';
import React, { ReactNode, useId, useState } from 'react';
import { mixinSx } from '@watershed/style/styleUtils';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import CollapseIcon, { type CollapseIconProps } from './CollapseIcon';

export default function CollapsibleSection({
  title,
  subtitle,
  titleVariant,
  children,
  chevronOnly,
  expansionButtonAlignment = 'end',
  iconProps = {
    direction: 'down',
  },
  initiallyExpanded,
  isExpanded: propIsExpanded,
  onExpanded,
  sx,
  sharedSx,
  buttonSx,
  contentSx,
  titleSx,
  timeout,
}: {
  title?: LocalizedString | JSX.Element;
  subtitle?: React.ReactNode | ((isExpanded: boolean) => React.ReactNode);
  titleVariant?: TypographyProps['variant'];
  children: ReactNode;
  chevronOnly?: boolean;
  iconProps?: CollapseIconProps;
  expansionButtonAlignment?: 'start' | 'end';
  initiallyExpanded?: boolean;
  isExpanded?: boolean;
  onExpanded?: (expanded: boolean) => void;
  sx?: SxProps<Theme>;
  sharedSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  timeout?: number;
}) {
  const [stateIsExpanded, setStateIsExpanded] = useState(
    propIsExpanded ?? initiallyExpanded ?? true
  );
  const contentDomId = useId();
  const isExpanded = propIsExpanded ?? stateIsExpanded;

  // Note: Wrapping div is required because the component is used in stacks
  const subtitleContent =
    subtitle && typeof subtitle === 'function'
      ? subtitle(isExpanded)
      : subtitle;

  const expansionButtonBox = (
    <Stack className="collapse" direction="row" gap={0.75}>
      {!chevronOnly && (
        <Typography component="span" aria-hidden color="textSecondary">
          {isExpanded ? 'Collapse' : 'Expand'}
        </Typography>
      )}
      <CollapseIcon size={16} color="text.secondary" {...iconProps} />
    </Stack>
  );
  return (
    <Stack sx={sx} gap={1.5}>
      <Button
        data-test={TestIds.CollapsibleSectionButton}
        aria-expanded={isExpanded}
        aria-controls={contentDomId}
        variant="text"
        onClick={() => {
          const newExpanded = !isExpanded;
          setStateIsExpanded(newExpanded);
          onExpanded?.(newExpanded);
        }}
        sx={mixinSx(
          {
            backgroundColor: 'white',
            padding: 0,
            width: `100%`,

            '&:hover, &:focus': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              '& .collapse': {
                backgroundColor: 'secondary.light',
              },
            },
            justifyContent: !title ? 'end' : undefined,
            '& .collapse': {
              borderRadius: '6px',
              py: 0.5,
              px: chevronOnly ? 0.5 : 1.5,
              display: 'flex',
              alignItems: 'center',
            },
          },
          buttonSx,
          sharedSx
        )}
      >
        {expansionButtonAlignment === 'start' && expansionButtonBox}
        {title && (
          <Stack flexGrow={1} flexDirection="row" gap={1} overflow="hidden">
            <Typography
              variant={titleVariant ?? 'h2'}
              component="span"
              sx={mixinSx(
                {
                  paddingRight: !!subtitleContent ? 0 : 1.5,
                  textAlign: 'left',
                },
                titleSx
              )}
            >
              {title}
            </Typography>
            {!!subtitleContent && (
              // In order to get text ellipses to work on subtitleContent with
              // Typography styled with:
              // whiteSpace: 'nowrap'
              // overflow: 'hidden'
              // textOverflow: 'ellipsis'
              // we need to wrap all containing elements with overflow: 'hidden' as well. So,
              // we do that just incase.
              <Stack pr={1} width="100%" flexDirection="row" overflow="hidden">
                {subtitleContent}
              </Stack>
            )}
          </Stack>
        )}
        {expansionButtonAlignment === 'end' && expansionButtonBox}
      </Button>

      <Collapse in={isExpanded} timeout={timeout}>
        <Box
          sx={mixinSx(
            {
              backgroundColor: 'white',
              borderRadius: 1,
              padding: 0,
            },
            sharedSx,
            contentSx
          )}
          id={contentDomId}
        >
          {children}
        </Box>
      </Collapse>
    </Stack>
  );
}
