import { Trans } from '@lingui/react/macro';
import { Box, Stack, Typography } from '@mui/material';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import SectionedDefinitionList from '@watershed/ui-core/components/SectionedDefinitionList';
import isDashboardApp from '@watershed/ui-core/utils/isDashboardApp';
import { GraphQLError } from 'graphql';
import type { CombinedError } from 'urql';

export default function FailedToContactApiDevelopmentErrorBox({
  error,
}: {
  error?: CombinedError;
}) {
  return (
    <Box sx={{ height: '100vh', display: 'flex' }}>
      {error?.response.status === 200 && error.graphQLErrors.length > 0 ? (
        <GraphqlErrorBox graphqlErrors={error.graphQLErrors} />
      ) : (
        <GenericErrorBox error={error} />
      )}
    </Box>
  );
}

function GraphqlErrorBox({
  graphqlErrors,
}: {
  graphqlErrors: Array<GraphQLError>;
}) {
  return (
    <ErrorBox
      sx={{
        margin: 'auto',
        padding: 2,
        maxWidth: 1200,
      }}
    >
      <Typography variant="h2" gutterBottom>
        <Trans context="Header">GraphQL Error</Trans>
      </Typography>
      <Stack gap={2}>
        {graphqlErrors.map((error, i) => (
          <SectionedDefinitionList
            key={i}
            sx={{ background: 'transparent', border: 'none' }}
          >
            <dt>
              <Trans context="Error message">Message</Trans>
            </dt>
            <dd>{error.message}</dd>
            <dt>
              <Trans context="Error path (GraphQL term)">Path</Trans>
            </dt>
            <dd>{error.path}</dd>
            <dt>
              <Trans context="Code referenced by technical error">Code</Trans>
            </dt>
            {/* TODO: Proper type for extensions -- GraphQL changed the type
            from any to unknown, which broke a few thing */}
            <dd>{(error.extensions as any).code ?? 'Unknown'}</dd>
            <dt>
              <Trans context="Technical term for error details">
                Stacktrace
              </Trans>
            </dt>
            <dd>
              <pre>
                {(error.extensions as any).exception?.stacktrace.join('\n') ??
                  '–'}
              </pre>
            </dd>
          </SectionedDefinitionList>
        ))}
      </Stack>
    </ErrorBox>
  );
}

function GenericErrorBox({ error }: { error?: CombinedError }) {
  const processName = isDashboardApp() ? 'dashboard-srv' : 'admin-srv';
  return (
    <ErrorBox
      level="warning"
      sx={{
        margin: 'auto',
        padding: 2,
        maxWidth: 800,
        '& .MuiTypography-root': { color: 'white' },
      }}
    >
      <Typography variant="h2" gutterBottom>
        <Trans context="Technical error detail">
          Could not reach GraphQL server
        </Trans>
      </Typography>
      <Typography variant="h4" gutterBottom>
        <Trans context="Technical error detail">
          The web frontend could not connect to the GraphQL API server. This
          could mean:
        </Trans>
      </Typography>
      <Box component="ol" sx={{ paddingLeft: 2, margin: 0 }}>
        <li>
          <Trans context="Technical error detail">
            The Next.js server is taking a long time to compile and restart, and
            you can refresh until it works 🤞.
          </Trans>
        </li>
        <li>
          <Trans context="Technical error detail">
            The `{processName}` process is not running, or has crashed. Check
            the output in your terminal. There could be a code error or you may
            need to install dependencies after switching branches or pulling
            code. Try `./scripts/install-deps`.
          </Trans>
        </li>
      </Box>
      {error?.response && (
        <Box sx={{ marginTop: 2 }}>
          <pre style={{ margin: 0 }}>
            <Trans context="Error detail UI">
              Error: {error.response.statusText}
            </Trans>
          </pre>

          <pre style={{ margin: 0 }}>
            <Trans context="Error detail UI">
              Status: {error.response.status}
            </Trans>
          </pre>
          <pre style={{ margin: 0 }}>
            <Trans context="Error detail UI">Url: {error.response.url}</Trans>
          </pre>
        </Box>
      )}
    </ErrorBox>
  );
}
