import { ParsedUrlQueryInput } from 'querystring';

export default function getPathWithQuery(
  path: string,
  params:
    | { [key: string]: string | undefined | null }
    | URLSearchParams
    | ParsedUrlQueryInput
    | undefined,
  fragment?: string | { [key: string]: string | undefined | null }
): string {
  const usp =
    params instanceof URLSearchParams
      ? params
      : new URLSearchParams(filterNullishValues(params ?? {}));
  const query = usp.toString();
  const fragmentString =
    fragment &&
    (typeof fragment === 'string'
      ? fragment
      : // We're copying what jotai does for their hashAtom
        // https://github.com/jotaijs/jotai-location/blob/main/src/atomWithHash.ts#L68
        new URLSearchParams(filterNullishValues(fragment ?? {})).toString());
  const hashTag = fragmentString ? `#${fragmentString}` : '';
  if (query.length > 0) {
    return `${path}?${query}${hashTag}`;
  }
  return `${path}${hashTag}`;
}

export function addQueryToPathAndQuery(
  original: string,
  params:
    | { [key: string]: string | undefined | null }
    | URLSearchParams
    | ParsedUrlQueryInput
    | undefined
): string {
  const [pathname, query] = original.split('?');
  const originalUsp = new URLSearchParams(query);
  const additionalUsp =
    params instanceof URLSearchParams
      ? params
      : new URLSearchParams(filterNullishValues(params ?? {}));
  return getPathWithQuery(pathname, {
    ...Object.fromEntries(originalUsp.entries()),
    ...Object.fromEntries(additionalUsp.entries()),
  });
}

function filterNullishValues(obj: object) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value != null)
  );
}
