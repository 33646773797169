import Icon, { IconProps } from '../Icon';
const ViewOffIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M21.29 22.71c.2.19.45.29.71.29v-.01a.996.996 0 0 0 .71-1.7l-4.494-4.494A1.006 1.006 0 0 0 18 16.58l-3.05-3.05a1.01 1.01 0 0 0-.241-.241l-4.034-4.034a.951.951 0 0 0-.175-.175L7.371 5.951a.993.993 0 0 0-.098-.098L2.71 1.29C2.32.9 1.68.9 1.29 1.29c-.39.39-.39 1.03 0 1.42L5.081 6.5A14.531 14.531 0 0 0 1.1 11.57a.98.98 0 0 0-.02.82C1.21 12.71 4.43 20 12 20h.03l.01-.01c1.833 0 3.607-.464 5.19-1.34l4.06 4.06Zm-5.55-5.55-1.693-1.693a3.988 3.988 0 0 1-2.037.603h-.08c-.52 0-1.02-.1-1.5-.29-.5-.2-.95-.5-1.33-.88a3.96 3.96 0 0 1-.88-1.33c-.2-.5-.3-1.03-.29-1.57 0-.54.13-1.06.35-1.56.075-.167.16-.329.257-.483L6.505 7.925A12.533 12.533 0 0 0 3.12 12c.73 1.41 3.5 5.99 8.89 5.99a9.42 9.42 0 0 0 3.73-.83Zm-5.707-5.707c-.06.187-.093.38-.093.577 0 .27.04.53.15.78.1.25.25.47.44.66.19.19.42.34.66.44.25.1.53.15.79.15.195 0 .385-.031.57-.09l-2.517-2.517Z"
      clipRule="evenodd"
    />
    <path d="M19.7 15.46c.19.15.41.22.63.22l-.01-.01c.29 0 .58-.12.78-.37.71-.88 1.32-1.85 1.8-2.88.12-.26.12-.56.01-.82-.13-.31-3.35-7.61-10.92-7.61-.46 0-.93.03-1.39.09a1.002 1.002 0 1 0 .25 1.99c.38-.05.77-.07 1.15-.07 5.39 0 8.17 4.58 8.89 5.99-.38.73-.83 1.42-1.34 2.06-.35.43-.28 1.06.15 1.41Z" />
  </Icon>
);
export default ViewOffIcon;
