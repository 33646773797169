import type { ComponentProps, ReactNode } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  IconButton as MuiIconButton,
  // eslint-disable-next-line no-restricted-imports
  IconButtonProps as MuiIconButtonProps,
  SxProps,
  Theme,
  TooltipProps,
} from '@mui/material';
import type { WatershedLinkProps } from '../types/LinkProps';
import createWatershedButton from '../utils/createWatershedButton';

type BaseIconButtonProps = Omit<
  MuiIconButtonProps,
  | 'to'
  | 'href'
  // We're not using the `tw` experimental Tailwind prop, which is marked as
  // optional in `MuiButtonProps`. But for some reason, when we introduced
  // `next-env.d.ts` to `ui-core`, it started causing this bizarre type error,
  // so we'll just omit it here. More details:
  // https://github.com/watershed-climate/ghg/pull/40050#issuecomment-1673322341
  | 'tw'
> &
  Omit<WatershedLinkProps, 'onClick'> & {
    tooltip?: ReactNode;
    tooltipPlacement?: TooltipProps['placement'];
    isIcon?: boolean;
    buttonWrapperSx?: SxProps<Theme>;
  };

const IconButton = createWatershedButton<BaseIconButtonProps>(MuiIconButton);
export type IconButtonProps = ComponentProps<typeof IconButton>;
export default IconButton;
