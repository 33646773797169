import { Box } from '@mui/material';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { GQOrgFieldsForAdminNavFragment } from '@watershed/shared-universal/generated/graphql';
import { useAdminNavQuery } from '@watershed/shared-frontend/generated/urql';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import useKeydown from '@watershed/shared-frontend/hooks/useKeydown';
import NavBar from './NavBar';
import QuickSwitcher, {
  useLoadQuickSwitcherData,
} from './QuickSwitcher/QuickSwitcher';
import AdminLoadingPage from './AdminLoadingPage';
import { registerPropertiesMixpanel } from '../utils/adminAnalytics';
import { CSSProperties } from '@mui/styles';
import { mixinSx } from '@watershed/style/styleUtils';
import AdminAppsDrawer from './AdminAppsDrawer';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';

gql`
  fragment OrgFieldsForAdminNav on Organization {
    id
    name
    hasUsers
    demoOrg
    testOrg
    watershedPlanLegacy
  }

  query AdminNav {
    organizations {
      edges {
        node {
          ...OrgFieldsForAdminNav
        }
      }
    }
  }
`;

type Breadcrumbs = Array<{ title: string; url?: string }>;

/**
 * Stateless, presentation-only layout component
 */
function AdminPageLayout({
  title,
  breadcrumbs = [],
  org = null,
  children,
  onQuickSwitch,
  onOpenQuickLinks,
  loading = false,
  bodySx,
  helpLink,
  helpLinkDescription,
}: {
  title?: string;
  breadcrumbs?: Breadcrumbs;
  org?: GQOrgFieldsForAdminNavFragment | null;
  onQuickSwitch?: () => void;
  onOpenQuickLinks?: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  bodySx?: CSSProperties;
  helpLink?: string;
  helpLinkDescription?: string;
}) {
  return (
    <>
      <NavBar
        org={org}
        title={title}
        breadcrumbs={breadcrumbs}
        onQuickSwitch={onQuickSwitch}
        onOpenQuickLinks={onOpenQuickLinks}
        helpLink={helpLink}
        helpLinkDescription={helpLinkDescription}
      />
      <Box sx={mixinSx({ padding: 2 }, bodySx)}>
        {loading ? <AdminLoadingPage /> : children}
      </Box>
    </>
  );
}

export default function AdminPageContainer({
  orgId: initialOrgId,
  title,
  breadcrumbs,
  children,
  bodySx,
  helpLink,
  helpLinkDescription,
}: {
  orgId?: string;
  title?: string;
  breadcrumbs: Breadcrumbs;
  children: React.ReactNode;
  /**
   * Styles applied to the body of the page-layout container.
   */
  bodySx?: CSSProperties;
  helpLink?: string;
  helpLinkDescription?: string;
}) {
  const [res] = useAdminNavQuery();
  const orgs = flattenConnection(res.data?.organizations);
  const [orgIdQueryParam] = useQueryParam('orgId');

  // Preload quick switcher data so first open is fast
  useLoadQuickSwitcherData();

  // For convenience, pull orgId from the query or path param if present.
  const orgId = initialOrgId ?? orgIdQueryParam;

  // Note: certain mutations (e.g. inviting a user) will temporarily make
  // the active org disappear while we refetch it. During that time, orgId
  // will be set, but activeOrg will be undefined. We tolerate that.
  const activeOrg = orgId ? orgs.find((org) => org.id === orgId) : null;

  // For Admin analytics, we want to store the org name for each event. But we
  // can't statically get the org name from the URL, since we just have an ID.
  // Thus, we 'register' the org name in the AdminPageContainer here, such that
  // the _leaving_ 'viewWithDuration' event will pick it up.
  //
  // This will also cause other events to spuriously pick up an org name, so
  // usages of this field should filter to only events with an orgId in their
  // route params. This will be done using a Mixpanel custom property.
  useEffect(() => {
    if (activeOrg?.name) {
      registerPropertiesMixpanel({ lastQueriedActiveOrgName: activeOrg?.name });
    }
  }, [activeOrg?.name]);

  const [showingQuickSwitcher, setShowingQuickSwitcher] = useState(false);
  const [showingQuickLinks, setShowingQuickLinks] = useState(false);
  useKeydown((evt) => {
    const cmdOrControl = evt.metaKey || evt.ctrlKey;
    if (cmdOrControl && evt.key === 'k') {
      evt.preventDefault();
      setShowingQuickSwitcher(true);
    }
    // quick switcher takes precedence over go to app
    if (cmdOrControl && evt.key === '.' && !showingQuickSwitcher) {
      evt.preventDefault();
      setShowingQuickLinks(!showingQuickLinks);
    }
    // The quick switcher has its own logic about hiding on escape, so just hide the quick links UI on escape
    if (evt.key === 'Escape') {
      evt.preventDefault();
      setShowingQuickLinks(false);
    }
  });

  // If you want to search admin from your browser's address bar, we support that!
  // Simply use the `quickSwitcherSearch` query param, and we'll open the quick
  // switcher with the search term pre-filled on page load (cleared after first use)
  const [quickSwitcherSearch, setQuickSwitcherSearch] = useQueryParam(
    'quickSwitcherSearch'
  );
  const [prefilledQuickSwitcherContent, setPrefilledQuickSwitcherContent] =
    useState<string | null>(quickSwitcherSearch);
  useEffect(() => {
    if (prefilledQuickSwitcherContent) {
      setShowingQuickSwitcher(true);
      setQuickSwitcherSearch(null); // clear the query param after first use
    }
  }, [prefilledQuickSwitcherContent, setQuickSwitcherSearch]);

  return (
    <AdminPageLayout
      org={activeOrg}
      title={title}
      breadcrumbs={breadcrumbs}
      onQuickSwitch={() => setShowingQuickSwitcher(true)}
      onOpenQuickLinks={() => setShowingQuickLinks(!showingQuickLinks)}
      bodySx={bodySx}
      helpLink={helpLink}
      helpLinkDescription={helpLinkDescription}
    >
      {children}
      {showingQuickSwitcher && (
        <QuickSwitcher
          orgs={orgs}
          activeOrgId={activeOrg?.id || null}
          onClose={() => {
            setShowingQuickSwitcher(false);
            setPrefilledQuickSwitcherContent(null); // query param only used the very first time quick switcher is used on page load
          }}
          prefilledContent={prefilledQuickSwitcherContent}
        />
      )}
      <AdminAppsDrawer
        open={showingQuickLinks}
        onClose={() => setShowingQuickLinks(false)}
      />
    </AdminPageLayout>
  );
}

AdminPageContainer.Loading = function AdminPageContainerLoading({
  breadcrumbs,
}: {
  breadcrumbs?: Breadcrumbs;
}) {
  return <AdminPageLayout loading breadcrumbs={breadcrumbs} />;
};
