import { CustomSpanAttributes } from '@watershed/shared-universal/constants/otelCustomAttributes';
import frontendHoneycomb from '../frontendHoneycomb';
import some from 'lodash/some';
import { IAnalyticsTrackerOptions } from 'perfume.js/dist/types/types';
import {
  MEANINGFUL_PAINT_PAGE_NAMES,
  MeaningfulPaintTimerProps,
  MeaningfulPaintAdditionalProperties,
} from './measureMeaningfulPaint';
import { clear, end, initPerfume, start } from 'perfume.js';
// eslint-disable-next-line
// @ts-ignore

type MeaningfulPaintPerfumeProps = MeaningfulPaintTimerProps &
  MeaningfulPaintAdditionalProperties;

export const perfume = {
  start,
  end,
  clear,
};

const WEB_VITAL_METRICS_MAPPING: { [key: string]: string } = {
  TTFB: CustomSpanAttributes.WEB_VITALS_TTFB,
  CLS: CustomSpanAttributes.WEB_VITALS_CLS,
  FCP: CustomSpanAttributes.WEB_VITALS_FCP,
  FID: CustomSpanAttributes.WEB_VITALS_FID,
  LCP: CustomSpanAttributes.WEB_VITALS_LCP,
  INP: CustomSpanAttributes.WEB_VITALS_INP,
};

const IGNORELIST_DOMAINS = [
  'honeycomb.io',
  'googletagmanager.com',
  'mep.watershedclimate.com',
  'otel.watershedclimate.com',
  'google-analytics.com',
  'cloudflare.com',
  'fullstory.com',
  'sentry.io',
  'mixpanel.com',
  'browser-intake-datadoghq.com',
  'dashboard-cdn.watershedclimate.com',
];

const shouldSendToHoneyComb = !!(
  process.env.NEXT_PUBLIC_HONEYCOMB_ENABLED !== 'false' &&
  process.env.NEXT_PUBLIC_HONEYCOMB_KEY &&
  process.env.NEXT_PUBLIC_APPLICATION
);

export default async function initializeFrontendPerformanceMonitoring() {
  try {
    initPerfume({
      resourceTiming: true,
      elementTiming: false,
      analyticsTracker: analyticsTracker(),
      maxMeasureTime: 180000,
      reportOptions: {
        ttfb: {
          reportAllChanges: false,
          durationThreshold: 0,
        },
        cls: {
          reportAllChanges: false,
          durationThreshold: 0,
        },
        fcp: {
          reportAllChanges: false,
          durationThreshold: 0,
        },
        fid: {
          reportAllChanges: false,
          durationThreshold: 0,
        },
        lcp: {
          reportAllChanges: false,
          durationThreshold: 0,
        },
        inp: {
          reportAllChanges: false,
          durationThreshold: 0,
        },
      },
    });
  } catch (e) {
    console.info(`Failed to initialize frontend performance monitoring ${e}`);
  }
}

type ResourceTimingData = {
  name: string;
  duration: number;
  encodedBodySize: number;
  decodedBodySize: number;
  responseStatus: number;
  initiatorType: string;
  transfersSize: number;
};

type PerfumeEvent<T = {}> = {
  attribution: T;
  metricName: string;
  data: number | ResourceTimingData;
};

function analyticsTracker() {
  return function (options: IAnalyticsTrackerOptions) {
    if (!shouldSendToHoneyComb) return;
    try {
      if (options.metricName in WEB_VITAL_METRICS_MAPPING) {
        emitWebVitalsEvent(options as PerfumeEvent);
      } else if (options.metricName === 'resourceTiming') {
        emitResourceTimingEvent(options as PerfumeEvent);
      } else if (MEANINGFUL_PAINT_PAGE_NAMES.has(options.metricName)) {
        emitMeaningfulPaintEvent(
          options as PerfumeEvent<MeaningfulPaintPerfumeProps>
        );
      } else {
        emitDefaultEvent(options as PerfumeEvent);
      }
    } catch (e) {
      // ignore errors.
    }
  };
}

function emitWebVitalsEvent(options: PerfumeEvent) {
  const metricName = WEB_VITAL_METRICS_MAPPING[options.metricName];

  void frontendHoneycomb.sendEvent({
    name: CustomSpanAttributes.BROWSER_WEB_VITAL_METRIC,
    [metricName]: options.data,
  });
}

function emitResourceTimingEvent(options: PerfumeEvent) {
  const perfumeData = options.data as ResourceTimingData;

  const isIgnored = some(IGNORELIST_DOMAINS, (url: string) => {
    return perfumeData.name.includes(url);
  });

  // Don't send events for DOMAINS we don't care about.
  if (isIgnored) {
    return;
  }

  let httpUrl = perfumeData.name;
  if (httpUrl.includes('X-Goog-Signature')) {
    httpUrl = httpUrl.replace(/(X-Goog-Signature=)[^&]*/, '$1<redacted>');
  }

  let httpHost: string | undefined;
  try {
    httpHost = new URL(httpUrl).host;
  } catch {
    // ignore unparsed hosts
  }

  void frontendHoneycomb.sendEvent({
    name: CustomSpanAttributes.BROWSER_RESOURCE_TIMING,
    [CustomSpanAttributes.HTTP_URL]: httpUrl,
    [CustomSpanAttributes.HTTP_HOST]: httpHost,
    [CustomSpanAttributes.HTTP_DECODED_BODY_SIZE]: perfumeData.decodedBodySize,
    [CustomSpanAttributes.HTTP_ENCODED_BODY_SIZE]: perfumeData.encodedBodySize,
    [CustomSpanAttributes.HTTP_DURATION_MS]: perfumeData.duration,
    [CustomSpanAttributes.HTTP_STATUS_CODE]: perfumeData.responseStatus,
  });
}

function emitMeaningfulPaintEvent(
  options: PerfumeEvent<MeaningfulPaintPerfumeProps>
) {
  void frontendHoneycomb.sendEvent({
    ...options.attribution,
    name: CustomSpanAttributes.BROWSER_WEB_VITAL_METRIC,
    [CustomSpanAttributes.OWNER]: options.attribution.owner,
    [CustomSpanAttributes.WEB_VITALS_BLUR_DURING_LOAD]:
      options.attribution.userLeftTabDuringLoad,
    [CustomSpanAttributes.WEB_VITALS_PAGE_NAME]: options.metricName,
    [CustomSpanAttributes.WEB_VITALS_MP]: options.data,
  });
}

function emitDefaultEvent(options: PerfumeEvent) {
  void frontendHoneycomb.sendEvent({
    name: CustomSpanAttributes.BROWSER_CLIENT_METRIC,
    [CustomSpanAttributes.BROWSER_CLIENT_METRIC_NAME]: options.metricName,
    [CustomSpanAttributes.BROWSER_CLIENT_METRIC_DURATION]: options.data,
    ...options.attribution,
  });
}
