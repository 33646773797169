import { Chip, ChipProps } from '@mui/material';
import { mixinSx } from '@watershed/style/styleUtils';

export type ToggleChipProps = ChipProps & {
  isSelected: boolean;
};

/**
 * A chip that can be toggled on and off. Its style will change based on
 * `isSelected`.
 */
export default function ToggleChip({
  isSelected,
  sx,
  ...props
}: ToggleChipProps) {
  return (
    <Chip
      variant="outlined"
      tabIndex={0}
      role="checkbox"
      aria-checked={isSelected}
      sx={mixinSx(
        {
          userSelect: 'none',
          '&.Mui-focusVisible': {
            boxShadow: (theme) =>
              `0 0 0 1.5px ${theme.palette.paper}, 0 0 0 3px ${theme.palette.primary.main}`,
          },
          // override MUI specificity
          '&&:hover': {
            backgroundColor: (theme) => theme.palette.cobalt05,
          },
          // override hover specificity
          '&&&[aria-checked="true"]': {
            backgroundColor: (theme) => theme.palette.grey20,
            borderColor: (theme) => theme.palette.grey20,
          },
        },
        sx
      )}
      {...props}
    />
  );
}
