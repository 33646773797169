import { useRouter } from 'next/router';
import React, { useCallback, useContext, useRef, useState } from 'react';

type Portal = React.ReactNode;
type PortalMap = Map<string, Portal>;

const defaults: {
  portals: PortalMap;
  addPortal: (portal: Portal) => string;
  removePortal: (portalId: string) => void;
} = {
  portals: new Map(),
  addPortal: () => 'portal-id',
  removePortal: () => {},
};

const PortalContext = React.createContext(defaults);

export function usePortalContext() {
  return useContext(PortalContext);
}

/**
 * Remount the whole thing when the page changes. This prevents e.g.
 * dialogs from persisting across page navigations.
 *
 * NOTE: does not apply to query param changes; if you want a portal to be
 * unmounted when the query params change, you'll need to handle that
 * yourself.
 */
function withRemountOnPageNavigation<T>(Component: React.ComponentType<T>) {
  return function WithRemountOnPageNavigation(props: T) {
    const router = useRouter();
    return <Component key={router.pathname} {...props} />;
  };
}

/**
 * Why is is useful to have a PortalContext, rather than just using
 * React.createPortal() or appending a new dialog/modal etc to the DOM? Because
 * we want to make sure all of our components are rendered within the main
 * React tree in order to be a descendent of our app's context providers.
 */
export function PortalContextProvider({
  children,
}: { children: React.ReactNode }) {
  const [portals, setPortals] = useState<PortalMap>(() => new Map());
  const currentPortalId = useRef(0);

  const getPortalId = () => `portal-${currentPortalId.current++}`;

  const addPortal = useCallback((portal: Portal) => {
    const portalId = getPortalId();
    setPortals((previous) => {
      const portals = new Map(previous);
      portals.set(portalId, portal);
      return portals;
    });
    return portalId;
  }, []);

  const removePortal = useCallback((portalId: string) => {
    setPortals((previous) => {
      const portals = new Map(previous);
      portals.delete(portalId);
      return portals;
    });
  }, []);

  return (
    <PortalContext.Provider value={{ portals, addPortal, removePortal }}>
      {children}
      {Array.from(portals.entries()).map(([portalId, portal]) => (
        <React.Fragment key={portalId}>{portal}</React.Fragment>
      ))}
    </PortalContext.Provider>
  );
}

/**
 * PortalContextProvider, but also uses Next Router to remound on
 * page navigation.
 */
export const PortalContextProviderWithNextRouter = withRemountOnPageNavigation(
  PortalContextProvider
);
