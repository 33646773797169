import invariant from 'invariant';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';

// Test IDs for Playwright tests.
export enum TestIds {
  // Supply chain
  SupplierTable = 'supplier-table',
  SupplierTableSearchField = 'supplier-table-search-field',
  SuppliersTableSearchFieldExpandButton = 'supplier-table-search-field-expand-button',
  SupplierFilterPopover = 'supplier-filter-popover',
  SupplierFilterPopoverButton = 'supplier-filter-popover-button',
  SupplierTableFilterForm = 'supplier-filter-form',
  SupplierFilterAddButton = 'supplier-filter-add-button',
  SupplierFilterDeleteButton = 'supplier-filter-delete-button',
  SupplierFilterSubmitButton = 'supplier-filter-submit-buttom',
  SupplierTableMapButton = 'supplier-table-map-button',
  SupplierMapDialog = 'supplier-map-dialog',
  SupplierDetailsPageSidebar = 'supplier-details-page-sidebar',
  SupplierDetailsPageSidebarEmissions = 'supplier-details-page-sidebar-emissions',
  SupplierDetailsPageSidebarSpend = 'supplier-details-page-sidebar-spend',
  SupplierDetailsPageSidebarEditContact = 'supplier-details-page-sidebar-edit-contact',
  SupplierDetailsPageOverviewTab = 'supplier-details-page-overview-tab',
  SupplierDetailsPageDisclosuresTab = 'supplier-details-page-disclosures-tab',
  SupplierDetailsPageSupplierEfsTab = 'supplier-details-page-supplier-efs-tab',
  EmissionsFactorGrid = 'emissions-factors-grid',
  SupplierDetailsPageDisclosureSection = 'supplier-details-page-disclosure-section',
  SupplierDetailsPageDisclosureTimelineItem = 'supplier-details-page-disclosure-timeline-item',
  SupplierDetailsPageContributionSection = 'supplier-details-page-contribution-section',
  SupplierDetailsPageEmissionsSection = 'supplier-details-page-emissions-section',
  SupplierDetailsPageTargetsSection = 'supplier-details-page-targets-section',
  SupplierDetailsPageTargetItem = 'supplier-details-page-target-item',
  SupplierDetailsPageCommitmentsSection = 'supplier-details-page-commitments-section',
  SupplierDetailsPageCommitmentItem = 'supplier-details-page-commitment-item',
  SupplierDetailsPageCombinedTargetsSection = 'supplier-details-page-combined-targets-section',
  SupplierDetailsPageInitiativesSection = 'supplier-details-page-initiatives-section',
  SupplierDetailsPageCreateTask = 'supplier-details-page-create-task',
  SupplierDetailsPageNewContactForm = 'supplier-details-page-new-contact-form',
  SupplierDetailsPageNewContactButton = 'supplier-details-page-new-contact-button',
  SupplierDetailsPageNewContactNameField = 'supplier-details-page-new-contact-name-field',
  SupplierDetailsPageNewContactEmailField = 'supplier-details-page-new-contact-email-field',
  SupplierDetailsPagePortalCreationRemoveTaskButton = 'supplier-details-page-portal-creation-remove-task-button',
  SupplierDetailsPageCloseSurveyButton = 'supplier-details-page-close-survey-button',
  SupplierDetailsPageEmailTemplateDialog = 'supplier-details-page-email-template-dialog',
  SupplierDetailsPageEngagementSection = 'supplier-details-page-engagement-section',
  SupplierDetailsPageResetFiltersButton = 'supplier-details-page-reset-filters-button',
  SupplierDetailsPageTasksTableElement = 'supplier-details-page-tasks-table',
  SupplierEmissionsTargetChart = 'supplier-emissions-target-chart',
  SupplierPriorityChip = 'supplier-priority-chip',
  ProgramConfigurationContainer = 'program-configuration-container',
  ProgramConfigurationButton = 'program-configuration-button',
  IncludedGhgCategories = 'included-ghg-categories',
  DisclosureQualityMessage = 'disclosure-quality-msg',
  SurveyActions = 'survey-actions',
  SurveySendButton = 'survey-send-button',
  SupplierMatchingTable = 'supplier-matching-table',
  SuppliersPageTabOverview = 'suppliers-page-tab-overview',
  SuppliersPageTabTasks = 'suppliers-page-tab-tasks',
  SuppliersPageToolbarLabel = 'suppliers-page-toolbar-label',
  SuppliersConfigTabOverview = 'suppliers-config-tab-overview',
  SuppliersConfigTabMaturityScoring = 'suppliers-config-tab-maturity-scoring',
  SuppliersConfigTabPortal = 'suppliers-config-tab-portal',
  SuppliersConfigTabCohorts = 'suppliers-config-tab-cohorts',
  SuppliersConfigTabSupplierMatching = 'suppliers-config-tab-supplier-matching',
  SuppliersConfigTabTasks = 'suppliers-config-tab-tasks',
  SuppliersConfigTabDeploys = 'suppliers-config-tab-deploys',
  SuppliersConfigTabMetrics = 'suppliers-config-tab-metrics',
  SuppliersConfigTabViews = 'suppliers-config-tab-views',
  SuppliersConfigTabNotifications = 'suppliers-config-tab-notifications',
  SuppliersConfigTabCustomSuppliers = 'suppliers-config-tab-custom-suppliers',
  SuppliersConfigTabCustomFields = 'suppliers-config-tab-custom-fields',
  SupplyChainContactsDialog = 'supply-chain-contact-dialog-grid',
  SupplyChainContactsDialogAddContact = 'supply-chain-contacts-dialog-add-contact',
  SupplyChainChartWrapper = 'supply-chain-chart-wrapper',
  SupplyChainNewViewButton = 'supply-chain-new-view-button',
  SupplyChainSaveViewNameButton = 'supply-chain-save-view-name-button',
  SupplyChainDeleteViewButton = 'supply-chain-delete-view-button',
  SupplyChainViewAddFilterButton = 'supply-chain-view-add-filter-button',
  SupplyChainViewRemoveFilterButton = 'supply-chain-view-remove-filter-button',
  SupplyChainViewResetFiltersButton = 'supply-chain-view-reset-filters-button',
  SupplyChainViewSubmitFiltersButton = 'supply-chain-view-submit-filters-button',
  SupplyChainViewSaveFiltersDropdown = 'supply-chain-view-save-filters-dropdown',
  SupplyChainViewSaveToCurrentButton = 'supply-chain-view-save-to-current-button',
  SupplyChainViewSaveToNewButton = 'supply-chain-view-save-to-new-button',
  SupplyChainViewDropdown = 'supply-chain-view-dropdown',
  SupplyChainManageViews = 'supply-chain-manage-views',
  SupplyChainNewChartButton = 'supply-chain-new-chart-button',
  SupplyChainChartEditButton = 'supply-chain-chart-edit-button',
  SupplyChainChartDeleteButton = 'supply-chain-chart-delete-button',
  SupplyChainChartEditChartDialog = 'supply-chain-chart-edit-chart-dialog',
  SupplyChainChartNewChartDialog = 'supply-chain-chart-new-chart-dialog',
  SupplyChainChartNewChartTypeInput = 'supply-chain-chart-new-chart-type-input',
  SupplyChainBarChartBar = 'supply-chain-bar-chart-bar',
  SupplyChainFunnelTooltipColumn = 'supply-chain-funnel-tooltip-column',
  SupplyChainSettingsSaveButton = 'supply-chain-settings-save-button',
  SupplyChainSettingsResetDefaultButton = 'supply-chain-settings-reset-default-button',
  SupplyChainNewCohortButton = 'supply-chain-new-cohort-button',
  SupplyChainDeleteCohortButton = 'supply-chain-delete-cohort-button',
  SupplyChainSelectSuppliersForCohortAutocomplete = 'supply-chain-select-suppliers-for-cohort-autocomplete',
  SupplyChainConfirmDeleteCohortButton = 'supply-chain-confirm-delete-cohort-button',
  SupplyChainSaveTaskConfig = 'save-task-config',
  SupplyChainTaskConfigName = 'task-config-name',
  SupplyChainTaskConfigEdit = 'task-config-edit',
  SupplyChainTaskConfigPreview = 'task-config-preview',
  SupplyChainTaskConfigList = 'task-config-list',
  SupplyChainTaskConfigClose = 'task-config-close',
  SuppliersPageAssignTasksButton = 'suppliers-assign-tasks',
  CompanyMatchingTableUnmatchedSortButton = 'company-matching-table-unmatched-sort-button',
  CompanyMatchingTableNoMatchFoundOption = 'company-matching-table-no-match-found-option',
  SupplyChainBulkDeploySelectContacts = 'bulk-deploy-select-contacts',
  SupplyChainBulkDeployAddNewContact = 'bulk-deploy-add-new-contact',
  SupplyChainBulkDeployAddContactsForm = 'bulk-deploy-add-contacts-form',
  SupplyChainBulkDeployAddAnotherTask = 'bulk-deploy-add-another-task',
  SupplyChainBulkDeployReportingYearDropdown = 'bulk-deploy-reporting-year-dropdown',
  SupplyChainScopeSummaryRow = 'scope-summary-row',
  SupplyChainEmissionsFactorEstimate = 'emissions-factor-estimate',
  SupplyChainTasksTabTable = 'supply-chain-tasks-tab-table',
  SupplyChainTasksViewResponseButton = 'supply-chain-tasks-view-response-button',
  SupplierTaskComment = 'supplier-task-comment',
  SupplyChainTaskSubmissionSuccessDialog = 'supply-chain-task-submission-success-dialog',
  SupplyChainReOpenTaskButton = 'supply-chain-re-open-task-button',
  SupplyChainImportFileLabel = 'supply-chain-import-file-label',
  SupplyChainCurrentEmissionsIntensityBlurb = 'supply-chain-current-emissions-intensity-blurb',
  SupplyChainAutoMappingButton = 'supply-chain-auto-mapping-button',
  SupplierMappingTableSearch = 'supplier-mapping-table-search',

  // Finance
  AddButton = 'add-button',
  AddAssetButton = 'add-asset-button',
  AddAssetNextButton = 'add-asset-next-button',
  AddAssetNameField = 'add-asset-name-field',
  AssetCreateAssetGroupButton = 'asset-create-asset-group-button',
  AssetCreateAssetSovereignBondButton = 'asset-create-asset-sovereign-bond-button',
  AssetCreatePersonalMotorVehicleInsuranceButton = 'asset-create-asset-personal-motor-vehicle-insurance-button',
  AssetCreateCommercialRealEstateButton = 'asset-create-commercial-real-estate-button',
  AssetHeaderTitle = 'asset-header-title',
  PreviewSurveyButton = 'preview-survey-button',
  CreateSurveyButton = 'create-survey-button',
  DeleteAssetsButton = 'delete-assets-button',
  BulkImportNoFundColumn = 'bulk-import-no-fund-column',
  BulkImportNoAssetClassColumn = 'bulk-import-no-asset-class-column',
  BulkImportButton = 'bulk-import-button',
  BulkImportMatching = 'bulk-import-matching',
  BulkImportMatchingNoChangesList = 'bulk-import-matching-no-changes-list',
  SandboxRealEstateButton = 'sandbox-real-estate-button',

  OpenFlagsIntroButton = 'open-flags-intro-button',
  CloseFlagsIntroButton = 'close-flags-intro-button',
  FinanceFlagsSidebarItem = 'finance-flags-sidebar-item',
  FinanceMeasurementSidebarItem = 'finance-measurement-sidebar-item',
  FinanceSnapshotChangelogDataGrid = 'finance-snapshot-changelog-data-grid',

  SurveyStateChip = 'survey-state-chip',
  EstimateSpinnerSnackbar = 'estimate-spinner-snackbar',
  EstimatingFootprintSnackbar = 'estimating-footprint-snackbar',
  CreatedHoldingWithEstimateSnackbar = 'created-holding-with-estimate-snackbar',
  CreatedHoldingSnackbar = 'created-holding-snackbar',
  FinanceMetricsBarSection = 'finance-metrics-bar-section',
  DownloadBulkImportTemplateButton = 'download-bulk-import-template-button',
  CreateAssetDialogContinueButton = 'create-asset-dialog-continue-button',
  AssetCorporatesDownloadTemplateButton = 'corporate-assets-download-template-button',
  AssetChangeLogTitle = 'FinanceChangelogTab-title',
  AssetTrackingTitle = 'FinanceAssetTrackingTab-Title',

  FinanceCreateSavedViewButton = 'finance-create-saved-view-button',
  FinanceEditSavedViewButton = 'finance-edit-saved-view-button',
  FinanceDeleteSavedViewButton = 'finance-delete-saved-view-button',
  FinanceSavedViewMetricSelector = 'finance-saved-view-metric-selector',
  FinanceSavedViewDimensionSelector = 'finance-saved-view-dimension-selector',
  FinanceSavedViewChartKindSelector = 'finance-saved-view-chart-kind-selector',
  FinanceSavedViewAddChartButton = 'finance-saved-view-add-chart-button',
  SavedViewsOnboardingDescription = 'saved-views-onboarding-description',

  // Surveys
  StartSurveyButton = 'start-survey-button',
  SurveyStepContainer = 'survey-step-container',

  // Reductions
  PublishedPageSection = 'published-page-section',
  PlanTabSummary = 'plan-tab-summary',
  EmissionTargetChartWrapper = 'emission-target-chart-wrapper',
  SupplierEngagementTargetChart = 'supplier-engagement-target-chart',
  RenewableElectricityProcurementChart = 'renewable-electricity-procurement-chart',
  ShowInitiativesButton = 'show-initiatives-button',
  CommonInitiativesTable = 'common-initiatives-table',
  CarbonRemovalButton = 'carbon-removal-button',
  CarbonFundingTable = 'carbon-funding-table',
  PlanTabDropdownMenu = 'plan-tab-dropdown-menu',
  AddPlanButton = 'add-plan-button',
  DeletePlanButton = 'delete-plan-button',
  AddTargetButton = 'add-target-button',
  TargetGhgScopeCategorySelector = 'target-ghg-scope-category-selector',
  TargetIntensityType = 'target-intensity-type-selector',
  PlansOverviewButton = 'plans-overview-button',
  PlansOverviewSinglePlan = 'plans-overview-single-plan',

  // Org Structure
  OrgStructureBlankSlate = 'org-structure-blank-slate',
  OrgStructureCreateNewVersionButton = 'org-structure-create-new-version-button',
  OrgStructureCreateNewVersionNameField = 'org-structure-create-new-version-name-field',
  OrgStructureAddDataDropdown = 'org-structure-add-data-dropdown',
  OrgStructureAddOrgUnitTypeDropdownItem = 'org-structure-add-org-unit-type-dropdown-item',
  OrgStructureCreateNewOrgUnitTypeNameField = 'org-structure-create-new-org-unit-type-name-field',
  OrgStructureDeleteOrgUnitTypeButton = 'org-structure-delete-org-unit-type-button',
  OrgStructureCreateNewOrgUnitButton = 'org-structure-create-new-org-unit-button',
  OrgStructureCreateNewOrgUnitNameField = 'org-structure-create-new-org-unit-name-field',
  OrgStructureCreateNewOrgUnitDescriptionField = 'org-structure-create-new-org-unit-description-field',
  OrgStructureDeleteOrgUnitButton = 'org-structure-delete-org-unit-button',
  OrgStructureCreateNewOrgUnitTypeRelationshipButton = 'org-structure-create-new-org-unit-type-relationship-button',
  OrgStructureOrgUnitTypeRelationshipSelector = 'org-structure-org-unit-type-relationship-selector',
  OrgStructureOrgUnitRelationshipSelector = 'org-structure-org-unit-relationship-selector',
  OrgStructurePublishButton = 'org-structure-publish-button',
  OrgStructureDeleteButton = 'org-structure-delete-button',
  OrgStructureUnpublishButton = 'org-structure-unpublish-button',
  OrgStructureUnpublishAreYouSureSwitch = 'org-structure-unpublish-are-you-sure-switch',
  OrgStructureImportButton = 'org-structure-import-button',
  OrgStructureIsLegalEntity = 'org-structure-is-legal-entity',

  // Support
  SupportAddNewCaseButton = 'support-add-new-case-button',
  SupportCaseSubjectInput = 'support-case-subject-input',
  SupportCaseDescriptionInput = 'support-case-description-input',
  SupportCaseSubmitButton = 'support-case-submit-button',
  SupportCaseProductAreaSelect = 'support-case-product-area-select',
  SupportCasePrioritySelect = 'support-case-priority-select',
  SupportCaseCommentInput = 'support-case-comment-input',
  SupportCaseCommentSubmitButton = 'support-case-comment-submit-button',
  SupportCaseSubscribeButton = 'support-case-subscribe-button',
  SupportCaseCloseButton = 'support-case-close-button',
  SupportCaseSidebar = 'support-case-sidebar',
  SupportCaseTimeline = 'support-case-timeline',
  SupportCaseTimelineItem = 'support-case-timeline-item',
  SupportAttachmentButton = 'support-attachment-button',
  SupportAttachmentSubmitButton = 'support-attachment-submit-button',
  SupportCaseAttachmentDownloadButton = 'support-case-attachment-download-button',
  NavHelpButton = 'nav-help-button',
  SupportNewCaseMenuItem = 'support-new-case-menu-item',
  SupportCenterMenuItem = 'support-center-menu-item',
  SupportCaseList = 'support-case-list',

  // Auth
  LoginWithPasswordButton = 'login-with-password-button',
  LoginWithSsoButton = 'login-with-sso-button',
  SubmitEmailButton = 'submit-email-button',
  LoginButton = 'login-button',
  PasswordInput = 'password-input',
  UserBarButton = 'user-bar-button',
  OrganizationSettingsItem = 'organization-settings-item',
  OrganizationStructureItem = 'organization-structure-item',

  // Measurement
  MeasurementProjectFilterSection = 'active-measurement-filter-section',
  ActiveMeasurementExpandButton = 'active-measurement-expand-button',
  DatasourceUploadButton = 'datasource-upload-button',
  ConfirmFinishedUploadingButton = 'confirm-finished-uploading-button',
  DataFormatSelect = 'data-format-select',
  DataFormattingNotes = 'data-formatting-notes',
  FacilitiesAddDataButton = 'facilities-add-data-button',
  FacilitiesAddDataButtonBuildings = 'facilities-add-data-button-buildings',
  FacilitiesAddDataButtonUtilities = 'facilities-add-data-button-utilities',
  StandardUploadDataButton = 'standard-upload-data-button',
  UploadDropdownButton = 'upload-dropdown-button',
  UploadOnboardingDialogConfirmation = 'upload-onboarding-dialog-confirmation',
  FacilitiesConfirmChangesButton = 'facilities-confirm-changes-button',
  ImportFlowSampleDataSchemaSelect = 'select-field-import-flow-sample-data',
  CustomEmissionsFactorsGrid = 'custom-emissions-factors-grid',
  MeasurementBulkTaskCreationDropdown = 'measurement-bulk-task-creation-dropdown',
  MeasurementBulkTaskCreationButton = 'measurement-bulk-task-creation-button',
  MeasurementExportTaskListButton = 'measurement-export-task-list-button',
  BulkUploadTaskImporter = 'bulk-upload-task-importer',

  OpenNewDataIssueDialog = 'open-new-data-issue-dialog',
  NewDataIssueTitleField = 'new-data-issue-title-field',
  NewDataIssueMessageField = 'new-data-issue-message-field',
  DataIssueConversations = 'data-issue-conversations',
  DataIssueDialogTitleHeader = 'data-issue-dialog-title-header',
  DataIssueDialogMessage = 'data-issue-dialog-message',
  DataIssueResponseField = 'data-issue-response-field',
  ResolveDataIssueButton = 'resolve-data-issue-button',
  OpenDataIssuePanel = 'open-data-issue-panel',
  TaskStatusLabel = 'task-status-label',
  FileStatusText = 'file-status-text',

  BiActivityDataEmbeddedChart = 'bi-activity-data-embedded-chart',
  BiActivityDataEmbeddedMetricSelector = 'bi-activity-data-embedded-metric-selector',
  BiActivityDataExploreButton = 'bi-activity-data-explore-button',

  // Footprint
  BenchmarkGroupsPageSection = 'benchmark-groups-page-section',
  BenchmarksPageSection = 'benchmarks-page-section',
  NewVersionNoticeUpdateDescription = 'new-version-notice-update-description',
  NewVersionNoticeChip = 'new-version-notice-chip',
  NewVersionNoticeDialogButton = 'new-version-notice-dialog-button',
  NewVersionUpdatesInfoText = 'new-version-updates-info-text',
  NewVersionReviewUpdatesButton = 'new-version-review-updates-button',

  // Overview
  EmissionsOverTimeSection = 'emissions-over-time-section',
  VendorsSection = 'vendors-section',
  // BI
  BiTable = 'bi-table',
  BiTableToolbar = 'bi-table-toolbar',
  BiChartContainer = 'bi-chart-container',
  BiActiveFiltersSection = 'bi-active-filters-section',
  BiActiveFilterForm = 'bi-filter-form',
  BiNoData = 'bi-no-data',
  // Drilldown
  DrilldownBarChart = 'drilldown-bar-chart',
  DrilldownPieChart = 'drilldown-pie-chart',
  DrilldownDatagridQuerySection = 'drilldown-datagrid-query-section',
  DrilldownDatagridQueryPanelAddFilterButton = 'drilldown-datagrid-query-panel-add-filter-button',
  DrilldownTableContent = 'drilldown-table-content',
  DrilldownGroupByAutocomplete = 'drilldown-group-by-autocomplete',
  MethodologyExplainerDialog = 'methodology-explainer-dialog',
  CalculationCitation = 'calculation-citation',
  ShowYourWorkButton = 'show-your-work-button',
  ShowYourWorkTable = 'show-your-work-table',
  DrilldownOverflowMenu = 'drilldown-overflow-menu',
  DrilldownConfigButton = 'drilldown-view-config-menu',
  // Activity data
  ActivityTypeList = 'activity-type-list',
  ActivityTypeRow = 'activity-type-row',
  ActivityTypeDataTab = 'activity-type-data-tab',
  ActivityTypeUploadsTab = 'activity-type-uploads-tab',
  ActivityTypeIssuesTab = 'activity-type-issues-tab',

  // Footprint configuration
  FootprintConfigSection = 'footprint-config-section',
  FootprintVersionNote = 'footprint-version-note',
  FootprintConfigDrawerClose = 'footprint-config-drawer-close',
  FootprintConfigDeduplicationButton = 'footprint-config-deduplication-button',
  DeduplicationPage = 'deduplication-page',
  CreateFootprintButton = 'create-footprint-button',
  CreateNewFootprintButton = 'create-new-footprint-button',
  FootprintListPageSidebarLearningHubLink = 'footprint-list-page-sidebar-learning-hub-link',
  FootprintListPageSidebarMethodologyUpdatesLink = 'footprint-list-page-sidebar-methodology-updates-link',

  // Footprint selectors
  FootprintSelector = 'footprint-selector',

  // Custom activity + EFs
  ConfirmReplaceEmissionsFactors = 'confirm-replace-emissions-factors',

  // Reporting
  BenchmarksCard = 'benchmarks-card',
  ScienceInfoCard = 'science-info-card',
  TcfdOnboardingDialog = 'tcfd-onboarding-dialog',
  CreateReportButton = 'create-report-button',
  GoToReportButton = 'go-to-report-button',
  AddOpportunityButton = 'add-opportunity-button',
  ReportQuestionDataGrid = 'report-question-data-grid',

  // Viz
  CategoricalBarChart = 'categorical-bar-chart',

  // General
  FileUploadInput = 'file-upload-input',
  CloseDialogButton = 'close-dialog-button',
  DialogFormSubmitButton = 'dialog-form-submit-button',
  DialogConfirmSubmitButton = 'dialog-confirm-submit-button',
  CollapsibleSectionButton = 'collapsible-section-button',
  DropdownMenuButton = 'dropdown-menu-button',
  Sidebar = 'sidebar',
  SidebarUserBar = 'sidebar-user-bar',
  LogoutButton = 'logout-button',
  Breadcrumb = 'breadcrumb',
  FilterRowFieldAutocomplete = ' filter-row-field-autocomplete',
  FilterRowValueAutocomplete = ' filter-row-value-autocomplete',
  FilterRowValueChip = ' filter-row-value-chip',
  FilterRowDeleteButton = 'filter-row-delete-button',

  // Home
  HomeWelcomeCard = 'home-welcome-card',

  // ui-core
  CircularProgressDeterminate = 'circular-progress-determinate',
  FileUploadMultipleImport = 'file-upload-multiple-import',
  FileUploadMultipleCancel = 'file-upload-multiple-cancel',
  FileUploadMultipleDelete = 'file-upload-multiple-delete',
  FileUploadMultipleDownload = 'file-upload-multiple-download',
  MarkdownTextFieldWithPreviewDisplay = 'markdown-text-field-with-preview-display',
  MarkdownTextFieldWithPreviewTextArea = 'markdown-text-field-with-preview-textarea',
  MarkdownTextFieldWithPreviewToggle = 'markdown-text-field-with-preview-toggle',
  SheetNavButtonFinish = 'sheet-nav-button-finish',
  SheetButtonClose = 'sheet-button-close',
  SheetButtonHelp = 'sheet-button-help',
  TextFieldInlineEdit = 'text-field-inline-edit',
  TextFieldInlineEditButton = 'text-field-inline-edit-button',
  ItemSelectionCard = 'item-selection-card',
  YMIntervalPopoverButton = 'ym-interval-popover-button',
  YMIntervalPopover = 'ym-interval-popover',
  OverflowActionButton = 'overflow-action-button',
  Checkbox = 'checkbox',

  // Error states
  ErrorStateFullPage = 'error:page',
  ErrorStateSnackbar = 'error:snackbar',
  ErrorStateCallout = 'error:callout',

  // loading states
  LoadingStateSkeleton = 'loading:skeleton',
  LoadingStateCircularProgress = 'loading:circular-progress',
  LoadingStateBigLoading = 'loading:big-loading',
  LoadingStateDataGrid = 'loading:data-grid',
}
export enum TestIdPrefix {
  autocompleteField = 'autocompleteField',
  ZodField = 'ZodField',
  ZodFieldAutocompletePopper = 'ZodFieldAutocompletePopper',
  ZodFormSubmit = 'ZodForm-submit',
  BiActivityDataEmbeddedMetricOption = 'bi-activity-data-embedded-metric-option',
}

export function getPrefixedTestId(prefix: TestIdPrefix, id: string): string {
  return `${prefix}-${id}`;
}

interface MockReplaceProps {
  name: string;
  excludedKeys?: Array<string>;
}

function replaceMockWithActual({
  name,
  jest: actualJest,
  excludedKeys = [],
}: MockReplaceProps & {
  jest: Pick<typeof jest, 'requireMock' | 'requireActual'>;
}): any {
  const mock = actualJest.requireMock(name) as any;
  const required = actualJest.requireActual(name);
  const allKeys = Object.keys(required);
  const keysNotInModule = difference(excludedKeys, allKeys);
  invariant(
    keysNotInModule.length === 0,
    `Tried to exclude unknown export from module ${name} - ${keysNotInModule}`
  );
  Object.assign(mock, omit(required, excludedKeys));
  return mock;
}

/**
   Replaces an array of jest mocks with the actual implementation. You can specify keys to leave mocked via excludedKeys.
   You must supply the global jest object from the test file in which the mocking was done.
   @example
   ```ts
      jest.mock('../FilesService');
      jest.mock('@watershed/service-essentials/utils/gcp');
      jest.mock('@watershed/service-essentials/utils/GcsClient', () => ({
        GcsClient: {
          create: jest.fn().mockReturnValue({
            copyFile: jest.fn(),
          }),
        },
      }));
      replaceMocksWithActual(
        jest,
        {
          name: '../FilesService',
          excludedKeys: ['uploadToGcsWithFileMetadata'],
        },
        '@watershed/service-essentials/utils/gcp',
        {
          name: '@watershed/service-essentials/utils/GcsClient',
          excludedKeys: ['GcsClient'],
        }
      );
   ```
 */

export function replaceMocksWithActual(
  actualJest: Pick<typeof jest, 'requireMock' | 'requireActual'>,
  ...mocks: Array<string | MockReplaceProps>
): void {
  mocks.map((name) => {
    const mockReplaceProps = typeof name === 'string' ? { name } : name;
    replaceMockWithActual({ ...mockReplaceProps, jest: actualJest });
  });
}

/**
 * Shallow expect all fields in an object to be equal,
 * omitting some fields.
 */
export function expectObjectEqualityOmitFields(
  testObject: Record<string, any>,
  expectObject: Record<string, any>,
  omitFields: Array<string>
): void {
  expect(omit(testObject, omitFields)).toMatchObject(
    omit(expectObject, omitFields)
  );
}

export function expectToBeDefined<T>(value: T | undefined): asserts value is T {
  expect(value).toBeDefined();
}

export function sortById<T extends { id: string }>(arr: Array<T>): Array<T> {
  return sortBy(arr, (element) => element.id);
}
