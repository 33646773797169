import { ReactNode, CSSProperties } from 'react';
import { Box } from '@mui/material';

export interface PageSplitProps {
  columns?: string;
  left: ReactNode | null;
  leftLabel?: LocalizedString;
  leftPadding?: boolean;
  right: ReactNode | null;
  rightLabel?: LocalizedString;
  rightPadding?: boolean;
  style?: CSSProperties;
}

function PageSplit({
  columns = 'repeat(2, 1fr)',
  left,
  leftPadding = true,
  leftLabel,
  right,
  rightPadding = true,
  rightLabel,
  style,
}: PageSplitProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: columns ?? 'repeat(2, 1fr)',
        gridGap: 1,
        backgroundColor: (theme) => theme.palette.divider,
        height: '100%',
      }}
      style={style}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          width: '100%',
          height: '100%',
          overflowX: 'auto',
        }}
        display="flex"
        flexDirection="column"
        padding={leftPadding ? 2 : undefined}
        role="region"
        aria-label={leftLabel}
        children={left}
      />
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          width: '100%',
          height: '100%',
          overflowX: 'auto',
        }}
        display="flex"
        flexDirection="column"
        padding={rightPadding ? 2 : undefined}
        role="region"
        aria-label={rightLabel}
        children={right}
      />
    </Box>
  );
}

export default PageSplit;
