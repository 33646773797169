import type { CaptureContext } from '@sentry/types';

/**
 * Thin wrapper around {@link Sentry.captureException} that determines node or browser environment based on window.
 *
 * Note that our Sentry clients are configured to filter certain error codes to only be sent to Sentry from certain
 * environments.   See {@link ErrorRegistry} for the list of error codes and their corresponding environments.
 *
 * @see {@link ErrorRegistry.shouldSendErrorToSentry}
 */
export function captureException(
  exception: unknown,
  captureContext?: CaptureContext
): string {
  const Sentry =
    // eslint-disable-next-line no-restricted-globals -- we are explicitly using window to determine environment here
    typeof window !== 'undefined'
      ? require('@sentry/browser')
      : require('@sentry/node');
  return Sentry.captureException(exception, captureContext);
}
