import {
  EMPLOYEE_GROUP_TYPE,
  EMPLOYMENT_TYPE,
  GENDER,
  LOBBYING_CONTRIBUTION_KIND,
  LOBBYING_EXPENSE_KIND,
  NON_EMPLOYEE_TYPE,
  OWN_WORKFORCE_TYPE,
  WASTE_TREATMENTS,
} from '../batSchemas/constants';
import {
  HEALTH_AND_SAFETY_INCIDENT_TYPES,
  HEALTH_AND_SAFETY_INCIDENTS_EMPLOYEE_TYPES,
} from '../csrd/constants';
import {
  getLocalizedCountryName,
  WS_ISO3166_COUNTRIES,
} from '../geo/countries';
import { SupportedLocale } from '@watershed/intl/constants';
import { i18n } from '@watershed/intl';
import { Scope1Or2GhgCategoryIds } from '../constants';
import { getObjectKeys } from '../getObjectKeys';

export const SUBSTANCE_OF_CONCERN = [
  'substance_of_concern',
  'substance_of_very_high_concern',
  'group_might_contain_substances_of_concern',
  'group_might_contain_substances_of_very_high_concern',
  'not_a_substance_of_concern',
  'unknown',
] as const;

// Reference doc: https://storage.cloud.google.com/watershed-global/cliq/csrd/substances_of_concern/ghs_hazard_statement_code_ec_hazard_class_mapping.xlsx
// We only output a subset of these today.
export const SUBSTANCE_OF_CONCERN_HAZARD_CLASS = [
  'acute_toxicity',
  'aerosol',
  'aspiration_hazard',
  'carcinogenicity',
  'chemical_under_pressure',
  'desensitised_explosives',
  'endocrine_disruptor_for_human_health',
  'endocrine_disruptor_for_the_environment',
  'explosive',
  'flammable_gases',
  'flammable_liquid',
  'flammable_solid',
  'germ_cell_mutagenicity',
  'hazardous_for_the_ozone_layer',
  'hazardous_to_the_aquatic_environment',
  'no_information_found',
  'oxidising_gas',
  'oxidising_liquid',
  'oxidising_solid',
  'persistent_bioaccumulative_and_toxic_very_persistent_and_very_bioaccumulative',
  'persistent_mobile_and_toxic_very_persistent_and_very_mobile',
  'pyrophoric_liquid',
  'pyrophoric_solid',
  'reproductive_toxicity',
  'respiratory_skin_sensitization',
  'self_heating_substance_or_mixture',
  'self_reactive_substance_or_mixture',
  'serious_eye_damage_eye_irritation',
  'skin_corrosion_irritation',
  'specific_target_organ_toxicity_repeated_exposure',
  'specific_target_organ_toxicity_single_exposure',
  'substance_or_mixture_corrosive_to_metals',
  'substance_or_mixture_which_in_contact_with_water_emits_flammable_gas',
] as const;

// Deprecated. We are not removing because this is referenced in BARTs and we cannot delete BARTs. We
// could probably change the BART field to be a string.
// TODO(CSRD): Remove column from BART
export const DEPRECATED__POLLUTANT_MEDIUM = [
  'air_emission',
  'water_emission',
  'soil_emission',
  'used',
  'procured',
  'leaves_in_product',
] as const;

export const SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM = [
  'procured',
  'generated',
] as const;

export const SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM_LABELS: Record<
  (typeof SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM)[number],
  string
> = {
  procured: 'Procured',
  generated: 'Generated',
};

const SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM = [
  'leaves_as_product',
  'leaves_in_product',
] as const;

const SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM_LABELS: Record<
  (typeof SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM)[number],
  string
> = {
  leaves_as_product: 'Leaves as product',
  leaves_in_product: 'Leaves in product',
};

export const SUBSTANCE_OUTFLOW_EMISSIVE_MEDIUM = [
  'air_emission',
  'water_emission',
  'soil_emission',
] as const;

export const SUBSTANCE_OUTFLOW_MEDIUM = [
  'leaves_as_services',
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM,
  ...SUBSTANCE_OUTFLOW_EMISSIVE_MEDIUM,
] as const;

export const SUBSTANCE_OUTFLOW_MEDIUM_LABELS: Record<
  (typeof SUBSTANCE_OUTFLOW_MEDIUM)[number],
  string
> = {
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM_LABELS,
  leaves_as_services: 'Leaves as services',
  air_emission: 'Air emission',
  water_emission: 'Water emission',
  soil_emission: 'Soil emission',
};

export const MICROPLASTICS_OUTFLOW_MEDIUM = [
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM,
  'emission',
] as const;

export const MICROPLASTICS_OUTFLOW_MEDIUM_LABELS: Record<
  (typeof MICROPLASTICS_OUTFLOW_MEDIUM)[number],
  string
> = {
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM_LABELS,
  emission: 'Emission',
};

export type Pollutant = {
  displayName: string;
  euPrtrThresholdAir?: number; // in kg/year
  euPrtrThresholdWater?: number; // in kg/year
  euPrtrThresholdSoil?: number; // in kg/year
};

// EU PRTR: https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32006R0166
export const EU_PRTR_CAS_NUMBER_POLLUTANTS = {
  '74-82-8': { displayName: 'Methane (74-82-8)', euPrtrThresholdAir: 100000 },
  '630-08-0': {
    displayName: 'Carbon monoxide (630-08-0)',
    euPrtrThresholdAir: 500000,
  },
  '124-38-9': {
    displayName: 'Carbon dioxide (124-38-9)',
    euPrtrThresholdAir: 100000000,
  },
  '10024-97-2': {
    displayName: 'Nitrous oxide (10024-97-2)',
    euPrtrThresholdAir: 10000,
  },
  '7664-41-7': {
    displayName: 'Ammonia (7664-41-7)',
    euPrtrThresholdAir: 10000,
  },
  '2551-62-4': {
    displayName: 'Sulphur hexafluoride (2551-62-4)',
    euPrtrThresholdAir: 50,
  },
  '15972-60-8': {
    displayName: 'Alachlor (15972-60-8)',
    euPrtrThresholdWater: 1,
  },
  '309-00-2': {
    displayName: 'Aldrin (309-00-2)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '1912-24-9': { displayName: 'Atrazine (1912-24-9)', euPrtrThresholdWater: 1 },
  '57-74-9': {
    displayName: 'Chlordane (57-74-9)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '143-50-0': {
    displayName: 'Chlordecone (143-50-0)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '470-90-6': {
    displayName: 'Chlorfenvinphos (470-90-6)',
    euPrtrThresholdWater: 1,
  },
  '85535-84-8': {
    displayName: 'Chloro-alkanes, C10-C13 (85535-84-8)',
    euPrtrThresholdWater: 1,
  },
  '2921-88-2': {
    displayName: 'Chlorpyrifos (2921-88-2)',
    euPrtrThresholdWater: 1,
  },
  '50-29-3': {
    displayName: 'DDT (50-29-3)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '107-06-2': {
    displayName: '1,2-dichloroethane (107-06-2)',
    euPrtrThresholdAir: 1000,
    euPrtrThresholdWater: 10,
    euPrtrThresholdSoil: 10,
  },
  '75-09-2': {
    displayName: 'Dichloromethane (75-09-2)',
    euPrtrThresholdAir: 1000,
    euPrtrThresholdWater: 10,
    euPrtrThresholdSoil: 10,
  },
  '60-57-1': {
    displayName: 'Dieldrin (60-57-1)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '330-54-1': { displayName: 'Diuron (330-54-1)', euPrtrThresholdWater: 1 },
  '115-29-7': {
    displayName: 'Endosulphan (115-29-7)',
    euPrtrThresholdWater: 1,
  },
  '72-20-8': {
    displayName: 'Endrin (72-20-8)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '76-44-8': {
    displayName: 'Heptachlor (76-44-8)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '118-74-1': {
    displayName: 'Hexachlorobenzene (118-74-1)',
    euPrtrThresholdAir: 10,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '87-68-3': {
    displayName: 'Hexachlorobutadiene (87-68-3)',
    euPrtrThresholdWater: 1,
  },
  '608-73-1': {
    displayName: '1,2,3,4,5,6-hexachlorocyclohexane (608-73-1)',
    euPrtrThresholdAir: 10,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '58-89-9': {
    displayName: 'Lindane (58-89-9)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '2385-85-5': {
    displayName: 'Mirex (2385-85-5)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '608-93-5': {
    displayName: 'Pentachlorobenzene (608-93-5)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '87-86-5': {
    displayName: 'Pentachlorophenol (87-86-5)',
    euPrtrThresholdAir: 10,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '1336-36-3': {
    displayName: 'Polychlorinated biphenyls (1336-36-3)',
    euPrtrThresholdAir: 0.1,
    euPrtrThresholdWater: 0.1,
    euPrtrThresholdSoil: 0.1,
  },
  '122-34-9': { displayName: 'Simazine (122-34-9)', euPrtrThresholdWater: 1 },
  '127-18-4': {
    displayName: 'Tetrachloroethylene (127-18-4)',
    euPrtrThresholdAir: 2000,
    euPrtrThresholdWater: 10,
  },
  '56-23-5': {
    displayName: 'Tetrachloromethane (56-23-5)',
    euPrtrThresholdAir: 100,
    euPrtrThresholdWater: 1,
  },
  '12002-48-1': {
    displayName: 'Trichlorobenzenes (12002-48-1)',
    euPrtrThresholdAir: 10,
    euPrtrThresholdWater: 1,
  },
  '71-55-6': {
    displayName: '1,1,1-trichloroethane (71-55-6)',
    euPrtrThresholdAir: 100,
  },
  '79-34-5': {
    displayName: '1,1,2,2-tetrachloroethane (79-34-5)',
    euPrtrThresholdAir: 50,
  },
  '79-01-6': {
    displayName: 'Trichloroethylene (79-01-6)',
    euPrtrThresholdAir: 2000,
    euPrtrThresholdWater: 10,
  },
  '67-66-3': {
    displayName: 'Trichloromethane (67-66-3)',
    euPrtrThresholdAir: 500,
    euPrtrThresholdWater: 10,
  },
  '8001-35-2': {
    displayName: 'Toxaphene (8001-35-2)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '75-01-4': {
    displayName: 'Vinyl chloride (75-01-4)',
    euPrtrThresholdAir: 1000,
    euPrtrThresholdWater: 10,
    euPrtrThresholdSoil: 10,
  },
  '120-12-7': {
    displayName: 'Anthracene (120-12-7)',
    euPrtrThresholdAir: 50,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '71-43-2': {
    displayName: 'Benzene (71-43-2)',
    euPrtrThresholdAir: 1000,
    euPrtrThresholdWater: 200,
    euPrtrThresholdSoil: 200,
  },
  '100-41-4': {
    displayName: 'Ethyl benzene (100-41-4)',
    euPrtrThresholdWater: 200,
    euPrtrThresholdSoil: 200,
  },
  '75-21-8': {
    displayName: 'Ethylene oxide (75-21-8)',
    euPrtrThresholdAir: 1000,
    euPrtrThresholdWater: 10,
    euPrtrThresholdSoil: 10,
  },
  '91-20-3': {
    displayName: 'Naphthalene (91-20-3)',
    euPrtrThresholdAir: 100,
    euPrtrThresholdWater: 10,
    euPrtrThresholdSoil: 10,
  },
  '117-81-7': {
    displayName: 'Di-(2-ethyl hexyl) phthalate (117-81-7)',
    euPrtrThresholdAir: 10,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '108-95-2': {
    displayName: 'Phenols (108-95-2)',
    euPrtrThresholdWater: 20,
    euPrtrThresholdSoil: 20,
  },
  '108-88-3': {
    displayName: 'Toluene (108-88-3)',
    euPrtrThresholdWater: 200,
    euPrtrThresholdSoil: 200,
  },
  '1582-09-8': {
    displayName: 'Trifluralin (1582-09-8)',
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  '1330-20-7': {
    displayName: 'Xylenes (1330-20-7)',
    euPrtrThresholdWater: 200,
    euPrtrThresholdSoil: 200,
  },
  '1332-21-4': {
    displayName: 'Asbestos (1332-21-4)',
    euPrtrThresholdAir: 1,
    euPrtrThresholdWater: 1,
  },
  '74-90-8': {
    displayName: 'Hydrogen cyanide (74-90-8)',
    euPrtrThresholdAir: 200,
  },
  '1806-26-4': {
    displayName: 'Octylphenols and Octylphenol ethoxylates (1806-26-4)',
    euPrtrThresholdWater: 1,
  },
  '206-44-0': {
    displayName: 'Fluoranthene (206-44-0)',
    euPrtrThresholdWater: 1,
  },
  '465-73-6': { displayName: 'Isodrin (465-73-6)', euPrtrThresholdWater: 1 },
  '36355-01-8': {
    displayName: 'Hexabromobiphenyl (36355-1-8)',
    euPrtrThresholdAir: 0.1,
    euPrtrThresholdWater: 0.1,
    euPrtrThresholdSoil: 0.1,
  },
  '191-24-2': {
    displayName: 'Benzo(g,h,i)perylene (191-24-2)',
    euPrtrThresholdWater: 1,
  },
  '34123-59-6': {
    displayName: 'Isoproturon (34123-59-6)',
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
} as const satisfies Record<string, Pollutant>;

export const EU_PRTR_CAS_NUMBER_POLLUTANT_NAMES = getObjectKeys(
  EU_PRTR_CAS_NUMBER_POLLUTANTS
);

export const OTHER_CAS_NUMBER_POLLUTANTS = {
  '75-69-4': { displayName: 'Trichlorofluoromethane (75-69-4)' },
  '75-71-8': { displayName: 'Dichlorodifluoromethane (75-71-8)' },
  '75-72-9': { displayName: 'Chlorotrifluoromethane (75-72-9)' },
  '76-13-1': {
    displayName: '1,1,2-trichloro-1,2,2-trifluoroethane (76-13-1)',
  },
  '76-14-2': {
    displayName: '1,2-dichloro-1,1,2,2-tetrafluoroethane (76-14-2)',
  },
  '76-15-3': {
    displayName: '1-chloro-1,1,2,2,2-pentafluoroethane (76-15-3)',
  },
  '1511-62-2': { displayName: 'Bromodifluoromethane (1511-62-2)' },
  '1842-05-3': { displayName: '1,1-dichloro-1,2-difluoroethane (1842-05-3)' },
  '354-15-4': {
    displayName: '1,1,2-trichloro-1,2-difluoroethane (354-15-4)',
  },
  '354-23-4': {
    displayName: '1,2-dichloro-1,1,2-trifluoroethane (354-23-4)',
  },
  '75-61-6': { displayName: 'Dibromodifluoromethane (75-61-6)' },
  '353-59-3': { displayName: 'Bromochlorodifluoromethane (353-59-3)' },
  '354-33-6': { displayName: '1,1,1,2,2-pentafluoroethane (354-33-6)' },
  '406-58-6': { displayName: '1,1,1,3,3-pentafluorobutane (406-58-6)' },
  '420-46-2': { displayName: '1,1,1-trifluoroethane (420-46-2)' },
  '431-89-0': { displayName: '1,1,1,2,3,3,3-heptafluoropropane (431-89-0)' },
  '679-86-7': { displayName: '1,1,2,2,3-pentafluoropropane (679-86-7)' },
  '690-39-1': { displayName: '1,1,1,3,3,3-hexafluoropropane (690-39-1)' },
  '75-10-5': { displayName: 'Difluoromethane (75-10-5)' },
  '75-37-6': { displayName: '1,1-difluoroethane (75-37-6)' },
  '75-46-7': { displayName: 'Trifluoromethane (75-46-7)' },
  '811-97-2': { displayName: '1,1,1,2-tetrafluoroethane (811-97-2)' },
  '1717-00-6': { displayName: '1,1-dichloro-1-fluoroethane (1717-00-6)' },
  '2837-89-0': {
    displayName: '2-chloro-1,1,1,2-tetrafluoroethane (2837-89-0)',
  },
  '306-83-2': {
    displayName: '2,2-dichloro-1,1,1-trifluoroethane (306-83-2)',
  },
  '354-21-2': {
    displayName: '1,2,2-trichloro-1,1-difluoroethane (354-21-2)',
  },
  '359-28-4': { displayName: '1,1,2-Trichloro-2-fluoroethane (359-28-4)' },
  '422-56-0': {
    displayName: '3,3-dichloro-1,1,1,2,2-pentafluoropropane (422-56-0)',
  },
  '507-55-1': {
    displayName: '1,3-dichloro-1,1,2,2,3-pentafluoropropane (507-55-1)',
  },
  '75-43-4': { displayName: 'Dichlorofluoromethane (75-43-4)' },
  '75-45-6': { displayName: 'Chlorodifluoromethane (75-45-6)' },
  '75-68-3': { displayName: '1-chloro-1,1-difluoroethane (75-68-3)' },
  '102687-65-0': {
    displayName: '(e)-1-chloro-3,3,3-trifluoroprop-1-ene (102687-65-0)',
  },
  '106-97-8': { displayName: 'Butane (106-97-8)' },
  '109-66-0': { displayName: 'Pentane (109-66-0)' },
  '111512-60-8': {
    displayName: '(Z)-1-Chloro-2,3,3,3-tetrafluoropropene (111512-60-8)',
  },
  '115-07-1': { displayName: 'Propene (115-07-1)' },
  '1814-88-6': { displayName: '1,1,1,2,2-pentafluoropropane (1814-88-6)' },
  '2252-84-8': {
    displayName: '1,1,1,2,2,3,3-heptafluoropropane (2252-84-8)',
  },
  '24270-66-4': { displayName: '1,1,2,3,3-pentafluoropropane (24270-66-4)' },
  '29118-24-9': {
    displayName: '(e)-1,3,3,3-tetrafluoroprop-1-ene (29118-24-9)',
  },
  '29118-25-0': {
    displayName: '(z)-1,3,3,3-tetrafluoroprop-1-ene (29118-25-0)',
  },
  '353-36-6': { displayName: 'Fluoroethane (353-36-6)' },
  '360-89-4': { displayName: 'Octafluoro-2-buene (360-89-4)' },
  '375-17-7': {
    displayName: '1,1,1,2,2,3,3,4,4-nonafluorobutane (375-17-7)',
  },
  '420-45-1': { displayName: '2,2-difluoropropane (420-45-1)' },
  '421-07-8': { displayName: '1,1,1-trifluoropropane (421-07-8)' },
  '431-31-2': { displayName: '1,1,1,2,3-pentafluoropropane (431-31-2)' },
  '431-63-0': { displayName: '1,1,1,2,3,3-hexafluoropropane (431-63-0)' },
  '460-73-1': { displayName: '1,1,1,3,3-pentafluoropropane (460-73-1)' },
  '5528-43-8': {
    displayName: '(z)-1,2,3,3,3-pentafluoroprop-1-ene (5528-43-8)',
  },
  '5595-10-8': { displayName: '1,2,3,3,3-Pentafluoroprop-1-ene (5595-10-8)' },
  '677-21-4': { displayName: '3,3,3-trifluoroprop-1-ene (677-21-4)' },
  '677-56-5': { displayName: '1,1,1,2,2,3-hexafluoropropane (677-56-5)' },
  '692-49-9': {
    displayName: '(z)-1,1,1,4,4,4-hexafluorobut-2-ene (692-49-9)',
  },
  '74-84-0': { displayName: 'Ethane (74-84-0)' },
  '74-98-6': { displayName: 'Propane (74-98-6)' },
  '75-28-5': { displayName: '2-Methylpropane (75-28-5)' },
  '75-38-7': { displayName: '1,1-difluoroethene (75-38-7)' },
  '754-12-1': { displayName: '2,3,3,3-tetrafluoroprop-1-ene (754-12-1)' },
  '78-78-4': { displayName: '2-Methylbutane (78-78-4)' },
  '115-25-3': { displayName: 'Octafluorocyclobutane (115-25-3)' },
  '355-25-9': { displayName: 'Decafluorobutane (355-25-9)' },
  '75-73-0': { displayName: 'Tetrafluoromethane (75-73-0)' },
  '76-16-4': { displayName: 'Hexafluoroethane (76-16-4)' },
  '76-19-7': { displayName: 'Octafluoropropane (76-19-7)' },
  '7439-92-1': { displayName: 'Lead (7439-92-1)' },
} as const satisfies Record<string, Pollutant>;

export const CAS_NUMBER_LABELS: Record<string, string> = {
  ...Object.fromEntries(
    Object.entries(EU_PRTR_CAS_NUMBER_POLLUTANTS).map(([key, pollutant]) => [
      key,
      pollutant.displayName,
    ])
  ),
  ...Object.fromEntries(
    Object.entries(OTHER_CAS_NUMBER_POLLUTANTS).map(([key, pollutant]) => [
      key,
      pollutant.displayName,
    ])
  ),
};

/**
 * EU_PRTR_POLLUTANT_GROUPS represents a collection of pollutant groups defined by the EU PRTR (European Pollutant Release and Transfer Register).
 * Each pollutant group is represented by a key-value pair, where the key is the name of the pollutant group and the value is an object of type `Pollutant`,
 * containing additional information about the pollutant group.
 *
 * EU PRTR: https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32006R0166
 * Reference doc: https://docs.google.com/spreadsheets/d/1wC8L7Oao2ybmHl-AurcQ0g0o7QbOnnxd65Okxag0k8Q/edit#gid=1451937436
 */
export const EU_PRTR_POLLUTANT_GROUPS = {
  non_methane_volatile_organic_compounds: {
    displayName: 'Non methane volatile organic compounds',
    euPrtrThresholdAir: 100000,
  },
  perfluorocarbons: {
    displayName: 'Perfluorocarbons',
    euPrtrThresholdAir: 100,
  },
  particulate_matter: { displayName: 'Particulate matter' },
  particulate_matter_10: {
    displayName: 'Particulate matter (PM10)',
    euPrtrThresholdAir: 50000,
  },
  particulate_matter_2_5: {
    displayName: 'Particulate matter (PM2.5)',
    euPrtrThresholdAir: 50000,
  },
  halons: { displayName: 'Halons', euPrtrThresholdAir: 1 },
  polycyclic_aromatic_hydrocarbons: {
    displayName: 'Polycyclic aromatic hydrocarbons',
    euPrtrThresholdAir: 50,
    euPrtrThresholdWater: 5,
    euPrtrThresholdSoil: 5,
  },
  sulfur_oxides: { displayName: 'Sulfur oxides' },
  hydrochlorofluorocarbons: {
    displayName: 'Hydrochlorofluorocarbons',
    euPrtrThresholdAir: 1,
  },
  hydrofluorocarbons: { displayName: 'Hydrofluorocarbons' },
  chlorofluorocarbons: {
    displayName: 'Chlorofluorocarbons',
    euPrtrThresholdAir: 1,
  },
  chlorides: {
    displayName: 'Chlorides',
    euPrtrThresholdWater: 2000000,
    euPrtrThresholdSoil: 2000000,
  },
  nitrogen_oxides: {
    displayName: 'Nitrogen oxides',
    euPrtrThresholdAir: 100000,
  },
  copper_and_compounds: {
    displayName: 'Copper and compounds',
    euPrtrThresholdAir: 100,
    euPrtrThresholdWater: 50,
    euPrtrThresholdSoil: 50,
  },
  lead_and_compounds: {
    displayName: 'Lead and compounds',
    euPrtrThresholdAir: 200,
    euPrtrThresholdWater: 20,
    euPrtrThresholdSoil: 20,
  },
  chromium_and_compounds: {
    displayName: 'Chromium and compounds',
    euPrtrThresholdAir: 100,
    euPrtrThresholdWater: 50,
    euPrtrThresholdSoil: 50,
  },
  nickel_and_compounds: {
    displayName: 'Nickel and compounds',
    euPrtrThresholdAir: 50,
    euPrtrThresholdWater: 20,
    euPrtrThresholdSoil: 20,
  },
  cadmium_and_compounds: {
    displayName: 'Cadmium and compounds',
    euPrtrThresholdAir: 10,
    euPrtrThresholdWater: 5,
    euPrtrThresholdSoil: 5,
  },
  arsenic_and_compounds: {
    displayName: 'Arsenic and compounds',
    euPrtrThresholdAir: 20,
    euPrtrThresholdWater: 5,
    euPrtrThresholdSoil: 5,
  },
  mercury_and_compounds: {
    displayName: 'Mercury and compounds',
    euPrtrThresholdAir: 10,
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  zinc_and_compounds: {
    displayName: 'Zinc and compounds',
    euPrtrThresholdAir: 200,
    euPrtrThresholdWater: 100,
    euPrtrThresholdSoil: 100,
  },
  halogenated_organic_compounds: {
    displayName: 'Halogenated organic compounds',
    euPrtrThresholdWater: 1000,
    euPrtrThresholdSoil: 1000,
  },
  brominated_diphenylethers: {
    displayName: 'Brominated diphenylethers',
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  nonylphenol_and_nonylphenol_ethoxylates: {
    displayName: 'Nonylphenol and Nonylphenol ethoxylates',
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  organotin_compounds: {
    displayName: 'Organotin compounds',
    euPrtrThresholdWater: 50,
    euPrtrThresholdSoil: 50,
  },
  polychlorinated_biphenyls: { displayName: 'Polychlorinated biphenyls' },
  total_nitrogen: {
    displayName: 'Total nitrogen',
    euPrtrThresholdWater: 50000,
    euPrtrThresholdSoil: 50000,
  },
  total_phosphorus: {
    displayName: 'Total phosphorus',
    euPrtrThresholdWater: 5000,
    euPrtrThresholdSoil: 5000,
  },
  tribytyltin_and_compounds: { displayName: 'Tributyltin and compounds' },
  triphenyltin_and_compounds: {
    displayName: 'Triphenyltin and compounds',
    euPrtrThresholdWater: 1,
    euPrtrThresholdSoil: 1,
  },
  total_organic_carbon: {
    displayName: 'Total organic carbon',
    euPrtrThresholdWater: 50000,
  },
  cyanides: {
    displayName: 'Cyanides',
    euPrtrThresholdWater: 50,
    euPrtrThresholdSoil: 50,
  },
  fluorides: {
    displayName: 'Fluorides',
    euPrtrThresholdWater: 2000,
    euPrtrThresholdSoil: 2000,
  },
  fluorine_and_inorganic_compounds: {
    displayName: 'Fluorine and inorganic compounds',
    euPrtrThresholdAir: 5000,
  },
  hydrogen_cyanide: { displayName: 'Hydrogen cyanide' },
  octylphenols_and_octylphenol_ethoxylates: {
    displayName: 'Octylphenols and Octylphenol ethoxylates',
  },
  chlorine_and_inorganic_compounds: {
    displayName: 'Chlorine and inorganic compounds',
    euPrtrThresholdAir: 10000,
  },
} as const satisfies Record<string, Pollutant>;

export const BTEX_CAS_NUMBERS = [
  '71-43-2', // Benzene
  '100-41-4', // Ethyl benzene
  '108-88-3', // Toluene
  '108-90-7', // Xylene
];

export const POLLUTANT_GROUP_LABELS: Record<string, string> =
  Object.fromEntries(
    Object.entries(EU_PRTR_POLLUTANT_GROUPS).map(([key, pollutant]) => [
      key,
      pollutant.displayName,
    ])
  );

export const EU_PRTR_POLLUTANT_GROUP_NAMES = getObjectKeys(
  EU_PRTR_POLLUTANT_GROUPS
);

export const SUBSTANCE_OF_CONCERN_LABELS: Record<
  (typeof SUBSTANCE_OF_CONCERN)[number],
  string
> = {
  substance_of_concern: 'Substance of concern',
  not_a_substance_of_concern: 'Not a substance of concern',
  group_might_contain_substances_of_concern:
    'Group might contain substances of concern',
  group_might_contain_substances_of_very_high_concern:
    'Group might contain substances of very high concern',
  substance_of_very_high_concern: 'Substance of very high concern',
  unknown: 'Unknown',
};

// Reference doc: https://docs.google.com/spreadsheets/d/1wC8L7Oao2ybmHl-AurcQ0g0o7QbOnnxd65Okxag0k8Q/edit#gid=1597682651
// We only output a subset of these in our EM today.
export const COMMON_SUBSTANCE_CAS_NUMBERS = [
  '630-08-0',
  '7664-41-7',
  '7439-92-1',
  '10024-97-2',
  '75-10-5',
  '354-33-6',
  '75-45-6',
  '67-66-3',
  '71-43-2',
] as const;

export const ENERGY_KIND_VALUES = ['cooling', 'electricity', 'heat', 'steam'];

export const HEATING_VALUE_KIND_VALUES = ['HHV', 'LHV'];

export const EMPLOYEE_GROUP_TYPE_LABELS: Record<
  (typeof EMPLOYEE_GROUP_TYPE)[number],
  string
> = {
  permanent: 'Permanent',
  temporary: 'Temporary',
  employee_with_non_guaranteed_hours: 'Non-guaranteed hours',
};

export const EMPLOYMENT_TYPE_LABELS: Record<
  (typeof EMPLOYMENT_TYPE)[number],
  string
> = {
  full_time: 'Full-time',
  part_time: 'Part-time',
};

export const GENDER_LABELS: Record<(typeof GENDER)[number], string> = {
  male: 'Male',
  female: 'Female',
  unknown: 'Unknown',
  other: 'Other',
};

export const OWN_WORKFORCE_TYPE_LABELS: Record<
  (typeof OWN_WORKFORCE_TYPE)[number],
  string
> = {
  employees: 'Employees',
  non_employees: 'Non-employees',
};

// the keys have prefixes to make this list sortable by age instead of alphabetically
export const AGE_GROUP_LABELS: Record<string[number], string> = {
  A_under_30: 'Under 30',
  B_30_50: '30-50',
  C_over_50: 'Over 50',
};

export const NON_EMPLOYEE_TYPE_LABELS: Record<
  (typeof NON_EMPLOYEE_TYPE)[number],
  string
> = {
  other: 'Other',
  people_provided_by_undertakings_primarily_engaged_in_employment_activities:
    'People provided by undertakings primarily engaged in employment activities',
  self_employed_people: 'Self-employed people',
};

export const LOBBYING_CONTRIBUTION_KIND_LABEL_MAPPING: Record<
  (typeof LOBBYING_CONTRIBUTION_KIND)[number],
  string
> = {
  in_kind: 'In-kind',
  financial: 'Financial',
};

export const LOBBYING_EXPENSE_KIND_LABEL_MAPPING: Record<
  (typeof LOBBYING_EXPENSE_KIND)[number],
  string
> = {
  direct_political_expense: 'Direct political expense',
  internal_or_external_lobbying_expense:
    'Internal or external lobbying expense',
  lobbying_membership_expense: 'Lobbying membership expense',
};

export const INCIDENT_TYPE_LABEL_MAPPING: Record<
  (typeof HEALTH_AND_SAFETY_INCIDENT_TYPES)[number],
  string
> = { ill_health: 'Ill health', injury: 'Injury' };

export const HEALTH_AND_SAFETY_INCIDENT_EMPLOYEE_TYPE_LABELS: Record<
  (typeof HEALTH_AND_SAFETY_INCIDENTS_EMPLOYEE_TYPES)[number],
  string
> = {
  employee: 'Employee',
  non_employee: 'Non-employee',
  value_chain_on_site: 'Value chain on site',
};

export const COUNTRY_LABEL_OBJECTS: Array<{
  value: string;
  label: string;
}> = WS_ISO3166_COUNTRIES.map((c) => ({
  value: c.alpha_2,
  label: getLocalizedCountryName(c.alpha_2, i18n.locale as SupportedLocale),
}));

export const WASTE_TREATMENT_LABELS: Record<
  (typeof WASTE_TREATMENTS)[number],
  string
> = {
  combusted: 'Combusted',
  landfilled: 'Landfilled',
  recycled: 'Recycled',
  prepared_for_reuse: 'Prepared for reuse',
  anaerobically_digested: 'Anaerobically digested',
  composted: 'Composted',
  unspecified: 'Unspecified',
};

export const GHG_REMOVAL_UNDERTAKING_SCOPE_LABELS: Record<
  Scope1Or2GhgCategoryIds,
  string
> = {
  '1 Scope 1': 'Within organization',
  '2 Scope 2': 'Value chain',
};
