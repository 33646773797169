import groupBy from 'lodash/groupBy';

import baseCountries from '../generated/shared_data/geo/iso3166-1.json';
import countryOverrides from '../generated/shared_data/geo/country_overrides.json';
import extraCountries from '../generated/shared_data/geo/extra_countries.json';
import countryAliases from '../generated/shared_data/geo/country_aliases.json';
import keyBy from 'lodash/keyBy';
import {
  SupportedLocale,
  PSEUDO_LOCALE,
  DEFAULT_LOCALE,
} from '@watershed/intl/constants';
import memoize from 'lodash/memoize';
import { pseudoLocalize } from '@watershed/intl/utils';

// Data format of the raw countries data from JSON
interface IRawCountryData {
  name: string;
  alpha_2: string; // ISO3166-1 alpha2
  alpha_3: string; // ISO3166-1 alpha3
  common_name?: string;
  official_name?: string;
}

export interface ICountryData extends IRawCountryData {
  aliases?: Array<string>;
}

function getAllCountries(): Array<ICountryData> {
  const aliasMap = groupBy(countryAliases, (ca) => ca.code);
  const results: Array<ICountryData> = [];

  for (const country of [...baseCountries, ...extraCountries]) {
    // If we override the country to a subdivision, ignore it.
    if (country.alpha_2 in countryOverrides) {
      continue;
    }

    results.push({
      ...country,
      aliases: aliasMap[country.alpha_2]?.map((a: { name: string }) => a.name),
    });
  }
  return results;
}

// Metadata about all the countries supported by Watershed.
export const WS_ISO3166_COUNTRIES: Array<ICountryData> = getAllCountries();
export function getCountryName(data: ICountryData): string {
  // Prefer the common name, but fallback to the official name. I'm not certain
  // Watershed has officially gaveled an approach, but this has been what we've
  // done for a long time so we're sticking with it until we decide to change.
  return data.common_name ?? data.name;
}
export const WS_ISO3166_COUNTRY_BY_NAME: Record<
  string,
  ICountryData | undefined
> = keyBy(WS_ISO3166_COUNTRIES, (c) => getCountryName(c));

export const WS_ISO3166_COUNTRY_BY_ALPHA_2: Record<string, ICountryData> =
  keyBy(WS_ISO3166_COUNTRIES, (c) => c['alpha_2']);

export const WS_ISO3166_COUNTRY_BY_ALPHA_3: Record<string, ICountryData> =
  keyBy(WS_ISO3166_COUNTRIES, (c) => c['alpha_3']);

export const isUnitedStates = (alpha2Country: string): boolean =>
  alpha2Country === 'US';

export const EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

// Is alpha2Country of one of Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden.
export const isEu = (alpha2Country: string): boolean => {
  return EU_COUNTRIES.includes(alpha2Country);
};

export const isEuOrUk = (alpha2Country: string): boolean => {
  return isEu(alpha2Country) || alpha2Country === 'GB';
};

/**
 * Returns the alpha-2 country code from a subdivision code.
 */
export function getCountryCodeFromSubdivisionCode(
  subdivisionCode: string
): string {
  return subdivisionCode.slice(0, 2);
}

export type WSPlace = {
  streetAddress: string;
  city: string;
  state?: string;
  country: string;
  countryAlpha2: string;
  postalCode?: string;
};

export function getStateFromPlace(place: WSPlace): string {
  // Google Places API returns Puerto Rico as a country, but we override it as a state
  return place.country === 'PR' ? 'Puerto Rico' : (place.state ?? '');
}

const getIntlDisplayNames = memoize(
  (locale: SupportedLocale) =>
    new Intl.DisplayNames([locale], { type: 'region' })
);

export const getLocalizedCountryName = memoize(
  (alpha2: string, locale: SupportedLocale) => {
    // Puerto Rico is the only country that is being overridden to be US
    const alpha2Override = alpha2 === 'PR' ? 'US' : alpha2;

    if (locale === PSEUDO_LOCALE) {
      return pseudoLocalize(
        getIntlDisplayNames(DEFAULT_LOCALE).of(alpha2Override) ?? ''
      );
    }

    return getIntlDisplayNames(locale).of(alpha2Override) ?? '';
  },
  (alpha2, locale) => `${alpha2}-${locale}`
);
