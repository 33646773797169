// eslint-disable-next-line no-restricted-imports
import {
  GridLoadIcon,
  GridLoadingOverlay,
  GridSkeletonCell,
  UncapitalizedGridPremiumSlotsComponent,
} from '@mui/x-data-grid-premium';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { ComponentProps } from 'react';

function WrappedLoadingOverlay(
  props: ComponentProps<typeof GridLoadingOverlay>
) {
  return (
    <GridLoadingOverlay {...props} data-test={TestIds.LoadingStateDataGrid} />
  );
}

function WrappedSkeletonCell(props: ComponentProps<typeof GridSkeletonCell>) {
  return (
    <GridSkeletonCell {...props} data-test={TestIds.LoadingStateDataGrid} />
  );
}

function WrappedGridLoadIcon(props: ComponentProps<typeof GridLoadIcon>) {
  return <GridLoadIcon {...props} data-test={TestIds.LoadingStateDataGrid} />;
}

/**
 * Wrap all loading slot components to allow us to attach test IDs.
 */
export const loadingSlots: Partial<UncapitalizedGridPremiumSlotsComponent> = {
  loadingOverlay: WrappedLoadingOverlay,
  skeletonCell: WrappedSkeletonCell,
  loadIcon: WrappedGridLoadIcon,
};
