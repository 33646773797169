type DevEnv = 'local-dev' | 'dev-proxy' | 'production-preview' | 'production';

const labels: Record<DevEnv, string> = {
  'local-dev': 'Local',
  'dev-proxy': 'Dev Proxy',
  'production-preview': 'Production - Preview',
  production: 'Production',
};

export const getCurrentDevEnv: () => DevEnv = () => {
  if (window.location.hostname.startsWith('dashboard-preview')) {
    return 'production-preview';
  }

  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
  ) {
    return 'production';
  }

  if (window.location.hostname.startsWith('localhost')) {
    return 'local-dev';
  }

  if (window.location.hostname.startsWith('proxy')) {
    return 'dev-proxy';
  }

  // If we can't positively affirm we're working locally,
  // fall back and assume we're in a production-like env.
  return 'production';
};

export const getCurrentDevEnvLabel = () => {
  return labels[getCurrentDevEnv()];
};
