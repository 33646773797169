import invariant from 'invariant';
import { ValueOf } from './utilTypes';

export function mapStrToEnum<
  T extends Record<string, string>,
  S extends string = string,
>(str: S, enumObj: T): ValueOf<T> {
  const found = mapStrToEnumOrNull(str, enumObj);
  invariant(found != null, `No enum value matched str=${str}`);
  return found;
}

export default mapStrToEnum;

export function mapStrToEnumOrNull<T extends Record<string, string>>(
  str: string,
  enumObj: T
): ValueOf<T> | null {
  const found = (Object.values(enumObj) as Array<ValueOf<T>>).find(
    (value) => value === str
  );
  return found ?? null;
}

export function mapStrToEnumOrUndefined<T extends Record<string, string>>(
  str: string | null | undefined,
  enumObj: T
): ValueOf<T> | undefined {
  if (!str) {
    return undefined;
  }
  return (Object.values(enumObj) as Array<ValueOf<T>>).find(
    (value) => value === str
  );
}

export function strIsEnum<T extends Record<string, string>>(
  str: string,
  enumObj: T
): str is ValueOf<T> {
  const found = mapStrToEnumOrNull(str, enumObj);
  return found != null;
}

export function mapStrToArrayEnum<T extends ReadonlyArray<string>>(
  str: string,
  enumArr: T
): T[number] {
  const found = enumArr.find((value) => value === str) ?? null;
  invariant(found != null, `No enum value matched str=${str}`);
  return found;
}
