import { FOOTPRINT_SNAPSHOT_IDS_DRILLDOWN_PARAM } from '@watershed/shared-universal/footprint/constants';

/**
 * Parses URL-encoded snapshot IDs from a query parameter.
 * Uses URLSearchParams to handle multiple values.
 *
 * @param idsString - A URL query string, or null
 * @returns An array of decoded snapshot ID strings, or null if input is empty/invalid
 */
export function parseSnapshotIdsQueryParam(
  idsString: string | null
): Array<string> | null {
  if (!idsString) {
    return null;
  }

  try {
    const params = new URLSearchParams(idsString);
    const ids = params.getAll(FOOTPRINT_SNAPSHOT_IDS_DRILLDOWN_PARAM);
    const validIds = ids.map((id) => id.trim()).filter(Boolean);
    return validIds.length > 0 ? validIds : null;
  } catch (e) {
    return null;
  }
}

/**
 * Converts an array of snapshot IDs (or single ID) into a URL query string.
 * Uses URLSearchParams for proper encoding of multiple values.
 *
 * @param ids - A single snapshot ID string, array of snapshot IDs, or null
 * @returns A URL query string of IDs, or null if input is empty/invalid
 */
export function encodeSnapshotIdsQueryParam(
  ids: string | Array<string> | null
): string | null {
  if (!ids) {
    return null;
  }

  const idsArray = Array.isArray(ids) ? ids : [ids];
  const validIds = idsArray.map((id) => String(id).trim()).filter(Boolean);

  if (validIds.length === 0) {
    return null;
  }

  const params = new URLSearchParams();
  for (const id of validIds) {
    params.append(FOOTPRINT_SNAPSHOT_IDS_DRILLDOWN_PARAM, id);
  }
  return params.toString();
}
