import Icon, { IconProps } from '../Icon';
const DraftsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="m16.28 6.427-7.987 7.987a1 1 0 0 0-.263.465l-1 4a1 1 0 0 0 1.213 1.212l4-1a1 1 0 0 0 .464-.263l9.5-9.5a3.121 3.121 0 1 0-4.414-4.414l-1.488 1.488-.012.012-.012.013Zm2.927-.099a1.121 1.121 0 0 1 1.586 1.586L20 8.707l-1.586-1.586.793-.793ZM17 8.535l1.586 1.586-7.097 7.097-.695.174a1.01 1.01 0 0 0-.087-.1l-.975-.975.171-.684L17 8.535Z"
      clipRule="evenodd"
    />
    <path d="M1 6.121a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Zm1 3a1 1 0 0 0 0 2h7a1 1 0 1 0 0-2H2Zm-1 5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Z" />
  </Icon>
);
export default DraftsIcon;
