import Icon, { IconProps } from '../Icon';
const ViewIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="m22.919 12.395-.002.003-.003.007-.01.021a9.013 9.013 0 0 1-.153.322c-.105.21-.26.506-.47.857a15.381 15.381 0 0 1-1.923 2.575C18.615 18.057 15.868 20 12 20c-3.868 0-6.615-1.943-8.358-3.82a15.388 15.388 0 0 1-1.923-2.575 13.448 13.448 0 0 1-.591-1.107 5.001 5.001 0 0 1-.033-.072l-.01-.02-.002-.008-.001-.002-.001-.002a1.002 1.002 0 0 1 0-.789l.002-.003.003-.007.009-.021.033-.072a13.448 13.448 0 0 1 .591-1.107A15.386 15.386 0 0 1 3.642 7.82C5.385 5.943 8.132 4 12 4c3.868 0 6.615 1.943 8.358 3.82a15.38 15.38 0 0 1 1.923 2.575 13.466 13.466 0 0 1 .591 1.107l.033.072.01.02.002.008.002.003c.107.252.107.538 0 .79Zm-19.482.187A11.932 11.932 0 0 1 3.112 12a13.388 13.388 0 0 1 1.996-2.82C6.615 7.557 8.868 6 12 6c3.132 0 5.385 1.557 6.892 3.18A13.386 13.386 0 0 1 20.888 12a13.386 13.386 0 0 1-1.996 2.82C17.385 16.442 15.132 18 12 18c-3.132 0-5.385-1.557-6.892-3.18a13.388 13.388 0 0 1-1.67-2.238Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ViewIcon;
