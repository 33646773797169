import getPathWithQuery from '@watershed/shared-universal/utils/getPathWithQuery';
import {
  PreviewDeployName,
  dashboardUrl,
} from '@watershed/shared-universal/utils/helpers';
import {
  ORG_ID_PARAM,
  SESSION_STORAGE_ORG_ID_KEY,
} from '@watershed/shared-universal/utils/pinnedOrganizationUniversalConstants';

export function getPinnedOrganizationId(): string | null {
  return window.sessionStorage.getItem(SESSION_STORAGE_ORG_ID_KEY);
}

export function setPinnedOrganizationId(orgId: string | null): void {
  if (orgId) {
    window.sessionStorage.setItem(SESSION_STORAGE_ORG_ID_KEY, orgId);
  } else {
    window.sessionStorage.removeItem(SESSION_STORAGE_ORG_ID_KEY);
  }
}

export function initPinnedOrganizationId(orgId: string): void {
  if (getPinnedOrganizationId() === null) {
    setPinnedOrganizationId(orgId);
  } else {
    console.warn(`ERROR: Running initOrganizationId with same orgId: ${orgId}`);
  }
}

export function clearPinnedOrganizationId(): void {
  // Clear our session storage dataset and hard refresh to get us out of any bad states.
  window.sessionStorage.removeItem(SESSION_STORAGE_ORG_ID_KEY);
  window.location.reload();
}

// Send a POST request to the server to switch the organization.
// This forces a hard-refresh, clearing some session data.
// Opening a new tab clears our session state. Should only
// be set to false when this is already being called in a setting
// where a new tab is being opened (ex: cmd + k)
export function switchPinnedOrganizationId({
  orgId,
  shouldOpenInNewTab = true,
  redirect,
  reloadInOrgHomeIfLoggedOut,
  previewDeployName,
}: {
  orgId: string;
  shouldOpenInNewTab?: boolean;
  redirect?: string;
  // when set to true, if the user isn't logged in in product login
  // will redirect the user into the target org's homepage--
  // should be used for internal org switching only as this causes a double
  // page reload that's unavoidable
  reloadInOrgHomeIfLoggedOut?: boolean;
  previewDeployName?: PreviewDeployName;
}): void {
  const form = document.createElement('form');
  const queryParams: Record<string, string | undefined> = { redirect };
  if (reloadInOrgHomeIfLoggedOut) {
    queryParams[ORG_ID_PARAM] = orgId;
  }
  const url = dashboardUrl(
    getPathWithQuery(`/auth/switch-organization/${orgId}`, queryParams),
    { previewDeployName }
  );
  form.action = url;
  form.method = 'POST';
  if (shouldOpenInNewTab) {
    form.target = '_blank';
  } else {
    clearPinnedOrganizationId();
  }
  document.body.appendChild(form);
  form.submit();
}
