import { SupportedLocale } from '../constants';
import { useAtomValue } from 'jotai';
import { atomLocale } from './atoms';

/**
 * Where possible, use this hook in the frontend to fetch the user locale.
 * @returns the user's current derived locale
 */
export default function useLocale(): SupportedLocale {
  return useAtomValue(atomLocale);
}
