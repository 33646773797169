import pseudolocale from 'pseudolocale';
import invariant from 'invariant';
import {
  SupportedLocale,
  SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
  LocalizationEnvs,
  LocalizationEnv,
  DEFAULT_LOC_ENV,
  LOCALES,
  LOCALIZATION_ENVS,
} from './constants';

/**
 * Falls back to the default locale if no input locale is provided
 * @param acceptLanguage the accept-language of a request, if it exists
 * @param preferredLocale the preferred locale in the context of the caller,
 *                        if it exists.
 */
export function deriveCurrentLocale(
  acceptLanguage: string | null,
  preferredLocale: string | null
): SupportedLocale {
  const derivedLocale = acceptLanguage ?? preferredLocale ?? DEFAULT_LOCALE;

  if (!isValidLocale(derivedLocale)) {
    return DEFAULT_LOCALE;
  }
  return derivedLocale as SupportedLocale;
}

/**
 * Falls back to the production localization environment
 */
export function deriveLocalizationEnv(env: string | null): LocalizationEnv {
  if (!LocalizationEnvs.includes(env as LocalizationEnv)) {
    return DEFAULT_LOC_ENV;
  }
  return env as LocalizationEnv;
}

export function pseudoLocalize(str: string): string {
  return pseudolocale(str, {
    delimiter: '%&&&%',
    prepend: '',
    append: '',
  });
}

export function localeName(locale: SupportedLocale): string {
  return LOCALES[locale];
}

export function localizationEnvName(env: LocalizationEnv): string {
  return LOCALIZATION_ENVS[env];
}

export function isValidLocale(locale: string): locale is SupportedLocale {
  return SUPPORTED_LOCALES.includes(locale as SupportedLocale);
}

export function assertIsValidLocale(locale: string): void {
  invariant(isValidLocale(locale), 'Unsupported locale', { locale });
}
