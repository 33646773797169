import type { UncapitalizedGridPremiumSlotsComponent } from './DataGrid';
import ArrowUpIcon from '@watershed/icons/components/ArrowUp';
import ArrowDownIcon from '@watershed/icons/components/ArrowDown';
import UpDownIcon from '@watershed/icons/components/UpDown';
import { Stack, type SxProps, type Theme } from '@mui/material';
import MoreIcon from '@watershed/icons/components/More';
import type { IconProps } from '@watershed/icons/Icon';
import PushPinIcon from '@watershed/icons/components/PushPin';
import SumIcon from '@watershed/icons/components/Sum';
import GroupByIcon from '@watershed/icons/components/GroupBy';
import ViewOffIcon from '@watershed/icons/components/ViewOff';
import ListFilterIcon from '@watershed/icons/components/ListFilter';
import CloseIcon from '@watershed/icons/components/Close';
import ColumnIcon from '@watershed/icons/components/Column';

export const CONTAINER_SIZE = 24;
const ICON_SIZE = 16;

/**
 * The buttons are TINY if we let the icon size determine the button size, so this container
 * ensures the buttons are a reasonable size.
 */
function IconContainer({
  Icon,
  size = ICON_SIZE,
  color,
  sx,
}: {
  Icon: (props: IconProps) => JSX.Element;
  size?: number;
  color?: (theme: Theme) => string;
  sx?: Omit<SxProps<Theme>, 'color'>;
}) {
  return (
    <Stack
      sx={{
        width: CONTAINER_SIZE,
        height: CONTAINER_SIZE,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon size={size} color={color} sx={sx} />
    </Stack>
  );
}

function SortedAscendingIcon() {
  return <IconContainer Icon={ArrowUpIcon} />;
}

function SortedDescendingIcon() {
  return <IconContainer Icon={ArrowDownIcon} />;
}

function UnsortedIcon() {
  return <IconContainer Icon={UpDownIcon} color={(t) => t.palette.grey50} />;
}

/**
 * A vertical ... icon for the column menu.
 */
function ColumnMenuIcon() {
  return (
    <IconContainer
      Icon={MoreIcon}
      color={(t) => t.palette.grey50}
      sx={{
        transform: 'rotate(90deg)',
      }}
    />
  );
}

function FilteredIcon() {
  return (
    <IconContainer Icon={ListFilterIcon} color={(t) => t.palette.grey50} />
  );
}

function ColumnMenuFilterIcon() {
  return <IconContainer Icon={ListFilterIcon} />;
}

function PinToLeftIcon() {
  return <IconContainer Icon={PushPinIcon} sx={{ rotate: '45deg' }} />;
}

function PinToRightIcon() {
  return <IconContainer Icon={PushPinIcon} sx={{ rotate: '-45deg' }} />;
}

function AggregateIcon() {
  return <IconContainer Icon={SumIcon} />;
}

function GroupIcon() {
  return <IconContainer Icon={GroupByIcon} />;
}

function HideColumnIcon() {
  return <IconContainer Icon={ViewOffIcon} />;
}

function ManageColumnsIcon() {
  return <IconContainer Icon={ColumnIcon} />;
}

function ClearFilterIcon() {
  return <IconContainer Icon={CloseIcon} />;
}

/**
 * This slots object provides some default icon overrides and will be combined with the normal
 * passed slots from DataGrid use sites. These icons can still be overridden but are discouraged!
 */
export const bigRockIconSlots: Partial<UncapitalizedGridPremiumSlotsComponent> =
  {
    columnSortedAscendingIcon: SortedAscendingIcon,
    columnSortedDescendingIcon: SortedDescendingIcon,
    columnUnsortedIcon: UnsortedIcon,
    columnFilteredIcon: FilteredIcon,
    columnMenuIcon: ColumnMenuIcon,
    columnMenuSortAscendingIcon: SortedAscendingIcon,
    columnMenuSortDescendingIcon: SortedDescendingIcon,
    columnMenuPinLeftIcon: PinToLeftIcon,
    columnMenuPinRightIcon: PinToRightIcon,
    columnMenuFilterIcon: ColumnMenuFilterIcon,
    columnMenuAggregationIcon: AggregateIcon,
    columnMenuGroupIcon: GroupIcon,
    columnMenuUngroupIcon: GroupIcon,
    columnMenuHideIcon: HideColumnIcon,
    columnMenuManageColumnsIcon: ManageColumnsIcon,
    columnMenuClearIcon: ClearFilterIcon,
  };
