/**
 * Asserts that a value is not `null` or `undefined`, converting its type from
 * `T | null | undefined` to `T`. Useful when filtering out null values from a collection.
 *
 * Note: this function is the inverse of `isNullish`.
 *
 * @example
 * ```ts
 * const values: Array<number | null | undefined> = [1, null, 2, undefined, 3];
 * const nonNullValues: Array<number> = values.filter(isNotNullish);
 * // nonNullValues is now [1, 2, 3]
 * ```
 */ export default function isNotNullish<T>(
  x: T | null | undefined
): x is NonNullable<T> {
  return x != null;
}
