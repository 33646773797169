import { NextRouter } from 'next/router';
import wsFetch from './wsFetch';

const TEN_HOURS_MS = 10 * 60 * 60 * 1000;

function findWSVersion(document: HTMLDocument) {
  // Extract the current version from our version meta tag
  const versionMeta = document.querySelector("meta[name='ws:version']");
  return versionMeta?.getAttribute('content') || null;
}

export interface AutoUpdateControllerOptions {
  interval?: number;
  onUpdateRequired?: () => void;
}

export default class AutoUpdateController {
  constructor(private version: string) {}

  private async latestVersion(): Promise<string | null> {
    try {
      const res = await wsFetch('/api/version', { cache: 'no-store' });

      if (!res.ok) {
        return null;
      }

      const payload = await res.json();
      return payload.version ?? null;
    } catch (error) {
      // We never want our autoupdate code to crash the application. Instead we
      // gracefully return a null version indicating we were unable to get a
      // latest version and record an error with sentry.
      return null;
    }
  }

  async isUpdateRequired() {
    const serverVersion = await this.latestVersion();
    console.info(
      `Auto-update: client version: ${this.version}; server version: ${serverVersion}`
    );
    if (serverVersion === null) {
      return false;
    }
    return serverVersion !== this.version;
  }

  static setWantsRefreshOnNextNavigation(router: NextRouter) {
    router.events.on('routeChangeStart', (url) => {
      const incomingPath = new URL(url, window.location.origin).pathname;
      if (incomingPath !== window.location.pathname) {
        window.location.href = url;
      }
    });
  }

  static initialize(
    router: NextRouter,
    options: AutoUpdateControllerOptions = {}
  ) {
    const { interval = TEN_HOURS_MS, onUpdateRequired } = options;
    const version = findWSVersion(window.document);
    if (version === null) {
      console.info('Unable to find a version number, auto-update disabled');
      return;
    } else {
      console.info(`Initializing auto-update with ${version}`);
    }

    // Instantiate a VersionChecker w/ that version
    const checker = new AutoUpdateController(version);
    let numErrors = 0;

    setInterval(async () => {
      try {
        if ((await checker.isUpdateRequired()) === false) {
          return;
        }
        // Run our hook, if we have one registered.
        try {
          if (onUpdateRequired) {
            onUpdateRequired();
          }
          numErrors = 0;
        } catch (error) {
          numErrors += 1;
          if (numErrors > 3) {
            // Sentry.captureException(error);
          }
        }

        this.setWantsRefreshOnNextNavigation(router);
      } catch (error: any) {
        console.info(
          `Unhandled error while auto-updating: ${error?.stack || error}`
        );
      }
    }, interval);

    return checker;
  }
}
