import { useEffect } from 'react';
import { Typography, Container, Box } from '@mui/material';
import WarningIcon from '@watershed/icons/components/Warning';
import { maybeNotifySentry } from '@watershed/shared-frontend/utils/errorUtils';
import DetailedError from '@watershed/shared-frontend/components/DetailedError';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import AdminPageContainer from './AdminPageContainer';
import Button from '@watershed/ui-core/components/Button';
import { doLogout } from '@watershed/shared-frontend/hooks/useLogout';
import NotFoundPage from './NotFoundPage';
import capitalize from 'lodash/capitalize';

// Tell TypeScript that our Error might have more properties than the native
// error.
interface FlexiError extends Error {
  code?: string;
}

// These codes are considered "handled" by the error page: all we need to do is
// inform the user. Errors with these codes will not be sent to Sentry.
const handledCodes = ['FORBIDDEN', 'NOT_FOUND', 'UNAUTHENTICATED'] as const;
type HandledCode = (typeof handledCodes)[number];

function ForbiddenPageBody() {
  return (
    <>
      <Typography variant="h1" paragraph>
        Forbidden
      </Typography>
      <Typography variant="body2">
        Reach out to a member of the team for access.
      </Typography>
      <Box marginTop={2}>
        <Button onClick={() => doLogout()}>Sign out</Button>
      </Box>
    </>
  );
}

function UnexpectedErrorPageBody({ error }: { error: FlexiError }) {
  useEffect(() => {
    maybeNotifySentry(error);
  }, [error]);

  return (
    <Container maxWidth="md" fixed style={{ paddingTop: 32 }}>
      <WarningIcon
        color={(theme) => theme.palette.error.main}
        size={72}
        marginBottom={2}
      />
      <Typography variant="h1" gutterBottom>
        Uh oh! An exception occurred.
      </Typography>
      <Box marginTop={2}>
        <ErrorBox>
          <DetailedError error={error} />
        </ErrorBox>
      </Box>
    </Container>
  );
}

function SessionExpiredPageBody() {
  return (
    <>
      <WarningIcon size={128} marginBottom={1} color="secondary.main" />
      <Typography variant="h1" gutterBottom>
        Your login session has expired
      </Typography>
      <Typography variant="body2">Redirecting you to login…</Typography>
    </>
  );
}

const handledCodePages: {
  [code in HandledCode]: ({ error }: { error: Error }) => JSX.Element;
} = {
  FORBIDDEN: ForbiddenPageBody,
  NOT_FOUND: NotFoundPage,
  UNAUTHENTICATED: SessionExpiredPageBody,
};

export default function ErrorPage({ error }: { error: FlexiError }) {
  const isChunkLoadError = error.name === 'ChunkLoadError';
  useEffect(() => {
    if (isChunkLoadError) {
      // Chunk loading errors happen when there's a long-lived tab that tries to
      // load a `React.lazy()`-loaded page after a new deploy has happened. Perhaps
      // a better way to address this would be to add the AutoUpdateController here
      // like in Dashboard, but this is a good fallback.
      window.location.reload();
    }
  }, [isChunkLoadError]);

  if (isChunkLoadError) {
    return <AdminPageContainer.Loading />;
  }

  const HandledCodePage =
    error.code && handledCodePages[error.code as HandledCode];
  const title =
    (error.code && capitalize(error.code).replace('_', ' ')) ?? 'Error';

  return (
    <AdminPageContainer breadcrumbs={[{ title }]}>
      {HandledCodePage ? (
        <HandledCodePage error={error} />
      ) : (
        <UnexpectedErrorPageBody error={error} />
      )}
    </AdminPageContainer>
  );
}
