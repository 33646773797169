import { NextRouter } from 'next/router';
import {
  MouseEvent as ReactMouseEvent,
  KeyboardEvent as ReactKeyboardEvent,
} from 'react';

export function shouldOpenInNewTab(
  event: MouseEvent | ReactMouseEvent | KeyboardEvent | ReactKeyboardEvent
) {
  if (event.metaKey || event.ctrlKey) {
    return true;
  }
  if ('button' in event && event.button === 1) {
    return true;
  }
  return false;
}

export function navigateToPathOnClick(
  event: MouseEvent | ReactMouseEvent,
  router: NextRouter,
  path: string,
  opts?: NavigateToPathOpts
) {
  return navigateToPathOnEvent(event, router, path, opts);
}

export function navigateToPathOnKeypress(
  event: KeyboardEvent | ReactKeyboardEvent,
  router: NextRouter,
  path: string,
  opts?: NavigateToPathOpts
) {
  return navigateToPathOnEvent(event, router, path, opts);
}

export function navigateToPathOnCallback(
  router: NextRouter,
  path: string,
  opts?: NavigateToPathOpts
) {
  return navigateToPathOnEvent(null, router, path, opts);
}

interface NavigateToPathOpts {
  openInNewTab?: boolean;
}

function navigateToPathOnEvent(
  event:
    | MouseEvent
    | ReactMouseEvent
    | KeyboardEvent
    | ReactKeyboardEvent
    | null,
  router: NextRouter,
  path: string,
  { openInNewTab }: NavigateToPathOpts = {}
) {
  if (
    (event && shouldOpenInNewTab(event)) ||
    path.startsWith('http') ||
    openInNewTab
  ) {
    window.open(path, '_blank', 'noopener');
  } else {
    void router.push(path);
  }
}
