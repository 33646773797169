import z from 'zod';
import { YM, YMInterval } from '../utils/YearMonth';

export const yearMonthSchema = z
  .number()
  .positive()
  .int()
  .transform(YM.fromNumber);

export const ymIntervalShapeSchema = z.object({
  start: yearMonthSchema,
  end: yearMonthSchema,
});
export const ymIntervalSchema = ymIntervalShapeSchema.transform(
  YMInterval.fromObject
);
export const ymIntervalArray = z.array(ymIntervalSchema);

export const ymIntervalsSchema = z.array(ymIntervalSchema);

export const tcfdRiskSchema = z.object({
  id: z.string(),
  title: z.string(),
  userDescription: z.string(),
  kind: z.string(),
  physicalRiskSeverity: z.string().optional(),
});

export type TcfdRisk = z.infer<typeof tcfdRiskSchema>;

export const tcfdImpactSchema = z.object({
  shortTermImpact: z.string().optional(),
  mediumTermImpact: z.string().optional(),
  longTermImpact: z.string().optional(),
});

export const sfdrPaiSchema = z.object({
  key: z.string(),
  parentCategory: z.string().optional(),
  category: z.string(),
  subcategory: z.string(),
  metric: z.string(),
  required: z.boolean(),
  value: z.number().optional(),
});

export type SfdrPai = z.infer<typeof sfdrPaiSchema>;

export type TcfdImpact = z.infer<typeof tcfdImpactSchema>;

export const ymIntervalStringSchema = z
  .string()
  .transform(YMInterval.fromURLString);

export const dateWithFormatOptionsSchema = z.object({
  date: z.date({ coerce: true }),
  options: z
    .object({
      weekday: z.enum(['long', 'short', 'narrow']).optional(),
      year: z.enum(['numeric', '2-digit']).optional(),
      // only allow alphabetical month formats to avoid
      // incorrectness across locales
      month: z.enum(['long', 'short', 'narrow']).optional(),
      day: z.enum(['numeric', '2-digit']).optional(),
      hour: z.enum(['numeric', '2-digit']).optional(),
      minute: z.enum(['numeric', '2-digit']).optional(),
    })
    .optional(),
});

// See note on supporting date strings in the zod documentation:
// https://github.com/colinhacks/zod#dates
export const dateSchema = z.preprocess((arg) => {
  if ((typeof arg === 'string' && arg.length > 0) || arg instanceof Date) {
    return new Date(arg);
  }
  return undefined;
}, z.date().optional());

export const emptyObjectSchema = z.record(z.never());
export type EmptyObject = z.infer<typeof emptyObjectSchema>;

export function isEmptyObject(obj: object): obj is EmptyObject {
  const result = emptyObjectSchema.safeParse(obj);
  return result.success;
}
