import {
  GQBiBaseFieldMeta,
  GQBiDimensionFieldMeta,
  GQBiEmptyStringType,
  GQBiFieldMeta,
  GQBiFieldNumberMeta,
  GQBiFieldStringMeta,
  GQBiKnownDisplayValueMappingType,
  GQBiMeasureFieldMeta,
  GQBiQueryMode,
  GQBiQueryOrderDirection,
  GQBiSavedView,
  GQBiSavedViewQueryContextInput,
  GQBiSavedViewVisibility,
  GQBiChartKind,
  GQBiAggregateMethod,
  GQCreateBiSavedViewInput,
  GQUpdateBiSavedViewInput,
  GQBiFieldDateMeta,
  GQCustomReportsWithSavedViewQuery,
  GQBiNormalizedMeasureInput,
  GQFlags,
  GQBiFilterOperator,
  GQBiQuerySingleMeasureSelectorInput,
  GQBiFieldYearMonthMeta,
} from '../generated/graphql';

import * as z from 'zod';
import { YMInterval } from '../utils/YearMonth';
import { ymIntervalSchema } from '../zodSchemas/utilSchemas';
import { Satisfies } from '../types/Satisfies';
import { ArrayifyUnion } from '../types/ArrayifyUnion';
// eslint-disable-next-line no-restricted-imports
import {
  GQBiSingularPrimitiveNonNullValue,
  GQBiRawSingularType,
  GQBiRawArrayType,
  GQBiRawValueType,
  GQBiDataRow,
  biPrimitiveValueNonNullSchema,
} from '../customScalarGqlTypes';
import { Expression } from '../biV2';
import { Eval } from '../types/Eval';
import { Collapse } from '../types/Collapse';
import { getZodEnumValuesFromEnum } from '../utils/zod';
import omit from 'lodash/omit';

export const BiFieldTypeSchema = z.enum([
  'string',
  'number',
  'boolean',
  'date',
  'yearmonth',
]);

export type BiSingularPrimitiveNonNullValue = GQBiSingularPrimitiveNonNullValue;
export type BiRawSingularType = GQBiRawSingularType | undefined;
export type BiRawArrayType = GQBiRawArrayType;
export type BiRawValueType = GQBiRawValueType | undefined;
export type BiDataRow = GQBiDataRow;

export const BiFieldType = BiFieldTypeSchema.enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BiFieldType = z.infer<typeof BiFieldTypeSchema>;

export type BiFieldTypeToPrimitiveType = Satisfies<
  Record<BiFieldType, BiSingularPrimitiveNonNullValue>,
  {
    [BiFieldType.string]: string;
    [BiFieldType.number]: number;
    [BiFieldType.boolean]: boolean;
    [BiFieldType.date]: number;
    [BiFieldType.yearmonth]: number;
  }
>;

export const FieldMetaTypeConditioningPropertiesConst = [
  'isMultiValue',
  'type',
  'fieldId',
] as const satisfies Array<keyof BiFieldMeta>;
export type FieldMetaTypeConditioningProperties =
  (typeof FieldMetaTypeConditioningPropertiesConst)[number];

type BiMeasureFieldMetaTypeInfo = Omit<
  Pick<BiMeasureFieldMeta, FieldMetaTypeConditioningProperties>,
  'fieldId'
>;

export type BiFieldMetaTypeInfo = Pick<
  BiFieldMeta,
  FieldMetaTypeConditioningProperties
>;

type BiFieldMetaToPrimitiveNonNull<M extends BiFieldMetaTypeInfo> =
  // when passed an 'as const' fieldmeta the isMultiValue key isn't there at all so we need this check because EVERYTHING extends unknown
  // so without this we fall through to the true | false | undefined case which is incorrect. unknown for this is equivalent to only undefined
  unknown extends M['isMultiValue']
    ? BiFieldTypeToPrimitiveType[M['type']]
    : true | false extends M['isMultiValue']
      ?
          | ArrayifyUnion<BiFieldTypeToPrimitiveType[M['type']]>
          | BiFieldTypeToPrimitiveType[M['type']]
      : M['isMultiValue'] extends true
        ? ArrayifyUnion<BiFieldTypeToPrimitiveType[M['type']]>
        : M['isMultiValue'] extends false
          ? BiFieldTypeToPrimitiveType[M['type']]
          : undefined extends M['isMultiValue']
            ? BiFieldTypeToPrimitiveType[M['type']]
            : never;

export type BiFieldMetaToPrimitiveType<M extends BiFieldMetaTypeInfo> =
  | BiFieldMetaToPrimitiveNonNull<M>
  | null
  | undefined;

/**
 * Bi-related gating feature flags.
 *
 * The enum here serves two purposes:
 * - Centralizes the set of feature flags related to BI (easier to track add/remove)
 * - Improves type-checking performance (not using a large enum for this base type)
 */
const BiFieldGatingFlags = [
  GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  GQFlags.UseBartForMonetaryIntensity,
  GQFlags.BiDrilldownSupplierMeasures,
  GQFlags.BiDrilldownFacilitiesMeasures,
  GQFlags.AssetManagerFootprintSnapshotInDevelopment,
] as const;

export const biCommonFieldMetaSchema = z.object({
  fieldId: z.string(),
  displayName: z.string(),
  description: z.string().optional(),
  // controls the grouping of options in the select dropdown
  fieldFamily: z.string().optional(),
  // naming?
  hiddenInFilters: z.boolean().optional(),
  hiddenInGroupBy: z.boolean().optional(),
  // default is Empty
  emptyStringType: z.nativeEnum(GQBiEmptyStringType).optional(),
  isMultiValue: z.boolean().optional(),
  // If undefined, default to false
  isCustomField: z.boolean().optional(),
  /**
   * If undefined, viewable by everyone
   * Define these in {@link BiFieldGatingFlags}
   */
  gatingFeatureFlag: z.enum(BiFieldGatingFlags).optional(),
});

export type BiCommonFieldMeta = z.infer<typeof biCommonFieldMetaSchema>;

const baseListOptionItemSchema = z.object({
  label: z.string().nullable(),
});
export const dimensionListOptionItemSchema = baseListOptionItemSchema.extend({
  value: z.union([z.string(), z.boolean()]).nullable(),
});
export const stringDimensionListOptionItemSchema =
  baseListOptionItemSchema.extend({
    value: z.string().nullable(),
  });

export const biStringFieldMetaSchema = biCommonFieldMetaSchema.merge(
  z.object({
    type: z.literal(BiFieldType.string),
    shouldSentenceCaseValues: z.boolean().optional(),
    shouldNullifyPseudoNulls: z.boolean().optional(),
    knownDisplayValueMappingType: z
      .nativeEnum(GQBiKnownDisplayValueMappingType)
      .optional(),
    staticListOptions: z.array(stringDimensionListOptionItemSchema).optional(),
  })
);
export type BiStringFieldMeta = z.infer<typeof biStringFieldMetaSchema>;

export interface IntensityMetadata {
  numerator: number;
  denominator: number;
}

export interface BiCalculatedValue<T extends BiRawValueType = BiRawValueType> {
  value: T;
  metadata: {
    intensity?: IntensityMetadata;
  };
}

export type NumericValueWithMetadata = BiCalculatedValue<
  number | null | undefined
>;

/**
 * Format of BI timeseries data. We are keeping this in shared-universal because
 * anomaly detection needs it.
 */
export type FormattedTimeSeriesDataRow = {
  measure: NumericValueWithMetadata;
  timeRaw: BiRawValueType;
  groupId: string;
  groupFormatted: string;
};

// We can't use z.nativeEnum(GQBiFilterOperator)
// here because doing the following in ConditionFormFragment.tsx
// does not work:
//   ComponentProps<
//    typeof ZodFormFragment<typeof biQueryFilterStringValuesSchema>
//   >
const biFilterOperatorSchema = z.enum(
  getZodEnumValuesFromEnum(GQBiFilterOperator)
);
export const biQueryFilterSchemaRaw = z.object({
  dimension: z.string(),
  // TODO: technically this is not a mixed type but iteration of
  // the union on the actual array makes typing very annoying
  // TODO: with the addition of operators such as "less than"
  // we should support single values as well instead of having
  // arrays of length 1.
  value: z.array(biPrimitiveValueNonNullSchema.nullable()),
  operator: biFilterOperatorSchema,
});

export interface BiQueryFilterSchema {
  dimension: string;
  value: Array<typeof biPrimitiveValueNonNullSchema._type | null>;
  operator: typeof biFilterOperatorSchema._type;
}
// Need to explicitly type cast this to prevent an error where the inferred type of
// the ReportComponentSchemas node exceeds the maximum length the compiler will
// serialized. This is just a workaround. We need to come up with a more permanent fix.
// The biQueryFilterSchemaRaw version is used in various places to construct derived schemas
export const biQueryFilterSchema: z.ZodSchema<BiQueryFilterSchema> =
  biQueryFilterSchemaRaw;

export const biArrayQueryFilterSchema = z.array(biQueryFilterSchema);

export type BiQueryFilter = z.TypeOf<typeof biQueryFilterSchemaRaw>;
export type BiQueryFilters = z.TypeOf<typeof biArrayQueryFilterSchema>;

/**
 * - sum:
 * Suitable for summable quantities like kg of carbon.
 * Results in sum() sql aggregate function.
 *
 * - avg:
 * Suitable for quantities that must be averaged regardless of the
 * grouping dimensions e.g. employee salary, hourly rate.
 * Results in avg() sql aggregate function.
 *
 * - monthlyAvg:
 * Suitable for "gauges" like employee headcount or building sqft.
 * If 'month' is one of the grouping dimensions, behaves like 'sum'.
 * If 'month' is not one of the dimensions, we will first aggregate
 * with a 'sum' function across all grouping dimensions plus 'month',
 * then we aggregate the resulting summed quantity with an 'avg'
 * function across all grouping dimensions but not month. E.g.
 *
 * with inner as (
 *   select month, country, sum(headcount) as headcount
 *   from employee_data group by month, country
 * )
 * select country, avg(headcount) as headcount
 * from inner group by country
 */
export type AggregateMethod = GQBiAggregateMethod;

export const biNumberFieldMetaSchema = biCommonFieldMetaSchema.merge(
  z.object({
    type: z.literal(BiFieldType.number),
    aggregateType: z.nativeEnum(GQBiAggregateMethod).optional(),

    precision: z.number().optional(),
    disableCommas: z.boolean().optional(),
    includeUnit: z.boolean().optional(),
    shouldFormatToPercent: z.boolean().optional(),

    unit: z.string().optional(),
    unitDimension: z.string().optional(),
    dependentDimensions: z.array(z.string()).optional(),
    aggregatedUnitDimension: z.string().optional(),

    // Optional scaling factor by which underlying data should be divided before being returned.
    divideBy: z.number().optional(),
    unitUserFacing: z.string().optional(),
    isCurrency: z.boolean().optional(),
  })
);
export type BiNumberFieldMeta = z.infer<typeof biNumberFieldMetaSchema>;

export const biBooleanFieldMetaSchema = biCommonFieldMetaSchema.merge(
  z.object({
    type: z.literal(BiFieldType.boolean),
  })
);
export type BiBooleanFieldMeta = z.infer<typeof biBooleanFieldMetaSchema>;

const dateMonthOptions: z.ZodSchema<Intl.DateTimeFormatOptions['month']> =
  z.enum(['numeric', '2-digit', 'long', 'short', 'narrow']);
const dateDayOptions: z.ZodSchema<Intl.DateTimeFormatOptions['day']> = z.enum([
  'numeric',
  '2-digit',
]);
const dateYearOptions: z.ZodSchema<Intl.DateTimeFormatOptions['year']> = z.enum(
  ['numeric', '2-digit']
);
export const dateTimeFormatOptionsSchema = z.object({
  day: dateDayOptions.optional(),
  month: dateMonthOptions.optional(),
  year: dateYearOptions.optional(),
});

export const biDateFieldMetaSchema = biCommonFieldMetaSchema.merge(
  z.object({
    type: z.literal(BiFieldType.date),
    format: dateTimeFormatOptionsSchema.optional(),
  })
);
export type BiDateFieldMeta = z.infer<typeof biDateFieldMetaSchema>;

export const biYearMonthFieldMetaSchema = biCommonFieldMetaSchema.merge(
  z.object({
    type: z.literal(BiFieldType.yearmonth),
    format: dateTimeFormatOptionsSchema.optional(),
  })
);
export type BiYearMonthFieldMeta = z.infer<typeof biYearMonthFieldMetaSchema>;

export const biFieldMeta = z.discriminatedUnion('type', [
  biStringFieldMetaSchema,
  biNumberFieldMetaSchema,
  biBooleanFieldMetaSchema,
  biDateFieldMetaSchema,
  biYearMonthFieldMetaSchema,
]);
export type BiFieldMeta = z.infer<typeof biFieldMeta>;

export const biDimensionFieldMetaSchema = biFieldMeta;
export type BiDimensionFieldMeta = z.infer<typeof biDimensionFieldMetaSchema>;

export const biMeasureFieldMetaSchema = biNumberFieldMetaSchema.extend({
  isMultiValue: z.literal(false).optional(),
  filters: biArrayQueryFilterSchema.optional(),
});
export type BiMeasureFieldMeta = z.infer<typeof biMeasureFieldMetaSchema>;

function transformFieldMetaToBaseGqFieldMeta(
  fieldMeta: BiFieldMeta
): GQBiBaseFieldMeta {
  return {
    fieldId: fieldMeta.fieldId,
    displayName: fieldMeta.displayName,
    fieldFamily: fieldMeta.fieldFamily ?? null,
    description: fieldMeta.description ?? null,
    hiddenInFilters: fieldMeta.hiddenInFilters ?? null,
    hiddenInGroupBy: fieldMeta.hiddenInGroupBy ?? null,
    emptyStringType: fieldMeta.emptyStringType ?? null,
    isMultiValue: fieldMeta.isMultiValue ?? null,
    isCustomField: fieldMeta.isCustomField ?? null,
  };
}

export function transformStandardMetaToGqlFieldMeta(
  fieldMeta: BiFieldMeta
): GQBiFieldMeta {
  switch (fieldMeta.type) {
    case BiFieldType.string:
      return transformBiStringFieldMetaToGq(fieldMeta);
    case BiFieldType.number:
      return transformBiNumberFieldMetaToGq(fieldMeta);
    case BiFieldType.boolean:
      return {
        __typename: 'BiFieldBooleanMeta',
        ...transformFieldMetaToBaseGqFieldMeta(fieldMeta),
      } satisfies GQBiFieldMeta;
    case BiFieldType.date:
      return {
        ...transformBiDateFieldMetaToGq(fieldMeta),
      } satisfies GQBiFieldMeta;
    case BiFieldType.yearmonth:
      return {
        ...transformBiYearMonthFieldMetaToGq(fieldMeta),
      } satisfies GQBiFieldMeta;
  }
}

export function transformBiDimensionFieldMetaToGq(
  fieldMeta: BiDimensionFieldMeta
): GQBiDimensionFieldMeta {
  return transformStandardMetaToGqlFieldMeta(fieldMeta);
}

export function transformBiMeasureFieldMetaToGq(
  fieldMeta: BiMeasureFieldMeta
): GQBiMeasureFieldMeta {
  return transformBiNumberFieldMetaToGq(fieldMeta);
}

export function transformBiStringFieldMetaToGq(
  fieldMeta: BiStringFieldMeta
): GQBiFieldStringMeta {
  return {
    __typename: 'BiFieldStringMeta',
    ...transformFieldMetaToBaseGqFieldMeta(fieldMeta),
    shouldSentenceCaseValues: fieldMeta.shouldSentenceCaseValues ?? false,
    shouldNullifyPseudoNulls: fieldMeta.shouldNullifyPseudoNulls ?? null,
    knownDisplayValueMappingType:
      fieldMeta.knownDisplayValueMappingType ?? null,
    staticListOptions: fieldMeta.staticListOptions ?? null,
  };
}

export function transformBiDateFieldMetaToGq(
  fieldMeta: BiDateFieldMeta
): GQBiFieldDateMeta {
  return {
    __typename: 'BiFieldDateMeta',
    ...transformFieldMetaToBaseGqFieldMeta(fieldMeta),
    format: fieldMeta.format
      ? {
          day: fieldMeta.format.day ?? null,
          month: fieldMeta.format.month ?? null,
          year: fieldMeta.format.year ?? null,
        }
      : null,
  };
}

export function transformBiYearMonthFieldMetaToGq(
  fieldMeta: BiYearMonthFieldMeta
): GQBiFieldYearMonthMeta {
  return {
    __typename: 'BiFieldYearMonthMeta',
    ...transformFieldMetaToBaseGqFieldMeta(fieldMeta),
    format: fieldMeta.format
      ? {
          day: fieldMeta.format.day ?? null,
          month: fieldMeta.format.month ?? null,
          year: fieldMeta.format.year ?? null,
        }
      : null,
  };
}

export function transformBiNumberFieldMetaToGq(
  fieldMeta: BiNumberFieldMeta
): GQBiFieldNumberMeta {
  return {
    __typename: 'BiFieldNumberMeta',
    ...transformFieldMetaToBaseGqFieldMeta(fieldMeta),
    precision: fieldMeta.precision ?? null,
    disableCommas: fieldMeta.disableCommas ?? null,
    includeUnit: fieldMeta.includeUnit ?? null,
    shouldFormatToPercent: fieldMeta.shouldFormatToPercent ?? null,
    unit: fieldMeta.unit ?? null,
    unitDimension: fieldMeta.unitDimension ?? null,
    unitUserFacing: fieldMeta.unitUserFacing ?? null,
    dependentDimensions: fieldMeta.dependentDimensions ?? null,
    aggregatedUnitDimension: fieldMeta.aggregatedUnitDimension ?? null,
    aggregateType: fieldMeta.aggregateType ?? null,
    divideBy: fieldMeta.divideBy ?? null,
    isCurrency: fieldMeta.isCurrency ?? null,
  };
}

export const biMeasureMetaSchema = z.object({
  // TODO: We may need this to be nullable, to indicate all dimensions are supported (e.g. for literal expressions)
  supportedDimensions: z.array(z.string()),
  fieldMeta: biMeasureFieldMetaSchema,
});
export type BiMeasureMeta = z.infer<typeof biMeasureMetaSchema>;

const dimensionComputedTypeSchema = z.enum([
  // this indicates that we will take the numerator value either dimensions or filters and intersect it's dimension values
  // with the supportedDimensions of the normalizingQuery measure
  // for dimensions this is obvious
  // for filters it means removing any filters whose dimension isn't the supportedDimensions of the normalizingQuery measure
  'NumeratorIntersection',
]);

export const biNormalizedMeasureSchema = z.object({
  baseMeasure: z.string(),
  // these filters are in addition to the overall query level filters
  // but only get applied to the numerator of the normalized measure
  baseFilters: biQueryFilterSchema.array().nullish(),
  normalizingQuery: z
    .intersection(
      z.intersection(
        z.object({
          measure: z.string(),
        }),
        z.union([
          z.object({
            dimensions: z.array(z.string()),
          }),
          z.object({
            computedDimensionType: dimensionComputedTypeSchema,
          }),
        ])
      ),
      z.union([
        z.object({
          filters: biQueryFilterSchema.array(),
        }),
        z.object({
          computedFiltersType: dimensionComputedTypeSchema,
        }),
      ])
    )
    .nullish(),
});

export const biArrayNormalizedMeasureSchema = z.array(
  biNormalizedMeasureSchema
);

export type BiNormalizedMeasure = z.TypeOf<typeof biNormalizedMeasureSchema>;

const biCustomMetricRefSchema = z.object({
  id: z.string().nullable(),
  stableId: z.string(),
});

export type BiCustomMetricRef = z.infer<typeof biCustomMetricRefSchema>;

export const biArrayCustomMetricRefsSchema = z.array(biCustomMetricRefSchema);

export const biArrayQueryDimensionSchema = z.array(z.string());
export type BiDimensions = z.infer<typeof biArrayQueryDimensionSchema>;

export const biQueryOrderSchema = z.object({
  dimensionOrMeasure: z.string(),
  direction: z.nativeEnum(GQBiQueryOrderDirection),
});

export type BiOrder = z.infer<typeof biQueryOrderSchema>;

export const biSavedViewQuerySnapshotContext = z.object({
  mode: z.enum([
    GQBiQueryMode.Snapshot,
    GQBiQueryMode.FinanceFootprintSnapshot,
  ]),
  footprintKind: z.string(),
});

export const biSavedViewQueryContextSchema = z
  .discriminatedUnion('mode', [biSavedViewQuerySnapshotContext])
  .nullable();

export type BiSavedViewQueryContextType = z.TypeOf<
  typeof biSavedViewQueryContextSchema
>;

export const biSavedViewSchema = z.object({
  id: z.string(),
  stableId: z.string(),
  orgId: z.string(),
  ownerId: z.string(),
  editorId: z.string(),
  viewVisibility: z.nativeEnum(GQBiSavedViewVisibility),
  editVisibility: z.nativeEnum(GQBiSavedViewVisibility),
  jsonSchemaVersion: z.string(),
  name: z.string(),
  filters: biArrayQueryFilterSchema,
  dimensions: biArrayQueryDimensionSchema,
  normalizedMeasures: biArrayNormalizedMeasureSchema,
  timeIntervals: z.array(ymIntervalSchema),
  customMetrics: biArrayCustomMetricRefsSchema.nullable(),
  order: biQueryOrderSchema.nullable(),
  chartKind: z.nativeEnum(GQBiChartKind),
  mode: z.nativeEnum(GQBiQueryMode),
  lastVersionId: z.string().nullable(),
  searchTerm: z.string().nullable(),
  queryContext: biSavedViewQueryContextSchema,
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  deletedAt: z.date().nullish(),
});
export type BiSavedViewType = z.TypeOf<typeof biSavedViewSchema>;

export enum TemplateIcon {
  auto,
  calculator,
  calendar,
  city,
  cleanPower,
  factory,
  flash,
  friends,
  money,
  stack,
}

export enum SavedViewTemplateIntervalType {
  Full,
  MostRecent,
  TimeComparison,
}
// Note: this is a subset of the full type for FE purposes only,
// excluding certain fields that we don't need on the client side
// and hydrating the normalized measures with their meta
export const biSavedViewPartialSchema = biSavedViewSchema
  .omit({
    // use stableId instead
    id: true,
    orgId: true,
    editorId: true,
    jsonSchemaVersion: true,
    lastVersionId: true,
    createdAt: true,
    updatedAt: true,
  })
  .merge(
    z.object({
      isDraft: z.boolean().optional().default(false),
      intervalType: z.nativeEnum(SavedViewTemplateIntervalType).optional(),
    })
  );

export type BiSavedViewPartialType = z.TypeOf<typeof biSavedViewPartialSchema>;

export type WatershedSavedViewType = Omit<
  BiSavedViewPartialType,
  'stableId' | 'ownerId' | 'isDraft'
> & {
  templateId: string;
  owner: {
    displayName: string;
  };
  icon: TemplateIcon;
  description: string;
  intervalType: SavedViewTemplateIntervalType;
};

function dbQueryContextToGqlType(
  queryContext?: BiSavedViewType['queryContext']
): GQBiSavedViewQueryContextInput | null {
  if (!queryContext?.footprintKind) {
    return null;
  }
  // Note: we only support snapshot queries in saved views for now
  return {
    mode: GQBiQueryMode.Snapshot,
    snapshot: {
      footprintKind: queryContext.footprintKind,
    },
  };
}

/**
 * Converts a database representation of a saved view to a
 * GraphQL representation for an insert operation.
 */
export function toGqlSavedViewCreateFields(
  view: WatershedSavedViewType | BiSavedViewPartialType
): GQCreateBiSavedViewInput {
  return {
    name: view.name,
    timeIntervals: view.timeIntervals,
    chartKind: view.chartKind,
    normalizedMeasures: view.normalizedMeasures,
    customMetrics: view.customMetrics,
    order: view.order,
    searchTerm: view.searchTerm,
    queryContext: dbQueryContextToGqlType(view.queryContext),
    dimensions: view.dimensions,
    filters: view.filters,
    editVisibility: view.editVisibility,
    viewVisibility: view.viewVisibility,
    mode: view.mode,
  };
}

/**
 * Converts a database representation of a saved view to a
 * GraphQL representation for an update operation.
 */
export function toGqlSavedViewUpdateFields(
  view: BiSavedViewPartialType
): GQUpdateBiSavedViewInput {
  return {
    ownerId: view.ownerId,
    stableId: view.stableId,
    ...omit(toGqlSavedViewCreateFields(view), 'mode'),
  };
}

export type CustomReportInfo =
  GQCustomReportsWithSavedViewQuery['customReportsWithBiSavedView'][number];

export function toGqlNormalizedMeasure(
  normalizedMeasure: BiNormalizedMeasure
): GQBiSavedView['normalizedMeasures'][number] {
  return {
    ...normalizedMeasure,
    baseFilters: normalizedMeasure.baseFilters
      ? normalizedMeasure.baseFilters
      : [],
    normalizingQuery: toGqlNormalizingQuery(normalizedMeasure.normalizingQuery),
  };
}

function toGqlNormalizingQuery(
  normalizingQuery: BiNormalizedMeasure['normalizingQuery'] | null
): GQBiSavedView['normalizedMeasures'][number]['normalizingQuery'] | null {
  if (!normalizingQuery) {
    return null;
  }
  return {
    measure: normalizingQuery.measure,
    dimensions:
      'dimensions' in normalizingQuery ? normalizingQuery.dimensions : null,
    filters: 'filters' in normalizingQuery ? normalizingQuery.filters : null,
    computedDimensionType:
      'computedDimensionType' in normalizingQuery
        ? normalizingQuery.computedDimensionType
        : null,
    computedFiltersType:
      'computedFiltersType' in normalizingQuery
        ? normalizingQuery.computedFiltersType
        : null,
  };
}

export function gqlQueryContextToDbType(
  queryContext?: GQBiSavedViewQueryContextInput | null
): BiSavedViewType['queryContext'] {
  return queryContext?.snapshot
    ? {
        // Note: we only support snapshot queries in saved views for now
        mode: GQBiQueryMode.Snapshot,
        footprintKind: queryContext.snapshot.footprintKind,
      }
    : null;
}

const biMetricTypesSchema = z.enum(['Normalized', 'Custom']);

/** Strings representing expression types */
type BiMetricType = z.infer<typeof biMetricTypesSchema>;

/** Enum of expression types */
export const BiMetricTypes: { [T in BiMetricType]: T } =
  biMetricTypesSchema.enum;

export type BiMetricNormalized = {
  type: typeof BiMetricTypes.Normalized;
  meta: BiMeasureMeta;
  normalizedMeasure: BiNormalizedMeasure;
};

export type BiMetricCustom = {
  type: typeof BiMetricTypes.Custom;
  meta: BiMeasureMeta;
  customMetric: BiCustomMetric;
};

export type BiMetric = BiMetricNormalized | BiMetricCustom;

export interface BiQueryComponents {
  filters: Array<BiQueryFilter>;
  dimensions: BiDimensions;
  metrics: Array<BiMetric>;
  timeIntervals: Array<YMInterval>;
  order?: Array<BiOrder> | undefined;
}

export const biMetadataSchema = z.object({
  timeInterval: ymIntervalSchema.optional(),
  measures: biMeasureMetaSchema.array(),
  dimensions: z.array(z.object({ fieldMeta: biDimensionFieldMetaSchema })),
});
export type BiMetadata = z.infer<typeof biMetadataSchema>;

export interface BiSupportedMeasure {
  measure: string;
  supportedDenominators: Array<string>;
}

/** Model representing a custom metric */
export type BiCustomMetric = {
  id?: string;
  stableId?: string;
  name: string;
  expression: Expression;
};

/**
 * Creates keys for use with hash and local storage atoms
 */
export const BiQueryComponentHashKey = {
  filters: biLocalStorageKey('filters'),
  dimensions: biLocalStorageKey('dimensions'),
  intervals: biLocalStorageKey('intervals'),
  measures: biLocalStorageKey('measures'),
  customMetrics: biLocalStorageKey('customMetrics'),
  view: biLocalStorageKey('view'),
  footprintKind: biLocalStorageKey('footprintKind'),
  showCharts: biLocalStorageKey('showCharts'),
  chartKind: biLocalStorageKey('chartKind'),
  showTable: biLocalStorageKey('showTable'),
  tableKind: biLocalStorageKey('tableKind'),
  timeSeriesDimension: biLocalStorageKey('timeSeriesDimension'),
} as const;

/**
 * Creates namespaced keys for use with local storage atoms
 */
function biLocalStorageKey(prefix: string): string {
  return `bi:${prefix}`;
}

type FindByProp<T extends Array<any>, K extends keyof any, V> = T extends Array<
  infer U
>
  ? U extends {
      [key in K]: V;
    }
    ? U
    : never
  : never;

export type TypedBiRow<M extends Array<BiFieldMetaTypeInfo>> = Eval<{
  [FieldId in M[number]['fieldId']]: // note: weirdly unioning with null and undefined makes TS have a harder type expanded this type with Eval
  // leaving the union inline here lets it expand rows down to things like {tco2 : number| null | undefined} which is not what we want
  BiFieldMetaToPrimitiveNonNull<
    // it would be nice to make this type more efficient
    // as is it's O(n^2) where n is number of field metas passed, which should be relatively small N but still
    // i tried iterating the indices of the tuple and directly mapping to fieldId and meta but TS loses the per key type of some reason when doing that
    // see: https://www.typescriptlang.org/play/?#code/C4TwDgpgBAqgdgSwPZygXigb2ALigcgC8IAnJfAGigEMo8BGAXwB8tcCUJKoAjOqAEyMA3AFgAUBNCQoASTgATBAGMIAZwA8AFSgQAHsAiK1NOCADaAXQB86KAFE9ygDYBXBRA0BrCCCQAzKC0qHz9A6jMrazFJcWloAEFtXQMjBRN4ZDgou0wJKAKocwBpKARUeSVVTS1bahMtEqgAMig4VwBbHlJLc3xgfEtLPGKYkQkpcGgAIWT9Q2NYRBQcjDzxQqLS8rlFFXVtOpNiy35iiUYJuKmoBIE7JPNsfiJScipaBkYqZ7x8Tm4fDwQhsEgA9AAqCQ8JDAAAWUFCJgA5hBgLxYQjivg1ABCXFQZEkagkXGTGR3XL5QrEMh4ABEAAZ6VBWPT6PSYptOAzmayoOzORcJBCweSZvcMLMnui-rT3jR+EwfrKOHAuFQgYJGKDxJDxVBppKsNSCozeUKNoV6AyOWMRWCgA
    FindByProp<M, 'fieldId', FieldId>
  > | null;
}>;

export type NormalizedMeasuresToMetas<
  M extends Array<GQBiNormalizedMeasureInput>,
> = {
  [K in M[number]['fieldId']]: Collapse<
    {
      fieldId: K;
    } & BiMeasureFieldMetaTypeInfo
  >;
}[M[number]['fieldId']];

// type A = TypedBiRow<
//   [
//     {
//       fieldId: 'tco2eQuantity';
//       type: 'number';
//       isMultiValue?: false;
//     },
//     {
//       fieldId: 'tco2eLocationQuantity';
//       type: 'number';
//       isMultiValue?: false;
//     },
//   ]
// >;

/**
 * Used for data lineage. Can be removed once footprintKind is removed from this GQL type.
 */
export type BiSingleMeasureSelector = Omit<
  GQBiQuerySingleMeasureSelectorInput,
  'footprintKind'
>;

export const CATEGORICAL_FILTER_OPERATORS = [
  GQBiFilterOperator.In,
  GQBiFilterOperator.NotIn,
] as const;

export const NUMERICAL_FILTER_OPERATORS = [
  GQBiFilterOperator.Equal,
  GQBiFilterOperator.NotEqual,
  GQBiFilterOperator.GreaterThan,
  GQBiFilterOperator.GreaterThanOrEqual,
  GQBiFilterOperator.LessThan,
  GQBiFilterOperator.LessThanOrEqual,
] as const;
export type NumericalFilterOperator =
  (typeof NUMERICAL_FILTER_OPERATORS)[number];

export function isNumericalFilterOperator(
  o: GQBiFilterOperator
): o is NumericalFilterOperator {
  return NUMERICAL_FILTER_OPERATORS.includes(o as any);
}
