import Icon, { IconProps } from '../Icon';
const ChevronUpIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M11.293 8.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1-1.414 1.414L12 10.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ChevronUpIcon;
