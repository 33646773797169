import Icon, { IconProps } from '../Icon';
const SearchIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M16.618 18.032a9 9 0 1 1 1.414-1.414l3.675 3.675a1 1 0 0 1-1.414 1.414l-3.675-3.675ZM4 11a7 7 0 1 1 12.041 4.856.998.998 0 0 0-.185.185A7 7 0 0 1 4 11Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default SearchIcon;
