import { Stack, Typography } from '@mui/material';
import { CircularProgressWithTelemetry } from './CircularProgressWithTelemetry';

export default function LoadingPage({
  loadingMessage,
}: {
  loadingMessage?: string | null;
}) {
  return (
    <Stack
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingY={6}
      gap={2}
    >
      {/* TODO (DI-12679): All LoadingPages should pass in a unique eventName */}
      <CircularProgressWithTelemetry eventName="loadingPage" />
      {loadingMessage && (
        <Typography variant="body2">{loadingMessage}</Typography>
      )}
    </Stack>
  );
}
