import { Box, SxProps, Theme } from '@mui/material';
import { mixinSx, systemStyleObjectType } from '@watershed/style/styleUtils';

const sxHelpers = {
  grid: systemStyleObjectType({
    display: 'grid',
    gridTemplateColumns: 'minmax(200px, 0.33fr) 1fr',
  }),
  fullSpan: systemStyleObjectType({
    gridColumnStart: 1,
    gridColumnEnd: 'span 2',
  }),
  borderTop: systemStyleObjectType({
    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
  }),
};

/**
 * See also: DefinitionList.
 */
export default function SectionedDefinitionList({
  sx,
  fullBleed,
  children,
}: React.PropsWithChildren<{ sx?: SxProps<Theme>; fullBleed?: boolean }>) {
  return (
    <Box
      component="dl"
      sx={mixinSx(
        {
          ...sxHelpers.grid,
          backgroundColor: (theme) => theme.palette.background.paper,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: '4px',
          margin: 0,
          '& dt': (theme) => ({
            ...theme.typography.h3,
          }),
          '& dd': {
            margin: 0,
          },
          '& dt, & dd': {
            ...sxHelpers.borderTop,
            padding: 2,
          },
          '& dt:first-of-type, & dd:first-of-type': {
            borderTopWidth: 0,
          },
          // For the semantically-valid edge cases of adjacent dt/dd pairs, make
          // it look good still.
          '& dd+dd': {
            gridColumnStart: 2,
          },
          '& dt:has(+dt)': {
            gridColumnEnd: 'span 2',
          },
        },
        fullBleed && {
          borderRadius: 0,
          borderWidth: 0,
        },
        sx
      )}
    >
      {children}
    </Box>
  );
}

SectionedDefinitionList.sxHelpers = sxHelpers;
