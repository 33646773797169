// Helpers and wrappers for writing result kinds to the PLR

import {
  getAllResultKindDefinitions,
  ResultKindDefinition,
} from '@watershed/shared-universal/emissionsModel/resultKindDefinitions';

// Type that wraps any result kind that has configured SQL columns
type PlrEnabledResultKind = Omit<
  ResultKindDefinition,
  'sqlQuantityField' | 'sqlNamespacedVariableField'
> & {
  sqlQuantityField: string;
  sqlNamespacedVariableField: string;
};

// Returns true if resultKind has SQL columns defined, i.e. sqlQuantityField and sqlNamespacedVariableField.
export function isPlrEnabledResultKind(
  resultKind: ResultKindDefinition
): resultKind is PlrEnabledResultKind {
  if (resultKind.sqlQuantityField && resultKind.sqlNamespacedVariableField) {
    return true;
  }
  return false;
}

/**
 * Returns all result kinds that have SQL columns defined, i.e. sqlQuantityField and sqlNamespacedVariableField.
 */
export function getAllPlrEnabledResultKindDefinitions(): Array<PlrEnabledResultKind> {
  // Gotta loop through and save to appease the typescript overlords
  const plrKinds: Array<PlrEnabledResultKind> = [];
  getAllResultKindDefinitions().forEach((def) => {
    if (isPlrEnabledResultKind(def)) {
      plrKinds.push(def);
    }
  });

  return plrKinds;
}
