import { useEffect } from 'react';

import debug from '@watershed/shared-universal/utils/debug';
import { perfume } from './initializeFrontendPerformanceMonitoring';

/*
 * A global set of running timer names to ensure that we don't start
 * a 2nd timer for the same event as a running one.
 * The value type in this map is passed to mixpanel as additional properties
 * when tracking events.
 */
const globalTimers = new Map<string, Object>();

/**
 * Starts a timer for a latency measurement. Note - we currently only support
 * 'Action' event types here.
 *
 * Only one timer can be running for a given eventName at a time, if a 2nd
 * timer for the same eventName is started, it will be ignored.
 *
 * @param eventName Name of the event which will be reported to Honeycomb. Note
 *  that this argument will be passed to Analytics.getEventName which
 *  automatically adds a prefix / suffix based on callsite and event type.
 */
export function startTimer(eventName: string) {
  // If there's already a timer running for this event, do nothing.
  if (globalTimers.has(eventName)) {
    return;
  }

  globalTimers.set(eventName, {});
  perfume?.start(eventName);
  debug(() => {
    performance.mark(`${eventName}.start`);
  }, '$measurePerf');
}

/**
 * Stops a timer for a latency measurement, and reports the measurement to
 * Honeycomb. Note - we currently only support 'Action' event types here.
 *
 * If the timer for the given eventName is not running, this function will
 * do nothing. This is intentional behaviour, with events that result in
 * UI changes in a very different part of the app (perhaps they open a modal
 * or trigger navigation) it's easier to just optimistically stop the timer
 * rather than having UI-level code keep track of whether it's running or not.
 *
 * @param eventName Name of the event which will be reported to Honeycomb. Note
 *  that this argument will be passed to Analytics.getEventName which
 *  automatically adds a prefix / suffix based on callsite and event type. This
 *  eventName must match the eventName passed to startTimer in order for the
 *  measurement to be correctly recorded.
 * @param attribution A custom attribution object which will be passed to Perfume
 *  as additional properties when ending the timer.
 */
export function stopTimer(
  eventName: string,
  options?: {
    attribution?: object;
  }
) {
  // If there's no timer running for this event, do nothing.
  if (!globalTimers.has(eventName)) {
    return;
  }

  perfume?.end(eventName, options?.attribution);
  globalTimers.delete(eventName);
  debug(() => {
    performance.mark(`${eventName}.stop`);
    try {
      const m = performance.measure(
        eventName,
        `${eventName}.start`,
        `${eventName}.stop`
      );
      performance.clearMarks(`${eventName}.start`);
      performance.clearMarks(`${eventName}.stop`);
      console.info(`[LATENCY] Event '${eventName}' took ${m.duration}ms`);
    } catch (err) {
      console.error(
        'Attempted to measure with missing mark. This will happen frequently when running react in strict mode, see https://legacy.reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects.',
        err
      );
    }
  }, '$measurePerf');
}

/**
 *
 * @param eventName Name of the event which will be reported to Honeycomb. Note
 *  that this argument will be passed to Analytics.getEventName which
 *  automatically adds a prefix / suffix based on callsite and event type. Note
 *  that if there is not an in-flight timer using this eventName then calling
 *  this function will be a no-op.
 * @param properties Passed to mixpanel as additional properties when track
 *  event is called.
 * @returns
 */
export function addPropertiesToTimer(eventName: string, properties: Object) {
  // If there's no timer running for this event, do nothing.
  if (!globalTimers.has(eventName)) {
    return;
  }

  const currentProperties = globalTimers.get(eventName) ?? {};
  globalTimers.set(eventName, {
    ...currentProperties,
    ...properties,
  });
}

/**
 * Logs how long a component is mounted, up to a max duration. The event will be logged
 * to Honeycomb with a prefix based on callsite and event type.
 *
 * If the loading indicator is shown for longer than the max duration, it will
 * stop the timer and log the duration to Honeycomb immediately, to ensure that
 * "infinite" spinners are captured.
 *
 * @param loadingEventName Will be used to namespace the timer and identify it in Honeycomb.
 * @param options.maxDurationMs After this duration, the loading event will be logged to Honeycomb, even if the loading indicator is still visible.
 */
const CLIENT_SIDE_LOADING_EVENT_NAME = 'loading';
const CLIENT_SIDE_LOADING_MAX_DURATION_MS = 10000;
export function useMeasureClientSideLoading(
  loadingEventName: string,
  {
    maxDurationMs = CLIENT_SIDE_LOADING_MAX_DURATION_MS,
  }: { maxDurationMs?: number } = {}
) {
  const eventName = `${loadingEventName}.${CLIENT_SIDE_LOADING_EVENT_NAME}`;
  useEffect(() => {
    startTimer(eventName);

    const timeout = setTimeout(() => stopTimer(eventName), maxDurationMs);

    return () => {
      stopTimer(eventName);
      clearTimeout(timeout);
    };
  }, [eventName, loadingEventName, maxDurationMs]);
}
