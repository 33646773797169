import { SupportedLocale } from '../constants';
import { deriveCurrentLocale } from '../utils';

/**
 * Derives the locale for callers in the frontend. Similar to `deriveCurrentLocale`
 * except that it looks up the browser navigator language as an additional fallback
 * value
 */
export default function deriveDefaultLocaleForFrontend(
  preferredLocale: string | null
): SupportedLocale {
  // TODO: Enable this when it's safe to do so
  // const derivedLocaleFromFrontend =
  //   preferredLocale ??
  //   (typeof navigator !== 'undefined' ? navigator.language : null);
  return deriveCurrentLocale(null, preferredLocale ?? null);
}
