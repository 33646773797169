import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import ChevronRightIcon from '@watershed/icons/components/ChevronRight';
import { IconProps } from '@watershed/icons/Icon';
import { mixinSx } from '@watershed/style/styleUtils';

export type CollapseIconProps = {
  direction?: 'down' | 'right';
} & IconProps;

export default function CollapseIcon({
  direction = 'down',
  ...props
}: CollapseIconProps) {
  const Icon = direction === 'down' ? ChevronDownIcon : ChevronRightIcon;
  const rotate = direction === 'down' ? '-180deg' : '90deg';
  return (
    <Icon
      {...props}
      sx={mixinSx(
        {
          transition: 'transform 150ms ease',
          '[aria-expanded="true"] &': {
            transform: `rotate(${rotate})`,
          },
        },
        props.sx
      )}
    />
  );
}
