export default function isExternalUrl(url: string, origin = window.origin) {
  return (
    url.replace(origin, '').startsWith('http') || url.startsWith('mailto:')
  );
}

export function isApiRouteUrl(url: string, origin = window.origin) {
  return (
    url.replace(origin, '').startsWith('/auth/') ||
    url.replace(origin, '').startsWith('/api/')
  );
}
