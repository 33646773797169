import { List, TextField, Stack } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { useState, useEffect, useRef, useMemo } from 'react';

import { useRouter } from 'next/router';
import useKey from 'react-use/lib/useKey';
import clamp from 'lodash/clamp';
import BlankSlate from '@watershed/ui-core/components/BlankSlate';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import SearchIcon from '@watershed/icons/components/Search';

import { navigateToPathOnKeypress } from '@watershed/ui-core/utils/NavigationUtils';
import sortBy from 'lodash/sortBy';
import {
  GQGetOrgSpecificQuickSwitcherDataQuery,
  GQGetOrgSpecificQuickSwitcherDataQueryVariables,
  GQOrgFieldsForAdminNavFragment,
} from '@watershed/shared-universal/generated/graphql';
import { InvertedIndexBuilder } from '@watershed/shared-universal/utils/InvertedIndex';
import {
  GetOrgSpecificQuickSwitcherDataDocument,
  useGetNonOrgSpecificQuickSwitcherDataQuery,
} from '@watershed/shared-frontend/generated/urql';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import useKeydown from '@watershed/shared-frontend/hooks/useKeydown';
import { useClient } from 'urql';
import DuckHuntDuck from '../DuckHuntDuck';
import { getQuickSwitcherItems } from './getQuickSwitcherItems';
import { QuickSwitcherItem } from './QuickSwitcherItem';
import { useAdminContext } from '../AdminContext';

// For performance, limit the maximum number of results we show
const MAX_ITEMS = 100;

/**
 * Exported so other components can preload this data.
 */
export function useLoadQuickSwitcherData(): {
  nonOrgSpecificQuickSwitcherData: ReturnType<
    typeof useGetNonOrgSpecificQuickSwitcherDataQuery
  >[0];
} {
  const [nonOrgSpecificQuickSwitcherData] =
    useGetNonOrgSpecificQuickSwitcherDataQuery();
  return { nonOrgSpecificQuickSwitcherData };
}

export default function QuickSwitcher({
  orgs,
  activeOrgId,
  onClose,
  prefilledContent,
}: {
  orgs: Array<GQOrgFieldsForAdminNavFragment>;
  activeOrgId: string | null;
  onClose: () => void;
  prefilledContent: string | null;
}) {
  const { nonOrgSpecificQuickSwitcherData } = useLoadQuickSwitcherData();
  const { activeWatershedEmployee } = useAdminContext();
  const urqlClient = useClient();
  const router = useRouter();
  const input = useRef<null | HTMLInputElement>(null);
  const [search, setSearch] = useState(prefilledContent ?? '');
  const allItems = useMemo(() => {
    return getQuickSwitcherItems({
      orgs,
      orgId: activeOrgId,
      nonOrgSpecificQuickSwitcherData: nonOrgSpecificQuickSwitcherData.data,
      activeWatershedEmployee,
    });
  }, [
    orgs,
    activeOrgId,
    nonOrgSpecificQuickSwitcherData,
    activeWatershedEmployee,
  ]);
  const [currentItems, setCurrentItems] = useState(allItems);
  const [subMenuTitle, setSubMenuTitle] = useState('');

  // autofocus is lovely, but React's Strict Mode means that it doesn't work in
  // localhost. here's a useful workaround - use a ref to focus the input field
  // on mount:
  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  }, []);

  /**
   * Fetches org-specific data on demand
   */
  async function getOrgSpecificData(orgId: string) {
    return urqlClient
      .query<
        GQGetOrgSpecificQuickSwitcherDataQuery,
        GQGetOrgSpecificQuickSwitcherDataQueryVariables
      >(GetOrgSpecificQuickSwitcherDataDocument, {
        orgId,
      })
      .toPromise();
  }

  // This feels weird, but if allItems updates (which it will from the network call), we actually want to reset whatever the current items are to re-show the top level list
  useEffect(() => {
    setCurrentItems(allItems);
    setSubMenuTitle('');
  }, [allItems]);

  const hasSubMenuOpen = !!subMenuTitle;

  const index = useMemo(() => {
    const builder = new InvertedIndexBuilder<(typeof allItems)[0]>();
    for (const item of currentItems) {
      const additionalSearchTerms = item.additionalSearchTerms || [];
      // Sometimes parens or underscores or dashes or apostrophes are used in titles, but we want to match with or without them
      const cleanerTitle = item.title
        .replace(/[\(\)._-]/g, ' ')
        .replace(/\'/g, '');
      builder.add(
        item,
        [item.title, cleanerTitle, ...additionalSearchTerms].join(' ')
      );
    }
    return builder.compile();
  }, [currentItems]);

  const items = useMemo(() => {
    const results = Array.from(index.findAllPrefix(search));

    // Other patterns might match beyond the items we have but
    // don't duplicate what we have in results
    results.push(
      ...allItems.filter((item) => {
        if (!item.matchesSearchTerm || results.includes(item)) {
          return false;
        }
        return item.matchesSearchTerm(search);
      })
    );

    const sorted = sortBy(results, (item) => -item.rank);
    return sorted.slice(0, MAX_ITEMS);
  }, [search, index, allItems]);

  const [focusI, setFocusI] = useState(0);
  useEffect(() => {
    setFocusI(0);
  }, [search]);
  useKey(
    'ArrowUp',
    (e) => {
      setFocusI((i) => clamp(i - 1, 0, items.length - 1));
      e.preventDefault();
    },
    {},
    [items]
  );
  useKey(
    'ArrowDown',
    (e) => {
      e.preventDefault();
      setFocusI((i) => clamp(i + 1, 0, items.length - 1));
    },
    {},
    [items]
  );
  useKey(
    'Enter',
    (e) => {
      e.preventDefault();
      if (items.length > 0) {
        const item = items[focusI];
        const url = item.url;
        const resolvedUrl = typeof url === 'string' ? url : url(search);
        navigateToPathOnKeypress(e, router, resolvedUrl, {
          openInNewTab: item.newTab,
        });
        onClose();
      }
    },
    {},
    [items, focusI]
  );

  // Sets child items on tab, if existent
  useKey(
    'Tab',
    async (e) => {
      e.preventDefault();
      if (items.length === 0) {
        return;
      }
      const childItems = items[focusI].childItems;
      if (childItems && childItems.length > 0) {
        if (!Array.isArray(childItems)) {
          setCurrentItems(await childItems(getOrgSpecificData));
        } else {
          setCurrentItems(childItems);
        }
        setSearch(''); // clear and get ready for more input
        setSubMenuTitle(items[focusI].subMenuTitle ?? '');
      }
    },
    {},
    [items, focusI]
  );

  // Resets back to top level items - uses this hook because its parent does too and it needs to be used the same way
  useKeydown((e) => {
    if (e.key !== 'Escape' || items.length === 0) {
      return;
    }
    e.preventDefault();
    if (hasSubMenuOpen) {
      setCurrentItems(allItems);
      setSubMenuTitle(''); // back to top level even if we were multiple layers deep
    }
  });

  return (
    <Dialog
      disableEscapeKeyDown={hasSubMenuOpen}
      onClose={onClose}
      transitionDuration={0}
      style={{
        height: 'clamp(128px, 50vh, 360px)',
      }}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'hsl(0deg 0% 100% / 75%)' },
        },
      }}
      header={{
        sx: (theme) => ({
          backgroundColor: theme.palette.common.white,
          marginLeft: 0, // with padding, aligns header with content below
          paddingLeft: 1, // with margin, aligns header with content below
        }),
        titleGridItemSx: {
          flexGrow: 1, // allows the text field input to take up the entire header space
        },
        title: `Jump to${subMenuTitle ? ` ${subMenuTitle}` : ''}…`,
        renderTitle: (title) => (
          <TextField
            aria-label={title}
            placeholder={title}
            inputProps={{ ref: input }}
            value={search}
            autoFocus
            onChange={(e) => {
              setSearch(e.target.value);
              setFocusI(0);
            }}
            sx={(theme) => ({
              flexGrow: 1,
              '& input': {
                ...theme.typography.h2,
                width: '100%',
                flexGrow: 1,
                fontSize: 18,
              },
            })}
          />
        ),
      }}
    >
      <Stack>
        {isFetchingOrStale(nonOrgSpecificQuickSwitcherData) ? (
          <Stack alignItems="center" paddingY={6}>
            <CircularProgress />
          </Stack>
        ) : (
          <List disablePadding sx={{ margin: -2 }}>
            {items.map((item, i) => (
              <QuickSwitcherItem
                title={item.title}
                url={typeof item.url === 'string' ? item.url : item.url(search)}
                key={item.url + item.title}
                index={i}
                focus={focusI === i}
                onClose={onClose}
                hasChildItems={!!item.childItems?.length}
                newTab={item.newTab}
              />
            ))}
            <DuckHuntDuck duckName="Golden Duck" />
          </List>
        )}
      </Stack>
      {items.length === 0 && (
        <BlankSlate
          icon={SearchIcon}
          title="No links found"
          style={{ height: '100%' }}
        />
      )}
    </Dialog>
  );
}
