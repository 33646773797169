import { NextRouter } from 'next/router';
import { QueryStringOrObj } from '@watershed/shared-universal/utils/queryParamUtils';

export function pushQuery(
  router: NextRouter,
  query: QueryStringOrObj,
  shallow?: boolean
): void {
  // TODO: URGENT Please fix this by await-ing or void-ing this line.
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  router.push(
    {
      pathname: router.pathname,
      query,
      hash: window.location.hash,
    },
    undefined,
    {
      shallow,
    }
  );
}

export function replaceQuery(
  router: NextRouter,
  query: QueryStringOrObj,
  shallow?: boolean
): void {
  // TODO: URGENT Please fix this by await-ing or void-ing this line.
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  router.replace(
    {
      pathname: router.pathname,
      query,
      hash: window.location.hash,
    },
    undefined,
    {
      scroll: false,
      shallow,
    }
  );
}
