import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

export class ForbiddenError extends WatershedError {
  status = 403;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('FORBIDDEN', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError);
    }
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(ForbiddenError);
  static wrapAndRethrow = makeRethrower(ForbiddenError);
}
