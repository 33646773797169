/**
 *
 * @param strs an array of strings to become both the key and value of enum-like reference object
 * @returns an enum-like reference object
 * @deprecated use z.enum instead see example
 * @example
 * const myEnumSchema = z.enum(['value', 'other']);
 * const MyEnum  = myEnumSchema.enum;
 * type MyEnum = z.infer<typeof myEnumSchema>;
 */
export const makeBetterEnum = <S extends string>(
  ...strs: Array<S>
): { [k in S]: k } => {
  const enumConst = strs.reduce(
    (accum, str) => {
      accum[str] = str;
      return accum;
    },
    {} as { [k in S]: k }
  );

  return enumConst;
};
