import { Tooltip, TooltipProps } from '@mui/material';

export type MaybeTooltipProps = Omit<TooltipProps, 'title'> & {
  title?: TooltipProps['title'] | null;
  dontWrap?: boolean;
};

/**
 * Prefer using Tooltip with empty string over this. MUI will not show
 * a tooltip if it has an empty string message.
 */
export default function MaybeTooltip({
  title,
  children,
  dontWrap = false,
  ...props
}: MaybeTooltipProps): JSX.Element {
  if (title) {
    return (
      <Tooltip title={title} {...props}>
        {/* If the child is disabled, we must wrap the component in a span.
        Since there is no consistent way to derive this attribute value 
        from the `children` component, we will always wrap it in a span unless you manually override this */}
        {dontWrap ? children : <span>{children}</span>}
      </Tooltip>
    );
  }
  return <>{children}</>;
}
