import { forwardRef } from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@watershed/ui-core/components/CircularProgress';
import { useMeasureClientSideLoading } from '../utils/frontendPerformanceMonitoring/measureLatencyEvent';

export const CircularProgressWithTelemetry = forwardRef(
  function CircularProgressWithTelemetry(
    {
      eventName,
      maxDurationMs,
      ...props
    }: CircularProgressProps & {
      // This name can be used to identify the loading events in Honeycomb.
      eventName: string;
      maxDurationMs?: number;
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) {
    useMeasureClientSideLoading(eventName, {
      maxDurationMs,
    });
    return <CircularProgress {...props} ref={ref} />;
  }
);
