import invariant from 'invariant';

// Round `n` to `places` decimal places
export default function round10(n: number, places: number = 0): number {
  invariant(places >= 0, 'places must be non-negative');
  invariant(Number.isInteger(places), 'places must be an integer');

  const powerOfTen = Math.pow(10, places);
  const out = Math.round((n + Number.EPSILON) * powerOfTen) / powerOfTen;
  return out;
}
