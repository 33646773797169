import uniq from 'lodash/uniq';
import invariant from 'invariant';

/**
 * Returns a new array with all the the toSort array's items, sorted in the same
 * order as the order array. Any items that aren't in the order array are sorted
 * the way Array.prototype.sort() works (alphabetical ascending, numeric
 * ascending).
 */
export function sortArrayByArray<T>(
  input: Array<T>,
  order: ReadonlyArray<any>
): Array<T> {
  return [
    ...input
      .filter((n) => order.includes(n))
      .sort((a, b) => order.indexOf(a) - order.indexOf(b)),
    ...input.filter((n) => !order.includes(n)).sort(),
  ];
}

// Given a bunch of arrays, return a sorted list of unique values.
export function getSortedUniqueValues<T>(...arr: Array<Array<T>>): Array<T> {
  const flattened = Array<T>().concat(...arr);
  flattened.sort();
  return uniq(flattened);
}

// Get the most common value in an array. If there's a tie, returns the first
// value to reach the the maximum frequency.
export function getArrayMode<T extends number | string>(
  input: Array<T>
): T | undefined {
  const counts: Map<T, number> = new Map<T, number>();
  let mode: T | undefined;
  let maxCount = 0;
  for (const cur of input) {
    const newCount = (counts.get(cur) ?? 0) + 1;
    counts.set(cur, newCount);
    if (newCount > maxCount) {
      mode = cur;
      maxCount = newCount;
    }
  }
  return mode;
}

export function* getArrayOfArraysIndexIterator(
  arrayLengths: Array<number>
): Generator<Array<number>> {
  const indices = new Array(arrayLengths.length).fill(0);

  invariant(
    arrayLengths.length > 0,
    'Please provide at least one array length to iterate over'
  );

  for (const length of arrayLengths) {
    invariant(length > 0, 'All arrays must have positive lengths');
  }

  do {
    yield indices;
    indices[indices.length - 1]++;
    for (let i = indices.length - 1; i > 0; i--) {
      if (indices[i] === arrayLengths[i]) {
        indices[i] = 0;
        indices[i - 1]++;
      }
    }
  } while (indices[0] < arrayLengths[0]);
}

/**
 * Splits an array into two arrays at the given index.
 * @param input the array to split
 * @param index the index at which to split the array
 * @returns an array containing two arrays, the first containing the elements
 *  before the given index, and the second containing the elements after the
 *  given index. Works with negative indices.
 */

export function arraySplitAt<T>(
  input: ReadonlyArray<T>,
  index: number
): [Array<T>, Array<T>] {
  return [input.slice(0, index), input.slice(index)];
}

export function arrayToggle<T>(input: Array<T>, item: T): Array<T> {
  if (input.includes(item)) {
    return input.filter((n) => n !== item);
  } else {
    return [...input, item];
  }
}

/**
 * Finds element in array and shifts it up or down by a given amount.
 * If the shift would move the element out of bounds, it is moved as far
 * as it can be moved in the given direction. For example, shifting an element
 * up by 2 when it is at index 1 will shift it to index 0.
 * @param input array to shift elements in
 * @param item item to shift
 * @param shiftDirection number of positions to shift iem. positive to shift up, negative to shift down
 * @returns new array with element shifted up or down by given amount. If item doesn't exist, original array is returned.
 */
export function shiftArrayElements<T>(
  input: Array<T>,
  item: T,
  shiftDirection: number
): Array<T> {
  const index = input.indexOf(item);
  if (index === -1) {
    return input;
  }
  const newIndex = index + shiftDirection;
  if (newIndex < 0) {
    return [item, ...input.filter((n) => n !== item)];
  } else if (newIndex >= input.length) {
    return [...input.filter((n) => n !== item), item];
  } else {
    const result = [...input];
    result.splice(index, 1);
    result.splice(newIndex, 0, item);
    return result;
  }
}

/**
 * Creates an array form 0 to n - 1 and maps it with callbackFn.
 */
export function mapN<T>(n: number, callbackFn: (i: number) => T): Array<T> {
  return Array.from({ length: n }, (_, index) => index).map(callbackFn);
}

/**
 * Typesafe way to filter out nullish values from an array
 */
export function filterNullish<T>(array: Array<T | null | undefined>): Array<T> {
  return array.flatMap((item) => (!!item ? [item] : []));
}
/**
 * Creates a new array that adds an item to an existing array, or creates a new
 * array if the input array is nullish.
 */
export function concatOrCreate<T>(
  source: Array<T> | undefined | null,
  addition: Array<T>
): Array<T> {
  if (!!source) {
    return [...source, ...addition];
  }
  return [...addition];
}

export function replaceOrDeleteElement<T>(
  arr: Array<NonNullable<T>>,
  index: number,
  value: NonNullable<T> | null
): Array<NonNullable<T>> {
  if (value === null) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }
  return [...arr.slice(0, index), value, ...arr.slice(index + 1)];
}

// Return an array of start and end indices
// for which an array of length arrayLength would be chunked
// by chunkSize
// start inclusive and end inclusive
export function getChunkIndicesForArrayLength(
  arrayLength: number,
  chunkSize: number
): Array<[number, number]> {
  const chunks: Array<[number, number]> = [];
  for (let start = 0; start < arrayLength; start += chunkSize) {
    chunks.push([start, Math.min(start + chunkSize, arrayLength)]);
  }
  return chunks;
}
