import * as Sentry from '@sentry/browser';
import { shouldSendErrorToSentry } from '@watershed/errors/ErrorRegistry';
import {
  makeCustomErrorInvariant,
  CustomErrorInvariant,
} from '@watershed/errors/WatershedError';
import { plainObjectFromError } from '@watershed/shared-universal/utils/serializableError';
import { ForbiddenError } from '@watershed/errors/ForbiddenError';
import { t } from '@lingui/core/macro';

interface IGqlError {
  path?: ReadonlyArray<string | number>;
  extensions?: {
    code?: string;
    originalError?: {
      message: string;
      stack: string;
    };
  };
}

interface IUrqlCombinedError extends Error {
  graphQLErrors: Array<IGqlError>;
  networkError?: Error;
  response?: Response;
}

type AnyError = any;

/**
 * WsError represents an error received from an upstream Watershed API, like
 * the GQL API.
 */
export class WsError extends Error {
  readonly name = 'WsError';
  readonly code: string;
  readonly originalError?: Error;
  readonly cause?: AnyError;
  /**
   * This field will have a more user-friendly message than the `message` field,
   * and should be used if showing this error to a user in a form or error state
   * somewhere.
   */
  readonly customerFacingMessage: string;

  constructor({
    code,
    message,
    customerFacingMessage,
    originalError,
  }: {
    code: string;
    message: string;
    customerFacingMessage: string;
    originalError?: Error;
  }) {
    super(message);
    this.code = code;
    this.originalError = originalError;
    this.cause = originalError;
    this.customerFacingMessage = customerFacingMessage;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, WsError);
    }
    Object.setPrototypeOf(this, WsError.prototype);
  }

  /**
   * Creates a WsError representation of an urql CombinedError.
   */
  static fromUrqlCombinedError(combinedError: IUrqlCombinedError): WsError {
    // User is no longer logged-in, due to inactivity or session being revoked.
    if (combinedError.response?.status === 401) {
      return new WsError({
        code: 'UNAUTHENTICATED',
        message: 'Unauthenticated',
        customerFacingMessage: t`You are not logged in. Please log in and try again.`,
        originalError: combinedError.networkError,
      });
    }

    if (combinedError.networkError) {
      return new WsError({
        code: 'NETWORK',
        message:
          // `networkError.message` is typed as `string`, but is often empty
          // string; provide a dev-friendly default for easier debugging.
          combinedError.networkError.message ||
          'GraphQL request failed due to network error; could be transient user web browser network issue, or could be symptom of our API not being reachable, if we see many of these.',
        customerFacingMessage: t`There was a problem with the network. Please check your connection and try again.`,
        originalError: combinedError.networkError,
      });
    }

    let code = 'UNEXPECTED';
    let message = combinedError.message;
    let customerFacingMessage = t`An unexpected error occurred. Please try again later.`;
    // Note - if there are multiple graphQLErrors present, we only use the last
    // one returned in the WsError.
    // TODO: consider surfacing all errors, not just the last one.
    const gqlError = (combinedError?.graphQLErrors ?? []).at(-1);
    if (gqlError?.extensions?.originalError) {
      message = gqlError.extensions.originalError.message;
    }
    if (gqlError?.extensions?.code) {
      code = gqlError.extensions?.code;
      const renderedPath = (gqlError.path ?? []).join('.');
      customerFacingMessage = message;
      message = `${message}, code=${code}, path=${renderedPath}`;
    }
    return new WsError({
      code,
      message,
      customerFacingMessage,
      originalError: combinedError,
    });
  }
}

export class InvalidApiResponseError extends Error {
  readonly name = 'InvalidApiResponseError';
  readonly code = 'INVALID_API_RESPONSE';
  readonly cause?: AnyError;
  constructor(message?: string, cause?: AnyError) {
    super(message);
    this.cause = cause;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidApiResponseError);
    }
    Object.setPrototypeOf(this, InvalidApiResponseError.prototype);
  }
  static invariant: CustomErrorInvariant = makeCustomErrorInvariant(
    InvalidApiResponseError
  );
}

/**
 * Given any error, determines whether or not to send it to Sentry, and if so
 * sends it. Any error without a `code` property will be sent. When the error
 * has a `code`, its checked against the ErrorRegistry to determine if, for that
 * code, the browser should notify Sentry or not.
 *
 * This function is used by the app's top-level error boundary component, but
 * can also be used by nested components if they think they maybe should report
 * an error to Sentry before they catch and swallow it (e.g. to render an error
 * message locally instead of letting it bubble up all the way to the top-level
 * error boundary).
 */
export function maybeNotifySentry(
  error: any,
  level?: Sentry.SeverityLevel,
  errorDetails?: { [key: string]: unknown }
): void {
  let castError = error;
  if (
    // Duck-type for urql's CombinedError.
    error.graphQLErrors !== undefined
  ) {
    // If we got here, it means some React code threw urql's CombinedError
    // directly instead of casting it. Cast it here to an WsError.
    castError = WsError.fromUrqlCombinedError(error);
  }
  if (shouldSendErrorToSentry('Browser', castError.code)) {
    if (castError.originalError) {
      Sentry.setContext(
        'wrapped_error',
        plainObjectFromError(castError.originalError)
      );
    }
    Sentry.captureException(castError, { level, extra: errorDetails });
  }
}

/**
 * Returns the `data` property from a GQL result in scenarios where it is optional,
 * most often due to loading. The useful aspect of this function is the thoughtful
 * treatment of errors. If the error was sent to Sentry on the server, it will not
 * be sent as a duplicate here.
 *
 * Note: This is most useful for non-page load queries (e.g. supporting dialogs)!
 */
export function getGqlResultData<T>(gqlResult: {
  data?: T;
  error?: IUrqlCombinedError;
}): T | undefined {
  if (gqlResult.error) {
    throw WsError.fromUrqlCombinedError(gqlResult.error);
  }
  return gqlResult.data;
}

/**
 * Returns the `data` property from a GQL result, or throws an appropriate
 * error. After using this, the caller will know that the returned data is not
 * undefined: no need for an invariant check to assert the type.
 *
 * Note: This is most useful for all page load queries!
 */
export function getGqlResultDataBang<T>(
  gqlResult: {
    data?: T;
    error?: IUrqlCombinedError;
  },
  {
    // If `true`, the result data must have `userHasPermission: true`, or a
    // ForbiddenError is thrown.
    requireUserHasPermission = false,
  }: { requireUserHasPermission?: boolean } = {}
): T {
  if (gqlResult.error) {
    throw WsError.fromUrqlCombinedError(gqlResult.error);
  }
  InvalidApiResponseError.invariant(
    gqlResult.data != null,
    'GQL result missing expected "data" property'
  );
  if (requireUserHasPermission) {
    ForbiddenError.invariant(
      (gqlResult.data as any).userHasPermission != null,
      `Missing data.userHasPermission in GQL result's data`
    );
    ForbiddenError.invariant(
      (gqlResult.data as any).userHasPermission === true,
      `ForbiddenError: userHasPermission=false in GQL result's data`
    );
  }
  return gqlResult.data;
}

export function isGqlResultPaused<T>(gqlResult: {
  fetching: boolean;
  stale: boolean;
  data?: T;
  error?: IUrqlCombinedError;
}): boolean {
  return (
    !gqlResult.fetching &&
    !gqlResult.stale &&
    !gqlResult.data &&
    !gqlResult.error
  );
}
/**
 * Handles errors from GQL result if needed, does not return anything.
 * If the error was sent to Sentry on the server, it will not
 * be sent as a duplicate here.
 */
export function maybeThrowForGqlError<T>(gqlResult: {
  data?: T;
  error?: IUrqlCombinedError;
}) {
  if (gqlResult.error) {
    throw WsError.fromUrqlCombinedError(gqlResult.error);
  }
}
