import { datadogLogs } from '@datadog/browser-logs';

if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
) {
  const clientToken = process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN;
  if (clientToken) {
    datadogLogs.init({
      clientToken,
      site: 'datadoghq.com',
      forwardConsoleLogs: 'all',
      forwardReports: 'all',
      service: process.env.NEXT_PUBLIC_APPLICATION,
      version: process.env.NEXT_PUBLIC_CLIENT_VERSION,
      env: process.env.NODE_ENV,
      sampleRate: 100,
      beforeSend: (event) => {
        if (event.message.includes('X-Goog-Signature')) {
          const message = event.message.replace(
            /(X-Goog-Signature=)[^&]*/,
            '$1<redacted>'
          );

          event.message = message;
        }
      },
    });
  }
}
