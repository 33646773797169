import { useDialogConfirm } from '@watershed/ui-core/components/DialogConfirm';
import { useLingui } from '@lingui/react/macro';

import { routeForLogin } from '@watershed/shared-universal/dashboardRoutes';
import LogOutIcon from '@watershed/icons/components/LogOut';
import Mixpanel from 'mixpanel-browser';
import { IdbKeyValueStore } from '../utils/indexedDb';
import { captureException } from '@sentry/browser';
import wsFetch from '../utils/wsFetch';

export const doLogout = async () => {
  const response = await wsFetch(`/auth/logout`, {
    method: 'POST',
  });

  // Remove all session storage (e.g. activeOrganization)
  window.sessionStorage.clear();

  // Wipe IndexedDB to get rid of any data we cached there
  try {
    await IdbKeyValueStore.clear();
  } catch (error) {
    captureException(error);
    console.warn('There was an issue clearing IndexedDB', error);
  }

  // Reset Mixpanel's distinct_id to avoid merging profiles
  try {
    Mixpanel.reset();
  } catch (e) {
    // ignore error if Mixpanel isn't initialized
  }

  if (response.ok) {
    // We want to "hard redirect" here so that our graphcache is cleared for us
    window.location.replace(routeForLogin());
  }
};

export default function useLogout() {
  const openConfirm = useDialogConfirm();
  const { t } = useLingui();

  const handleLogout = async () => {
    const isConfirmed = await openConfirm({
      title: t`Sign out`,
      message: t`Are you sure you want to sign out?`,
      confirmText: t`Sign out`,
      confirmIcon: <LogOutIcon />,
    });
    if (isConfirmed) {
      await doLogout();
    }
  };

  return handleLogout;
}
