import { Tooltip, Typography } from '@mui/material';
import { useElementIsOverflowing } from '@watershed/shared-frontend/hooks/useElementIsOverflowing';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

/**
 * This creates a typography component that is wrapped in a tooltip. The tooltip
 * only shows up though when the text is too small for the screen, and it also
 * shows proper UI for truncation, with ... at the end if the text is
 * truncating. Use this instead of <Typography> if your text may truncate or its
 * width is variable (like in a DataGrid).
 *
 * Omitting children will use the `tooltip` for the text instead of separate children.
 */
export default function TruncatingTypographyWithTooltip({
  children,
  tooltip,
  tooltipProps,
  ...props
}: {
  children?: ReactNode;
  component?: React.ElementType;
  tooltipProps?: Partial<ComponentPropsWithoutRef<typeof Tooltip>>;
  tooltip: NonNullable<ReactNode>;
} & Omit<ComponentPropsWithoutRef<typeof Typography>, 'noWrap'>) {
  const [ref, showTooltip] = useElementIsOverflowing();

  return (
    <Tooltip
      title={tooltip}
      disableHoverListener={!showTooltip}
      {...tooltipProps}
    >
      <Typography noWrap ref={ref} {...props}>
        {children === null || children === undefined ? tooltip : children}
      </Typography>
    </Tooltip>
  );
}
