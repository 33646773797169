import * as z from 'zod';
import { ArrayifyUnion } from './types/ArrayifyUnion';
import { CategoricalColumn, Columnar, PlainColumn } from './columnar';

export const filterValueNonNullSchema = z.union([
  z.string(),
  z.boolean(),
  z.number(),
]);
// NOTE: we use FilterValue in generated graphql types so don't rename it without also changing it in graphql.config.ts
// also be aware the changing this type (or the schema it comes from! filterValueNonNullSchema) will change both the input and output type of filters in generated graphql types
export type FilterValue = z.infer<typeof filterValueNonNullSchema>;
export const biPrimitiveValueNonNullSchema = filterValueNonNullSchema;
export type GQBiSingularPrimitiveNonNullValue = z.infer<
  typeof biPrimitiveValueNonNullSchema
>;

const biPrimitiveValueSchema = biPrimitiveValueNonNullSchema.nullable();
const biPrimitiveValueArraySchema: z.ZodType<GQBiRawArrayType> = z.union([
  z.array(z.string()),
  z.array(z.number()),
  z.array(z.boolean()),
]);
const biRawValueSchema = z.union([
  biPrimitiveValueSchema.optional(),
  biPrimitiveValueArraySchema,
]);
export const biDataRowSchema = z.record(biRawValueSchema);

const plainBiColumnSchema: z.ZodType<PlainColumn<GQBiRawValueType>> = z.object({
  type: z.literal('plain'),
  data: z.array(biRawValueSchema),
});
const categoricalBiColumnSchema: z.ZodType<
  CategoricalColumn<GQBiRawValueType>
> = z.object({
  type: z.literal('categorical'),
  categories: z.array(biRawValueSchema),
  data: z.array(z.number()),
});
export const biDataResultSchema: z.ZodType<GQBiDataResult> = z.record(
  z.union([plainBiColumnSchema, categoricalBiColumnSchema])
);

export type GQBiRawSingularType = z.infer<typeof biPrimitiveValueSchema>;

export type GQBiRawArrayType = ArrayifyUnion<GQBiSingularPrimitiveNonNullValue>;

export type GQBiRawValueType = z.infer<typeof biRawValueSchema>;

export type GQBiDataRow = z.infer<typeof biDataRowSchema>;
export type GQBiDataResult = Columnar<GQBiDataRow>;
