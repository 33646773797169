import { atomWithStorage } from 'jotai/utils';
import deriveDefaultLocaleForFrontend from './deriveDefaultLocaleForFrontend';
import { LocalizationEnv, DEFAULT_LOC_ENV } from '../constants';

export const atomLocale = atomWithStorage(
  'locale',
  deriveDefaultLocaleForFrontend(null), // Individual apps should override this with their own default locale
  /*= storage */ undefined, // default is localstorage
  { getOnInit: true }
);

export const atomLocalizationEnv = atomWithStorage<LocalizationEnv>(
  'localizationEnv',
  DEFAULT_LOC_ENV,
  /*= storage */ undefined, // default is localstorage
  { getOnInit: true }
);
